/* istanbul ignore file */
import type { FC } from 'react';
import { Multistep, useFormState, useMultistepApi } from 'informed';
import { useTranslation } from 'react-i18next';

import { Button } from '@ui-modules';
import { DriveFormSteps, DriveFormState, TripType } from '@common/interfaces';

import '../DriveForm.styles.scss';

interface StepProps {}

const ApprovingSharedDriveStep: FC<StepProps> = () => {
  const { t } = useTranslation();
  const { previous } = useMultistepApi();
  const { values } = useFormState() as unknown as { values: DriveFormState };

  const { returnTrip, tripInfo } = values;

  const isRoundTrip = tripInfo?.typeOfTrip?.value === TripType.RoundTrip;
  const isReturnTripFilled =
    returnTrip?.driveList?.length &&
    returnTrip.driveList[0].pickupDate &&
    returnTrip.driveList[0].dropoffDate &&
    returnTrip.driveList[0].pickupLocation &&
    returnTrip.driveList[0].dropoffLocation;

  return (
    <Multistep.Step step={DriveFormSteps.ApprovingSharedDrive}>
      <div
        className="shared-drive-approving"
        data-testid="planner-drive-form-approving-shared-driver"
      >
        <div className="warning">
          <p>{t('planner.multiWarn1')}.</p>
          <p>
            {t('common.click')} <b>{t('common.yes')}</b> {t('planner.multiWarn2')}{' '}
            {t('common.click')} <b>{t('common.no')}</b> {t('planner.multiWarn3')}.
          </p>
        </div>

        <div className="buttons">
          <Button
            data-testid="planner-drive-form-btn-approving-shared-driver-no"
            text={t('common.no')}
            variant="danger"
            onClick={previous}
          />

          {isRoundTrip && !isReturnTripFilled ? (
            <Button
              data-testid="planner-drive-form-btn-approving-shared-driver-yes"
              text={t('common.yes')}
              variant="submit"
              onClick={previous}
            />
          ) : (
            <Button
              data-testid="planner-drive-form-btn-approving-shared-driver-yes"
              text={t('common.yes')}
              type="submit"
              variant="submit"
            />
          )}
        </div>
      </div>
    </Multistep.Step>
  );
};

export default ApprovingSharedDriveStep;
