/* istanbul ignore file */
export const FilterDictionary: { [x: string]: string } = {
  drivers: 'driver',
  is_signed: 'signed',
  passenger_agencies: 'agency pax',
  transfer_types: 'transfer type',
  vehicle_agencies: 'agency vehicle',
  vehicle_types: 'vehicle type',
  vehicles: 'vehicle',
  is_ridesharing: 'ridesharing',
  is_carpooled: 'carpooled',
};

export const Purpose: { [x: string]: string } = {
  '2in_town_meeting': 'Mission or Meeting',
  '31cargo_docs': 'Cargo or Documents',
  '32work_bank': 'Bank',
  '33training_workshop': 'Training or Workshop',
  '3in_town_other_work': 'Other Work-related',
  '4in_town_home': 'Home or Hotel',
  '5in_town_clinic': 'UN Clinic',
  '6in_town_other_offwork': 'Personal Off-Work',
};

export const RequestingUnits: { [x: string]: string } = {
  '01unicef_advocacy': 'Advocacy',
  '01admin': 'Admin',
  '02audit': 'Audit',
  '03aviation': 'Aviation',
  '04budget': 'Budget and Programming',
  '05cbt': 'CBT',
  '06communication': 'Communication',
  '07emergency': 'Emergency Preparedness',
  '08facilities': 'Engineering and Facilities',
  '09ethics': 'Ethics',
  '10evaluation': 'Evaluation',
  '11finance': 'Finance',
  '12gender': 'Gender',
  '13hr': 'HR',
  '14innovation': 'Innovation',
  '15it': 'IT',
  '16legal': 'Legal',
  '17logistics': 'Logistics',
  '18medical': 'Medical and Wellness',
  '19mne': 'Monitoring and Evaluation',
  '20nutrition': 'Nutrition',
  '21ombdusman': 'Ombdusman',
  '22operations': 'Operations',
  '23partnership': 'Partnership and Governance',
  '24planning': 'Planning',
  '25programme': 'Policy and Programme',
  '26procurement': 'Procurement',
  '27school_feeding': 'School Feeding',
  '28security': 'Security',
  '29supply_chain': 'Unions',
  '30unions': 'Unions',
};
