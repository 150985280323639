/* istanbul ignore file */
import { IDropdownOption } from '@ui-modules/types';

export const bloodGroupOptions: IDropdownOption[] = [
  { value: '0', label: 'A+' },
  { value: '1', label: 'A-' },
  { value: '2', label: 'B+' },
  { value: '3', label: 'B-' },
  { value: '4', label: 'O+' },
  { value: '5', label: 'O-' },
  { value: '6', label: 'AB+' },
  { value: '7', label: 'AB-' },
];
