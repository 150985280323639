import { type FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { type AxiosError } from 'axios';
import { ArrayField, Form, type FormApi, type FormState } from 'informed';
import { Checkbox } from '@mantine/core';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';

import { Button } from '@ui-modules';
import { IDropdownOption } from '@ui-modules/types';
import { DateField, TextField } from '@ui-modules/informed';
import type {
  ApiList,
  TravelRequestCountriesListApi,
  TravelRequestItineraries,
  TravelRequestTripEditFormState,
  TravelRequestTripList,
  TravelRequestTripUpdate,
} from '@common/interfaces';
import { useRepository } from '@context';
import { Confirmation, Modal, Panel, Switch } from '@components';
import { LocalStorageKeys } from '@common/constants';
import { StatusCode } from '@common/types';
import { Serializer, formatDateString, notEmptyValidator } from '@common/utils';

import CityField from './CityField';
import CountryField from './CountryField';
import './styles.scss';

const TravelRequestTripEdit: FC = () => {
  const navigate = useNavigate();
  const { travelRequestRepository } = useRepository();
  const { state } = useLocation();
  const { t } = useTranslation();

  const [accepted, setAccepted] = useState<boolean>(true);
  const [countries, setCountries] = useState<IDropdownOption[]>([]);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [isSaveProcess, setSaveProcess] = useState<boolean>(false);
  const [isCitiesLoading, setCitiesLoading] = useState<boolean>(false);
  const [submittedData, setSubmittedData] = useState<TravelRequestTripUpdate | null>(null);
  const [travelRequestSubmitted, setTravelRequestSubmitted] = useState<boolean>(false);

  const formRef = useRef<FormApi>();
  const tripData = (state as any)?.tripData as TravelRequestTripList;
  const userEmail = localStorage.getItem(LocalStorageKeys.USER_EMAIL);

  const navBack = useCallback(() => navigate('/undss-travel-request/trip-list/'), [navigate]);

  const { isLoading: isCountriesLoading } = useQuery(
    'get-countries',
    () => travelRequestRepository.getCountries({ limit: 300 }),
    {
      onSuccess: (data: ApiList<TravelRequestCountriesListApi>) => {
        setCountries(data.results.map(Serializer.formatTravelRequestCountry));
      },
    },
  );

  const { mutate: updateTrip, isLoading: isUpdateTripLoading } = useMutation<
    unknown,
    AxiosError,
    TravelRequestTripUpdate
  >('update-trip', (data) => travelRequestRepository.updateTrip(data), {
    onSuccess: (status) => {
      if (status === StatusCode.SuccessCreated) {
        if (!isSaveProcess) {
          setTravelRequestSubmitted(true);
        } else {
          setSaveProcess(false);
          navBack();
        }
      }
    },
    onError: (error: any) => {
      if (error.message) {
        toast.error(error.response.data || t('common.errorMsgDefault'));
      }
    },
  });

  const processSave = async () => {
    formRef.current?.submitForm();

    const formState = formRef.current?.getFormState() as FormState;

    if (formState.errors && Object.keys(formState.errors).length) {
      return toast.error(t('travelRequest.msgSubmitError'));
    }

    const formData = formState.values as unknown as TravelRequestTripEditFormState;

    await setSaveProcess(true);

    updateTrip({
      booking: tripData.id,
      itinerary_set: formData.trips.map(Serializer.mapTravelRequestTripForm),
      profile_primary_email: userEmail!.replace(/"/g, ''),
      state: 'not_submitted',
    });
  };

  const processSubmit = () => {
    formRef.current?.submitForm();

    const formState = formRef.current?.getFormState() as FormState;

    if (formState.errors && Object.keys(formState.errors).length) {
      return toast.error(t('travelRequest.msgSubmitError'));
    }

    const formData = formState.values as unknown as TravelRequestTripEditFormState;

    setSubmittedData({
      booking: tripData.id,
      itinerary_set: formData.trips.map(Serializer.mapTravelRequestTripForm),
      profile_primary_email: userEmail!.replace(/"/g, ''),
      state: 'submitted',
    });

    setShowConfirmation(true);
  };

  useEffect(() => {
    if (!tripData) {
      navBack();
    }
  }, [navBack, tripData]);

  const initFormData: Record<string, unknown> = {
    trips: tripData?.trips?.map((item: TravelRequestItineraries) => ({
      arrivalCity: item.dropoffCityOption,
      arrivalDate: parseISO(item.dropoffDate),
      arrivalTime: item.dropoffTime,
      bookingInfo: `${item.pickupTown} - ${formatDateString(
        item.pickupDate,
        'yyyy-MM-dd',
        'dd MMM yyyy',
      )}`,
      departureCity: item.pickupCityOption,
      departureDate: parseISO(item.pickupDate),
      departureTime: item.pickupTime,
      id: item.id,
      inTransit: item.inTransit,
      officialBusiness: item.officialBusiness,
      purpose: item.purpose,
      remarks: item.remarks,
      vehicleModel: item.vehicleModel,
      vehiclePlateNumber: item.vehiclePlateNumber,
    })),
  };

  const isLoading = isCitiesLoading || isCountriesLoading || isUpdateTripLoading;

  return (
    <section className="hbh-container travel-request-trip-edit-page">
      {!travelRequestSubmitted ? (
        <Panel
          className="form-panel"
          isLoading={isLoading}
          title={t('travelRequest.tripEditTitle')}
        >
          <Form className="form-trip-edit" formApiRef={formRef} initialValues={initFormData}>
            <ArrayField name="trips">
              {() => (
                <ArrayField.Items>
                  {({ index, name }) => {
                    const { trips } = tripData;

                    return (
                      <Fragment key={name}>
                        {trips.length > 1 && <h3>Trip #{index + 1}</h3>}

                        <TextField name="arrivalTime" type="hidden" />
                        <TextField name="departureTime" type="hidden" />
                        <TextField name="id" type="hidden" />

                        <section className="fieldset">
                          <div className="field">
                            <TextField disabled label="Transportation Booking" name="bookingInfo" />
                          </div>

                          <div className="field field-switcher">
                            <span>
                              <Switch
                                defaultChecked={trips[index].officialBusiness}
                                label="Official Travel"
                                name="officialBusiness"
                              />
                            </span>
                            <span>
                              <Switch
                                defaultChecked={trips[index].inTransit}
                                label="In Transit"
                                name="inTransit"
                              />
                            </span>
                          </div>

                          <div className="field">
                            <TextField disabled label="Vehicle Model" name="vehicleModel" />
                          </div>

                          <div className="field">
                            <TextField
                              disabled
                              label="Vehicle Plate Number"
                              name="vehiclePlateNumber"
                            />
                          </div>

                          {countries.length > 0 && (
                            <>
                              <CountryField
                                defaultValue={countries.find(
                                  (i) => i.value === trips[index].countryCode,
                                )}
                                label="Departure Country *"
                                name="departureCountry"
                                options={countries}
                              />

                              <CountryField
                                defaultValue={countries.find(
                                  (i) => i.value === trips[index].countryCode,
                                )}
                                idx={index}
                                label="Arrival Country *"
                                name="arrivalCountry"
                                options={countries}
                              />
                            </>
                          )}

                          <CityField
                            idx={index}
                            label="Departure City *"
                            name="departureCity"
                            onFetching={setCitiesLoading}
                          />

                          <CityField
                            idx={index}
                            label="Arrival City *"
                            name="arrivalCity"
                            onFetching={setCitiesLoading}
                          />

                          <div className="field">
                            <DateField
                              disabled
                              label="Departure Date *"
                              name="departureDate"
                              showMonthDropdown
                              showYearDropdown
                              validate={notEmptyValidator}
                            />
                          </div>

                          <div className="field">
                            <DateField
                              disabled
                              label="Arrival Date *"
                              name="arrivalDate"
                              showMonthDropdown
                              showYearDropdown
                              validate={notEmptyValidator}
                            />
                          </div>

                          <div className="field">
                            <TextField
                              className="input"
                              label="Itinerary Purpose *"
                              name="purpose"
                              validate={notEmptyValidator}
                            />
                          </div>

                          <div className="field">
                            <TextField
                              className="input"
                              label="Remarks *"
                              name="remarks"
                              validate={notEmptyValidator}
                            />
                          </div>
                        </section>
                      </Fragment>
                    );
                  }}
                </ArrayField.Items>
              )}
            </ArrayField>

            <section className="terms">
              <Checkbox
                checked={accepted}
                label={
                  <>
                    <span>I&apos;ve read and accept the Terms and Conditions*</span>
                    <br />
                    <span>
                      <a
                        href={`${process.env.REACT_APP_TERMS_LINK}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Click here
                      </a>
                      &nbsp; to open in a new window
                    </span>
                  </>
                }
                onChange={(e) => {
                  setAccepted(e.currentTarget.checked);
                }}
              />
            </section>

            <section className="footer">
              <Button
                className="btn"
                text={t('common.btnBack')}
                variant="transparent"
                onClick={navBack}
              />
              <Button
                className="btn"
                disabled={isCountriesLoading || !accepted}
                text={t('common.btnSave')}
                variant="primary"
                onClick={processSave}
              />
              <Button
                className="btn"
                disabled={isCountriesLoading || !accepted}
                text={t('common.btnConfirm')}
                variant="submit"
                onClick={processSubmit}
              />
            </section>
          </Form>
        </Panel>
      ) : (
        <div className="travel-request-submitted">
          Your request has been successfully submitted! <br />
          To finalise your request, please visit
          <br />
          <a href="http://dss.un.org" target="_blank" rel="noreferrer">
            dss.un.org
          </a>
        </div>
      )}

      <Modal visible={showConfirmation}>
        <Confirmation
          message="You are about to submit a draft for UNDSS Security Clearance Request, Are you sure?"
          onCancel={() => {
            setShowConfirmation(false);
            setSubmittedData(null);
          }}
          onConfirm={() => {
            updateTrip(submittedData!);
            setShowConfirmation(false);
            setSubmittedData(null);
          }}
        />
      </Modal>
    </section>
  );
};

export default TravelRequestTripEdit;
