import {
  ITravelRequestRepository,
  TravelRequestParams,
  TravelRequestProfileCreate,
  TravelRequestTripUpdate,
} from '@common/interfaces';
import AxiosRepository from './axios.repository';

class TravelRequestRepository extends AxiosRepository implements ITravelRequestRepository {
  private buildParams(params?: TravelRequestParams) {
    const searchParams = new URLSearchParams();

    if (params?.countryCode) searchParams.append('country_code', String(params.countryCode));
    if (params?.limit) searchParams.append('limit', String(params.limit));
    if (params?.name) searchParams.append('name', String(params.name));
    if (params?.offset) searchParams.append('offset', String(params.offset));
    if (params?.ordering) searchParams.append('ordering', String(params.offset));

    return searchParams;
  }

  async createProfile(profile: TravelRequestProfileCreate) {
    const { status } = await this.post('/external-services/undss/profiles/form/', profile);
    return status;
  }

  async getAgencies() {
    const { data } = await this.get('/external-services/undss/agencies/');
    return data;
  }

  async getCities(params?: TravelRequestParams) {
    const { data } = await this.get(`/external-services/undss/cities/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  async getCountries(params?: TravelRequestParams) {
    const { data } = await this.get(`/external-services/undss/countries/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  async getProfile() {
    const { data } = await this.get('/external-services/undss/profiles/form/schema/');
    return { data };
  }

  async getTravelRequests(params?: TravelRequestParams) {
    const { data } = await this.get(`/external-services/undss/travel-requests/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  async updateTrip(payload: TravelRequestTripUpdate) {
    const { status } = await this.post('/external-services/undss/travel-requests/form/', payload);
    return status;
  }
}

export default TravelRequestRepository;
