import { IDriveUpdate, IReportRepository, ReportParams } from '@common/interfaces';
import fileDownload from 'js-file-download';
import AxiosRepository from './axios.repository';

class ReportRepository extends AxiosRepository implements IReportRepository {
  private buildParams(params?: ReportParams) {
    const searchParams = new URLSearchParams();

    if (params?.agencyId) searchParams.append('agency', params.agencyId);
    if (params?.approvedBy) searchParams.append('approved_by', params.approvedBy);
    if (params?.certifiedBy) searchParams.append('certified_by', params.certifiedBy);
    if (params?.createdAfter) searchParams.append('created_date_after', params.createdAfter);
    if (params?.createdBefore) searchParams.append('created_date_before', params.createdBefore);
    if (params?.facility) searchParams.append('facility', params.facility);
    if (typeof params?.isCarpooled === 'boolean')
      searchParams.append('is_carpooled', params.isCarpooled ? 'True' : 'False');
    if (typeof params?.isRideShared === 'boolean')
      searchParams.append('is_ride_shared', params.isRideShared ? 'True' : 'False');
    if (params?.limit) searchParams.append('limit', String(params.limit));
    if (params?.month) searchParams.append('month', String(params.month));
    if (params?.offset) searchParams.append('offset', String(params.offset));
    if (params?.ordering) searchParams.append('ordering', params.ordering);
    if (params?.passengerAgency) searchParams.append('passenger_agency', params.passengerAgency);
    if (params?.pickupDate) searchParams.append('pickup_date_lte', params.pickupDate);
    if (params?.preparedBy) searchParams.append('prepared_by', params.preparedBy);
    if (params?.search) searchParams.append('search', params.search);
    if (params?.year) searchParams.append('year', String(params.year));

    return searchParams;
  }

  async getCountryReport(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(`/facilities/${facility}/reports/country-report/?${query}`);

    return data;
  }

  async getDashboardData(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(`/facilities/${facility}/reports/country-analytics/?${query}`);

    return data;
  }

  async getDashboardDrivers(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(
      `/facilities/${facility}/reports/country-analytics/drivers/?${query}`,
    );

    return data;
  }

  async getDashboardFilters(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(
      `/facilities/${facility}/reports/country-analytics/filters/?${query}`,
    );
    return data;
  }

  async getDashboardVehicles(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(
      `/facilities/${facility}/reports/country-analytics/vehicles/?${query}`,
    );
    return data;
  }

  async getDriveWithWorkflow(facility: string, driveId: string) {
    const { data } = await this.get(
      `/facilities/${facility}/reports/cost-recovery-report/drives/${driveId}/workflow/`,
    );
    return data;
  }

  async getMonthlySummary(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(
      `/facilities/${facility}/reports/cost-recovery-report/summary/?${query}`,
    );
    return data;
  }

  async getReports(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(
      `/facilities/${facility}/reports/cost-recovery-report/?${query}`,
    );
    return data;
  }

  async getSummary(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(
      `/facilities/${facility}/reports/cost-recovery-report/summary-by-agency/?${query}`,
    );
    return data;
  }

  async createCountryReport(facility: string, date: string) {
    const { data } = await this.post(`/facilities/${facility}/reports/country-report/create/`, {
      reference_date: date,
    });
    return data;
  }

  async exportCountryReport(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data, headers } = await this.get(
      `/facilities/${facility}/reports/country-report/export/?${query}`,
      { responseType: 'blob' },
    );

    const filename = headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].split(';')[0]
      : '';

    fileDownload(new File([data], filename), filename);

    return data;
  }

  async exportDrives(facility: string, params?: ReportParams) {
    const query = this.buildParams(params);
    const { data, headers } = await this.get(
      `/facilities/${facility}/reports/cost-recovery-report/export/?${query}`,
      { responseType: 'blob' },
    );

    const filename = headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].split(';')[0]
      : '';

    fileDownload(new File([data], filename), filename);

    return data;
  }

  async updateDrive(facility: string, driveId: string, payload: IDriveUpdate) {
    const { data } = await this.patch(
      `/facilities/${facility}/reports/cost-recovery-report/drives/${driveId}/workflow/`,
      payload,
    );
    return data;
  }
}

export default ReportRepository;
