/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import {
  Ohchr,
  Oim,
  Un,
  Unaids,
  Undp,
  Undss,
  Unfao,
  Unfpa,
  Unhas,
  Unhcr,
  Unicef,
  UnWomen,
  Wfp,
  Who,
} from './agencies';

export class AgencyIcons {
  public static readonly icons: { [x: string]: (props: any) => JSX.Element } = {
    WHO: Who,
    WFP: Wfp,
    UNICEF: Unicef,
    UNHCR: Unhcr,
    UNHAS: Unhas,
    UNFPA: Unfpa,
    UNDSS: Undss,
    UN: Un,
    'UN Tanzania': Un,
    'UN South Africa': Un,
    UNIC: Un,
    IOM: Oim,
    'UN Women': UnWomen,
    UNDP: Undp,
    OHCHR: Ohchr,
    UNAIDS: Unaids,
    UNFAO: Unfao,
  };

  public static readonly defaultAgency = 'UN';

  public static icon(agency?: string): (props: any) => JSX.Element {
    return !agency
      ? this.icons[this.defaultAgency]
      : this.icons[agency]
      ? this.icons[agency]
      : this.icons[this.defaultAgency];
  }
}

export const getAgencyLogo = (agency: string, size = 33): JSX.Element => {
  const Logo = AgencyIcons.icon(agency);
  return <Logo fill="#E0E8ED" width={size} height={size} />;
};
