/* istanbul ignore file */
import type { FC } from 'react';
import { TextAreaField } from '@ui-modules/informed';
import { charValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface CommentFieldProps {
  label: string;
  name: string;
}

const CommentField: FC<CommentFieldProps> = ({ label, name }) => (
  <div className="field field-textarea">
    <TextAreaField
      className="textarea"
      label={label}
      name={name}
      validate={(value) => charValidator(value, 300)}
    />
  </div>
);

export default CommentField;
