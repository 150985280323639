/* istanbul ignore file */
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@ui-modules';
import '../DriveForm.styles.scss';

const getRecurringErrorsMessage = (errorsList: string[]) => {
  const formattedError = (message: string, idx: number) => <p key={idx}>{message}</p>;

  if (errorsList.length <= 2) return errorsList.map(formattedError);

  return (
    <>
      {errorsList.slice(0, 2).map((msg, idx) => formattedError(msg, idx))}
      <p>and {errorsList.slice(2).length} more...</p>
    </>
  );
};

interface RecurringForceCreateProps {
  errors: string[];
  onCancel: () => void;
  onConfirm: () => void;
}

const RecurringForceCreate: FC<RecurringForceCreateProps> = ({ errors, onCancel, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <section
      className="recurring-force-create"
      data-testid="planner-drive-form-recurring-force-create"
    >
      <div className="warning">
        <p>{t('common.recurring.forceCreate.conflictingDrives')}:</p>
        <br />
        {getRecurringErrorsMessage(errors)}
        <br />
        <p>{t('common.recurring.forceCreate.adviceMsg')}</p>
        <br />
        <p>{t('common.recurring.forceCreate.continuePrompt')}</p>
      </div>

      <div className="buttons">
        <Button
          data-testid="planner-drive-form-recurring-force-create-btn-cancel"
          text={t('common.no')}
          variant="danger"
          onClick={onCancel}
        />
        <Button
          text={t('common.yes')}
          variant="submit"
          onClick={onConfirm}
          data-testid="planner-drive-form-recurring-force-create-btn-confirm"
        />
      </div>
    </section>
  );
};

export default RecurringForceCreate;
