/* istanbul ignore file */
import type { FieldState } from 'informed';
import cn from 'classnames';
import { TextAreaField } from '@ui-modules/informed';
import { charValidator } from '@common/utils';
import '../../BookingPanel.styles.scss';

type FieldProps = {
  disabled?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
};

const CommentField = ({ disabled, label, name, placeholder, value, onChange }: FieldProps) => (
  <div className="field">
    <TextAreaField
      className={cn('comment', { disabled })}
      defaultValue={value}
      label={label}
      name={name}
      placeholder={placeholder}
      validate={(v) => charValidator(v, 300)}
      onChange={(fieldState: FieldState) => onChange?.(fieldState.value as string)}
    />
  </div>
);

export default CommentField;
