import type { FC } from 'react';
import { useAuth } from '@unbooking/ui-auth';
import { PoweredByLogo } from '@assets/svg/logos';
import { SUPPORT_EMAIL } from '@common/constants';

const ErrorPage: FC = () => {
  const { error } = useAuth();

  return (
    <div className="page-no-access">
      <div className="container">
        <PoweredByLogo width={75} height={75} />
        <h3 className="message">
          Ops, there was an error: {error ?? 'Server error'}. If you keep getting this error please
          contact support: <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </h3>
      </div>
    </div>
  );
};

export default ErrorPage;
