/* istanbul ignore file */
import { type FC, useEffect } from 'react';
import { t } from 'i18next';
import { useFormApi } from 'informed';

import type { IDropdownOption } from '@ui-modules/types';
import { Dropdown } from '@ui-modules/informed';
import { DriveFormSteps } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

interface FieldProps {
  defaultValue?: IDropdownOption | string;
  idx: number;
  options?: IDropdownOption[];
}

const PaxRequestUnitField: FC<FieldProps> = ({ defaultValue, idx, options }) => {
  const { setValue } = useFormApi();

  useEffect(() => {
    if (defaultValue && options?.length) {
      setValue(
        `${DriveFormSteps.Main}.passengersInfo[${idx}].requestingUnit`,
        options.find((option) => option.value === defaultValue),
      );
    }
  }, [defaultValue, idx, options, setValue]);

  return (
    <div className="field">
      <Dropdown
        className="dropdown"
        label={`${t('common.requestingUnit')} *`}
        name="requestingUnit"
        options={options}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default PaxRequestUnitField;
