/* istanbul ignore file */
import { t } from 'i18next';
import { TextAreaField } from '@ui-modules/informed';
import { charValidator } from '@common/utils';
import '../styles/modal.scss';

const RemarksField = () => (
  <div className="field field-textarea">
    <TextAreaField
      className="textarea"
      data-testid="planner-drive-form-field-remarks"
      label={`${t('planner.remarks')} - max 300 char.`}
      name="remarks"
      validate={(value) => charValidator(value, 300)}
    />
  </div>
);

export default RemarksField;
