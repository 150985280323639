/* istanbul ignore file */
export const COLORS = [
  '#095d93',
  '#3a89f7',
  '#30ac3e',
  '#f44a56',
  '#0adbbb',
  '#f5a623',
  '#296755',
  '#c7f2ff',
];
