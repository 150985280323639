import { Chip } from '@mantine/core';
import { useField } from 'informed';
import { OptionType } from '@common/interfaces';
import './ChipGroup.styles.scss';

interface ChipGroupProps {
  name: string;
  options: OptionType[];
  readonly?: boolean;
  onChange?: (value: string[]) => void;
}

const ChipGroup = ({ name, options, readonly, onChange, ...rest }: ChipGroupProps) => {
  const { fieldState, fieldApi, render } = useField({ name, defaultValue: [] });

  const toggleOption = (data: string[], value: string) =>
    data.includes(value) ? data.filter((v) => v !== value) : [...data, value];

  const handleSelectionChange = (value: string) => {
    const selectedOptions = toggleOption(fieldState.value as string[], value);
    fieldApi.setValue(selectedOptions);
    if (onChange) onChange(selectedOptions);
  };

  return render(
    <Chip.Group
      multiple
      value={fieldState?.value as string[]}
      onChange={(value: string[]) => fieldApi.setValue(value)}
    >
      {options.map((i: OptionType) => (
        <Chip
          checked={(fieldState?.value as string[])?.includes(i.value)}
          classNames={{ root: 'chip-group', label: 'chip-group-label' }}
          disabled={readonly}
          key={i.value}
          value={i.value}
          onChange={() => handleSelectionChange(i.value)}
          {...rest}
        >
          {i.label}
        </Chip>
      ))}
    </Chip.Group>,
  );
};

export default ChipGroup;
