import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Pagination } from '@unbooking/ui-modules';
import { DriverType } from '@common/interfaces';
import { Driver, Loader } from '@components';

const DriverList = ({
  data,
  isEditable,
  isLoading,
  isLoadingProcess,
  selectedPage,
  totalItems,
  setPageSize,
  setSelectedPage,
  onDriverEdit,
  onDriverStatusChange,
}: {
  data: DriverType[];
  isEditable?: boolean;
  isLoading: boolean;
  isLoadingProcess?: boolean;
  selectedPage: number;
  totalItems: number;
  setPageSize: (size: number) => void;
  setSelectedPage: (page: number) => void;
  onDriverEdit: (id: string) => void;
  onDriverStatusChange: (driver: DriverType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <Loader fullScreen spinning={isLoading}>
        {!isLoading && !totalItems ? (
          <div className="empty-list">{t('common.emptyList')}</div>
        ) : (
          <>
            {data.map((driver: DriverType) => (
              <Driver
                driver={driver}
                isEditable={isEditable}
                isLoading={isLoadingProcess}
                key={driver.id}
                onDriverEdit={onDriverEdit}
                onDriverStatusChange={onDriverStatusChange}
              />
            ))}

            <div className="driver-list-footer">
              {!isLoading && (
                <div className="last-update">
                  {t('common.lastUpdate')}: {format(new Date(), 'dd/MM/yyyy HH:mm')}
                </div>
              )}

              {totalItems > 10 && (
                <Pagination
                  className="pagination"
                  selectedPage={selectedPage}
                  showJumper
                  showPageSize
                  totalPages={totalItems}
                  variant="light"
                  onPageChange={setSelectedPage}
                  onPageSizeChange={setPageSize}
                />
              )}
            </div>
          </>
        )}
      </Loader>
    </div>
  );
};

export default DriverList;
