import { ChangeEvent, FC, useEffect } from 'react';
import { SwitchProps, Switch as Switcher } from '@mantine/core';
import { useField } from 'informed';
import cn from 'classnames';
import './styles.scss';

export interface SwitcherProps extends Omit<SwitchProps, 'onChange'> {
  className?: string;
  defaultChecked?: boolean;
  label?: string;
  name: string;
  onChange?: (v: boolean) => void;
}

const Switch: FC<SwitcherProps> = ({
  className,
  defaultChecked = false,
  label,
  name,
  onChange,
}) => {
  const {
    fieldState: { value },
    fieldApi: { setValue },
    render,
  } = useField<SwitcherProps, boolean>({ defaultChecked, name });

  useEffect(() => {
    setValue(defaultChecked);
  }, [defaultChecked, setValue]);

  return render(
    <div className={cn('switch', className)}>
      <Switcher
        checked={value as boolean}
        classNames={{
          track: 'switch-track',
        }}
        data-testid="switcher"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const currentValue = event.currentTarget.checked;
          setValue(currentValue);
          onChange?.(currentValue);
        }}
      />

      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
    </div>,
  );
};

export default Switch;
