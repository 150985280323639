import { FC, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Form } from 'informed';
import { TextAreaField } from '@ui-modules/informed';
import { commentValidator } from '@common/utils';
import { IndInvoicePatch } from '@common/interfaces';
import css from './styles.scss';

interface CommentsProps {
  value?: string;
  updateInvoice: (comment: Partial<IndInvoicePatch>) => void;
}

const Comments: FC<CommentsProps> = ({ value, updateInvoice }) => {
  const [comment, setComment] = useState<string>('');
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (value?.length) {
      setComment(value);
    }
  }, [value]);

  return (
    <Form>
      <TextAreaField
        className={css.textfield}
        defaultValue={comment}
        name="invoice_comment_text"
        placeholder={t('invoice.insertComment')}
        validate={commentValidator}
        onBlur={(e) => {
          if (isChanged) {
            setIsChanged(false);
            setComment(e.value as string);
            updateInvoice({ invoice_comment_text: e.value as string });
          }
        }}
        onChange={() => setIsChanged(true)}
      />
    </Form>
  );
};

export default Comments;
