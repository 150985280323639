import { useCallback, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SchemaDescription } from 'yup';
import get from 'lodash/get';
import set from 'lodash/set';
import * as yup from 'yup';
import { FormContext } from '../provider/form';

export const useSchema = (name: string, recursive?: boolean) => {
  const { schema } = useContext(FormContext);
  const { formState, watch } = useFormContext();

  const data = useMemo(() => {
    if (!schema) return { label: '', required: false, type: '' };
    const innerSchema = yup.reach(schema, name, watch(name));
    const fieldSchema = innerSchema?.describe({ parent: watch(), value: watch(name) });
    const { label = '', optional, type = '' } = fieldSchema as SchemaDescription;

    return { label, required: !optional, type };
  }, [name, schema, watch]);

  const error = useMemo(() => get(formState.errors, name), [formState.errors, name]);

  const validate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      if (schema) schema.validateSyncAt(name, set({}, name, value), { recursive });
    },
    [name, recursive, schema],
  );

  return useMemo(() => ({ ...data, error, validate }), [data, error, validate]);
};
