/* istanbul ignore file */
export enum BFDailyFrequency {
  AllDays = '7',
  WorkingDaysFromMonday = '5',
  WorkingDaysFromSunday = '6',
}

export enum BFRecurringPeriod {
  Daily = '3',
  Weekly = '2',
}

export enum BFTransferType {
  airport = 'Airport',
  in_town = 'In Town',
  out_of_town = 'Out Of Town',
}

export enum BFTripType {
  'one-way' = 'One Way',
  'round-trip' = 'Round Trip',
  'multi-leg' = 'Multi-leg',
}

export type BFPaxInfo = {
  agency: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  requestingUnit: string;
};

export type BFRoute = {
  dropoffEqualFacility?: boolean;
  dropoffLat?: number;
  dropoffLng?: number;
  dropoffLocation: string;
  dropoffLocExtra?: string;
  dropoffTown: string;
  pickupDate: Date | null;
  pickupEqualFacility?: boolean;
  pickupLat?: number;
  pickupLng?: number;
  pickupLocation: string;
  pickupLocExtra?: string;
  pickupTime: string;
  pickupTown: string;
  relatedScheduleId?: string;
};

export type BFState = {
  acceptConditions: boolean;
  addRecurring: boolean;
  agentDetails?: {
    email: string;
    firstName: string;
    lastName: string;
  };
  bookingDetails: {
    agency: string;
    attachments?: File[];
    email: string;
    firstName: string;
    indexNumber?: string;
    lastName: string;
    phoneNumber?: string;
  };
  bookingExtra: {
    flightArrivalDepartureTime?: string;
    flightNumber?: string;
    managerEmail?: string;
    pax?: string;
    purpose?: string;
    requestingUnit?: string;
    remarks?: string;
  };
  bookingFormType: string;
  passengersInfo?: BFPaxInfo[];
  recurring?: {
    recurringFrequency: BFDailyFrequency;
    recurringPeriod: BFRecurringPeriod;
    recurringUntil: Date;
  };
  routes: BFRoute[];
  transferType: string;
  typeOfTrip: string;
  travellerDetails?: {
    agency?: string;
    email?: string;
    firstName?: string;
    indexNumber?: string;
    lastName?: string;
    phoneNumber?: string;
    requestingUnit?: string;
  };
};
