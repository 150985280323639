import { IChecklistStatus, IVehiclesChecklist } from '@common/interfaces';

export const examineChecklistStatus = (
  checklist: IVehiclesChecklist,
): { status: IChecklistStatus; statusName: string } => {
  let status: IChecklistStatus;
  let statusName;

  if (!checklist.submitted && checklist.todayDrivesCount === 0) {
    status = 'not-used';
    statusName = 'Not used';
  } else if (!checklist.submitted && checklist.todayDrivesCount > 0) {
    status = 'not-reported';
    statusName = 'Not reported';
  } else if (checklist.criticalIssuesCount >= 1) {
    status = 'critical';
    statusName = 'Critical Issues';
  } else if (checklist.issuesCount >= 1) {
    status = 'issues';
    statusName = 'Issues';
  } else {
    status = 'clear';
    statusName = 'Clear';
  }

  return { status, statusName };
};
