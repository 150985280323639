import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { type AxiosError } from 'axios';

import type { IFacility } from '@common/interfaces';
import { useGlobalContext, useRepository } from '@context';
import { SessionStorageKeys } from '@common/constants';
import { StatusCode } from '@common/types';
import { Serializer } from '@common/utils';

const DO_URL = process.env.REACT_APP_DO_URL;
let savedAgency: string;

interface IFacilityResponse {
  agencyId: string;
  agencyName: string;
  facility: IFacility;
  facilityId: string;
  isLoading: boolean;
}

const useFacility = (): IFacilityResponse => {
  const agency = sessionStorage.getItem(SessionStorageKeys.AGENCY)!;
  const agencyId = sessionStorage.getItem(SessionStorageKeys.AGENCY_ID)!;
  const facilityId = sessionStorage.getItem(SessionStorageKeys.FACILITY)!;

  const { agencyName, facility, setAgencyName, setFacility } = useGlobalContext();
  const { facilityRepository } = useRepository();

  const {
    data: facilityData,
    isLoading,
    refetch,
  } = useQuery('get-facility', () => facilityRepository.getFacility(agencyId, facilityId), {
    enabled: !!agencyName && (facility.city.length === 0 || facility.country.length === 0),
    onError: (error: AxiosError) => {
      if (error.response) {
        const { status } = error.response;
        if (
          status === StatusCode.ClientErrorForbidden ||
          status === StatusCode.ClientErrorNotFound
        ) {
          window.location.replace(DO_URL!);
        }
      }
    },
  });

  useEffect(() => {
    if (facilityData) {
      if (!facilityData.is_active) return window.location.replace(DO_URL!);

      setFacility(Serializer.formatFacility(facilityData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityData]);

  useEffect(() => {
    if (savedAgency && savedAgency !== agencyName) refetch();
    if (agencyName) savedAgency = agencyName;
    if (!agencyName) setAgencyName(agency || 'WFP'); // TODO: For the tests only, remove it
  }, [agency, agencyName, refetch, setAgencyName]);

  return { agencyId, agencyName, facility, facilityId, isLoading };
};

export default useFacility;
