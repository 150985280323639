/* istanbul ignore file */
import { useTranslation } from 'react-i18next';
import { useFormApi } from 'informed';
import { TextField } from '@ui-modules/informed';
import { DriveFormSteps as Steps } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

type FieldProps = {
  idx: number;
  isMultiLeg?: boolean;
  isReturnTrip?: boolean;
  label?: string;
  name: string;
};

const AddressField = ({
  idx,
  isMultiLeg = false,
  isReturnTrip = false,
  label,
  name,
}: FieldProps) => {
  const { t } = useTranslation();
  const { getFormState } = useFormApi();
  const formState = getFormState().values as any;
  const tripKey = isReturnTrip ? Steps.ReturnTrip : Steps.Trip;
  const fieldsetKey = isMultiLeg ? 'driveListMultileg' : 'driveList';
  const drives = formState?.[tripKey]?.[fieldsetKey];
  const { [`${name}AddressRequired`]: isAddressRequired } = drives?.[idx] || {};

  const validateLocationAddress = (value: unknown, formValues: Record<string, any>) => {
    const data = formValues?.[tripKey]?.[fieldsetKey];
    return data?.[idx]?.[`${name}AddressRequired`] ? notEmptyValidator(value) : undefined;
  };

  return (
    <div className="field" data-testid="planner-drive-form-field-address">
      <TextField
        className="input"
        label={`${label} ${!isAddressRequired ? '(optional)' : ''}`}
        name={`${name}Address`}
        validate={validateLocationAddress}
      />
      {isAddressRequired && (
        <span className="field-description">{t('bookingForm.tooltipAddress')}</span>
      )}
    </div>
  );
};

export default AddressField;
