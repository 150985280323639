import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, PageTitle } from '@unbooking/ui-modules';
import { useFacility, useFeatureFlags } from '@common/hooks';
import { useRepository } from '@context';
import { DriverType, DriverCreate, DriverUpdate } from '@common/interfaces';
import { Modal, Tabs, UnavailabilityForm } from '@components';
import { DriverEdit, DriverList } from './components';
import { useDriverList } from './hooks';
import './DriverListPage.styles.scss';

const DriversListPage: FC = () => {
  const { flags } = useFeatureFlags();
  const { mobilityRepository } = useRepository();
  const { t } = useTranslation();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city, country } = facility;
  const tabs = ['My Drivers', 'Shared'];

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [showDriverForm, setShowDriverForm] = useState<boolean>(false);
  const [driverUnavailable, setDriverUnavailable] = useState<DriverType | null>(null);

  const [driverPageSize, setDriverPageSize] = useState<number>(10);
  const [driverSelectedPage, setDriverSelectedPage] = useState<number>(1);
  const [sharedDriverPageSize, setSharedDriverPageSize] = useState<number>(10);
  const [sharedDriverSelectedPage, setSharedDriverSelectedPage] = useState<number>(1);

  const {
    data: { count: driverTotal = 0, data: driverList = [] } = {},
    isLoading: isDriversLoading,
    refetch: getDrivers,
  } = useDriverList(agencyId, facilityId, driverPageSize, driverSelectedPage, false);

  const {
    data: { count: sharedDriverTotal = 0, data: sharedDriverList = [] } = {},
    isLoading: isSharedDriversLoading,
    refetch: getSharedDrivers,
  } = useDriverList(agencyId, facilityId, sharedDriverPageSize, sharedDriverSelectedPage, true);

  const { isLoading: isUnavailableProcess, mutate: deleteUnavailable } = useMutation(
    'delete-unavailable',
    ({ id, unavailable }: { id: string; unavailable: string }) =>
      mobilityRepository.deleteUnavailable(facilityId, id, unavailable, 'driver'),
    {
      onSuccess: () => {
        if (selectedTab === tabs[0]) getDrivers();
        if (selectedTab === tabs[1]) getSharedDrivers();
      },
    },
  );

  const { isLoading: isDriverCreating, mutateAsync: createDriver } = useMutation(
    'create-driver',
    (data: DriverCreate) => mobilityRepository.createDriver(facilityId, data),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgDriverUpdated'));
        setShowDriverForm(false);
        if (selectedTab === tabs[0]) getDrivers();
        if (selectedTab === tabs[1]) getSharedDrivers();
      },
      onError: (e: any) => {
        if (e.message) toast.error(e.response.data || t('common.errorMsgDefault'));
      },
    },
  );

  const { isLoading: isDriverUpdating, mutateAsync: updateDriver } = useMutation(
    'update-driver',
    ({ driverId, data }: { driverId: string; data: DriverUpdate }) =>
      mobilityRepository.updateDriver(facilityId, driverId, data),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgDriverUpdated'));
        setShowDriverForm(false);
        setSelectedDriver(null);
        if (selectedTab === tabs[0]) getDrivers();
        if (selectedTab === tabs[1]) getSharedDrivers();
      },
      onError: (e: any) => {
        if (e.message) toast.error(t('common.errorMsgDefault'));
      },
    },
  );

  const handleDriverStatusChange = (driver: DriverType) => {
    if (driver?.currentUnavailability) {
      deleteUnavailable({ id: driver.id, unavailable: driver.currentUnavailability.id });
    } else {
      setDriverUnavailable(driver);
    }
  };

  useEffect(() => {
    if (selectedDriver) setShowDriverForm(true);
  }, [selectedDriver]);

  const showAddBtn = flags.addDriverAndVehicle?.is_active || undefined;
  const title =
    city && country
      ? `${t('mobility.driversListTitle')} ${city}, ${country} | ${agencyName} View`
      : '';

  const renderToolbar = useMemo(
    () =>
      showAddBtn && (
        <Button
          className="btn btn-add"
          text={t('mobility.addDriver')}
          variant="primary"
          onClick={() => setShowDriverForm(true)}
        />
      ),
    [showAddBtn, t],
  );

  return (
    <section className="hbh-container driver-list">
      <PageTitle bottomLine className="driver-list-title" filters={renderToolbar} title={title} />

      <Tabs
        badges={[driverTotal, sharedDriverTotal]}
        className="driver-list-tabs"
        selectedTab={tabs.indexOf(selectedTab)}
        tabs={tabs}
        onTabSelect={setSelectedTab}
      />

      {selectedTab === tabs[0] && (
        <DriverList
          data={driverList}
          isEditable
          isLoading={isDriversLoading}
          isLoadingProcess={isUnavailableProcess}
          selectedPage={driverSelectedPage}
          totalItems={driverTotal}
          setPageSize={setDriverPageSize}
          setSelectedPage={setDriverSelectedPage}
          onDriverEdit={setSelectedDriver}
          onDriverStatusChange={handleDriverStatusChange}
        />
      )}

      {selectedTab === tabs[1] && (
        <DriverList
          data={sharedDriverList}
          isLoading={isSharedDriversLoading}
          selectedPage={sharedDriverSelectedPage}
          totalItems={sharedDriverTotal}
          setPageSize={setSharedDriverPageSize}
          setSelectedPage={setSharedDriverSelectedPage}
          onDriverEdit={setSelectedDriver}
          onDriverStatusChange={handleDriverStatusChange}
        />
      )}

      <Modal
        showBtnClose
        variant="light"
        visible={showDriverForm}
        onClose={() => {
          setShowDriverForm(false);
          if (selectedDriver) setSelectedDriver(null);
        }}
      >
        <DriverEdit
          driverId={selectedDriver}
          isLoading={isDriverCreating || isDriverUpdating}
          readOnly={selectedTab === tabs[1]}
          createDriver={createDriver}
          updateDriver={updateDriver}
        />
      </Modal>

      <Modal
        showBtnClose
        variant="dark"
        visible={!!driverUnavailable}
        onClose={() => setDriverUnavailable(null)}
      >
        {driverUnavailable && (
          <UnavailabilityForm
            id={driverUnavailable.id}
            label={`${driverUnavailable?.name} ${driverUnavailable?.lastName}`}
            type="driver"
            onSubmit={() => {
              if (selectedTab === tabs[0]) getDrivers();
              if (selectedTab === tabs[1]) getSharedDrivers();
              setDriverUnavailable(null);
            }}
          />
        )}
      </Modal>
    </section>
  );
};

export default DriversListPage;
