import { useRef, useState } from 'react';
import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { type FileButtonProps, Button, FileButton, List, Stack, Text } from '@mantine/core';
import { Attachment, Close } from '@assets/svg/icons';

export type FileInputProps<T extends FieldValues> = {
  className?: string;
  label: string;
  maxFileNumber?: number;
  maxFileSize?: number;
  multiple?: boolean;
  onError?: (error: string) => void;
} & UseControllerProps<T> &
  Omit<FileButtonProps, 'children' | 'defaultValue' | 'multiple' | 'onChange'>;

export function FileUpload<T extends FieldValues>({
  className,
  control,
  defaultValue,
  label,
  maxFileNumber,
  maxFileSize,
  multiple = false,
  name,
  rules,
  shouldUnregister,
  onError,
  ...props
}: FileInputProps<T>) {
  const {
    field: { value, onChange, ...field },
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  const resetRef = useRef<() => void>(null);

  const [files, setFiles] = useState<File[]>(value || []);

  const handleFileChange = (newFiles: File | File[] | null) => {
    if (!newFiles) {
      setFiles([]);
      return;
    }

    const updatedFiles = Array.isArray(newFiles) ? [...files, ...newFiles] : [...files, newFiles];

    if (maxFileNumber && updatedFiles.length > maxFileNumber) return onError?.('maxFileNumber');

    const invalidFileSize = updatedFiles.some((file) => maxFileSize && file.size > maxFileSize);

    if (invalidFileSize) return onError?.('maxFileSize');

    setFiles(updatedFiles);
    onChange?.(updatedFiles);
  };

  const handleRemoveFile = (file: File) => {
    const updatedFiles = files.filter((f) => f !== file);
    setFiles(updatedFiles);
    onChange?.(updatedFiles);

    resetRef.current?.();
  };

  const btnText =
    multiple && maxFileNumber ? `Upload (Max: ${maxFileNumber - files.length})` : 'Upload';

  const isUploadDisabled = !!(maxFileNumber && files.length >= maxFileNumber);

  return (
    <Stack className="file-upload" spacing={5}>
      {label && (
        <Text c="white" size="md" fw={500} mb={0}>
          {label}
        </Text>
      )}
      <FileButton
        disabled={isUploadDisabled}
        multiple={multiple}
        resetRef={resetRef}
        onChange={handleFileChange}
        {...field}
        {...props}
      >
        {(prop) => <Button {...prop}>{btnText}</Button>}
      </FileButton>

      {Array.isArray(files) && files.length > 0 && (
        <List className="file-upload-list">
          {files.map((f: File) => (
            <List.Item className="file-upload-list-item" key={f.name}>
              <Attachment />
              <div className="file-upload-list-item-name">{f.name}</div>
              <Button
                className="file-upload-list-item-remove"
                size="xs"
                variant="link"
                onClick={() => handleRemoveFile(f)}
              >
                <Close />
              </Button>
            </List.Item>
          ))}
        </List>
      )}
    </Stack>
  );
}
