/* istanbul ignore file */
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { LocalStorageKeys } from '@common/constants';
import { StatusCode } from '@common/types';

const tokenFederatedUser = localStorage.getItem(LocalStorageKeys.X_USER_TOKEN);
let migrationMessageShown = false;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_VERSION}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const modifiedConfig: InternalAxiosRequestConfig = { ...config };

    if (tokenFederatedUser) {
      modifiedConfig.headers.Authorization = `Token ${tokenFederatedUser}`;
      modifiedConfig.headers['X-User-Email'] = JSON.parse(
        localStorage.getItem(LocalStorageKeys.USER_EMAIL) || '',
      );
    }

    return modifiedConfig;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error: AxiosError) => {
    if (error.response?.status === StatusCode.ClientErrorNotAcceptable && !migrationMessageShown) {
      toast.warning('Service line is currently under migration. Please, visit the page later.');

      migrationMessageShown = true;
    }

    if (axios.isAxiosError(error)) {
      if (error.config) {
        Sentry.setContext('Error fields', {
          baseURL: error.config?.baseURL,
          headers: error.config?.headers,
          message: error.response?.data,
          method: error.config?.method,
          url: error.config?.url,
        });
      }

      Sentry.captureException(error);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
