/* eslint-disable react/destructuring-assignment */
/* istanbul ignore file */
function Device(props: any) {
  return (
    <svg
      width={13}
      height={14}
      viewBox="0 0 13 14"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 11.872V1.128A1.13 1.13 0 011.133 0h7.72C9.493 0 10 .504 10 1.128V2.52H8.573V1.406h-7.16v8.782h3.72v1.684c0 .398.107.796.32 1.128h-4.32A1.13 1.13 0 010 11.872zm7.333-8.29h4.534A1.13 1.13 0 0113 4.709v7.163A1.13 1.13 0 0111.867 13H7.333A1.13 1.13 0 016.2 11.872V4.71a1.13 1.13 0 011.133-1.127zm2.827 8.038a.562.562 0 00-.56-.557.562.562 0 00-.56.557c0 .306.253.558.56.558.307 0 .56-.253.56-.558zm-2.533-1.432h3.96V5h-3.96v5.187z"
        fill={props.fill}
      />
    </svg>
  );
}

export default Device;
