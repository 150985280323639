/* eslint-disable no-param-reassign */
import camelcaseKeys from 'camelcase-keys';
import { format, startOfMonth, startOfYear, subDays, subMonths, subYears } from 'date-fns';
import { DashboardData, DashboardDataApi, FilterReport } from '@common/interfaces';
import { DateFormat } from '@common/types';
import {
  BookingStateType,
  ChartBarData,
  ChartDataItem,
  ChartPieData,
  DriveType,
  TransferTypeDisplay,
  TripTypeDisplay,
} from './types';

const isDefaultChartData = (arg: any[]): arg is ChartDataItem[] =>
  arg.length > 0 && 'value' in arg[0];

const mapBarChartData = (data: ChartDataItem[], itemsToShow: number = 5): ChartBarData => {
  const sorted = data.sort((a, b) => b.value - a.value);

  const displayed = itemsToShow <= 0 ? sorted : sorted.slice(0, itemsToShow);
  const other = itemsToShow <= 0 ? [] : sorted.slice(itemsToShow);

  const agenda = [...displayed.map((item) => item.label), ...(other.length > 0 ? ['Other'] : [])];
  const totalOtherValue = other.reduce((sum, item) => sum + item.value, 0);
  const dataValues = [...displayed.map((item) => item.value), totalOtherValue];

  const chartData: ChartBarData = {
    agenda,
    data: [{ data: dataValues, label: '' }],
  };

  return chartData;
};

const mapPieChartData = (data: ChartDataItem[], itemsToShow: number = 5): ChartPieData => {
  const total = data.reduce((sum, item) => sum + item.value, 0);

  if (data.length > itemsToShow) {
    const sorted = data.sort((a, b) => b.value - a.value);
    const displayed = sorted.slice(0, itemsToShow);
    const other = sorted.slice(itemsToShow);
    const otherTotal = other.reduce((sum, item) => sum + item.value, 0);

    displayed.push({ label: 'Other', value: otherTotal });

    return {
      data: displayed.map((item) => item.value),
      labels: displayed.map((item) => `${((item.value / total) * 100).toFixed(2)}% ${item.label}`),
      total,
    };
  }

  return data
    .sort((a, b) => b.value - a.value)
    .reduce(
      (agg, item) => {
        const percentage = (item.value / total) * 100;

        agg.data.push(item.value);
        agg.labels.push(`${percentage.toFixed(2)}% ${item.label}`);
        agg.total += item.value;

        return agg;
      },
      { data: [], labels: [], total: 0 } as ChartPieData,
    );
};

const mapLab = (items: ChartDataItem[], enumType: any) =>
  items.map((item) => ({
    label: enumType[item.label] || item.label,
    value: item.value,
  }));

const transformData = (data: DashboardDataApi): any => {
  const obj = camelcaseKeys(data, { deep: true }) as DashboardData;

  if (obj.driveType) obj.driveType = mapLab(obj.driveType, DriveType);
  if (obj.recurringTypeOfTrip)
    obj.recurringTypeOfTrip = mapLab(obj.recurringTypeOfTrip, TripTypeDisplay);
  if (obj.state) obj.state = mapLab(obj.state, BookingStateType);
  if (obj.transferType) obj.transferType = mapLab(obj.transferType, TransferTypeDisplay);
  if (obj.typeOfTrip) obj.typeOfTrip = mapLab(obj.typeOfTrip, TripTypeDisplay);

  return obj;
};

export function dataAdapter<T>(
  data: DashboardDataApi,
  chartType: string[],
  itemsToShow?: Record<string, number>,
): T {
  return Object.entries(transformData(data) as DashboardData).reduce((acc, [key, val]) => {
    if (chartType.includes(key)) {
      return { ...acc, [key]: mapBarChartData(val, itemsToShow ? itemsToShow[key] : undefined) };
    }

    if (isDefaultChartData(val)) {
      return { ...acc, [key]: mapPieChartData(val, itemsToShow ? itemsToShow[key] : undefined) };
    }

    return { ...acc, [key]: val };
  }, {} as T);
}

export function filterAdapter<T>(filters: FilterReport[]): T {
  const params: Record<string, any> = {};

  filters.forEach((filter) => {
    if (filter.customPeriod) {
      const [start, end] = filter.customPeriod;
      if (start && end) {
        params.createdBefore = format(end, DateFormat.ApiDate);
        params.createdAfter = format(start, DateFormat.ApiDate);
      }
    } else if (filter.period) {
      const { value } = filter.period;
      const current = new Date();

      switch (value) {
        case 'week':
          params.createdBefore = format(current, DateFormat.ApiDate);
          params.createdAfter = format(subDays(current, 6), DateFormat.ApiDate);
          break;
        case 'month':
          params.createdBefore = format(current, DateFormat.ApiDate);
          params.createdAfter = format(startOfMonth(current), DateFormat.ApiDate);
          break;
        case 'year':
          params.createdBefore = format(current, DateFormat.ApiDate);
          params.createdAfter = format(startOfYear(current), DateFormat.ApiDate);
          break;
        case 'monthPrev':
          params.createdBefore = format(subDays(startOfMonth(current), 1), DateFormat.ApiDate);
          params.createdAfter = format(startOfMonth(subMonths(current, 1)), DateFormat.ApiDate);
          break;
        case 'yearPrev':
          params.createdBefore = format(subDays(startOfYear(current), 1), DateFormat.ApiDate);
          params.createdAfter = format(startOfYear(subYears(current, 1)), DateFormat.ApiDate);
          break;
        default:
          break;
      }
    } else if (filter.agency) {
      params.passengerAgency = filter.agency.value;
    } else if (filter.location) {
      params.facility = filter.location.value;
    }
  });

  return params as T;
}
