import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ILastRatings } from '@common/interfaces';
import { LastRatings, ReviewRating } from '@components';
import { Vehicle } from '@assets/svg/icons';
import './styles.scss';

export interface AverageRatingProps {
  backgroundColor: 'red' | 'yellow' | 'green' | 'badge-color';
  facilityName?: string;
  ratings?: ILastRatings[];
  type?: 'brief' | 'full';
  value?: number;
}

const AverageRating: FC<AverageRatingProps> = ({
  backgroundColor,
  facilityName,
  ratings = [],
  type = 'brief',
  value,
}) => {
  const { t } = useTranslation();

  const getBackgroundColor = (color: 'red' | 'yellow' | 'green' | 'badge-color'): string => {
    if (color === 'red') return '#b21217';
    if (color === 'yellow') return '#c6bb1a';
    if (color === 'green') return '#0c9415';
    if (color === 'badge-color') return 'rgba(93, 81, 21, 0.6)';
    return '#c6bb1a';
  };

  return (
    <div
      className={`average-rating ${type}`}
      style={{ backgroundColor: getBackgroundColor(backgroundColor) }}
    >
      <div className="average-rating-header">
        <div className="average-rating-header-facility">
          <div className="average-rating-header-title">
            {type === 'brief' && (
              <div data-testid="average-rating-icon" className="icon">
                <Vehicle />
              </div>
            )}
            {facilityName}
          </div>
          <div className="sub-title">{t('common.averageRating')}</div>
        </div>
        <div className="rating-box">
          <ReviewRating value={value} />
        </div>
      </div>
      {type === 'full' && !!ratings?.length && <LastRatings ratings={ratings} />}
    </div>
  );
};

export default AverageRating;
