/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Form, useFormApi } from 'informed';

import { Button, Pagination, Table } from '@ui-modules';
import { Dropdown, TextField } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { History, Search } from '@assets/svg/icons';
import { useRepository } from '@context';
import { MONTHS, YEARS, CURRENT_MONTH, CURRENT_YEAR } from '@common/constants';
import { useFacility } from '@common/hooks';
import { getErrors, Serializer } from '@common/utils';
import { ApiList, InvoiceHistory, InvoiceHistoryApi } from '@common/interfaces';
import './styles.scss';

interface IFormData {
  current_month: IDropdownOption;
  current_year: IDropdownOption;
  keyword: string;
}

enum ReducerActionType {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  KEYWORD = 'KEYWORD',
  PAGE = 'PAGE',
  CLEAR = 'CLEAR',
}

interface ISearchData {
  current_month: string;
  current_year: string;
  keyword: string;
  page: string;
}

interface IReducerAction {
  type: ReducerActionType;
  payload?: string;
}

const initialSearchData: ISearchData = {
  keyword: '',
  current_month: CURRENT_MONTH.value,
  current_year: CURRENT_YEAR.value,
  page: '1',
};

const searchDataReducer = (state: ISearchData, action: IReducerAction): ISearchData => {
  switch (action.type) {
    case ReducerActionType.MONTH:
      return { ...state, current_month: action.payload || '', page: '1' };
    case ReducerActionType.YEAR:
      return { ...state, current_year: action.payload || '', page: '1' };
    case ReducerActionType.KEYWORD:
      return { ...state, keyword: action.payload || '', page: '1' };
    case ReducerActionType.PAGE:
      return { ...state, page: action.payload || '' };
    case ReducerActionType.CLEAR:
      return { ...initialSearchData };
    default:
      return state;
  }
};

const HistoryInvoicePage: FC = () => {
  const { facilityId } = useFacility();
  const { financialRepo } = useRepository();
  const { t } = useTranslation();

  const [invoiceData, setInvoiceData] = useState<InvoiceHistory[]>([]);
  const [searchData, dispatch] = useReducer(searchDataReducer, initialSearchData);
  const [totalItems, setTotalItems] = useState(0);

  const { isLoading } = useQuery(
    ['get-invoices', searchData],
    () =>
      financialRepo.getInvoices(facilityId, {
        limit: 10,
        offset: (+searchData.page - 1) * 10,
        current_month: searchData.current_month,
        current_year: searchData.current_year,
        search: searchData.keyword,
      }),
    {
      onSuccess(data: ApiList<InvoiceHistoryApi>) {
        setTotalItems(data.count);
        setInvoiceData(data.results.map(Serializer.formatInvoiceHistory));
      },
      onError(error: any) {
        if (error.response) toast.error(getErrors(error.response.data));
      },
    },
  );

  const months = [{ value: '', label: t('common.allMonths') }, ...MONTHS];
  const years = [{ value: '', label: t('common.allYears') }, ...YEARS];

  const columns = [
    {
      dataIndex: 'referenceDate',
      key: 'referenceDate',
      title: t('invoice.issueDate'),
      render: (record: string) => (
        <span>{record ? format(new Date(record), 'dd.MM.yyyy') : ''}</span>
      ),
    },
    {
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      title: t('invoice.invoiceNumber'),
      render: (record: string) => <span>{record || '-'}</span>,
    },
    {
      key: 'bookedBy',
      title: t('invoice.bookedBy'),
      render: (record: any) => <span>{`${record.firstName} ${record.lastName}`}</span>,
    },
    {
      dataIndex: 'agency',
      key: 'agency',
      title: t('common.paxAgency'),
      render: (record: string) => <span>{record || '-'}</span>,
    },
    {
      dataIndex: 'totalNumberOfPassengers',
      key: 'totalNumberOfPassengers',
      title: 'Pax N°',
      render: (record: string) => <span>{record || '-'}</span>,
    },
    {
      dataIndex: 'transferType',
      key: 'transferType',
      title: t('common.transferType'),
      render: (record: string) => <span>{record ? record.replace('_', ' ') : '-'}</span>,
    },
    {
      dataIndex: 'totalKm',
      key: 'totalKm',
      title: 'Km',
      render: (record: string) => <span>{record || '0.00'}</span>,
    },
    {
      dataIndex: 'totalDuration',
      key: 'totalDuration',
      title: t('common.duration'),
      render: (record: string) => <span>{record || '00:00'}</span>,
    },
    {
      dataIndex: 'requestingUnit',
      key: 'requestingUnit',
      title: t('common.unit'),
      render: (record: string) => <span>{record || '-'}</span>,
    },
    {
      dataIndex: 'invoiceTotal',
      key: 'invoiceTotal',
      title: t('common.total'),
      render: (record: string) => <span>{record || '-'}</span>,
    },
    {
      dataIndex: 'bookingState',
      key: 'bookingState',
      title: t('common.status'),
      render: (record: string) => <span className="status">{record || '-'}</span>,
    },
    {
      dataIndex: 'pdf',
      key: 'actions',
      title: '',
      render: (record: string) => (
        <Button
          className="btn-download"
          text={t('common.btnDownload')}
          variant="submit"
          onClick={() => window.open(record)}
        />
      ),
    },
  ];

  const ResetButton: FC = React.memo(() => {
    const formApi = useFormApi();

    const handleReset = (): void => {
      formApi.setValue('keyword', initialSearchData.keyword);
      formApi.setValue('current_month', CURRENT_MONTH);
      formApi.setValue('current_year', CURRENT_YEAR);

      dispatch({ type: ReducerActionType.CLEAR });
    };

    return (
      <Button
        className="btn-reset"
        text={t('common.btnResetFilters')}
        variant="link"
        onClick={handleReset}
      />
    );
  });

  return (
    <div className="invoice-history-container">
      <div className="title">
        <History />
        {t('invoice.individualInvoiceListTitle')}
      </div>

      <div className="filters-container">
        <Form
          className="form"
          onSubmit={(formState) => {
            const { keyword } = formState.values as unknown as IFormData;
            dispatch({ type: ReducerActionType.KEYWORD, payload: keyword });
          }}
        >
          <div className="field-search">
            <TextField
              className="input"
              initialValue={initialSearchData.keyword}
              name="keyword"
              placeholder={t('invoice.inputSearchByInvoiceNumber')}
            />
            <Button className="btn-search" icon={<Search />} type="submit" variant="primary" />
            <ResetButton />
          </div>

          <div className="filters">
            <div className="field month">
              <Dropdown
                defaultValue={CURRENT_MONTH}
                label={t('common.month')}
                name="current_month"
                options={months}
                onChange={({ value }: IDropdownOption) => {
                  dispatch({ type: ReducerActionType.MONTH, payload: value });
                }}
              />
            </div>
            <div className="field year">
              <Dropdown
                name="current_year"
                defaultValue={CURRENT_YEAR}
                label={t('common.year')}
                options={years}
                onChange={({ value }: IDropdownOption) => {
                  dispatch({ type: ReducerActionType.YEAR, payload: value });
                }}
              />
            </div>
          </div>
        </Form>
      </div>

      <div className="row">
        <Table
          columns={columns}
          data={invoiceData}
          isLoading={isLoading}
          rowKey="invoiceNumber"
          variant="light"
        />
      </div>

      <div className="footer">
        {totalItems > 10 ? (
          <Pagination
            selectedPage={+searchData.page}
            showJumper
            totalPages={totalItems}
            onPageChange={(selected: number): void =>
              dispatch({ type: ReducerActionType.PAGE, payload: selected.toString() })
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default HistoryInvoicePage;
