import { AxiosInstance } from 'axios';

class AxiosRepository {
  public constructor(private axiosClient: AxiosInstance) {}

  public get = this.axiosClient.get;

  public patch = this.axiosClient.patch;

  public post = this.axiosClient.post;

  public put = this.axiosClient.put;

  public delete = this.axiosClient.delete;

  public options = this.axiosClient.options;
}

export default AxiosRepository;
