/* eslint-disable react/destructuring-assignment */
/* istanbul ignore file */
function Offline(props: any) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.562 17.25a.685.685 0 01-.486-.202L.952 1.924a.687.687 0 11.972-.972l15.124 15.124a.687.687 0 01-.486 1.174zM13.812 10.066a.683.683 0 01-.444-.163 6.858 6.858 0 00-1.424-.931.688.688 0 01.603-1.236c.614.3 1.19.676 1.71 1.118a.686.686 0 01-.445 1.212z"
        fill={props.fill}
      />
      <path
        d="M4.188 10.066a.687.687 0 01-.442-1.215 8.221 8.221 0 013.879-1.794.687.687 0 11.232 1.356 6.835 6.835 0 00-3.229 1.493.683.683 0 01-.44.16zM16.274 7.625a.685.685 0 01-.455-.172 10.367 10.367 0 00-7.65-2.546.687.687 0 11-.11-1.37 11.744 11.744 0 018.67 2.885.688.688 0 01-.455 1.203zM1.726 7.625a.688.688 0 01-.455-1.203 11.596 11.596 0 013.435-2.104.687.687 0 01.504 1.279 10.223 10.223 0 00-3.028 1.855.685.685 0 01-.456.173zM11.392 12.513a.684.684 0 01-.398-.127 3.442 3.442 0 00-3.982 0 .688.688 0 01-.796-1.121 4.817 4.817 0 015.574 0 .688.688 0 01-.398 1.248zM9 15.187a.678.678 0 01-.687-.688c0-.179.075-.357.199-.488a.721.721 0 01.976 0c.124.13.2.31.2.488a.678.678 0 01-.688.688z"
        fill={props.fill}
      />
    </svg>
  );
}

export default Offline;
