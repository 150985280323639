import { IChecklistAnswer, IVehiclesChecklistDetails } from '@common/interfaces';

export const getChecklistAnswers = (checklist?: IVehiclesChecklistDetails): IChecklistAnswer[] => {
  const answers: IChecklistAnswer[] = [];

  if (checklist?.answers && Object.keys(checklist.answers).length) {
    Object.values(checklist?.answers).forEach((category) => {
      category.forEach((answer) => {
        answers.push(answer);
      });
    });
  } else if (checklist?.questions?.items) {
    Object.values(checklist.questions.items).forEach((category) => {
      category.forEach((question) => {
        answers.push({
          ...question,
          answer: 'no',
          imagesUrl: [],
          imageUrl: '',
          note: '',
          question: question.questionEn,
          tooltip: question.tooltip,
        });
      });
    });
  }

  return answers;
};
