import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@ui-modules';
import './Confirmation.styles.scss';

interface ConfirmationProps {
  message: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
}

const Confirmation: FC<ConfirmationProps> = ({ message, onCancel, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <section className="modal-confirmation">
      <div className="modal-confirmation-message">
        <p>{message}</p>
      </div>

      <div className="modal-confirmation-buttons">
        <Button text={t('common.no')} variant="danger" onClick={onCancel} />
        <Button text={t('common.yes')} variant="submit" onClick={onConfirm} />
      </div>
    </section>
  );
};

export default Confirmation;
