import { type FC, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, isAfter, isEqual } from 'date-fns';
import { Form, useFieldState, useFormApi } from 'informed';

import { Button } from '@unbooking/ui-modules';
import { DateField } from '@ui-modules/informed';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { formatDateString, requiredField } from '@common/utils';
import { CheckboxCustom, Loader, RadioGroupInformed } from '@components';
import { IRadio } from '@common/interfaces';
import './styles.scss';

export interface FieldProps {
  isSameDay: boolean;
  setSameDay: (value: boolean) => void;
}

export interface FormStateProps {
  deleted?: boolean;
  rangeEnd?: string;
  rangeStart: string;
  reason?: string;
}

export interface UnavailabilityFormProps {
  id: string;
  label: string;
  type: 'driver' | 'vehicle';
  onSubmit: () => void;
}

const RangeStartField: FC<FieldProps> = ({ isSameDay, setSameDay }) => {
  const { t } = useTranslation();
  const formApi = useFormApi();
  const { value: endDate } = useFieldState('rangeEnd');

  return (
    <div className="field">
      <DateField
        inline
        label={t('common.fromIncluded')}
        maxDate={!isSameDay ? (endDate as Date) : undefined}
        minDate={new Date()}
        name="rangeStart"
        validate={requiredField}
        onChange={(value) => {
          if (isEqual(value as Date, endDate as Date)) setSameDay(true);
          if (isSameDay || isAfter(value as Date, endDate as Date)) {
            formApi.setValue('rangeEnd', value);
          }
        }}
      />
    </div>
  );
};

const RangeEndField: FC<FieldProps> = ({ isSameDay, setSameDay }) => {
  const { t } = useTranslation();
  const { value: startDate } = useFieldState('rangeStart');

  const [newEndDate, setNewEndDate] = useState<Date | null>(null);

  return (
    <div className={`field ${!startDate || isSameDay ? 'disabled' : ''}`}>
      <DateField
        inline
        label={t('common.toIncluded')}
        minDate={!isSameDay ? (startDate as Date) : undefined}
        name="rangeEnd"
        validate={requiredField}
        selected={isSameDay ? (startDate as Date) : newEndDate}
        onChange={(value) => {
          if (isEqual(startDate as Date, value as Date)) setSameDay(true);
          setNewEndDate(value as Date);
        }}
      />
    </div>
  );
};

const CheckboxField: FC<FieldProps> = ({ isSameDay, setSameDay }) => {
  const { t } = useTranslation();
  const formApi = useFormApi();
  const { value: startDate } = useFieldState('rangeStart');

  return (
    <CheckboxCustom
      isChecked={isSameDay}
      label={t('common.sameDay')}
      name="sameDay"
      variant="white"
      onChange={(value) => {
        setSameDay(value);
        if (value && startDate) {
          formApi.setValue('rangeEnd', startDate);
        }
      }}
    />
  );
};

const UnavailabilityForm: FC<UnavailabilityFormProps> = ({ id, label, type, onSubmit }) => {
  const { facilityId } = useFacility();
  const { mobilityRepository } = useRepository();
  const { t } = useTranslation();

  const [period, setPeriod] = useState<FormStateProps | null>();
  const [periodErrors, setPeriodErrors] = useState<string[]>([]);
  const [periodExist, setPeriodExist] = useState<boolean>(false);
  const [sameDay, setSameDay] = useState<boolean>(false);

  const { mutate: updateUnavailabilities, isLoading } = useMutation<
    unknown,
    any,
    { fromData: FormStateProps }
  >(
    'update-unavailabilities',
    ({ fromData }) =>
      mobilityRepository.updateUnavailabilities(facilityId, id, type, {
        since_date: fromData.rangeStart,
        ...(fromData?.deleted ? { deleted: true } : {}),
        ...(fromData?.rangeEnd ? { until_date: fromData?.rangeEnd } : {}),
        ...(fromData?.reason && type === 'vehicle' ? { reason: Number(fromData.reason) } : {}),
      }),
    {
      onSuccess: () => {
        setPeriod(null);
        onSubmit();
      },
      onError: (error) => {
        if (error.response) {
          setPeriodErrors(error?.response?.data?.errors);
          setPeriodExist(true);
        }
      },
    },
  );

  const vehicleReasonOptions: IRadio[] = [
    { value: '1', label: t('mobility.vehicleReasonRegularMaintenance') },
    { value: '2', label: t('mobility.vehicleReasonExtraordinaryRepair') },
    { value: '3', label: t('mobility.vehicleReasonForDisposal') },
  ];

  return (
    <Loader spinning={isLoading}>
      <Form
        className="form-unavailability"
        onSubmit={(formState: Record<string, unknown>) => {
          const state = { ...(formState.values as FormStateProps) };

          const start = new Date(state.rangeStart);
          const end = new Date(state?.rangeEnd!);

          if (isAfter(start, end)) {
            toast.error(t('common.errorMsgDateRange'));
          } else {
            if (isEqual(start, end)) setSameDay(true);

            setPeriod({
              rangeEnd: format(end, 'yyyy-MM-dd'),
              rangeStart: format(start, 'yyyy-MM-dd'),
              reason: state.reason,
            });
          }
        }}
      >
        {!period ? (
          <div className="form-box field-calendar">
            <h2>
              {type === 'driver'
                ? `${t('mobility.selectWhen')} ${label} ${t('mobility.willBeUnavailable')}`
                : `${t('mobility.selectWhy')} ${label} ${t('mobility.willBeUnavailable')}`}
            </h2>

            {type === 'vehicle' ? (
              <div className="row">
                <RadioGroupInformed
                  initialValue="1"
                  name="reason"
                  options={vehicleReasonOptions}
                  type="white"
                />
              </div>
            ) : null}

            <div className="row">
              <RangeStartField isSameDay={sameDay} setSameDay={setSameDay} />
              <RangeEndField isSameDay={sameDay} setSameDay={setSameDay} />
            </div>

            <div className="footer">
              <CheckboxField isSameDay={sameDay} setSameDay={setSameDay} />

              <div className="buttons">
                <Button
                  className="btn btn-disable"
                  text={`${t('common.disable')} ${
                    type === 'vehicle' ? t('common.vehicle') : t('common.driver')
                  }`}
                  type="button"
                  variant="danger"
                  onClick={() => {
                    setPeriod({
                      deleted: true,
                      rangeStart: format(new Date(), 'yyyy-MM-dd'),
                    });
                  }}
                />

                <Button
                  className="btn btn-submit"
                  text={t('common.btnConfirmDates')}
                  type="submit"
                  variant="submit"
                />
              </div>
            </div>
          </div>
        ) : null}

        {period ? (
          <div className="confirm-box">
            {period.deleted ? (
              <>
                <h2>{`${label} ${
                  periodExist ? t('mobility.willNotBeDisabled') : t('mobility.willBeDisabled')
                }`}</h2>
                <div className="row">
                  <div className="column">
                    <div className="period-label">{t('common.fromIncluded')}</div>
                    <div className="period-date">
                      {formatDateString(period.rangeStart, 'yyyy-MM-dd', 'dd/MM/yyyy')}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2>{`${label} ${
                  periodExist ? t('mobility.willNotBeUnavailable') : t('mobility.willBeUnavailable')
                }`}</h2>
                <div className="row">
                  {sameDay ? (
                    <div className="column">
                      <div className="period-label">{t('mobility.oneDay')}</div>
                      <div className="period-date">
                        {formatDateString(period.rangeStart, 'yyyy-MM-dd', 'dd/MM/yyyy')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="column">
                        <div className="period-label">{t('common.fromIncluded')}</div>
                        <div className="period-date">
                          {formatDateString(period.rangeStart, 'yyyy-MM-dd', 'dd/MM/yyyy')}
                        </div>
                      </div>
                      <div className="column">
                        <div className="period-label">{t('common.toIncluded')}</div>
                        <div className="period-date">
                          {formatDateString(period?.rangeEnd!, 'yyyy-MM-dd', 'dd/MM/yyyy')}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {periodExist ? (
              <div className="row">
                {period.deleted ? (
                  <div className="column">
                    <div className="status-exist-warning">
                      {periodErrors?.length
                        ? periodErrors.map((msg) => <p key={msg}>{msg}</p>)
                        : null}
                    </div>
                  </div>
                ) : (
                  <div className="column">
                    <div className="status-exist-warning">
                      {periodErrors?.length
                        ? periodErrors.map((msg) => <p key={msg}>{msg}</p>)
                        : null}
                    </div>
                    <div className="status-exist-message">
                      {t('common.btnChange')} {type === 'vehicle' ? 'the vehicle' : 'driver'}{' '}
                      {t('mobility.statusExistMsg')}
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            <div className="footer">
              {periodExist ? (
                <Button
                  className="btn btn-back"
                  text={t('common.btnBack')}
                  type="button"
                  variant="transparent"
                  onClick={() => {
                    setPeriod(null);
                    setPeriodExist(false);
                  }}
                />
              ) : (
                <Button
                  className="btn btn-submit"
                  text={t('common.btnConfirm')}
                  type="button"
                  variant="submit"
                  onClick={() => updateUnavailabilities({ fromData: period })}
                />
              )}
            </div>
          </div>
        ) : null}
      </Form>
    </Loader>
  );
};

export default UnavailabilityForm;
