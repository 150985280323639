/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';
import { useFieldState } from 'informed';

import { DateField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

const DropoffDateField: FC = () => {
  const { value: pickupDate } = useFieldState('pickupDate');

  return (
    <div className="field" data-testid="planner-drive-form-field-dropoff-date">
      <DateField
        className="input"
        label={t('bookingDetails.dropoffDate')}
        minDate={pickupDate as Date}
        name="dropoffDate"
        showMonthDropdown
        showYearDropdown
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DropoffDateField;
