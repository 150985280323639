/* istanbul ignore file */
import { memoize } from 'lodash';
import { AxiosInstance } from 'axios';
import { StatusCode } from '@common/types';

export const getToken = memoize(async (axios: AxiosInstance, client = 'web') => {
  const { status, data } = await axios.get(`/subscription-tokens/${client}`);

  if (status === StatusCode.SuccessOK) {
    const { token } = data;
    return token;
  }
});
