/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import cn from 'classnames';

import { Button } from '@unbooking/ui-modules';
import { ArrowRound, ChecklistAlert, ChecklistCritical, ChecklistIssue } from '@assets/svg/icons';
import { IChecklistAnswer, IVehiclesChecklistDetails } from '@common/interfaces';
import { examineChecklistStatus, getChecklistAnswers } from '@pages/VehiclesChecklistPage/utils';
import OutsideAlerter from '@components/OutsideAlerter';
import './styles.scss';

interface IDriverInfoPopup {
  checklist: IVehiclesChecklistDetails;
  onClosePopup: () => void;
}

const Answer: FC<{ answer: IChecklistAnswer }> = ({ answer }): JSX.Element => (
  <li className="issue">
    {answer.isCritical ? <ChecklistAlert className="critical-issue" /> : null}
    {answer.question}
    {answer.note ? <q>{answer.note}</q> : null}
    {answer.imagesUrl?.length
      ? answer.imagesUrl.map((img, i) => <img src={img} alt="issue" key={i} />)
      : null}
  </li>
);

export const DriverInfoPopup: FC<IDriverInfoPopup> = ({ checklist, onClosePopup }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { status: type } = examineChecklistStatus(checklist);
  const answers = getChecklistAnswers(checklist);

  const issuesText =
    type === 'issues'
      ? t('mobility.issuesTextVehicleCanStart')
      : t('mobility.issuesTextBeforeStarting');

  const issues = answers.filter((answer) => answer.answer !== 'yes');

  return (
    <OutsideAlerter onClickOutside={onClosePopup}>
      <aside className="driver-info-popup" data-testid="driver-info-popup">
        <header
          className={cn('header', {
            'header-issue': type === 'issues',
            'header-critical': type === 'critical',
          })}
        >
          <div
            className={cn('icon-container', {
              'icon-container-issue': type === 'issues',
              'icon-container-critical': type === 'critical',
            })}
          >
            {type === 'issues' ? <ChecklistIssue /> : <ChecklistCritical />}
          </div>
          <h2>{type === 'issues' ? t('mobility.issues') : t('mobility.criticalIssue')}</h2>
          <button type="button" onClick={onClosePopup}>
            <ArrowRound className="close-icon" type="bottom" />
          </button>
        </header>

        <div className="content">
          <div className="main-info">
            <h4 className="date">
              {format(parse(checklist.created, 'dd/MM/yyyy', new Date()), 'dd MMMM yyyy')}
            </h4>
            <h5>{t('mobility.checklistCompletedByDriver')}</h5>
            <h3 className="driver">
              {checklist.driverFirstName && checklist.driverLastName
                ? `${checklist.driverFirstName} ${checklist.driverLastName}`
                : 'Unknown'}
            </h3>
            <h5>{t('mobility.forVehicle')}</h5>
            <div className="vehicle">
              <div className="image">
                <img src={checklist.vehicleImage} alt="Vehicle" />
              </div>
              <h4>
                {checklist.vehicleModelName} / {checklist.plateNumber}
              </h4>
            </div>
            <h5 className="description">{issuesText}</h5>
          </div>

          <div className="issues">
            {issues.length > 4 ? (
              <>
                {issues.slice(0, 5).map((answer, i) => (
                  <Answer answer={answer} key={i} />
                ))}
                <p>
                  + {issues.length - 4} {t('mobility.otherIssues')}
                </p>
              </>
            ) : (
              issues.map((answer, i) => <Answer answer={answer} key={i} />)
            )}
          </div>

          <div className="footer">
            <Button
              text={t('mobility.btnGoToChecklist')}
              variant="transparent"
              onClick={() => navigate(0)}
            />
          </div>
        </div>
      </aside>
    </OutsideAlerter>
  );
};
