/* istanbul ignore file */
import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { ILastRatings, FeedbackLatestListApi } from '@common/interfaces';
import AverageRating from '@components/AverageRating';

const FacilityAverageRating: FC = () => {
  const {
    facility: { agencyId, avgRating, categoryName, city, country },
    facilityId,
  } = useFacility();

  const { feedbackRepository } = useRepository();

  const [feedbacks, setFeedbacks] = useState<ILastRatings[]>([]);

  useQuery('get-feedbacks', () => feedbackRepository.getLatestFeedbacks(facilityId, { agencyId }), {
    enabled: Boolean(agencyId && facilityId),
    retry: false,
    onSuccess: (data: FeedbackLatestListApi[]) => {
      setFeedbacks(
        data?.map(
          (f: FeedbackLatestListApi): ILastRatings => ({
            date: f.created,
            id: f.uuid,
            refCode: f.reference_code,
            value: f.rate,
          }),
        ),
      );
    },
  });

  return city && country ? (
    <AverageRating
      backgroundColor="badge-color"
      facilityName={`${city} ${categoryName}, ${country}`}
      ratings={feedbacks}
      type="full"
      value={Number(avgRating?.toFixed(1)) || 0}
    />
  ) : null;
};

export default FacilityAverageRating;
