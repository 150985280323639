import { Link, useMatch } from 'react-router-dom';
import cn from 'classnames';

import { HomeOffice } from '@assets/svg/icons';
import { Crumb } from '@common/interfaces';
import './RootLayout.styles.scss';

const Navigation = ({ crumbs, homeUrl }: { crumbs: Crumb[]; homeUrl: string }) => {
  if (crumbs.length === 0) return null;

  return (
    <section className="ddo-nav">
      {crumbs.map(({ name, path }) =>
        path === homeUrl ? (
          <a className={cn('ddo-nav-item', 'ddo-nav-item-icon')} href={homeUrl} key={path}>
            <HomeOffice />
          </a>
        ) : (
          <Link key={path} className="ddo-nav-item" to={path}>
            {name}
          </Link>
        ),
      )}
    </section>
  );
};

interface LayoutProps {
  routes: Crumb[];
  route: Crumb;
  homeUrl: string;
  node?: JSX.Element;
}

const RootLayout = ({ routes, route, homeUrl, node }: LayoutProps) => {
  const { Component } = route;
  const match = useMatch(route.path);
  const routeParams: Record<string, any> = match?.params!;
  const bgStyle = route.bgImg ? { style: { background: `url("${route.bgImg}") center` } } : {};

  const resolvePath = (path: string) =>
    routeParams
      ? Object.keys(routeParams).reduce(
          (resolvedPath, param) => resolvedPath.replace(`:${param}`, routeParams[param]),
          path,
        )
      : path;

  const crumbs = routes
    .filter(({ path }) => path !== '/' && route.path.includes(path))
    .map(({ path, ...rest }) => ({
      ...rest,
      path: resolvePath(path),
    }));

  return (
    <main
      className={cn('ddo-main-layout', { 'bg-image': route.bgImg, 'bg-color': !route.bgImg })}
      {...bgStyle}
    >
      <div className="ddo-page-wrapper">
        {route.showNav && <Navigation crumbs={crumbs} homeUrl={homeUrl} />}
        <Component {...route.data} />
        {node}
      </div>
    </main>
  );
};

export default RootLayout;
