import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@unbooking/ui-modules';
import { TravelRequestItineraries } from '@common/interfaces';

interface TripListProps {
  data: TravelRequestItineraries[];
}

export const TripList: FC<TripListProps> = ({ data }) => {
  const { t } = useTranslation();

  const columns = [
    {
      dataIndex: 'pickupDate',
      key: 'pickupDate',
      title: t('bookingDetails.pickupDate'),
    },
    {
      dataIndex: 'pickupTime',
      key: 'pickupTime',
      title: t('bookingDetails.pickupTime'),
    },
    {
      dataIndex: 'pickupTown',
      key: 'pickupTown',
      title: t('bookingDetails.pickupTown'),
    },
    {
      dataIndex: 'dropoffDate',
      key: 'dropoffDate',
      title: t('bookingDetails.dropoffDate'),
    },
    {
      dataIndex: 'dropoffTime',
      key: 'dropoffTime',
      title: t('bookingDetails.dropoffTime'),
    },
    {
      dataIndex: 'dropoffTown',
      key: 'dropoffTown',
      title: t('bookingDetails.dropoffTown'),
    },
  ];

  return (
    <div className="table-trips">
      <Table columns={columns} data={data} variant="dark" />
    </div>
  );
};

export default memo(TripList);
