import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import type { SelectProps as SelectMantineProps } from '@mantine/core';
import { Select as SelectMantine } from '@mantine/core';

export type SelectProps<T extends FieldValues> = {
  options: { label: string; value: string }[];
} & UseControllerProps<T> &
  Omit<SelectMantineProps, 'data' | 'defaultValue' | 'value'>;

export function Select<T extends FieldValues>({
  control,
  defaultValue,
  name,
  options,
  required,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: SelectProps<T>) {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <SelectMantine
      classNames={{ error: 'field-error', wrapper: 'select-wrapper' }}
      data={options}
      error={!value ? fieldState.error?.message : undefined}
      value={value}
      withAsterisk={!!required}
      onChange={(val) => {
        fieldOnChange(val);
        onChange?.(val);
      }}
      {...field}
      {...props}
    />
  );
}
