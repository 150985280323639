import { FC, useRef, useEffect, RefObject, ReactNode } from 'react';

const useOutsideAlerter = (ref: RefObject<HTMLDivElement>, onClickOutside: () => void): void => {
  useEffect(() => {
    const handleClickOutside = (event: any): void => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, ref]);
};

interface OutsideAlerterProps {
  children: ReactNode;
  onClickOutside: () => void;
}

const OutsideAlerter: FC<OutsideAlerterProps> = ({ children, onClickOutside }) => {
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, onClickOutside);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
