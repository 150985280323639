/* istanbul ignore file */
import type { FC } from 'react';
import ReactStars from 'react-stars';

interface RatingStarsProps {
  activeColor?: string;
  count?: number;
  inactiveColor?: string;
  half?: boolean;
  readOnly?: boolean;
  size?: 'normal' | 'big';
  value?: number;
  onChange?: (newValue: number) => void;
}

const RatingStars: FC<RatingStarsProps> = ({
  activeColor = 'rgba(255,255,255,1)',
  count = 5,
  inactiveColor = 'rgba(255,255,255,0.4)',
  half = true,
  readOnly = false,
  size = 'normal',
  value = 0,
  onChange,
}) => {
  const pixelSize = size === 'normal' ? 16 : 30;

  return (
    <ReactStars
      count={count}
      half={half}
      size={pixelSize}
      edit={!readOnly}
      value={value}
      color1={inactiveColor}
      color2={activeColor}
      onChange={onChange}
    />
  );
};

export default RatingStars;
