/* istanbul ignore file */
import { t } from 'i18next';
import { Button } from '@components';
import { Check } from '@assets/svg/icons';
import '../styles/modal.scss';

const ButtonSubmit = () => (
  <Button
    className="btn-submit"
    data-testid="planner-drive-form-btn-save"
    leftIcon={<Check />}
    text={t('common.btnConfirm')}
    type="submit"
    variant="submit"
  />
);

export default ButtonSubmit;
