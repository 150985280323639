import type { FC } from 'react';
import { RatingStars } from '@components';
import './ReviewRating.styles.scss';

interface ReviewRatingProps {
  activeStarColor?: string;
  backgroundColor?: string;
  inactiveColor?: string;
  orientation?: 'left' | 'right';
  textColor?: string;
  value?: number;
}

const ReviewRating: FC<ReviewRatingProps> = ({
  activeStarColor = 'rgba(255,255,255,1)',
  backgroundColor = 'transparent',
  inactiveColor = 'rgba(255,255,255,0.4)',
  orientation,
  textColor = '#fff',
  value,
}) => (
  <div className={`review-rating ${orientation}`} style={{ backgroundColor, color: textColor }}>
    <div className="review-rating-value" data-testid="review-rating-value">
      {value}
    </div>
    <RatingStars
      activeColor={activeStarColor}
      inactiveColor={inactiveColor}
      readOnly
      value={value}
    />
    {orientation === 'left' && (
      <div
        className="review-rating-arrow arrow-left"
        style={{
          borderColor: `transparent ${backgroundColor} transparent transparent`,
        }}
      />
    )}
    {orientation === 'right' && (
      <div
        className="review-rating-arrow arrow-right"
        style={{
          borderColor: `transparent transparent transparent ${backgroundColor}`,
        }}
      />
    )}
  </div>
);

export default ReviewRating;
