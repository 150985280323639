import { IFacilityRepository } from '@common/interfaces';
import AxiosRepository from './axios.repository';

class FacilityRepository extends AxiosRepository implements IFacilityRepository {
  async getFacility(agencyId: string, facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/details/?agency=${agencyId}`);

    return data;
  }

  async getLocationFiles(agencyId: string, category: string, facilityId: string, lang: string) {
    const { data } = await this.get(
      `/facilities/${facilityId}/files/${category}/?agency=${agencyId}`,
      {
        headers: {
          'Accept-Language': lang,
        },
      },
    );

    return data;
  }
}

export default FacilityRepository;
