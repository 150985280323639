import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@unbooking/ui-modules';
import { BookingRoute } from '@common/interfaces';

interface DrivesTableProps {
  data: BookingRoute[];
}

export const DrivesTable: FC<DrivesTableProps> = ({ data }) => {
  const { t } = useTranslation();

  const columns = [
    {
      dataIndex: 'pickupDate',
      key: 'pickupDate',
      title: t('bookingDetails.pickupDate'),
    },
    {
      dataIndex: 'pickupTime',
      key: 'pickupTime',
      title: t('bookingDetails.pickupTime'),
    },
    {
      dataIndex: 'pickupLocation',
      key: 'pickupLocation',
      title: t('bookingDetails.pickupLocation'),
    },
    {
      dataIndex: 'pickupTown',
      key: 'pickupTown',
      title: t('bookingDetails.pickupTown'),
    },
    {
      dataIndex: 'dropoffDate',
      key: 'dropoffDate',
      title: t('bookingDetails.dropoffDate'),
    },
    {
      dataIndex: 'dropoffTime',
      key: 'dropoffTime',
      title: t('bookingDetails.dropoffTime'),
    },
    {
      dataIndex: 'dropoffLocation',
      key: 'dropoffLocation',
      title: t('bookingDetails.dropoffLocation'),
    },
    {
      dataIndex: 'dropoffTown',
      key: 'dropoffTown',
      title: t('bookingDetails.dropoffTown'),
    },
    {
      dataIndex: 'stateDisplay',
      key: 'stateDisplay',
      render: (record: string) => <div className="col-drive-status">{record}</div>,
    },
  ];

  return (
    <div className="table-drives">
      <Table columns={columns} data={data} rowKey="drivePK" variant="dark" />
    </div>
  );
};

export default memo(DrivesTable);
