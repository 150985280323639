import type { FC } from 'react';
import { Loader } from '@components';
import './Panel.styles.scss';

interface PanelProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  color?: string;
  isLoading?: boolean;
  title?: JSX.Element | string;
  width?: string;
}

const Panel: FC<PanelProps> = ({
  children,
  className = '',
  color,
  isLoading = false,
  title,
  width,
}) => (
  <section
    className={`surface-panel ${className}`}
    style={{ color: `${color || ''}`, width: `${width || ''}` }}
  >
    <Loader fullScreen spinning={isLoading} />
    {title && <h1 className="surface-panel-title">{title}</h1>}
    {children}
  </section>
);

export default Panel;
