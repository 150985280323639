/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ChangeEvent, ComponentProps, ElementType, ReactNode } from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { FormContext } from './form';
import { InputDatePicker, InputDropdown, InputMultiSelect, InputText } from '../components';
import { useSchema } from '../utils';

export type FieldType = 'date-picker' | 'dropdown' | 'multi-select' | 'text';
export type FieldProps<T extends ElementType> = {
  className?: string;
  input: FieldType;
  name: string;
  wide?: boolean;
} & ComponentProps<T>;

export function Field<T extends ElementType>({
  className,
  input = 'text',
  name,
  onChange,
  wide,
  ...props
}: FieldProps<T>) {
  const { mode } = useContext(FormContext);
  const {
    formState: { isSubmitted },
    clearErrors,
    register,
    resetField,
    setError,
    setValue,
    watch,
  } = useFormContext();

  const { error, required, validate } = useSchema(name);

  const handleValidate = useCallback(
    (value: any, hasError: boolean) => {
      try {
        validate(value);
        clearErrors(name);
      } catch (e: any) {
        if (hasError) setError(name, { ...e });
      }
    },
    [clearErrors, name, setError, validate],
  );

  const handleDatePickerChange = useCallback(
    (date: Date | null) => {
      handleValidate(date, isSubmitted || mode === 'onChange');
      setValue(name, date);
      onChange?.(date);
    },
    [handleValidate, isSubmitted, mode, name, onChange, setValue],
  );

  const handleDropdownChange = useCallback(
    (value: string | null) => {
      handleValidate(value, isSubmitted || mode === 'onChange');
      setValue(name, value);
      onChange?.(value);
    },
    [handleValidate, isSubmitted, mode, name, onChange, setValue],
  );

  const handleMultiSelectChange = useCallback(
    (values: string[]) => {
      handleValidate(values, isSubmitted || mode === 'onChange');
      setValue(name, values);
      onChange?.(values);
    },
    [handleValidate, isSubmitted, mode, name, onChange, setValue],
  );

  const handleTextInputChange = useCallback(
    (e: ChangeEvent<any>) => {
      handleValidate(e.target.value, isSubmitted || mode === 'onChange');
      setValue(name, e.target.value);
      onChange?.(e);
    },
    [handleValidate, isSubmitted, mode, name, onChange, setValue],
  );

  const handleChange = useCallback(
    (e: any) => {
      switch (input) {
        case 'text':
          handleTextInputChange(e as ChangeEvent<HTMLInputElement>);
          break;
        case 'date-picker':
          handleDatePickerChange(e as Date | null);
          break;
        case 'dropdown':
          handleDropdownChange(e as string | null);
          break;
        case 'multi-select':
          handleMultiSelectChange(e as string[]);
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input],
  );

  useEffect(() => {
    register(name);
    handleValidate(watch(name), isSubmitted);
    return () => {
      clearErrors(props.name);
      resetField(props.name);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FieldComponent = useMemo(() => {
    switch (input) {
      case 'text':
        return InputText;
      case 'date-picker':
        return InputDatePicker;
      case 'dropdown':
        return InputDropdown;
      case 'multi-select':
        return InputMultiSelect;
      default:
        return null;
    }
  }, [input]);

  if (!FieldComponent) return null;

  const baseProps = {
    error: error?.message as ReactNode,
    name,
    required,
    value: watch(name),
    onChange: handleChange,
  };

  const specificProps = {
    ...props,
    ...(input === 'dropdown' || input === 'multi-select' ? { options: props.options } : {}),
  };

  return (
    <div className={cn('field', { 'field-wide': wide }, className)}>
      <FieldComponent {...baseProps} {...specificProps} />
    </div>
  );
}

export default Field;
