/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/prop-types */
import { type FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import { Form } from 'informed';
import { v4 as uuid } from 'uuid';

import { Button, Table } from '@ui-modules';
import { TextField, DateField } from '@ui-modules/informed';
import { AgencyInvoiceServiceApi, AgencyInvoicePatch } from '@common/interfaces';
import { DateFormat } from '@common/types';
import { DeleteBlock } from '@components';
import { formatNumber, notEmptyValidator, processFormattedNumber } from '@common/utils';
import { CloseRound as Close, Plus } from '@assets/svg/icons';
import './styles.scss';

interface ChargesProps {
  data: AgencyInvoiceServiceApi[];
  updateInvoice: (data: Partial<AgencyInvoicePatch>) => void;
}

const InvoiceCharges: FC<ChargesProps> = ({ data, updateInvoice }) => {
  const { t } = useTranslation();

  const [forms, setForms] = useState<string[]>([]);

  const add = () => setForms((prev) => [...prev, uuid()]);
  const del = (id: string) => setForms((prev) => [...prev].filter((i) => i !== id));

  const tableData = data?.map((props: AgencyInvoiceServiceApi, idx: number) => {
    const { date, description, quantity, unit_price } = props;

    return {
      actions: (
        <DeleteBlock
          key={description}
          id={idx}
          deleteAction={() => {
            const newItems = data
              .filter((item) => JSON.stringify(props) !== JSON.stringify(item))
              .map((item) => ({ ...item, id: undefined }));
            updateInvoice({ services: newItems });
          }}
        />
      ),
      date: format(parse(date!, DateFormat.ApiDate, new Date()), DateFormat.DateByDots),
      description: description || '',
      id: idx,
      quantity,
      unit_price,
    };
  });

  const columns = useMemo(
    () => [
      {
        key: 'date',
        dataIndex: 'date',
        Header: 'Date',
      },
      {
        key: 'description',
        dataIndex: 'description',
        Header: 'Service',
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        Header: t('invoice.quantity'),
      },
      {
        key: 'unit_price',
        dataIndex: 'unit_price',
        Header: t('common.price'),
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        Header: '',
      },
    ],
    [t],
  );

  return (
    <section className="agency-invoice-charges-container">
      <div className="table">
        <Table columns={columns} data={tableData} locale={{ emptyText: 'No items' }} />
      </div>

      {forms.map((id: string) => (
        <Form
          className="form"
          key={id}
          onSubmit={(formState) => {
            const { date, description, quantity, unit_price } =
              formState.values as unknown as AgencyInvoiceServiceApi;

            updateInvoice({
              services: [
                ...data.map((item) => ({ ...item, id: undefined })),
                {
                  date: format((date || new Date()) as Date, DateFormat.ApiDate),
                  description,
                  quantity: +processFormattedNumber(quantity as unknown as string),
                  unit_price: +processFormattedNumber(unit_price as unknown as string),
                },
              ],
            });

            setForms([]);
          }}
        >
          <div className="form-row">
            <div className="form-cell">
              <DateField name="date" placeholder="Date" validate={notEmptyValidator} />
            </div>
            <div className="form-cell">
              <TextField
                name="description"
                placeholder={t('common.description')}
                validate={notEmptyValidator}
              />
            </div>
            <div className="form-cell">
              <TextField
                name="quantity"
                placeholder={t('invoice.quantity')}
                mask={formatNumber}
                validate={notEmptyValidator}
              />
            </div>
            <div className="form-cell">
              <TextField
                name="unit_price"
                placeholder={t('common.price')}
                mask={(value) => formatNumber(value, true)}
                validate={notEmptyValidator}
              />
            </div>
            <div className="form-actions">
              <Button
                className="confirm"
                text={t('common.btnConfirm')}
                type="submit"
                variant="primary"
              />
              <Button className="delete" icon={<Close />} variant="icon" onClick={() => del(id)} />
            </div>
          </div>
        </Form>
      ))}
      <div className="row">
        <Button icon={<Plus />} text="Add Service" variant="secondary" onClick={() => add()} />
      </div>
    </section>
  );
};

export default InvoiceCharges;
