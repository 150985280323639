import { forwardRef } from 'react';
import { Select, type SelectProps } from '@mantine/core';
import './Dropdown.styles.scss';

export type DropdownProps = Omit<SelectProps, 'data'> & {
  options: { label: string; value: string }[];
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  icon: JSX.Element;
  label: string;
}

const DropdownItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ icon, label, ...rest }: ItemProps, ref) => (
    <div ref={ref} {...rest}>
      {icon}
      <span>{label}</span>
    </div>
  ),
);

export default function Dropdown({
  defaultValue,
  name,
  options,
  required,
  variant = 'unstyled',
  onChange,
  ...props
}: DropdownProps) {
  return (
    <Select
      classNames={{
        dropdown: 'dropdown-field-list',
        icon: 'dropdown-field-icon',
        input: 'dropdown-field-input',
        item: 'dropdown-field-item',
        rightSection: 'dropdown-field-ctrl',
        wrapper: 'dropdown-field',
      }}
      data={options}
      itemComponent={DropdownItem}
      variant={variant}
      withAsterisk={!!required}
      onChange={onChange}
      {...props}
    />
  );
}
