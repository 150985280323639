/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import {
  IChecklistTab,
  IVehiclesChecklistDetails,
  VehicleChecklistDetailsApi,
} from '@common/interfaces';
import { formatDateString, getErrors } from '@common/utils';
import { Serializer, getChecklistAnswers } from '@pages/VehiclesChecklistPage/utils';
import {
  ChecklistAlert,
  ChecklistNoActive,
  ChecklistNoDisabled,
  ChecklistYesActive,
  ChecklistYesDisabled,
} from '@assets/svg/icons';
import { ChecklistTabs } from './components';
import './styles.scss';

const ChecklistDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    agencyName,
    facility: { agencyId, country, city, uuid: facilityId },
    facilityId: facilityIdOnUNBH,
  } = useFacility();

  const { vehiclesChecklistRepository } = useRepository();

  const relatedChecklistsFetched = useRef<boolean>(false);
  const [checklist, setChecklist] = useState<IVehiclesChecklistDetails>();
  const [checklistTabs, setChecklistTabs] = useState<IChecklistTab[]>([]);
  const [selectedChecklistId, setSelectedChecklistId] = useState<string>(id || '');

  useQuery(
    ['get-checklist-details', selectedChecklistId],
    () => vehiclesChecklistRepository.getChecklistDetails(facilityIdOnUNBH, selectedChecklistId),
    {
      onSuccess(data: VehicleChecklistDetailsApi) {
        setChecklist(Serializer.formatChecklistDetails(data));
      },
      onError(err: any) {
        toast.error(getErrors(err.response));
      },
    },
  );

  const { mutateAsync: getRelatedChecklists } = useMutation<
    unknown,
    unknown,
    { currentChecklist: IVehiclesChecklistDetails }
  >(['get-related-checklists-for-driver'], ({ currentChecklist }) => {
    const checklistCreatedDate = formatDateString(
      currentChecklist.created,
      'dd/MM/yyyy',
      'yyyy-MM-dd',
    );

    return vehiclesChecklistRepository.getChecklists(facilityIdOnUNBH, {
      createdAfter: checklistCreatedDate,
      createdBefore: checklistCreatedDate,
      driverId: currentChecklist.driverId!,
      agencyId,
      locationId: facilityId,
    });
  });

  const getRelatedChecklistsForDriver = useCallback(
    async (checklistData: IVehiclesChecklistDetails): Promise<void> => {
      let checklistTabsData: IChecklistTab[] = [];

      if (checklistData.driverId) {
        const relatedChecklistsData = (await getRelatedChecklists({
          currentChecklist: checklistData,
        })) as any;

        checklistTabsData = relatedChecklistsData.results
          .filter((c: any) => c.id !== checklistData.id)
          .map((c: any) => ({ ...c, main: false }));
        checklistTabsData.unshift({ ...checklistData, main: true });
      } else {
        checklistTabsData.push({ ...checklistData, main: true });
      }

      setChecklistTabs(checklistTabsData);
    },
    [getRelatedChecklists],
  );

  useEffect(() => {
    if (checklist && facilityId && !relatedChecklistsFetched.current) {
      getRelatedChecklistsForDriver(checklist);
      relatedChecklistsFetched.current = true;
    }
  }, [checklist, facilityId, getRelatedChecklistsForDriver]);

  const yesAnswerIcons = (
    <div className="icons-container">
      <ChecklistYesActive />
      <div className="icons-divider" />
      <ChecklistNoDisabled />
    </div>
  );

  const noAnswerIcons = (
    <div className="icons-container">
      <ChecklistYesDisabled />
      <div className="icons-divider" />
      <ChecklistNoActive />
    </div>
  );

  const notSubmittedAnswerIcons = (
    <div className="icons-container">
      <ChecklistYesDisabled />
      <div className="icons-divider" />
      <ChecklistNoDisabled />
    </div>
  );

  const answers = getChecklistAnswers(checklist);
  const driverName =
    checklist?.driverFirstName && checklist.driverLastName
      ? `${checklist?.driverFirstName} ${checklist?.driverLastName}`
      : t('checklist.unknownDriver');

  return (
    <section className="page-container checklist-details-page">
      <div className="header">
        <h2 className="title">
          {driverName} -{' '}
          <span>
            {agencyName} {country}, {city}
          </span>
        </h2>
        <h3>
          {checklist?.created
            ? formatDateString(checklist.created, 'dd/MM/yyyy', 'dd MMMM yyyy')
            : null}
        </h3>
      </div>
      {checklistTabs.length ? (
        <ChecklistTabs checklistTabs={checklistTabs} onTabSelect={setSelectedChecklistId} />
      ) : null}
      <table className="table-checklist">
        <thead>
          <tr>
            <th>{t('checklist.checkInformation')}</th>
            <th>{t('common.status')}</th>
            <th>{t('common.note')}</th>
            <th>{t('common.photo')}</th>
            <th>{t('checklist.remarksFromManager')}</th>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer) => (
            <tr data-testid="checklist-answer" key={answer.question}>
              <td>
                {answer.isCritical && answer.answer === 'no' ? (
                  <ChecklistAlert className="critical-icon" />
                ) : null}{' '}
                {answer.question}
              </td>
              <td>
                {checklist?.submitted
                  ? answer.answer === 'yes'
                    ? yesAnswerIcons
                    : noAnswerIcons
                  : notSubmittedAnswerIcons}
              </td>
              <td>{answer.note}</td>
              <td>
                {answer.imagesUrl?.length
                  ? answer.imagesUrl.map((img) => (
                      <img src={img} alt="answer-attachment" key={img} />
                    ))
                  : null}
              </td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default ChecklistDetailsPage;
