import { format } from 'date-fns';
import { ICalendarChecklistVehicle } from '@common/interfaces';

export const getUnavailability = (vehicle: ICalendarChecklistVehicle): string => {
  let unavailabilityLabel: string;
  let startDate: Date;
  let endDate: Date;

  if (vehicle.criticalIssue) {
    unavailabilityLabel = 'Critical Issue';
    startDate = new Date(vehicle.criticalIssue.start);
    endDate = new Date(vehicle.criticalIssue.end);
  } else {
    unavailabilityLabel = vehicle.currentUnavailability?.reasonLabel ?? '';
    startDate = new Date(vehicle.currentUnavailability?.sinceDate!);
    endDate = new Date(vehicle.currentUnavailability?.untilDate!);
  }

  const startDateString = format(startDate, 'd');
  const endDateString = format(endDate, 'd');
  const dateFormat = format(startDate, 'MMMM yyyy');

  if (startDateString === endDateString) {
    return `${unavailabilityLabel} on ${startDateString} ${dateFormat}!`;
  }

  return `${unavailabilityLabel} from ${startDateString} to ${endDateString} ${dateFormat}!`;
};
