import { useState } from 'react';
import { useQuery } from 'react-query';
import { useRepository } from '@context';
import { AvailableLocations, AvailableLocationsApi } from '@common/interfaces';
import { Serializer } from '@common/utils';

interface AvailableLocationsResponse {
  isLoading: boolean;
  locationNotFound: boolean;
  locations: AvailableLocations[];
  userLocation: AvailableLocations | undefined;
}

const useAvailableLocations = (facilityId?: string): AvailableLocationsResponse => {
  const { accountRepository } = useRepository();

  const [locationNotFound, setLocationNotFound] = useState(false);
  const [locations, setLocations] = useState<AvailableLocations[]>([]);
  const [userLocation, setUserLocation] = useState<AvailableLocations | undefined>();

  const { isLoading } = useQuery(
    'get-available-locations',
    () => accountRepository.getAvailableLocations(),
    {
      onSuccess: (data: AvailableLocationsApi[]) => {
        const mappedData = data?.map(Serializer.formatAvailableLocations);
        setLocations(mappedData);

        if (facilityId) {
          const foundLocation = mappedData.find((i) => i.id === +facilityId!);
          setUserLocation(foundLocation);
          setLocationNotFound(!foundLocation);
        }
      },
    },
  );

  return { isLoading, locationNotFound, locations, userLocation };
};

export default useAvailableLocations;
