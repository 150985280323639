import cn from 'classnames';
import { Button } from '@components';
import { useMaintenanceBanner } from '@common/hooks';
import { Close } from '@assets/svg/icons';
import Warning from '@assets/img/WarningIcon.png';
import './MaintenanceBanner.styles.scss';

export interface MaintenanceBannerProps {
  className?: string;
  switchName: string;
}

export default function MaintenanceBanner({ className, switchName }: MaintenanceBannerProps) {
  const { bannerMsg, closeBanner } = useMaintenanceBanner(switchName);

  if (!bannerMsg) return null;

  return (
    <div className={cn('maintenance-banner', className)}>
      <img src={Warning} alt="Warning" />
      <p className="maintenance-banner-msg">{bannerMsg}</p>
      <Button rightIcon={<Close />} variant="icon" onClick={closeBanner} />
    </div>
  );
}
