/* istanbul ignore file */
import { t } from 'i18next';
import type { IDropdownOption } from '@ui-modules/types';
import { Dropdown } from '@ui-modules/informed';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator, validateWithPax } from '@common/utils';
import '../styles/modal.scss';

const PaxField = ({ disabled, options }: { disabled?: boolean; options: IDropdownOption[] }) => {
  const { value: noPax } = useFieldState<boolean>('noPax');

  return (
    <div className="field" data-testid="planner-drive-form-field-pax">
      <Dropdown
        className="dropdown"
        isDisabled={disabled || noPax}
        label={t('common.pax')}
        name="pax"
        options={options}
        validate={validateWithPax(notEmptyValidator)}
      />
    </div>
  );
};

export default PaxField;
