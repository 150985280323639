import type { ComponentProps, FC } from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const DEFAULT_ITEM_HEIGHT = 25;

ChartJS.register(BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip);

interface ChartProps {
  agenda: string[];
  axis?: 'x' | 'y';
  chartHeight?: number;
  colors: string[];
  dataset?: {
    data: number[];
    label: string;
  }[];
  emptyText?: string;
  height?: number;
  hideLegend?: boolean;
  title?: string;
}

const createChartConfig = (props: ChartProps): ComponentProps<typeof Bar> => ({
  data: {
    labels: props.agenda || [],
    datasets: (props.dataset || []).map((item, idx) => ({
      ...item,
      backgroundColor: props.colors[idx] || '#c5d3e2',
      borderWidth: 0,
      maxBarThickness: 15,
    })),
  },
  options: {
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    indexAxis: props.axis || ('x' as const),
    color: 'white',
    plugins: {
      legend: {
        align: 'start',
        display: !props.hideLegend,
        position: 'top',
        labels: {
          boxHeight: 16,
          boxWidth: 16,
          font: { weight: '600' },
          padding: 30,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          color: 'white',
          font: {
            size: 12,
          },
        },
      },
      x: {
        ticks: {
          color: 'white',
        },
      },
    },
  },
});

const BarChart: FC<ChartProps> = (props) => {
  const { dataset, emptyText, title } = props;
  const height =
    dataset && dataset.length ? Math.max(dataset[0].data.length * DEFAULT_ITEM_HEIGHT, 250) : 250;

  return (
    <div className="chart bar">
      {title && <h3>{title}</h3>}

      {dataset?.length && (
        <div style={{ height, width: '100%' }}>
          <Bar {...createChartConfig(props)} />
        </div>
      )}

      {!dataset?.length && emptyText && <div className="chart-data-empty">{emptyText}</div>}
    </div>
  );
};

export default BarChart;
