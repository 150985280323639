import { forwardRef, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import FullCalendar, {
  CustomContentGenerator,
  DatesSetArg,
  EventContentArg,
  EventSourceInput,
} from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {
  CalendarVehiclesChecklistResourceProps,
  ICalendarChecklistVehicle,
} from '@common/interfaces';
import './Calendar.styles.scss';

interface CalendarProps {
  eventContent?: CustomContentGenerator<EventContentArg>;
  events: EventSourceInput;
  headerToolbarCalendar?: JSX.Element;
  resourceContent?: CustomContentGenerator<CalendarVehiclesChecklistResourceProps>;
  resources?: ICalendarChecklistVehicle[];
  onDoubleClick?: (resourceId: string) => void;
  onViewChange?: (payload: DatesSetArg) => void;
}

let doubleClickTimeoutId: NodeJS.Timeout | null = null;

const CalendarVehiclesChecklist = forwardRef<FullCalendar, CalendarProps>(
  (
    {
      eventContent,
      events,
      headerToolbarCalendar,
      resourceContent,
      resources,
      onDoubleClick,
      onViewChange,
    },
    calendarRef,
  ) => {
    const [isRendered, setRendered] = useState(false);

    useEffect(() => {
      if (headerToolbarCalendar) {
        const container = document.createElement('div');
        const toolbarContainer = document.getElementsByClassName('fc-toolbar-chunk')[1];

        container.className = 'fc-toolbar-calendar';

        createRoot(container).render(headerToolbarCalendar);

        if (toolbarContainer.hasChildNodes() && isRendered) {
          toolbarContainer.innerHTML = '';
        }

        toolbarContainer.appendChild(container);
      }

      setRendered(true);

      return () => {
        setRendered(false);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerToolbarCalendar]);

    return (
      <div className="fc-calendar-vehicles-checklist">
        <FullCalendar
          contentHeight="auto"
          datesSet={(payload: DatesSetArg) => onViewChange?.(payload)}
          dateClick={({ resource }) => {
            if (onDoubleClick) {
              if (doubleClickTimeoutId) {
                clearTimeout(doubleClickTimeoutId);
                doubleClickTimeoutId = null;
                return onDoubleClick(resource?.id || '');
              }

              doubleClickTimeoutId = setTimeout(() => {
                if (doubleClickTimeoutId) {
                  clearTimeout(doubleClickTimeoutId);
                  doubleClickTimeoutId = null;
                }
              }, 200);
            }
          }}
          dayMaxEvents
          eventContent={eventContent}
          eventOverlap={false}
          events={events}
          headerToolbar={{
            left: 'title prev next',
            center: '',
            right: '',
          }}
          initialView="resourceTimelineWeek"
          locale="en-GB" // 00:00 to 24:00
          plugins={[interactionPlugin, resourceTimelinePlugin]}
          ref={calendarRef}
          resources={resources}
          resourceAreaHeaderContent="&nbsp;"
          resourceAreaWidth="310px"
          resourceLabelContent={resourceContent}
          resourceOrder="" // disable sorting by default
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          slotDuration={{ days: 1 }}
          slotLabelFormat={{
            day: 'numeric', // Display numeric day (e.g., 1, 2)
            weekday: 'short', // Display short weekday names (e.g., Mon, Tue)
          }}
          stickyHeaderDates
          titleFormat={{ day: 'numeric', month: 'short', year: 'numeric' }}
        />
      </div>
    );
  },
);

CalendarVehiclesChecklist.displayName = 'CalendarVehiclesChecklist';

export default CalendarVehiclesChecklist;
