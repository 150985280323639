import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IMonthlySummary } from '@common/interfaces';
import { Loader } from '@components';
import { BalanceWallet } from '@assets/svg/icons';

interface IWFPMonthlySummary {
  data: IMonthlySummary | null;
  loading: boolean;
}

const MonthlySummary: FC<IWFPMonthlySummary> = ({ data, loading }) => {
  const { t } = useTranslation();

  return (
    <section className="monthly-summary-box">
      <div className="header">
        <BalanceWallet />
        {t('report.monthlySummary')}
      </div>

      <Loader spinning={loading}>
        <div className="row">
          <div className="label">{t('report.monthlyTrips')}</div>
          <div className="value">{data?.drives}</div>
        </div>
        <div className="row">
          <div className="label">{t('report.monthlyBookings')}</div>
          <div className="value">{data?.bookingsCount}</div>
        </div>
        <div className="row">
          <div className="label">{t('report.monthlyTripsDistance')}</div>
          <div className="value">{data?.totalMileage || '0'} km</div>
        </div>
        <div className="row">
          <div className="label">{t('report.monthlyTripsDuration')}</div>
          <div className="value">{data?.totalTime || '00:00'}</div>
        </div>
        <div className="row">
          <div className="label">{t('common.greenFund')}</div>
          <div className="value">${data?.greenFund || '0.0'}</div>
        </div>
        <div className="footer">
          <div className="label">{t('report.monthlyCostRecoveries')}</div>
          <div className="value">${data?.amount || '0.0'}</div>
        </div>
      </Loader>
    </section>
  );
};
export default MonthlySummary;
