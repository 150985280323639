/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { addMinutes, isAfter, isEqual, isSameDay, parse } from 'date-fns';
import { useFormApi } from 'informed';
import { DateField } from '@ui-modules/informed';
import type { PlAcceptDrive } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { DateFormat as DF } from '@common/types';
import '../../BookingPanel.styles.scss';

type FieldProps = {
  acceptDrives?: PlAcceptDrive[];
  disabled?: boolean;
  leg: number;
  value: string;
  onChange?: (value: Date | string) => void;
};

const PickupDateField = ({ acceptDrives, disabled, leg, value, onChange }: FieldProps) => {
  const formApi = useFormApi();
  const { value: dropoffDate } = useFieldState<Date>(`${leg}.dropoffDate`);
  const { value: dropoffTime } = useFieldState<Date>(`${leg}.dropoffTime`);
  const { value: pickupTime } = useFieldState<Date>(`${leg}.pickupTime`);

  const [minDate, setMinDate] = useState<Date | undefined>();

  useEffect(() => {
    if (acceptDrives?.length && leg > 1) {
      const prevData = acceptDrives[leg - 2].dropoffDate;
      const prevDateParsed = parse(prevData, DF.ApiDate, new Date());

      setMinDate(prevDateParsed);

      if (isAfter(prevDateParsed, parse(value, 'yyyy-MM-dd', new Date()))) {
        formApi.setValue(`${leg}.pickupDate`, prevDateParsed);
      }
    }

    if (acceptDrives && value) {
      const isScheduled = acceptDrives?.[leg - 1].isScheduled;
      if (isScheduled) formApi.setValue(`${leg}.pickupDate`, parse(value, DF.ApiDate, new Date()));
    }
  }, [acceptDrives, formApi, leg, value]);

  return (
    <div className="field">
      <DateField
        className="input"
        defaultValue={value ? parse(value, DF.ApiDate, new Date()) : undefined}
        disabled={disabled}
        dropdownMode="select"
        label={t('bookingDetails.pickupDate')}
        minDate={minDate}
        name={`${leg}.pickupDate`}
        showMonthDropdown
        showYearDropdown
        onChange={(val: Date | string) => {
          const date = val as Date;
          const isSameOrAfter = isSameDay(date, dropoffDate) || isAfter(date, dropoffDate);

          // set dropoffDate
          if (isAfter(date, dropoffDate)) {
            formApi.setValue(`${leg}.dropoffDate`, date);
          }

          // set dropoffTime
          if (
            isSameOrAfter &&
            (isEqual(pickupTime, dropoffTime) || isAfter(pickupTime, dropoffTime))
          ) {
            formApi.setValue(`${leg}.dropoffTime`, addMinutes(pickupTime, 15));
          }

          onChange?.(date);
        }}
      />
    </div>
  );
};

export default PickupDateField;
