/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';
import { subMonths } from 'date-fns';
import { useFormApi } from 'informed';

import { DateField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface PickupDateFieldProps {
  fieldGroup?: string;
}

const PickupDateField: FC<PickupDateFieldProps> = ({ fieldGroup }) => {
  const formApi = useFormApi();

  return (
    <div className="field" data-testid="planner-drive-form-field-pickup-date">
      <DateField
        className="input"
        dropdownMode="select"
        label={t('bookingDetails.pickupDate')}
        minDate={subMonths(new Date(), 1)}
        name="pickupDate"
        showMonthDropdown
        showYearDropdown
        validate={notEmptyValidator}
        onChange={(value) => {
          formApi.setValue(fieldGroup ? `${fieldGroup}.dropoffDate` : 'dropoffDate', value);
        }}
      />
    </div>
  );
};

export default PickupDateField;
