/* istanbul ignore file */
import cn from 'classnames';
import type { PlDriverResource, PlVehicleResource } from '@common/interfaces';
import { DriveStatus, FuelType, ProtType } from '@common/interfaces';
import { Button } from '@components';
import {
  Armoured,
  DriverPlaceholder,
  Electric,
  Plus,
  TripCompleted,
  TripNotStarted,
  TripStarted,
} from '@assets/svg/icons';

export const getDriveStateIcon = (state: DriveStatus): JSX.Element | null => {
  const applyIcon = (elem: JSX.Element, status: DriveStatus): JSX.Element => (
    <>
      {elem} {status.replace(/_/g, ' ')}
    </>
  );

  let icon: JSX.Element | null;

  switch (state) {
    case DriveStatus.NotStarted:
      icon = applyIcon(<TripNotStarted />, state);
      break;
    case DriveStatus.Started:
      icon = applyIcon(<TripStarted />, state);
      break;
    case DriveStatus.Completed:
    case DriveStatus.Finished:
      icon = applyIcon(<TripCompleted />, state);
      break;
    default:
      icon = null;
      break;
  }

  return icon;
};

export const getDriverResource = (
  content: PlDriverResource,
  showBtnAdd: boolean,
  onResourceAddClick?: (id: string) => void,
): JSX.Element => {
  const {
    resource: { extendedProps, id, title },
  } = content;

  return (
    <div
      className={cn('fc-resource-content', {
        shared: extendedProps.shared,
        disabled: extendedProps.isDisabled,
      })}
    >
      <div className="fc-resource-content-desc">
        <div className="fc-resource-content-desc-driver">
          <div className="driver-photo" data-testid="driver-photo">
            {extendedProps.photo ? (
              <img
                src={extendedProps.photo}
                alt={`${extendedProps.name} ${extendedProps.lastName}`}
              />
            ) : (
              <div className="driver-photo-placeholder" data-testid="driver-photo-placeholder">
                <DriverPlaceholder />
              </div>
            )}
          </div>
          <div>
            <h3>{title}</h3>
            <span>{extendedProps.driverAgency}</span>
          </div>
        </div>
      </div>

      {showBtnAdd && (
        <Button
          className="btn btn-add-resource"
          leftIcon={<Plus />}
          type="button"
          variant="icon"
          onClick={() => onResourceAddClick?.(id)}
        />
      )}
    </div>
  );
};

export const getVehicleResource = (
  content: PlVehicleResource,
  showBtnAdd: boolean,
  onResourceAddClick?: (id: string) => void,
): JSX.Element => {
  const {
    resource: { extendedProps, id, title },
  } = content;

  return (
    <div
      className={cn('fc-resource-content', {
        shared: extendedProps.shared,
        disabled: extendedProps.isDisabled,
      })}
      data-testid="mobility-planner-resource"
    >
      <div className="fc-resource-content-vehicle-image">
        <img src={extendedProps.image} alt={title} />
        {extendedProps.protection === ProtType.Armoured && (
          <div className="icon armoured">
            <Armoured />
          </div>
        )}

        {extendedProps.fuelType === FuelType.Electric && (
          <div className="icon electric">
            <Electric />
          </div>
        )}
      </div>
      <div className="fc-resource-content-desc">
        {extendedProps.agencyLogo && (
          <img src={extendedProps.agencyLogo} alt={title} className="agency-logo" />
        )}
        <div>
          <h3>{extendedProps.plateNumber}</h3>
          <span>{title}</span>
        </div>
      </div>
      {showBtnAdd && (
        <Button
          className="btn btn-add-resource"
          leftIcon={<Plus />}
          type="button"
          variant="icon"
          onClick={() => onResourceAddClick?.(id)}
        />
      )}
    </div>
  );
};
