import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Loader } from '@components';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { ApiList, IFacilityResource } from '@common/interfaces';
import ResourcesLogo from '@assets/img/ResourcesIcon.png';
import PDFIcon from '@assets/img/PDFIcon.png';
import FileIcon from '@assets/img/FileIcon.png';
import './styles.scss';

const DO_URL = process.env.REACT_APP_DO_URL;

const ResourcesPage: FC = () => {
  const {
    agencyName,
    facility: { agencyId },
    facilityId,
  } = useFacility();
  const { state } = useLocation();
  const { facilityRepository } = useRepository();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const category = ((state as any)?.resource as string) || 'manuals';
  const lang = i18n.language;

  const [resources, setResources] = useState<IFacilityResource[]>([]);

  const { isFetching, refetch: getLocationFiles } = useQuery(
    `get-location-files`,
    () => facilityRepository.getLocationFiles(agencyId, category, facilityId, lang),
    {
      enabled: false,
      onSuccess: (data: ApiList<IFacilityResource>) => {
        if (data.results?.length) setResources(data.results);
      },
    },
  );

  useEffect(() => {
    if (!category && DO_URL) navigate(DO_URL);
  }, [category, navigate]);

  useEffect(() => {
    if (!!agencyId && !!facilityId && lang) {
      getLocationFiles();
    }
  }, [agencyId, facilityId, getLocationFiles, lang]);

  return (
    <section className="resources-page hbh-main-menu-container">
      <div className="hbh-menu-panel">
        <div className="hbh-menu-panel-header">
          <div className="logo">
            <img src={ResourcesLogo} alt="logo" />
          </div>
          <h2 className="title">
            <span className="capitalize">{category}</span> | {agencyName}
          </h2>
        </div>
        <div className="hbh-menu-panel-content">
          <Loader spinning={isFetching}>
            {resources?.length ? (
              resources.map((resource) => {
                const resourceLink = resource.file ?? resource.link;

                return (
                  <div className="resource" key={resourceLink.split('/').pop()}>
                    <img
                      className="icon"
                      src={resourceLink.includes('.pdf') ? PDFIcon : FileIcon}
                      alt="resource"
                    />
                    <div className="description">
                      <a href={resourceLink} rel="noreferrer" target="_blank">
                        {resource.description}
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="resource-empty">
                No <span className="capitalize">{category}</span> yet
              </div>
            )}
          </Loader>
        </div>
      </div>
    </section>
  );
};

export default ResourcesPage;
