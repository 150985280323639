/* istanbul ignore file */
import { FC, useEffect } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';

import { IDropdownOption } from '@ui-modules/types';
import { Dropdown, TextField } from '@ui-modules/informed';
import { DriveFormSteps } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface PickupTownFieldProps {
  fieldGroup?: string;
  options?: IDropdownOption[];
  returnTrip?: boolean;
}

const PickupTownField: FC<PickupTownFieldProps> = ({ fieldGroup, options, returnTrip = false }) => {
  const formApi = useFormApi();
  const formState = formApi.getFormState().values as any;
  const tripData = formState?.[DriveFormSteps.Trip];
  const prevDropoffTownValue = tripData?.driveList ? tripData.driveList[0]?.dropoffTown : null;

  useEffect(() => {
    if (returnTrip && prevDropoffTownValue) {
      formApi.setValue(
        fieldGroup ? `${fieldGroup}.pickupTown` : 'pickupTown',
        prevDropoffTownValue,
      );
    }
  }, [fieldGroup, formApi, prevDropoffTownValue, returnTrip]);

  const dropoffLocation = fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation';
  const dropoffTown = fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown';
  const pickupLocation = fieldGroup ? `${fieldGroup}.pickupLocation` : 'pickupLocation';

  return options ? (
    <div className="field" data-testid="planner-drive-form-field-pickup-town">
      <Dropdown
        className="dark-border small-list"
        isClearable
        isCreatable
        label={t('bookingDetails.pickupTown')}
        name="pickupTown"
        options={options}
        validate={notEmptyValidator}
        onChange={(option) => {
          formApi.setValue(pickupLocation, undefined);

          if (
            option &&
            options?.length === 2 &&
            options?.find((i: IDropdownOption) => i.value === option.value)
          ) {
            formApi.setValue(dropoffLocation, undefined);

            formApi.setValue(
              dropoffTown,
              options?.find((i: IDropdownOption) => i.value !== option.value),
            );
          }
        }}
      />
    </div>
  ) : (
    <div className="field">
      <TextField
        className="input"
        data-testid="planner-drive-form-field-pickup-town"
        disabled={returnTrip}
        label={t('bookingDetails.pickupTown')}
        name="pickupTown"
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default PickupTownField;
