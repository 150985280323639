import { type FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Form } from 'informed';

import { TextField } from '@ui-modules/informed';
import { useAuth } from '@unbooking/ui-auth';
import { Button } from '@unbooking/ui-modules';
import { useRepository } from '@context';
import { Loader } from '@components';
import { LocalStorageKeys, SessionStorageKeys } from '@common/constants';
import { AuthProviderType } from '@common/interfaces';
import { useParamsParse } from '@common/hooks';
import {
  type SignInForm,
  type UserType,
  notEmptyValidator,
  processAuthProviderType,
  processNonFederatedUserFlow,
} from '@common/utils';
import './SignInPage.styles.scss';

const SignInPage: FC = () => {
  const navigate = useNavigate();
  const { authorize } = useAuth();
  const { parsed: isParsed, userType: userTypeParsed } = useParamsParse();
  const { accountRepository } = useRepository();

  const [isLoading, setLoading] = useState(false);
  const [userType, setUserType] = useState<UserType | null>(
    userTypeParsed !== 'non-federated' ? userTypeParsed : null,
  );

  const { mutate: getUserProfile, isLoading: isUserProfileLoading } = useMutation<
    { auth_provider: AuthProviderType },
    AxiosError,
    string
  >('get-user-profile', (userEmail) => accountRepository.getUserProfile(userEmail), {
    onSuccess: (data, email) => {
      const userTypeProvided: UserType = processAuthProviderType(data.auth_provider);
      const isB2CUser = userTypeProvided === 'b2c';

      setUserType(userTypeProvided);
      if (userTypeProvided !== 'non-federated') {
        authorize?.(userTypeProvided, isB2CUser ? email : undefined);
      }
    },
    onError: (error) => {
      if (error) toast.error("User doesn't exist");
    },
  });

  useEffect(() => {
    if (userType && userType !== 'non-federated') {
      setLoading(true);

      const redirectUrl = sessionStorage.getItem(SessionStorageKeys.REDIRECT_URL);
      const userEmail = sessionStorage.getItem(LocalStorageKeys.EMAIL);

      if (isParsed || redirectUrl) {
        const isB2CUser = userType === 'b2c';
        authorize?.(userType, isB2CUser ? userEmail! : undefined);
      } else {
        navigate('/');
      }
    }
  }, [authorize, isParsed, navigate, userType, setLoading]);

  return (
    <section className="page-login">
      <div className="login">
        <div className="row">
          <span className="login-greeting">
            Welcome to the <br /> <strong>UN Mobility</strong>
          </span>
        </div>

        <Loader spinning={isLoading || isUserProfileLoading} />

        <Form
          className="login-form"
          role="form"
          onSubmit={(formState) => {
            const state = formState.values as unknown as SignInForm;

            if (userType) {
              setLoading(true);
              processNonFederatedUserFlow(
                { ...state, email: state.email.toLowerCase() },
                navigate,
                setLoading,
              );
            } else {
              getUserProfile(state.email?.toLowerCase() as string);
            }
          }}
        >
          {(!userType || userType === 'non-federated') && (
            <div className="login-field">
              <TextField
                name="email"
                type="email"
                placeholder="Enter your email"
                validate={notEmptyValidator}
                onChange={() => {
                  if (userType) setUserType(null);
                }}
              />
            </div>
          )}

          {userType && userType === 'non-federated' ? (
            <div className="login-field">
              <TextField
                name="password"
                type="password"
                placeholder="Enter your password"
                validate={notEmptyValidator}
              />
            </div>
          ) : null}

          {!userType || userType === 'non-federated' ? (
            <Button
              className="login-link"
              text={userType ? 'Sign in' : 'Continue'}
              type="submit"
              variant="link"
            />
          ) : null}
        </Form>

        {userType && userType !== 'non-federated' && (
          <Button
            className="login-link"
            text="Sign in"
            type="button"
            variant="link"
            onClick={() => {
              setLoading(true);
              authorize?.(userType);
            }}
          />
        )}
      </div>
      <ToastContainer />
    </section>
  );
};

export default SignInPage;
