import type { FC } from 'react';
import { Loader } from '@components';
import './styles.scss';

const CallbackPage: FC = () => (
  <main className="page-callback">
    <Loader spinning text="Loading..." />
  </main>
);

export default CallbackPage;
