import type { FC } from 'react';
import { useFormApi } from 'informed';

import type { IDropdownOption } from '@ui-modules/types';
import { Dropdown } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import './styles.scss';

interface CityFieldProps {
  defaultValue?: IDropdownOption;
  idx?: number;
  label: string;
  name: string;
  options: IDropdownOption[];
}

const CountryField: FC<CityFieldProps> = ({ defaultValue, idx, label, name, options }) => {
  const formApi = useFormApi();
  const formState = formApi.getFormState().values as any;

  return (
    <div className="field">
      <Dropdown
        defaultValue={defaultValue}
        label={label}
        name={name}
        options={options}
        validate={notEmptyValidator}
        onChange={(option: IDropdownOption) => {
          if (
            name === 'arrivalCountry' &&
            typeof idx === 'number' &&
            formState?.trips.length > idx + 1
          ) {
            formApi.setValue(`trips[${idx + 1}].departureCountry`, option);
          }
        }}
      />
    </div>
  );
};

export default CountryField;
