/* eslint-disable no-nested-ternary */
import type { FC } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { addMinutes, format, isAfter, parse } from 'date-fns';
import { Form, useFormApi } from 'informed';
import type { AxiosError } from 'axios';

import { Button } from '@unbooking/ui-modules';
import { DateField } from '@ui-modules/informed';
import type { Drive, IDriveUpdate } from '@common/interfaces';
import { DateFormat } from '@common/types';
import { useFacility, useFieldState } from '@common/hooks';
import { formatDateString, requiredField } from '@common/utils';
import { useRepository } from '@context';
import { TimeField } from '@components';
import '../../../styles/modal.scss';

const DateInput: FC<{ label: string; name: string }> = ({ label, name }) => {
  const formApi = useFormApi();
  const { value: dropoffDate } = useFieldState<Date>('dropoffDate');
  const { value: pickupDate } = useFieldState<Date>('pickupDate');

  return (
    <div className="field field-date">
      <DateField
        dropdownMode="select"
        label={label}
        minDate={name === 'dropoffDate' ? pickupDate : undefined}
        name={name}
        showMonthDropdown
        showYearDropdown
        validate={requiredField}
        onChange={(value) => {
          if (name === 'pickupDate' && isAfter(value as Date, dropoffDate as Date)) {
            formApi.setValue('dropoffDate', value);
          }
        }}
      />
    </div>
  );
};

const TimeInput: FC<{ label: string; name: string }> = ({ label, name }) => {
  const formApi = useFormApi();
  const { value: dropoffDate } = useFieldState<Date>('dropoffDate');
  const { value: dropoffTime } = useFieldState<string>('dropoffTime');

  return (
    <div className="field field-time">
      <TimeField
        label={label}
        name={name}
        onChange={(value) => {
          if (!value) return;

          const pickupDateTime = new Date(`${format(dropoffDate, 'yyyy-MM-dd')} ${value}`);
          const dropoffDateTime = new Date(`${format(dropoffDate, 'yyyy-MM-dd')} ${dropoffTime}`);

          if (name === 'pickupTime' && isAfter(pickupDateTime, dropoffDateTime)) {
            const time = addMinutes(pickupDateTime, 1);
            formApi.setValue('dropoffTime', format(time, 'HH:mm'));
          } else if (name === 'dropoffTime' && isAfter(dropoffDateTime, pickupDateTime)) {
            const time = addMinutes(pickupDateTime, -1);
            formApi.setValue('pickupTime', format(time, 'HH:mm'));
          }
        }}
      />
    </div>
  );
};

type FormStateProps = {
  dropoffDate: Date;
  dropoffTime: string;
  pickupDate: Date;
  pickupTime: string;
};

type TimeSlotEditProps = { data: Drive; onSubmit: () => void };

const TimeSlotEdit: FC<TimeSlotEditProps> = ({ data, onSubmit }) => {
  const { reportRepository } = useRepository();
  const { t } = useTranslation();
  const { facilityId } = useFacility();

  const { mutateAsync: updateDrive } = useMutation<
    unknown,
    AxiosError,
    { driveId: string; payload: IDriveUpdate }
  >(
    'update-drive',
    ({ driveId, payload }) => reportRepository.updateDrive(facilityId, driveId, payload),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgUpdateDrive'));
        onSubmit();
      },
      onError: (error) => {
        if (error.message) toast.error(error.message || t('common.errorMsgDefault'));
      },
    },
  );

  const initFormData: Record<string, unknown> = {
    dropoffDate: data.dropoffDate
      ? parse(data.dropoffDate, DateFormat.ApiDateAlt, new Date())
      : new Date(),
    dropoffTime: formatDateString(data.dropoffTime, DateFormat.ApiTimeFull, DateFormat.ApiTime),
    pickupDate: data.pickupDate
      ? parse(data.pickupDate, DateFormat.ApiDateAlt, new Date())
      : new Date(),
    pickupTime: formatDateString(data.pickupTime, DateFormat.ApiTimeFull, DateFormat.ApiTime),
  };

  return (
    <Form
      className="form-time-slot-edit modal-light-form"
      initialValues={initFormData}
      onSubmit={(formState: Record<string, unknown>) => {
        const state = { ...(formState.values as FormStateProps) };

        updateDrive({
          driveId: data.id,
          payload: {
            distance: data.distance,
            end_date: format(state.dropoffDate, DateFormat.ApiDateAlt),
            end_time: state.dropoffTime,
            start_date: format(state.pickupDate, DateFormat.ApiDateAlt),
            start_time: state.pickupTime,
          },
        });
      }}
    >
      <div className="row">
        <div className="fieldset">
          <DateInput label={t('common.inputStartDate')} name="pickupDate" />
          <DateInput label={t('common.inputEndDate')} name="dropoffDate" />
          <TimeInput label={t('common.inputStartTime')} name="pickupTime" />
          <TimeInput label={t('common.inputEndTime')} name="dropoffTime" />

          <Button
            className="btn btn-submit"
            text={t('common.btnConfirmChanges')}
            type="submit"
            variant="submit"
          />
        </div>
      </div>
    </Form>
  );
};

export default TimeSlotEdit;
