/* istanbul ignore file */
import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormApi, useFormState, useFieldState } from 'informed';
import { t } from 'i18next';

import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { DriveFormSteps } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface GuestAgencyFieldProps {
  userAgencyOption: IDropdownOption;
}

const GuestAgencyField: FC<GuestAgencyFieldProps> = ({ userAgencyOption }) => {
  const formApi = useFormApi();
  const { values } = useFormState() as any;
  const { value: noPax } = useFieldState('noPax');

  useEffect(() => {
    if (noPax) {
      formApi.setValue(`${DriveFormSteps.Main}.agency`, userAgencyOption);
    } else if (values?.agenciesOptions?.length === 1) {
      const [agencyValue] = values.agenciesOptions;
      formApi.setValue(`${DriveFormSteps.Main}.agency`, agencyValue);

      if (agencyValue.isThirdParty) {
        toast.warn('Please make sure that the email entered is correct');
      }
    }
  }, [formApi, noPax, userAgencyOption, values.agenciesOptions]);

  return (
    <div className="field">
      <Dropdown
        className="dark-border small-list"
        data-testid="planner-drive-form-field-agency"
        label={t('common.agency')}
        name="agency"
        placeholder={values.agenciesOptions?.length > 1 ? 'Select...' : 'Provide user email first'}
        options={values?.agenciesOptions || []}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default GuestAgencyField;
