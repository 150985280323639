import cn from 'classnames';
import './PageTitle.styles.scss';

interface PageTitleProps {
  bottomLine?: boolean;
  className?: string;
  title: string;
  tools?: JSX.Element | null;
}

const PageTitle = ({ bottomLine = false, className = '', title, tools = null }: PageTitleProps) => (
  <section
    className={cn('ddo-page-title', { 'ddo-page-title-line': bottomLine }, className)}
    data-testid="ddo-page-title"
  >
    <h1 className="ddo-page-title-text">{title}</h1>
    {tools && <div className="ddo-page-title-tools">{tools}</div>}
  </section>
);

export default PageTitle;
