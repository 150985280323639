import { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { BulkAssignBooking, BulkAssignBookingUpdate, BulkAssignStatus } from '@common/interfaces';
import { Button, Modal } from '@components';
import { Check, Shuttle } from '@assets/svg/icons';
import './BulkAssign.styles.scss';

type BulkAssignProps = {
  data: BulkAssignBooking[];
  isLoading: boolean;
  opened: boolean;
  onClose: () => void;
  onSubmit: (payload: BulkAssignBookingUpdate[]) => void;
};

const BulkAssign: FC<BulkAssignProps> = ({ data, isLoading, opened, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const handleBulkAssignProcess = () => {
    const bulkData = data.filter((i) => i.status === BulkAssignStatus.Ok);
    if (!bulkData.length) return onClose();

    const payload = bulkData.map((i) => ({
      booking_id: i.bookingId!,
      routes: i.bulkAssignRoutes.map((r) => ({
        driver_id: r.driverId!,
        order: r.order,
        vehicle_id: r.vehicleId!,
      })),
    }));

    onSubmit(payload);
  };

  const availableProcessing = useMemo(
    () => data.filter((i) => i.status === BulkAssignStatus.Ok).length,
    [data],
  );

  return (
    <Modal
      className={cn('modal-bulk-assign', { loading: isLoading })}
      showBtnClose
      title={`You are processing ${availableProcessing} bookings automatically`}
      variant="dark"
      visible={opened}
      onClose={onClose}
    >
      <section className="bulk-assign">
        {data.map((booking) => (
          <div
            className={cn('bulk-assign-booking', {
              disabled: booking.status !== BulkAssignStatus.Ok,
            })}
            key={booking.id}
          >
            <div className="bulk-assign-booking-col">
              <Shuttle />
            </div>
            <div className="bulk-assign-booking-col">
              {booking.pickupDate}
              <div className="sub">{booking.pickupTime}</div>
            </div>
            <div className="bulk-assign-booking-col">
              {booking.pax} Pax, &nbsp;{booking.agencyShortName}
              <div className="sub">
                {booking.firstName}&nbsp;{booking.lastName}
              </div>
            </div>

            <div className="bulk-assign-booking-col">
              {booking.status === BulkAssignStatus.Ok ? (
                booking?.bulkAssignRoutes?.map((item) => (
                  <section key={item.order}>
                    <div>
                      Driver {item.driverLabel?.split(',')[0]}
                      <div className="sub">{item.driverLabel?.split(',')[1]}</div>
                    </div>
                    <div>
                      Vehicle {item.vehicleLabel?.split('|')[0]}
                      <div className="sub">
                        {t('mobility.plateNumber')} {item.vehicleLabel?.split('|')[1] || ''}
                      </div>
                    </div>
                  </section>
                ))
              ) : (
                <span className="warn">Not available for assignment</span>
              )}
            </div>
          </div>
        ))}

        <div className="footer">
          <Button
            className="btn"
            text={t('common.btnCancel')}
            variant="outline"
            onClick={onClose}
          />
          <Button
            className="btn btn-process"
            data-testid="btn-bulk-assign-submit"
            disabled={!availableProcessing}
            leftIcon={<Check />}
            text={t('common.btnConfirm')}
            variant="filled"
            onClick={handleBulkAssignProcess}
          />
        </div>
      </section>
    </Modal>
  );
};

export default BulkAssign;
