import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useDebounce } from 'usehooks-ts';

import { IDropdownOption } from '@ui-modules/types';
import { Button, Dropdown as FiltersDropdown, Pagination, Table } from '@unbooking/ui-modules';
import type { SortOrder } from '@common/interfaces';
import { BForwardType, ApiList, Bookings, BookingsApi, IFilters } from '@common/interfaces';
import { Serializer } from '@common/utils';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { DateRangeForm, Modal, PageTitle, Tabs, Tooltip } from '@components';
import { Attachment, Download, Expand, Recurring } from '@assets/svg/icons';
import { DrivesTable } from './components';
import './styles.scss';

const BookingListPage: FC = () => {
  const navigate = useNavigate();
  const { bookingRepository } = useRepository();
  const {
    agencyName,
    facility: { agencyId, city, country },
    facilityId,
  } = useFacility();
  const { t } = useTranslation();

  const tabs = useMemo(() => [t('booking.notProcessed'), t('booking.processed')], [t]);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [filterBookingAgency, setFilterBookingAgency] = useState<IDropdownOption | null>();
  const [filterBookingStates, setFilterBookingStates] = useState<IDropdownOption | null>();
  const [isForwardBookingProcessing, setForwardBookingProcessing] = useState<boolean>(false);
  const [isModalExportOpen, setIsModalExportOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();

  const debouncedSearch = useDebounce(searchQuery, 1000);

  const [bookings, setBookings] = useState<Bookings[]>([]);
  const [columnOrder, setColumnOrder] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [bookingsNotProcessed, setBookingsNotProcessed] = useState<Bookings[]>([]);
  const [columnOrderNotProcessed, setColumnOrderNotProcessed] = useState<string>('');
  const [pageSizeNotProcessed, setPageSizeNotProcessed] = useState<number>(10);
  const [selectedPageNotProcessed, setSelectedPageNotProcessed] = useState<number>(1);
  const [totalItemsNotProcessed, setTotalItemsNotProcessed] = useState<number>(0);

  const {
    isFetching: isBookingFetching,
    isLoading: isBookingLoading,
    refetch: getProcessedBookings,
  } = useQuery(
    [
      'get-bookings-processed',
      filterBookingAgency,
      filterBookingStates,
      columnOrder,
      pageSize,
      selectedPage,
      debouncedSearch,
    ],
    () =>
      bookingRepository.getBookings(facilityId, {
        agencyId,
        ordering: columnOrder,
        limit: pageSize,
        offset: !debouncedSearch ? (selectedPage - 1) * 10 : undefined,
        search: debouncedSearch?.trim(),
        show: 'processed',
        filters: {
          ...(filterBookingAgency ? { passenger_agency: filterBookingAgency?.value } : {}),
          ...(filterBookingStates ? { state: filterBookingStates?.value } : {}),
        },
      }),
    {
      enabled: !!agencyId,
      onSuccess(data: ApiList<BookingsApi>) {
        setBookings(data.results.map(Serializer.formatBooking));
        setTotalItems(data.count);
      },
    },
  );

  const {
    isFetching: isNotProcessedBookingFetching,
    isLoading: isNotProcessedBookingLoading,
    refetch: getNotProcessedBookings,
  } = useQuery(
    [
      'get-bookings-not-processed',
      filterBookingAgency,
      columnOrderNotProcessed,
      pageSizeNotProcessed,
      selectedPageNotProcessed,
      debouncedSearch,
    ],
    () =>
      bookingRepository.getBookings(facilityId, {
        agencyId,
        ordering: columnOrderNotProcessed,
        limit: pageSizeNotProcessed,
        offset: !debouncedSearch ? (selectedPageNotProcessed - 1) * 10 : undefined,
        search: debouncedSearch?.trim(),
        show: 'not-processed',
        filters: {
          ...(filterBookingAgency ? { passenger_agency: filterBookingAgency?.value } : {}),
        },
      }),
    {
      enabled: !!agencyId,
      onSuccess(data: ApiList<BookingsApi>) {
        setBookingsNotProcessed(data.results.map(Serializer.formatBooking));
        setTotalItemsNotProcessed(data.count);
      },
    },
  );

  const { data: filtersData, isLoading: isFiltersLoading } = useQuery<unknown, void, IFilters>(
    'booking-filters',
    () =>
      bookingRepository.getFilters(facilityId, {
        agencyId,
      }),
    { enabled: !!agencyId, refetchOnWindowFocus: false },
  );

  const { mutate: getExportBookings, isLoading: fileLoading } = useMutation<
    unknown,
    AxiosError,
    { ids?: string; rangeEnd?: string; rangeStart?: string }
  >(
    'export-bookings',
    ({ ids, rangeEnd, rangeStart }) =>
      bookingRepository.exportBookings(facilityId, {
        agencyId,
        ids,
        rangeEnd,
        rangeStart,
      }),
    {
      onError: (error: any) => {
        if (error) toast.error(t('common.msgErrorExport'));
      },
    },
  );

  const { mutate: forwardBooking } = useMutation<
    unknown,
    AxiosError,
    { agencyFk: string; bookingId: string }
  >(
    'forward-booking',
    ({ agencyFk, bookingId }) => bookingRepository.forwardBooking(facilityId, agencyFk, bookingId),
    {
      onSuccess: () => {
        toast.success(t('bookingList.bookingForwarded'));
        if (selectedTab === 0) getNotProcessedBookings();
        if (selectedTab === 1) getProcessedBookings();
        setForwardBookingProcessing(false);
      },
      onError: (error) => {
        if (error.message) {
          toast.error(error.message || t('common.errorMsgDefault'));
        }
      },
    },
  );

  const getExportCurrentView = (): void => {
    const data = selectedTab === 0 ? bookingsNotProcessed : bookings;
    if (data?.length) getExportBookings({ ids: data.map((i) => i.id).join(',') });
  };

  const getForwardedBtn = (record: Bookings, hideForward: boolean): JSX.Element | null =>
    !hideForward && record.forwardKey !== BForwardType.NotForForward ? (
      <Button
        className={
          record.forwardKey === BForwardType.Forward ? 'btn btn-forward' : 'btn btn-forwarded'
        }
        disabled={isForwardBookingProcessing || record.forwardKey !== BForwardType.Forward}
        text={record.forwardLabel}
        variant="primary"
        onClick={() => {
          setForwardBookingProcessing(true);

          if (!isForwardBookingProcessing) {
            forwardBooking({ agencyFk: record.agencyFk, bookingId: record.id });
          }
        }}
      />
    ) : null;

  const getBookingStateBtn = (record: Bookings): JSX.Element | null => {
    const applyBtn = (state: string, text: string): JSX.Element => (
      <Button
        aria-label={`btn-${state}`}
        className={`btn btn-${state}`}
        text={text}
        variant="transparent"
        onClick={() =>
          navigate(state !== 'not-processed' ? `${record.id}` : `booking-accept/${record.id}`)
        }
      />
    );

    if (record.forwardKey === BForwardType.Rejected) {
      return applyBtn('cancelled', t('booking.rejected'));
    }

    switch (record.state) {
      case 'accepted':
        return applyBtn('accepted', t('booking.accepted'));
      case 'cancelled':
        return applyBtn('cancelled', t('booking.cancelledByFP'));
      case 'cancelled_by_user':
        return applyBtn('cancelled', t('booking.userCancelled'));
      case 'disabled':
        return applyBtn('disabled', t('booking.accepted'));
      case 'finished':
        return applyBtn('finished', t('booking.finished'));
      case 'not_processed':
        return applyBtn('not-processed', t('booking.notProcessed'));
      case 'ongoing':
        return applyBtn('ongoing', t('booking.ongoing'));
      case 'rejected':
        return applyBtn('cancelled', t('booking.rejected'));
      default:
        return null;
    }
  };

  const columns = [
    {
      dataIndex: 'dispatchDate',
      key: 'created',
      title: t('booking.request'),
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      sorter: true,
    },
    {
      dataIndex: 'pickupDate',
      key: 'pickup_date',
      title: t('booking.pickup'),
    },
    {
      dataIndex: 'pickupTime',
      key: 'pickupTime',
      title: t('booking.time'),
    },
    {
      dataIndex: 'firstName',
      key: 'firstName',
      title: t('common.firstName'),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: 'lastName',
      key: 'lastName',
      title: t('common.lastName'),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: 'agencyShortName',
      key: 'agencyShortName',
      title: t('common.agency'),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: 'refCode',
      key: 'refCode',
      title: t('booking.refCode'),
    },
    {
      dataIndex: 'pax',
      key: 'pax',
      title: t('common.pax'),
    },
    {
      dataIndex: 'transferTypeDisplay',
      key: 'transferType',
      title: t('common.transferType'),
    },
    {
      key: 'typeOfTrip',
      title: t('common.typeOfTrip'),
      render: (record: Bookings) => (
        <div className="type-of-trip">
          {record.typeOfTripDisplay}{' '}
          {record.isRecurring && (
            <Tooltip label={t('common.recurringDrive')}>
              <Recurring />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      key: 'hasAttachments',
      title: '',
      render: (record: Bookings) => (
        <div className="attachments">
          {record.hasAttachments && (
            <Tooltip label={t('bookingList.optionalAttachments')}>
              <Attachment />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      key: 'forwarded',
      title: '',
      render: (record: Bookings) =>
        getForwardedBtn(record, selectedTab !== 0 && record.forwardKey === BForwardType.Forward),
    },
    {
      key: 'status',
      title: t('common.status'),
      render: (record: Bookings) => getBookingStateBtn(record),
    },
  ];

  const renderExpandedRow = (record: Bookings) => (
    <DrivesTable data={record.routes} key={record.id} />
  );

  const renderExpandedIcon = ({
    expanded,
    onExpand,
    record,
  }: {
    expanded: boolean;
    onExpand: any;
    record: Bookings;
  }) =>
    record.routes?.length > 0 ? (
      <Button
        className={expanded ? 'icon-expanded' : 'icon-expand'}
        icon={<Expand />}
        variant="icon"
        onClick={() => onExpand(record)}
      />
    ) : null;

  const agencyOptions = useMemo(
    () => [
      { label: t('common.allAgencies'), value: '' },
      ...(filtersData?.passenger_agencies ?? []),
    ],
    [filtersData?.passenger_agencies, t],
  );

  const bookingStateOptions = useMemo(
    () => [
      { label: t('common.allStatuses'), value: '' },
      ...(filtersData?.booking_states?.filter((status) => status.value !== 'not_processed') ?? []),
    ],
    [filtersData?.booking_states, t],
  );

  return (
    <section className="hbh-container booking-list-page">
      <PageTitle
        className="page-title"
        title={`${t('bookingList.title')} ${city}, ${country} | ${agencyName}`}
        bottomLine
        tools={
          <>
            <input
              className="field-search"
              type="text"
              placeholder={t('bookingList.inputSearch')}
              value={searchQuery}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(event.currentTarget.value);
              }}
            />
            <FiltersDropdown
              className="select outline"
              isDisabled={isFiltersLoading}
              label={<span className="hbh-select-label">{t('bookingList.filterLabelPax')}</span>}
              options={agencyOptions}
              placeholder={t('common.allAgencies')}
              value={filterBookingAgency}
              onChange={setFilterBookingAgency}
            />
            {selectedTab !== 0 && (
              <FiltersDropdown
                className="select outline"
                isDisabled={isFiltersLoading}
                label={
                  <span className="hbh-select-label">{t('bookingList.filterLabelStates')}</span>
                }
                options={bookingStateOptions}
                placeholder={t('common.allStatuses')}
                value={filterBookingStates}
                onChange={setFilterBookingStates}
              />
            )}

            <Button
              className="btn btn-export"
              disabled={fileLoading}
              icon={<Download />}
              text={t('bookingList.btnExport')}
              variant="submit"
              onClick={() => setIsModalExportOpen(true)}
            />
            <Button
              className="btn btn-export-view"
              disabled={fileLoading}
              icon={<Download />}
              text={t('bookingList.btnExportCurrentView')}
              variant="primary"
              onClick={getExportCurrentView}
            />
          </>
        }
      />

      <Tabs
        className="tabs"
        selectedTab={selectedTab}
        tabs={tabs}
        onTabSelect={(tab) => setSelectedTab(tabs.indexOf(tab))}
      />

      {selectedTab === 0 ? (
        <section className="booking-list-not-processed">
          <div className="table">
            <Table
              columns={columns}
              data={bookingsNotProcessed}
              isLoading={isNotProcessedBookingLoading || isNotProcessedBookingFetching}
              rowClassName={(record: Bookings) =>
                record.forwardKey === BForwardType.Rejected ||
                record.forwardKey === BForwardType.ProcessedByOtherAgency
                  ? 'row-disabled'
                  : ''
              }
              variant="dark"
              onChangeColumnOrder={setColumnOrderNotProcessed}
            />
          </div>

          {!isNotProcessedBookingFetching && totalItemsNotProcessed > 10 ? (
            <Pagination
              className="pagination"
              selectedPage={selectedPageNotProcessed}
              showJumper
              showPageSize
              totalPages={totalItemsNotProcessed}
              variant="dark"
              onPageChange={setSelectedPageNotProcessed}
              onPageSizeChange={setPageSizeNotProcessed}
            />
          ) : null}
        </section>
      ) : null}

      {selectedTab === 1 ? (
        <section className="booking-list-processed">
          <div className="table">
            <Table
              columns={columns}
              data={bookings}
              isLoading={isBookingLoading || isBookingFetching}
              expandable={{
                expandIcon: renderExpandedIcon,
                expandedRowRender: renderExpandedRow,
                rowExpandable: (record: Bookings) => record.routes?.length > 0,
              }}
              rowClassName={(record: Bookings) =>
                record.forwardKey === BForwardType.Rejected ||
                record.forwardKey === BForwardType.ProcessedByOtherAgency
                  ? 'row-disabled'
                  : ''
              }
              variant="dark"
              onChangeColumnOrder={setColumnOrder}
            />
          </div>

          {!isBookingFetching && totalItems > 10 ? (
            <Pagination
              className="pagination"
              selectedPage={selectedPage}
              showJumper
              showPageSize
              totalPages={totalItems}
              variant="dark"
              onPageChange={setSelectedPage}
              onPageSizeChange={setPageSize}
            />
          ) : null}
        </section>
      ) : null}

      <Modal title={t('bookingList.modalTitle')} visible={isModalExportOpen}>
        <DateRangeForm
          onClose={() => setIsModalExportOpen(false)}
          onSubmit={(data) =>
            getExportBookings({
              rangeEnd: data.rangeEnd as string,
              rangeStart: data.rangeStart as string,
            })
          }
        />
      </Modal>
    </section>
  );
};

export default BookingListPage;
