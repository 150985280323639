/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';

import { Button } from '@ui-modules';
import { IndInvoiceDetails } from '@common/interfaces';
import { DateFormat } from '@common/types';
import { ArrowRound } from '@assets/svg/icons';
import './styles.scss';

interface TripsDetailsProps {
  data?: IndInvoiceDetails;
}

const TripsDetails = ({ data }: TripsDetailsProps) => {
  const { t } = useTranslation();

  const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false);

  return (
    <section className="trip-details-container">
      <ul className="details">
        <li>
          <div className="label">{t('common.typeOfTrip')}</div>
          <div className="value">{data?.typeOfTripDisplay}</div>
        </li>
        <li>
          <div className="label">{t('common.transferType')}</div>
          <div className="value">{data?.transferTypeDisplay}</div>
        </li>
        <li>
          <div className="label">{t('invoice.paxNumber')}</div>
          <div className="value">{data?.nOfPassengers}</div>
        </li>
        <li>
          <div className="label">{t('invoice.departureTime')}</div>
          <div className="value">{data?.flightArrivalDepartureTime}</div>
        </li>
        <li>
          <div className="label">{t('common.flightNumber')}</div>
          <div className="value">{data?.flightNumber}</div>
        </li>
        <li>
          <div className="label">{t('common.budgetCode')}</div>
          <div className="value">{data?.budgetCode}</div>
        </li>
        <li>
          <div className="label">{t('common.remarks')}</div>
          <div className="value">{data?.remarks}</div>
        </li>
      </ul>

      <div className="more-details">
        <div className="more-details-panel" onClick={() => setIsOpenDetails(!isOpenDetails)}>
          <div className="more-details-panel-header">
            {isOpenDetails ? 'Hide' : 'Show'} {t('invoice.fullTripDetails')}
            <Button
              className="btn-more-details"
              icon={<ArrowRound className={isOpenDetails ? 'arrow-round-up' : undefined} />}
              variant="icon"
            />
          </div>
        </div>

        <div className={`${'content'} ${isOpenDetails ? 'open' : ''}`}>
          {data?.driveSet.length &&
            data?.driveSet.map((drive, idx) => (
              <div className="row" key={drive.id}>
                <div>
                  <h4>
                    {idx === 1 ? t('bookingDetails.returnTrip') : t('bookingDetails.outwardTrip')}
                  </h4>
                  <span>Ref code: {data?.refCode}</span>
                </div>

                <ul className="details">
                  <li>
                    <div className="label">{t('bookingDetails.pickupTown')}</div>
                    <div className="value">{drive?.pickupTown}</div>
                  </li>
                  <li>
                    <div className="label">{t('bookingDetails.pickupLocation')}</div>
                    <div className="value">{drive?.pickupLocation}</div>
                  </li>
                  <li>
                    <div className="label">{t('bookingDetails.pickupDate')}</div>
                    <div className="value">
                      {format(
                        parse(drive?.pickupDate, DateFormat.ApiDate, new Date()),
                        DateFormat.InvoiceInfoDate,
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="label">{t('bookingDetails.pickupTime')}</div>
                    <div className="value">
                      {format(
                        parse(drive?.pickupTime, DateFormat.ApiTimeFull, new Date()),
                        DateFormat.ApiTime,
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="label">{t('bookingDetails.dropoffTown')}</div>
                    <div className="value">{drive?.dropoffTown}</div>
                  </li>
                  <li>
                    <div className="label">{t('bookingDetails.dropoffLocation')}</div>
                    <div className="value">{drive?.dropoffLocation}</div>
                  </li>
                  <li>
                    <div className="label">{t('bookingDetails.dropoffDate')}</div>
                    <div className="value">
                      {format(
                        parse(drive?.dropoffDate, DateFormat.ApiDate, new Date()),
                        DateFormat.InvoiceInfoDate,
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="label">Est. {t('bookingDetails.returnTime')}</div>
                    <div className="value">
                      {format(
                        parse(drive?.dropoffTime, DateFormat.ApiTimeFull, new Date()),
                        DateFormat.ApiTime,
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="label">{t('common.vehicle')}</div>
                    <div className="value">{drive?.vehicle}</div>
                  </li>
                  <li>
                    <div className="label">{t('common.driver')}</div>
                    <div className="value">{drive?.driver}</div>
                  </li>
                  <li>
                    <div className="label">{t('invoice.agencyDriver')}</div>
                    <div className="value">{drive?.driverAgency}</div>
                  </li>
                  <li>
                    <div className="label">Km</div>
                    <div className="value">{drive?.km}</div>
                  </li>
                  <li>
                    <div className="label">{t('common.amount')}</div>
                    <div className="value">{drive?.price} USD</div>
                  </li>
                  {drive?.greenFund && drive?.greenFund ? (
                    <li>
                      <div className="label">{t('common.greenFund')}</div>
                      <div className="value">{drive.greenFund} USD</div>
                    </li>
                  ) : null}
                  <li>
                    <div className="label">{t('common.duration')}</div>
                    <div className="value">{drive?.duration}</div>
                  </li>
                </ul>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default TripsDetails;
