import { addDays, endOfDay, parse, startOfDay } from 'date-fns';
import {
  ICalendarChecklistEvent,
  ICalendarChecklistVehicle,
  VehicleType,
  IVehiclesChecklistApi,
  IVehiclesChecklistDetails,
  VehicleChecklistDetailsApi,
} from '@common/interfaces';

export default class Serializer {
  public static formatChecklistDetails(
    data: VehicleChecklistDetailsApi,
  ): IVehiclesChecklistDetails {
    const answers = data.answers
      ? Object.entries(data.answers).reduce(
          (prev, item) => ({
            ...prev,
            [item[0]]: item[1].map((i) => ({
              answer: i.answer,
              categoryName: i.category_name,
              id: i.id,
              imageUrl: i.image_url,
              imagesUrl: i.images_url,
              isCritical: i.is_critical,
              note: i.note,
              question: i.question,
              questionChoice: i.question_choice,
              tooltip: i.tooltip,
            })),
          }),
          {},
        )
      : undefined;

    const questions = data.questions
      ? {
          items: Object.entries(data.questions.items).reduce(
            (prev, item) => ({
              ...prev,
              [item[0]]: item[1].map((i) => ({
                categoryName: i.category__name,
                id: i.id,
                isCritical: i.is_critical,
                questionEn: i.question_en,
                questionFr: i.question_fr,
                tooltip: i.tooltip,
              })),
            }),
            {},
          ),
        }
      : undefined;

    return {
      agencyLogo: data.agency_logo,
      agencyShortName: data.agency_short_name,
      answers,
      cityLat: data.city_lat,
      cityLong: data.city_long,
      cityName: data.city_name,
      countryCode: data.country_iso_code,
      created: data.created,
      criticalIssuesCount: data.critical_issues_count,
      driverFirstName: data.driver_first_name,
      driverId: data.driver_id,
      driverLastName: data.driver_last_name,
      id: data.id,
      issuesCount: data.issues_count,
      locationId: data.location_id,
      odometerFormat: data.odometer_format,
      odometerValue: data.odometer_value,
      plateNumber: data.plate_number,
      questions,
      submitted: data.submitted,
      submittedAt: data.submitted_at,
      todayDrivesCount: data.today_drives_count,
      vehicleId: data.vehicle_id,
      vehicleImage: data.vehicle_image,
      vehicleModelName: data.vehicle_model_name,
      vehicleProtection: data.vehicle_protection,
    };
  }

  public static mapChecklistEventsForCalendar(
    data: IVehiclesChecklistApi[],
  ): ICalendarChecklistEvent[] {
    return data.map((event: IVehiclesChecklistApi) => ({
      className: '',
      end: endOfDay(parse(event.created, 'dd/MM/yyyy', new Date())),
      id: event.id,
      resourceId: event.vehicle_id,
      start: startOfDay(parse(event.created, 'dd/MM/yyyy', new Date())),
      extendedProps: {
        agencyLogo: event.agency_logo,
        agencyShortName: event.agency_short_name,
        cityLat: event.city_lat,
        cityLong: event.city_long,
        cityName: event.city_name,
        countryCode: event.country_iso_code,
        created: event.created,
        criticalIssuesCount: event.critical_issues_count,
        driverFirstName: event.driver_first_name,
        driverId: event.driver_id,
        driverLastName: event.driver_last_name,
        id: event.id,
        issuesCount: event.issues_count,
        locationId: event.location_id,
        odometerFormat: event.odometer_format,
        odometerValue: event.odometer_value,
        plateNumber: event.plate_number,
        submitted: event.submitted,
        submittedAt: event.submitted_at,
        todayDrivesCount: event.today_drives_count,
        vehicleId: event.vehicle_id,
        vehicleImage: event.vehicle_image,
        vehicleModelName: event.vehicle_model_name,
        vehicleProtection: event.vehicle_protection,
      },
    }));
  }

  public static mapVehiclesForScheduler(
    vehicles: VehicleType[],
    checklists: IVehiclesChecklistApi[],
  ): ICalendarChecklistVehicle[] {
    const CHECKLIST_DATE_FORMAT = 'dd/MM/yyyy';

    return vehicles.map((vehicle) => {
      let criticalIssue: { start: string; end: string } | null = null;
      const criticalVehicleChecklists = checklists.filter(
        (c) => c.vehicle_id === vehicle.id && c.critical_issues_count > 0,
      );

      if (criticalVehicleChecklists.length) {
        const createdDate = parse(
          criticalVehicleChecklists[0].created,
          CHECKLIST_DATE_FORMAT,
          new Date(),
        );

        criticalIssue = {
          start: createdDate.toString(),
          end: createdDate.toString(),
        };

        for (let i = 1; i < criticalVehicleChecklists.length; i += 1) {
          const previousCriticalChecklist = parse(
            criticalVehicleChecklists[i - 1].created,
            CHECKLIST_DATE_FORMAT,
            new Date(),
          );
          const nextCriticalChecklist = parse(
            criticalVehicleChecklists[i].created,
            CHECKLIST_DATE_FORMAT,
            new Date(),
          );

          if (nextCriticalChecklist.getTime() === addDays(previousCriticalChecklist, 1).getTime()) {
            criticalIssue.end = nextCriticalChecklist.toString();
          }
        }
      }

      return {
        key: String(vehicle.id),
        ...(criticalIssue ? { criticalIssue } : {}),
        ...vehicle,
      };
    });
  }
}
