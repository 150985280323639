/* istanbul ignore file */
import { FC, useEffect } from 'react';
import { t } from 'i18next';
import { addMinutes, isAfter, isEqual, isSameDay, setHours, setMinutes } from 'date-fns';
import { useFormApi } from 'informed';

import { DateField } from '@ui-modules/informed';
import { TIME_INTERVAL } from '@common/constants';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface DropoffTimeFieldProps {
  fieldGroup?: string;
  returnTrip?: boolean;
}

const DropoffTimeField: FC<DropoffTimeFieldProps> = ({ fieldGroup, returnTrip = false }) => {
  const formApi = useFormApi();
  const { value: dropoffDate } = useFieldState<Date>('dropoffDate');
  const { value: dropoffTime } = useFieldState<Date>('dropoffTime');
  const { value: pickupDate } = useFieldState<Date>('pickupDate');
  const { value: pickupTime } = useFieldState<Date>('pickupTime');

  const isSameDate = pickupDate && dropoffDate && isSameDay(pickupDate, dropoffDate);
  const minEndTime = addMinutes(pickupTime, TIME_INTERVAL);

  useEffect(() => {
    if (
      returnTrip &&
      isSameDate &&
      (isAfter(pickupTime, dropoffTime) || isEqual(pickupTime, dropoffTime))
    ) {
      formApi.setValue(fieldGroup ? `${fieldGroup}.dropoffTime` : 'dropoffTime', minEndTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupTime]);

  return (
    <div className="field" data-testid="planner-drive-form-field-dropoff-time">
      <DateField
        className="input"
        dateFormat="HH:mm"
        label={t('bookingDetails.dropoffTime')}
        maxTime={isSameDate ? setHours(setMinutes(new Date(), 45), 23) : undefined}
        minTime={
          isSameDate
            ? setHours(setMinutes(new Date(), minEndTime.getMinutes()), minEndTime.getHours())
            : undefined
        }
        name="dropoffTime"
        showTimeSelect
        showTimeSelectOnly
        timeCaption={undefined}
        timeFormat="HH:mm"
        timeIntervals={TIME_INTERVAL}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DropoffTimeField;
