/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';

import { TextField } from '@ui-modules/informed';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator, validateWithPax } from '@common/utils';
import '../../DriveForm.styles.scss';

const GuestSurnameField: FC = () => {
  const { value: noPax } = useFieldState<boolean>('noPax');

  return (
    <div className="field">
      <TextField
        className="input"
        data-testid="planner-drive-form-field-surname"
        disabled={noPax}
        label={t('common.surname')}
        name="lastName"
        validate={validateWithPax(notEmptyValidator)}
      />
    </div>
  );
};

export default GuestSurnameField;
