const THOUSAND_DIVIDER = ',';
const MAX_ORDER = 7;
const MAX_DECIMAL_ORDER = 2;

export const formatNumber = (value: string | number, acceptsDecimals = false): string => {
  if (typeof value === 'number') {
    return value.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, THOUSAND_DIVIDER);
  }

  if (typeof value !== 'string') {
    return '';
  }

  let val = value.replace(/,/g, '');
  let decimal: string | null = null;

  if (val?.includes('.')) {
    if (acceptsDecimals) {
      [val, decimal] = val.split('.');

      if (decimal.length > MAX_DECIMAL_ORDER) {
        decimal = decimal.substring(0, decimal.length - 1);
      }
    } else {
      val = val.substring(0, val.length - 1);
    }
  }

  if (!/^[0-9.]+$/.test(val) || val.length > MAX_ORDER) {
    val = val.substring(0, val.length - 1);
  }

  val = (+val).toFixed();

  if (Number.isNaN(+val)) {
    return '';
  }

  const formatted = val.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSAND_DIVIDER);

  return decimal !== null ? `${formatted}.${decimal}` : formatted;
};

export const processFormattedNumber = (value: string | number) => {
  if (typeof value !== 'string') {
    return value;
  }

  if (value?.includes('.') && value[value.length - 1] === '.') {
    return value.substring(0, value.length - 1);
  }

  return value.replaceAll(',', '');
};
