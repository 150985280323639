import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form } from 'informed';
import {
  endOfMonth,
  endOfWeek,
  format,
  getMonth,
  getYear,
  isSameDay,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import FullCalendar, { DatesSetArg, EventContentArg } from '@fullcalendar/react';
import type { DatesRangeValue } from '@mantine/dates';
import { Button } from '@unbooking/ui-modules';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { MONTHS, YEARS } from '@common/constants';
import { useRepository } from '@context';
import { DatePicker, Loader } from '@components';
import {
  ChecklistClearSmall,
  ChecklistCriticalSmall,
  ChecklistDetails,
  ChecklistIssuesSmall,
  ChecklistNotReportedSmall,
} from '@assets/svg/icons';
import {
  CalendarVehiclesChecklistResourceProps,
  IVehiclesChecklist,
  IVehiclesChecklistDetails,
  ProtType,
  VehicleChecklistDetailsApi,
} from '@common/interfaces';
import { useDrivers, useFacility, useVehicles } from '@common/hooks';
import { getErrors } from '@common/utils';

import { CalendarVehiclesChecklist, DriverInfoPopup } from './components';
import { Serializer, getChecklistOverallStatus, getUnavailability } from './utils';
import './styles.scss';

const VehiclesChecklistPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    agencyName,
    facility: { agencyId, city, country, uuid: facilityId },
    facilityId: facilityIdOnUNBH,
    isLoading: isFacilityLoading,
  } = useFacility();

  const { vehiclesChecklistRepository } = useRepository();
  const { driverOptions } = useDrivers();
  const { isLoading: isVehiclesLoading, vehicleOptions, vehicles } = useVehicles();

  const calendarRef = useRef<FullCalendar | null>(null);

  const currentWeek = startOfWeek(new Date());
  const weekStartDate = format(currentWeek, 'yyyy-MM-dd');
  const weekEndDate = format(endOfWeek(currentWeek), 'yyyy-MM-dd');

  const [currentDate, setCurrentDate] = useState<Date>(currentWeek);
  const [month, setOverviewMonth] = useState<number>(getMonth(new Date()));
  const [year, setOverviewYear] = useState<number>(getYear(new Date()));
  const [createdAfter, setCreatedAfter] = useState<string>(weekStartDate);
  const [createdBefore, setCreatedBefore] = useState<string>(weekEndDate);

  const [selectedDriver, setSelectedDriver] = useState<IDropdownOption>();
  const [selectedVehicle, setSelectedVehicle] = useState<IDropdownOption>();

  const [selectedChecklistId, setSelectedChecklistId] = useState<string | null>(null);
  const [checklistDetails, setChecklistDetails] = useState<IVehiclesChecklistDetails | null>();

  const {
    data: checklistData,
    isLoading: isChecklistLoading,
    refetch,
  } = useQuery(
    'get-checklists',
    async () =>
      vehiclesChecklistRepository.getChecklists(facilityIdOnUNBH, {
        agencyId,
        createdAfter,
        createdBefore,
        driverId: selectedDriver?.value,
        locationId: facilityId,
      }),
    { enabled: false },
  );

  useQuery(
    ['get-checklist-details', selectedChecklistId],
    () => vehiclesChecklistRepository.getChecklistDetails(facilityIdOnUNBH, selectedChecklistId!),
    {
      enabled: !!selectedChecklistId,
      onSuccess(data: VehicleChecklistDetailsApi) {
        setChecklistDetails(Serializer.formatChecklistDetails(data));
      },
      onError(err: any) {
        toast.error(getErrors(err.response));
      },
    },
  );

  const { data: overview, refetch: getChecklistSummary } = useQuery(
    [`get-checklists-summary`, month, year],
    () =>
      vehiclesChecklistRepository.getChecklistSummary(facilityIdOnUNBH, {
        agencyId,
        createdAfter: format(startOfMonth(new Date(year, month)), 'yyyy-MM-dd'),
        createdBefore: format(endOfMonth(new Date(year, month)), 'yyyy-MM-dd'),
        driverId: selectedDriver?.value,
        locationId: facilityId,
      }),
    { enabled: false },
  );

  const handleViewChange = ({ startStr }: DatesSetArg): void => {
    if (currentDate && !isSameDay(currentDate, parseISO(startStr))) {
      setCurrentDate(parseISO(startStr));
    }
  };

  const renderEventContent = (eventContent: EventContentArg): JSX.Element => {
    const {
      event: { extendedProps },
    } = eventContent;

    const { driverFirstName, driverLastName, id } = extendedProps;

    const { status, statusName } = getChecklistOverallStatus(extendedProps as IVehiclesChecklist);
    let content: JSX.Element;

    if (status === 'not-used') {
      content = <div>{statusName}</div>;
    } else {
      const iconsMapper: Record<
        string,
        React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>
      > = {
        clear: ChecklistClearSmall,
        issues: ChecklistIssuesSmall,
        critical: ChecklistCriticalSmall,
        'not-reported': ChecklistNotReportedSmall,
      };

      const ChecklistIcon = iconsMapper[status];
      const driverFullName = `${driverFirstName ?? '\u00A0'} ${driverLastName ?? '\u00A0'}`;

      content = (
        <>
          <div className={`fc-event-header fc-event-header-${status}`}>{statusName}</div>
          <div className="fc-event-desc">
            <h4>{driverFullName}</h4>
            <div className="fc-event-desc-status">
              <div className={`fc-event-desc-icon fc-event-desc-icon-${status}`}>
                <ChecklistIcon />
              </div>
              <ChecklistDetails className="fc-event-desc-icon-details" />
            </div>
          </div>
        </>
      );
    }

    return (
      <figure
        className={`fc-event-content fc-event-content-${status}`}
        data-testid={`vehicles-checklist-event-${status}`}
        onDoubleClick={() => {
          if (status === 'issues' || status === 'critical') {
            setSelectedChecklistId(id);
          } else {
            navigate(`${id}`);
          }
        }}
      >
        {content}
      </figure>
    );
  };

  const renderResourceContent = ({
    resource: { extendedProps },
  }: CalendarVehiclesChecklistResourceProps): JSX.Element => (
    <section className="fc-resource-content" data-testid="vehicles-checklist-calendar-resource">
      <div className="fc-resource-content-vehicle-image">
        <img src={extendedProps.model?.image ?? undefined} alt={extendedProps.plateNumber} />
        <div className="fc-resource-content-vehicle-protection">
          {extendedProps.protection === ProtType.Soft ? 'Soft' : 'Armoured'}
        </div>
      </div>
      <div className="fc-resource-content-desc">
        <h3>
          {extendedProps.model?.name} <br /> {extendedProps.plateNumber}
          {extendedProps.currentUnavailability || extendedProps.criticalIssue ? (
            <p
              className={`${extendedProps.currentUnavailability ? 'issues' : ''} ${
                extendedProps.criticalIssue ? 'critical' : ''
              }`}
            >
              {getUnavailability(extendedProps)}
            </p>
          ) : null}
        </h3>
      </div>
    </section>
  );

  const headerToolbarCalendar = useMemo(
    () => (
      <div data-testid="vehicles-checklist-calendar-date-picker">
        <DatePicker
          firstDayOfWeek={0}
          format="DD MMM YY"
          iconPosition="left"
          showIconOnly
          type="range"
          value={[currentDate, endOfWeek(currentDate)]}
          variant="light"
          onChange={(v) => {
            const date = v as DatesRangeValue;
            const currentWeekStartDate = date[0] && startOfWeek(date[0]);

            if (currentWeekStartDate) {
              calendarRef.current?.getApi().gotoDate(currentWeekStartDate);
              setCurrentDate(currentWeekStartDate);
            }
          }}
        />
      </div>
    ),
    [currentDate],
  );

  useEffect(() => {
    if (agencyId && facilityId) {
      refetch();
    }
  }, [agencyId, createdAfter, createdBefore, facilityId, refetch, selectedDriver]);

  useEffect(() => {
    if (agencyId && facilityId && year) {
      getChecklistSummary();
    }
  }, [agencyId, facilityId, getChecklistSummary, month, year]);

  useEffect(() => {
    if (currentDate) {
      setCreatedBefore(format(endOfWeek(currentDate), 'yyyy-MM-dd'));
      setCreatedAfter(format(currentDate, 'yyyy-MM-dd'));
    }
  }, [currentDate]);

  const calendarChecklistEvents = useMemo(
    () => Serializer.mapChecklistEventsForCalendar(checklistData?.results ?? []),
    [checklistData],
  );

  const visibleVehicles = useMemo(
    () => (selectedVehicle ? vehicles.filter(({ id }) => selectedVehicle.value === id) : vehicles),
    [vehicles, selectedVehicle],
  );

  const calendarVehicles = useMemo(
    () => Serializer.mapVehiclesForScheduler(visibleVehicles, checklistData?.results ?? []),
    [checklistData, visibleVehicles],
  );

  return (
    <section className="vehicles-checklist">
      <Loader fullScreen spinning={isChecklistLoading || isFacilityLoading || isVehiclesLoading}>
        <header className="vehicles-checklist-header">
          <section className="vehicles-checklist-filters">
            <h1>
              {`${t('mobility.vehiclesChecklist')} - `}
              <span>
                {agencyName} {country}, {city}
              </span>
            </h1>
            <Form className="filters">
              <div className="driver">
                <Dropdown
                  className="dropdown outline"
                  name="filterByDriver"
                  options={driverOptions}
                  placeholder={t('mobility.filterByDriver')}
                  value={selectedDriver}
                  onChange={({ value }) => {
                    setSelectedDriver(
                      driverOptions.find(({ value: driverId }) => driverId === value),
                    );
                  }}
                />
              </div>
              <div className="vehicle">
                <Dropdown
                  className="dropdown outline"
                  name="filterByVehicle"
                  options={vehicleOptions}
                  placeholder={t('mobility.filterByVehicle')}
                  value={selectedVehicle}
                  onChange={({ value }) => {
                    setSelectedVehicle(
                      vehicleOptions.find(({ value: vehicleId }) => vehicleId === value),
                    );
                  }}
                />
              </div>
              <Button
                className="btn btn-reset"
                text={t('common.btnResetFilters')}
                variant="link"
                onClick={() => {
                  setSelectedDriver(undefined);
                  setSelectedVehicle(undefined);
                }}
              />
            </Form>
          </section>

          <section className="vehicles-checklist-info">
            <section className="vehicles-checklist-info-overview">
              <div>
                <Form className="filters">
                  <div className="overview">
                    <h3>{t('mobility.overviewFor')}</h3>
                    <Dropdown
                      className="dropdown outline"
                      defaultValue={MONTHS.find(({ value }) => value === format(new Date(), 'M'))}
                      name="overviewMonth"
                      options={MONTHS}
                      onChange={(option: IDropdownOption) => setOverviewMonth(+option.value - 1)}
                    />
                    <Dropdown
                      className="dropdown outline"
                      defaultValue={YEARS.find(({ value }) => value === format(new Date(), 'yyyy'))}
                      name="overviewYear"
                      options={YEARS}
                      onChange={(option: IDropdownOption) => setOverviewYear(+option.value)}
                    />
                  </div>
                </Form>

                <ul className="checklist">
                  <li className="clear">
                    {overview?.aggregated.clear_number ?? 0} {t('mobility.clear')}
                  </li>
                  <li className="critical">
                    {overview?.aggregated.critical_issues_number ?? 0} {t('mobility.critical')}
                  </li>
                  <li className="not-reported">
                    {overview?.aggregated.not_submitted_number ?? 0} {t('mobility.notReported')}
                  </li>
                  <li className="issues">
                    {overview?.aggregated.issues_number ?? 0} {t('mobility.issues')}
                  </li>
                  <li className="not-used">
                    {overview?.aggregated.not_used ?? 0} {t('mobility.notUsed')}
                  </li>
                </ul>
              </div>

              <div className="daily-checks">
                <h2>{overview?.aggregated.submitted_number ?? 0}</h2>
                <small>{t('mobility.submitted')}</small>
              </div>
            </section>
          </section>
        </header>

        <main>
          {visibleVehicles.length ? (
            <CalendarVehiclesChecklist
              eventContent={renderEventContent}
              events={calendarChecklistEvents}
              headerToolbarCalendar={headerToolbarCalendar}
              ref={calendarRef}
              resourceContent={renderResourceContent}
              resources={calendarVehicles}
              onViewChange={handleViewChange}
            />
          ) : null}

          {checklistDetails && (
            <DriverInfoPopup
              checklist={checklistDetails}
              onClosePopup={() => {
                setChecklistDetails(null);
                setSelectedChecklistId(null);
              }}
            />
          )}
        </main>
      </Loader>
    </section>
  );
};

export default VehiclesChecklistPage;
