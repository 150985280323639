import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, isAfter, isEqual } from 'date-fns';
import { Form, useFieldState, useFormApi } from 'informed';

import { Button } from '@unbooking/ui-modules';
import { DateField } from '@ui-modules/informed';
import { requiredField } from '@common/utils';
import './styles.scss';

export interface FormStateProps {
  rangeEnd: Date | string;
  rangeStart: Date | string;
}

export interface DateRangeFormProps {
  dateFormat?: string;
  onClose?: () => void;
  onSubmit: (data: FormStateProps) => void;
}

const RangeStartField: FC = () => {
  const formApi = useFormApi();
  const { value: endDate } = useFieldState('rangeEnd');
  const { t } = useTranslation();

  return (
    <div className="field">
      <DateField
        dropdownMode="select"
        label={`${t('common.rangeStart')}:`}
        name="rangeStart"
        placeholder={`${t('common.inputStartDate')}`}
        showMonthDropdown
        showYearDropdown
        validate={requiredField}
        onChange={(value) => {
          if (isAfter(value as Date, endDate as Date)) {
            formApi.setValue('rangeEnd', value);
          }
        }}
      />
    </div>
  );
};

const RangeEndField: FC = () => {
  const { value: startDate } = useFieldState('rangeStart');
  const { t } = useTranslation();

  return (
    <div className="field">
      <DateField
        dropdownMode="select"
        label={`${t('common.rangeEnd')}:`}
        minDate={startDate as Date}
        name="rangeEnd"
        placeholder={`${t('common.inputEndDate')}`}
        showMonthDropdown
        showYearDropdown
        validate={requiredField}
      />
    </div>
  );
};

const DateRangeForm: FC<DateRangeFormProps> = ({
  dateFormat = 'yyyy-MM-dd',
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      className="form-date-range"
      onSubmit={(formState: Record<string, unknown>) => {
        const state = { ...(formState.values as FormStateProps) };

        const start = new Date(state.rangeStart);
        const end = new Date(state.rangeEnd);

        if (isAfter(start, end) && isEqual(start, end)) {
          toast.error('The specified date range is not valid');
        } else {
          onSubmit({
            rangeEnd: format(end, dateFormat),
            rangeStart: format(start, dateFormat),
          });
          onClose?.();
        }
      }}
    >
      <div className="row">
        <RangeStartField />
        <RangeEndField />
      </div>
      <div className="footer">
        <Button
          className="btn btn-cancel"
          text={t('common.btnCancel')}
          variant="secondary"
          onClick={() => onClose?.()}
        />
        <Button
          className="btn btn-submit"
          text={t('common.btnExport')}
          type="submit"
          variant="primary"
        />
      </div>
    </Form>
  );
};

export default DateRangeForm;
