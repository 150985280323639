/* istanbul ignore file */
function Ohchr(props: any) {
  return (
    <svg width={18} height={22} viewBox="0 0 18 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.578 6.951l.46.469c.464.731 1.116 1.457 1.76 1.92-.137-.293-.141-.634-.284-.926-.287-.817-1.203-1.214-1.936-1.463zM4.23 7.043c-.568.233-1.182.419-1.605.963-.328.438-.43.961-.596 1.461l.502-.438c.763-.543.96-1.426 1.699-1.986z"
        fill="#EAF2F9"
      />
      <path
        d="M15.34 7.804c.633.425.592 1.213.817 1.853.06.267.22.51.238.78-.34-1.006-1.486-1.115-1.902-2.066l.131.554c.322 1.079 1.58 1.34 2.058 2.315-.148-.707.113-1.445-.095-2.14-.174-.594-.657-1.118-1.246-1.296zM1.467 8.766c-.484.725-.09 1.73-.226 2.59.173-.355.507-.688.798-.938.674-.444 1.277-1.15 1.3-1.944-.358.938-1.538 1.061-1.83 2.059h-.03c.435-.828.19-1.999 1.02-2.613-.435.128-.804.457-1.032.846z"
        fill="#EAF2F9"
      />
      <path
        d="M16.713 8.985c.441.658.19 1.585.16 2.353.018.37.078.744.053 1.115-.053-.148-.064-.305-.101-.459-.18-.833-1.14-1.242-1.527-1.944.06.238.101.475.184.7.334.988 1.335 1.652 1.474 2.724.088-1.023.793-1.84.53-2.953-.136-.56-.363-1.11-.773-1.536zM1.148 9.12a3.48 3.48 0 00-.699 1.462c-.23 1.12.431 1.986.574 3.01.19-1.285 1.515-2.095 1.587-3.435-.388.724-1.33 1.145-1.503 2.004 0 .141-.043.275-.072.407l-.03-.03.047-.554c.16-.993-.406-2.018.096-2.864z"
        fill="#EAF2F9"
      />
      <path
        d="M17.715 11.356c.166.987-.418 1.73-.656 2.59-.131.329-.078.75-.275 1.037-.012-.166.078-.324.09-.5.148-1.104-.79-1.754-1.002-2.687-.012.251.005.488.04.714.055 1.164.801 2.109.586 3.321.216-.287.377-.58.633-.859.857-.916 1.078-2.446.584-3.616zM.234 11.484c-.31.733-.291 1.7-.06 2.462.203.786.937 1.334 1.318 2.018-.066-.287-.053-.646-.012-.94.25-1.015.645-1.997.584-3.124-.148.81-.857 1.383-.941 2.219-.055.34.03.676.072.98a1.34 1.34 0 01-.162-.524c-.12-1.1-.984-1.903-.799-3.091z"
        fill="#EAF2F9"
      />
      <path
        d="M16.014 13.896c-.566 1.25-.154 2.907-.847 4.094l.113-.097c1.342-.749 2.958-2.01 2.69-3.85-.023.25-.1.475-.214.689-.525.835-1.421 1.517-1.863 2.382-.011.03-.054.05-.072.018l.09-.117c.633-.931.066-2.076.103-3.119zM1.964 14.018c.072.403-.054.756-.113 1.12-.203.702-.101 1.526.299 2.098-.131-.067-.215-.227-.299-.365-.496-.969-1.742-1.519-1.85-2.682-.005.422-.005.866.12 1.244.346 1.304 1.754 1.974 2.702 2.63-.673-1.188-.316-2.801-.859-4.045z"
        fill="#EAF2F9"
      />
      <path
        d="M15.442 15.744l-.502.67c-.673.951-.595 2.25-1.532 3.06.484-.159 1.015-.225 1.515-.336 1.128-.291 2.314-1.426 2.475-2.608-.203.493-.626.86-1.056 1.146-.74.352-1.491.756-2.118 1.316h-.07l.042-.061c1.078-.733.786-2.147 1.246-3.187zM2.537 15.846l.173.451c.168 1.011.244 2.126 1.146 2.766l-.012.012c-.292-.128-.512-.379-.776-.54-.848-.563-2.016-.812-2.494-1.883.131.785.656 1.487 1.289 1.974.757.682 1.814.628 2.739.901-.341-.269-.531-.574-.686-.95-.34-.958-.638-1.957-1.38-2.73z"
        fill="#EAF2F9"
      />
      <path
        d="M14.164 17.717c-.686.458-1.277 1.238-1.902 1.736-.346.268-.758.348-1.164.47.896.067 1.64.498 2.579.438a2.859 2.859 0 002.392-1.547c-.693.84-1.802.791-2.822.889-.34.042-.674.146-1.013.146.011-.012.023-.036.04-.032 1.114-.096 1.43-1.332 1.89-2.1zM3.815 17.79c.459.549.476 1.273 1.115 1.724.244.219.572.262.847.378-.304.03-.584-.097-.888-.116-1.027-.129-2.202.005-2.954-.847.34.609.943 1.139 1.574 1.346 1.193.463 2.273-.219 3.413-.323-1.324-.06-2.04-1.535-3.107-2.162z"
        fill="#EAF2F9"
      />
      <path
        d="M12.404 20.642c-1.02-.475-2.2-1.005-3.393-.555a3.365 3.365 0 00-1.994 0c-1.048.489-2.481 1.225-3.59.41.208.255.435.376.673.553.715.475 1.748.427 2.488.043.68-.293 1.199-1.165 2.064-.89-.734.377-1.408.83-2.017 1.474l.37.323c.538-.726 1.26-1.328 2.035-1.683.752.373 1.437 1.013 2.005 1.653l.37-.325a8.41 8.41 0 00-2.006-1.443c.186-.05.412-.036.602-.018.777.347 1.372 1.04 2.25 1.146.929.152 1.759-.233 2.33-.927l-.44.239c-.517.237-1.216.176-1.747 0zM9.274.144c-.031 1.568.562 2.883 1.184 4.188 1.215 2.3 3.132 4.306 3.787 6.84.522 2.201.11 4.628-1.386 6.294-1.065 1.158-2.52 1.636-4.037 1.85l-.03-.038c.804-.75.985-1.949.663-2.991-.383-1.247-1.365-2.25-1.848-3.46-.603-1.208-1.005-2.514-.863-3.986.09-.954.22-1.88.462-2.776.02-.02.05 0 .07-.01-.553 2.612 1.174 4.6 2.84 6.46.725.914 1.287 1.9 1.478 3.038.09-.4-.01-.857-.11-1.247-.633-2.064-1.748-3.946-2.43-5.99-.404-1.326-.604-2.74-.663-4.2.01-.02.031-.03.06-.02.031 2.086 1.355 3.663 2.441 5.33 1.114 1.463 2.129 2.962 2.279 4.813h.02c.281-1.363-.07-2.758-.58-3.936l-1.467-3.167C10.21 4.876 9.146 2.665 9.196.024c.078-.084.068.08.078.12z"
        fill="#EAF2F9"
      />
      <path
        d="M4.368 9.721c-.402.935-.422 2.134-.13 3.127.321 1.353 1.636 2.552 2.953 2.971-1.305-1.52-1.626-3.614-1.284-5.64.009-.02.03-.011.05-.02-.1.916.03 1.822.343 2.61.372 1.084 1.104 2.027 1.957 2.876.56.71.954 1.734.502 2.62-.18.322-.422.576-.732.828l-.06-.009c.11-.352-.202-.593-.444-.8-1.465-.847-3.304-1.948-3.795-3.642-.522-1.677.01-3.429.564-4.92l.06-.04.016.04z"
        fill="#EAF2F9"
      />
    </svg>
  );
}

export default Ohchr;
