import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'informed';
import { TextAreaField, TextField } from '@ui-modules/informed';
import { Button } from '@unbooking/ui-modules';
import { emailValidator } from '@common/utils';
import './styles.scss';

export interface IFormState {
  answer: string;
  adminEmail: string;
}

export interface IReviewAnswerForm {
  confirmButtonText?: string;
  feedback?: string;
  feedbackTitle?: string;
  needAdminEmail?: boolean;
  placeholder?: string;
  requireValidation?: boolean;
  title?: string;
  onConfirm?: (answer: string, adminEmail?: string) => void;
}

const ReviewAnswerForm: FC<IReviewAnswerForm> = ({
  confirmButtonText,
  feedback,
  feedbackTitle = 'Feedback',
  needAdminEmail = false,
  placeholder,
  requireValidation = false,
  title,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const [adminEmail, setAdminEmail] = useState<string | undefined>();
  const [answer, setAnswer] = useState<string>('');

  const checkValidation = useCallback(() => {
    if (!requireValidation) return true;
    return !!answer && (!needAdminEmail || (needAdminEmail && !!adminEmail));
  }, [requireValidation, answer, adminEmail, needAdminEmail]);

  const [isValid, setIsValid] = useState<boolean>(checkValidation());

  useEffect(() => {
    setIsValid(checkValidation());
  }, [requireValidation, needAdminEmail, answer, adminEmail, checkValidation]);

  return (
    <div className="review-answer">
      {!!title && <h2 className="review-answer-title">{title}</h2>}

      <div className="review-answer-content">
        {!!feedback && !!feedbackTitle && (
          <div className="review-answer-feedback">
            <div className="review-answer-feedback-title">{feedbackTitle}</div>
            <div className="review-answer-feedback-text">{feedback}</div>
          </div>
        )}

        <Form
          className="review-answer-form"
          onSubmit={(formState: Record<string, unknown>) => {
            const state = { ...(formState.values as IFormState) };

            onConfirm?.(state.answer, state.adminEmail);
            setAnswer('');
          }}
        >
          <div className="field">
            <TextAreaField
              defaultValue={answer}
              placeholder={placeholder}
              name="answer"
              onChange={(state) => setAnswer(state.value as string)}
            />
          </div>

          {needAdminEmail && (
            <div className="field">
              <TextField
                initialValue={answer}
                label={t('common.email')}
                placeholder={t('feedback.inputAdminEmail')}
                name="answer"
                type="email"
                validate={emailValidator}
                onChange={(state) => setAdminEmail(state.value as string)}
              />
            </div>
          )}

          {!!confirmButtonText && (
            <Button
              className="btn btn-confirm"
              disabled={!isValid}
              text={confirmButtonText}
              type="submit"
            />
          )}
        </Form>
      </div>
    </div>
  );
};

export default ReviewAnswerForm;
