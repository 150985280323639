import { forwardRef } from 'react';
import FullCalendar, {
  CustomContentGenerator,
  DatesSetArg,
  EventContentArg,
  EventSourceInput,
} from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import type { PlDriverResource, PlVehicleResource, PlDriver, PlVehicle } from '@common/interfaces';
import './Planner.styles.scss';

interface CalendarProps {
  eventContent?: CustomContentGenerator<EventContentArg>;
  events: EventSourceInput;
  headerToolbar?: JSX.Element[];
  resourceContent?: CustomContentGenerator<PlDriverResource | PlVehicleResource>;
  resources?: PlDriver[] | PlVehicle[];
  onDoubleClick?: (date: Date, resourceId: string, viewType: string) => void;
  onViewChange?: (payload: DatesSetArg) => void;
}

let doubleClickTimeoutId: NodeJS.Timeout | null = null;

const Planner = forwardRef<FullCalendar, CalendarProps>(
  (
    {
      eventContent,
      events,
      headerToolbar,
      resourceContent,
      resources,
      onDoubleClick,
      onViewChange,
    },
    calendarRef,
  ) => (
    <div className="fc-wrapper">
      {headerToolbar?.map((elem: JSX.Element) => elem) || null}

      <FullCalendar
        contentHeight="auto"
        datesSet={(payload: DatesSetArg) => onViewChange?.(payload)}
        dateClick={({ date, resource, view }) => {
          if (onDoubleClick) {
            if (doubleClickTimeoutId) {
              clearTimeout(doubleClickTimeoutId);
              doubleClickTimeoutId = null;
              return onDoubleClick(date, resource?.id || '', view.type);
            }

            doubleClickTimeoutId = setTimeout(() => {
              if (doubleClickTimeoutId) {
                clearTimeout(doubleClickTimeoutId);
                doubleClickTimeoutId = null;
              }
            }, 200);
          }
        }}
        eventContent={eventContent}
        eventOrder="order"
        eventOverlap={false}
        events={events}
        headerToolbar={{
          left: 'prev today next resourceTimelineDay,resourceTimelineWeek',
          center: 'title',
          right: '',
        }}
        initialView="resourceTimelineDay"
        locale="en-GB" // 00:00 to 24:00
        plugins={[interactionPlugin, resourceTimelinePlugin]}
        ref={calendarRef}
        resources={resources}
        resourceAreaHeaderContent="&nbsp;"
        resourceAreaWidth="260px"
        resourceLabelContent={resourceContent}
        resourceOrder="" // disable sorting by default
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        stickyHeaderDates
        titleFormat={{ day: 'numeric', month: 'long', year: 'numeric' }}
        views={{
          resourceTimelineDay: {
            slotDuration: '00:30:00',
            slotLabelFormat: {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            },
            slotLabelInterval: '01:00',
            slotMaxTime: '24:00:00',
            slotMinTime: '00:00:00',
            slotMinWidth: 20,
          },
          resourceTimelineWeek: {
            slotDuration: { days: 1 },
            slotLabelFormat: {
              day: 'numeric',
              weekday: 'short',
            },
          },
        }}
      />

      {!resources?.length && <div className="fc-content-empty">No resources</div>}
    </div>
  ),
);

Planner.displayName = 'Planner';

export default Planner;
