import type { FC, ReactNode } from 'react';
import { FieldState, Radio, RadioGroup, useFormState } from 'informed';
import { IRadio } from '@common/interfaces';
import './styles.scss';

export interface RadioGroupProps {
  className?: string;
  initialValue?: string;
  name: string;
  options: IRadio[];
  orientation?: 'horizontal' | 'vertical';
  radioGroupLabel?: string;
  type?: 'default' | 'white';
  onChange?:
    | ((fieldState: FieldState, event: React.SyntheticEvent<Element, Event>) => void)
    | undefined;
}

const RadioGroupInformed: FC<RadioGroupProps> = ({
  className,
  initialValue,
  name,
  options,
  orientation = 'horizontal',
  radioGroupLabel,
  type = 'default',
  onChange,
  ...rest
}) => {
  const { errors } = useFormState();
  const error = errors?.[name] as ReactNode;

  return (
    <div className={className ? `radio ${className}` : 'radio'}>
      {radioGroupLabel && <div className="radio-label">{radioGroupLabel}</div>}
      <div className={`radio-group radio-group-${orientation} radio-group-${type}`}>
        <RadioGroup initialValue={initialValue} name={name} onChange={onChange} {...rest}>
          {options?.map(({ label, value }: IRadio) => (
            <Radio key={value} label={label} value={value} />
          ))}
        </RadioGroup>
      </div>
      {error ? <p className="radio-error">{error}</p> : null}
    </div>
  );
};

export default RadioGroupInformed;
