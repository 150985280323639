/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import { type FC, useEffect } from 'react';
import { t } from 'i18next';
import { useFormApi } from 'informed';

import { Dropdown } from '@ui-modules/informed';
import type { IDropdownOption } from '@ui-modules/types';
import type { PlDrive, PlDriver, PlVehicle } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

interface IDriverField {
  driver?: string | null;
  drivers?: PlDriver[];
  fieldGroup?: string;
  isEdit?: boolean;
  isShuttle?: boolean;
  options: IDropdownOption[];
  sharedDrive?: PlDrive | null;
  vehicles?: PlVehicle[];
}

const DriverField: FC<IDriverField> = ({
  driver,
  drivers,
  fieldGroup,
  isEdit,
  isShuttle,
  options,
  sharedDrive,
  vehicles,
}) => {
  const { setValue } = useFormApi();
  const { value: driverOption } = useFieldState<IDropdownOption>('driver');
  const { value: vehicleOption } = useFieldState<IDropdownOption>('vehicle');

  const selectedVehicle = vehicles?.find((v) => v.id === vehicleOption?.value);
  const preferredDriver = !isEdit
    ? options.find((i) => i.value === selectedVehicle?.preferredDriver)
    : null;
  const defaultValue =
    preferredDriver || options.find((i) => i.value === (sharedDrive?.driverId || driver));

  useEffect(() => {
    if (driverOption) {
      const selectedDriver = drivers?.find((i) => i.id === driverOption?.value);
      const preferredVehicle = vehicles?.find((i) => i.id === selectedDriver?.preferredVehicle);

      if (preferredVehicle) {
        const vehicleOpt = { label: preferredVehicle.title, value: preferredVehicle.id };
        setValue(fieldGroup ? `${fieldGroup}.vehicle` : 'vehicle', vehicleOpt);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverOption]);

  useEffect(() => {
    if (vehicleOption) {
      if (driverOption) {
        const isSharedDriverSelected = vehicles?.some(
          (i: PlVehicle) => i.shared && i.preferredDriver === driverOption.value,
        );

        if (isSharedDriverSelected && !selectedVehicle?.shared) {
          setValue(fieldGroup ? `${fieldGroup}.driver` : 'driver', null);
          return;
        }
      }

      if (selectedVehicle?.shared && preferredDriver && isEdit) {
        setValue(fieldGroup ? `${fieldGroup}.driver` : 'driver', preferredDriver);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleOption]);

  useEffect(() => {
    if (defaultValue) {
      setValue(fieldGroup ? `${fieldGroup}.driver` : 'driver', defaultValue);
    }
  }, [defaultValue, fieldGroup, setValue]);

  return (
    <div className="field" data-testid="planner-drive-form-field-driver">
      <Dropdown
        className="dropdown"
        defaultValue={defaultValue}
        isDisabled={isShuttle ? false : sharedDrive !== null}
        label={t('common.driver')}
        name="driver"
        options={options}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DriverField;
