/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';

import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface PurposeFieldProps {
  purposeOptions: IDropdownOption[];
}

const PurposeField: FC<PurposeFieldProps> = ({ purposeOptions }) => (
  <div className="field" data-testid="planner-drive-form-field-purpose">
    <Dropdown
      className="dark-border small-list"
      label={t('common.purpose')}
      name="purpose"
      options={purposeOptions}
      validate={notEmptyValidator}
    />
  </div>
);

export default PurposeField;
