export enum DateFormat {
  ApiDate = 'yyyy-MM-dd',
  ApiDateAlt = 'dd/MM/yyyy',
  ApiDateAltFull = 'dd/MM/yyyy HH:mm:ss',
  ApiDateFull = 'yyyy-MM-dd HH:mm:ss',
  ApiTime = 'HH:mm',
  ApiTimeFull = 'HH:mm:ss',
  BookingInfoDate = 'dd MMM yyyy',
  DateByDots = 'dd.MM.yyyy',
  InvoiceInfoDate = 'dd MMMM yyyy',
}

export enum DateInputFormat {
  DateByDots = 'DD.MM.YYYY',
}
