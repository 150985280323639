/* istanbul ignore file */
import { IDropdownOption } from '@ui-modules/types';
import { AgencyApi, AgencyType } from './Common';

export enum ProtType {
  Armoured = 'armoured',
  Soft = 'soft',
}

export enum OwnerType {
  External = 'external_provide',
  Owned = 'owned',
  Rented = 'rented',
}

export type UnavailabilityApi = {
  deleted?: boolean;
  reason?: number;
  reason_label?: string;
  since_date: string;
  since_time: string;
  until_date: string;
  until_time: string;
  uuid: string;
};
export type Unavailability = {
  deleted?: boolean;
  id: string;
  reason?: number;
  reasonLabel?: string;
  sinceDate: string;
  sinceTime: string;
  untilDate: string;
  untilTime: string;
};
export type UnavailabilityUpdate = {
  deleted?: boolean;
  reason?: number;
  since_date?: string;
  since_time?: string;
  until_date?: string;
  until_time?: string;
};
export type LocationApi = {
  agency: string;
  agency_id: string;
  city: string;
  country: string;
  is_shared: boolean;
  location_id: string;
  name: string;
  uuid: string;
};
export type LocationType = {
  agency: string;
  agencyId: string;
  city: string;
  country: string;
  isShared: boolean;
  locationId: string;
  name: string;
  uuid: string;
};

export type PreferredDriverApi = { last_name: string; name: string; uuid: string };
export type SharedLocationApi = { agency_id: string; location_id: string };
export type VehicleModelApi = { full_name: string; image: string; pk: string };
export type VehicleModel = { id: string; image: string; name: string };
export type VehicleModelListApi = { full_name: string; pk: string };

export interface DriverApi {
  agency: AgencyApi | null;
  blood_group: number | null;
  blood_group_display: string | null;
  current_unavailability: UnavailabilityApi | null;
  email: string;
  last_name: string;
  license_expiration_date: string | null;
  license_number: string | null;
  name: string;
  phone_number: string;
  photo: string | null;
  preferred_vehicle: string;
  shared_agencies_count: number;
  uuid: string;
}

export interface DriverType {
  agency: AgencyType | null;
  bloodGroup: number;
  bloodGroupDisplay: string;
  currentUnavailability: Unavailability | null;
  email: string;
  id: string;
  lastName: string;
  licenseExpirationDate: string;
  licenseNumber: string;
  name: string;
  phoneNumber: string;
  photo: string | null;
  preferredVehicle: string;
  sharedAgenciesCount: number;
}

export interface DriverCreate {
  agency: string;
  email: string;
  last_name: string;
  license_expiration_date: string | null;
  license_number: string | null;
  name: string;
  phone_number: string;
}

export interface DriverDetailsApi {
  blood_group: string | null;
  blood_group_display: string | null;
  email: string;
  last_modified_date: string;
  last_name: string;
  license_expiration_date: string;
  license_number: string;
  locations: LocationApi[];
  name: string;
  phone_number: string;
  photo: string;
  preferred_vehicle: IDropdownOption | null;
  shared_location_options: LocationApi[];
  shared_locations: LocationApi[];
  uuid: string;
}

export interface DriverDetails {
  bloodGroup: string | null;
  bloodGroupDisplay: string | null;
  email: string;
  id: string;
  lastModifiedDate: string;
  lastName: string;
  licenseExpirationDate?: Date;
  licenseNumber: string;
  locations: LocationType[];
  name: string;
  phoneNumber: string;
  photo: string;
  preferredVehicle: IDropdownOption | null;
  sharedLocationOptions: IDropdownOption[];
  sharedLocations: string[];
}

export interface DriverUpdate {
  agency?: AgencyApi;
  blood_group?: number;
  email?: string;
  is_active?: boolean;
  last_name?: string;
  license_expiration_date?: string | null;
  license_number?: string | null;
  name?: string;
  phone_number?: string;
  photo?: string;
  preferred_vehicle?: string | null;
  shared_locations?: SharedLocationApi[];
}

export interface VehicleApi {
  agency: AgencyApi | null;
  chassis_number: string | null;
  connected_to_gps: boolean;
  current_unavailability: UnavailabilityApi | null;
  gvlp_number: string | null;
  is_active: boolean;
  label: string;
  max_capacity: number;
  model: VehicleModelApi | null;
  plate_number: string;
  protection: ProtType;
  roof_number: string | null;
  shared_agencies_count: number;
  total_km: number;
  uuid: string;
  vehicle_owner: OwnerType;
}

export interface VehicleType {
  agency: AgencyType | null;
  chassisNumber: string | null;
  connectedToGps: boolean;
  currentUnavailability: Unavailability | null;
  gvlpNumber: string | null;
  id: string;
  isActive: boolean;
  label: string;
  maxCapacity: number;
  model: VehicleModel | null;
  plateNumber: string;
  protection: ProtType;
  roofNumber: string | null;
  sharedAgenciesCount: number;
  totalKm: number;
  vehicleOwner: OwnerType;
}

export interface VehicleCreate {
  agency: string;
  chassis_number: string;
  gvlp_number?: string | null;
  max_capacity: number;
  model_fk: string;
  plate_number: string;
  protection: ProtType;
  roof_number?: string | null;
}

export interface VehicleDetailsApi {
  agency: AgencyApi | null;
  chassis_number: string | null;
  connected_to_gps: boolean;
  gvlp_number: string | null;
  is_active: boolean;
  label: string;
  last_modified_date: string;
  locations: LocationApi[];
  max_capacity: number;
  model: VehicleModelApi | null;
  model_choices: IDropdownOption[];
  plate_number: string;
  preferred_driver: IDropdownOption | null;
  protection: ProtType;
  roof_number: string | null;
  shared_location_options: LocationApi[];
  shared_locations: LocationApi[];
  total_km: number;
  uuid: string;
  vehicle_owner: OwnerType;
}

export interface VehicleDetails {
  agency: AgencyType | null;
  chassisNumber: string;
  connectedToGps: boolean;
  gvlpNumber: string;
  id: string;
  isActive: boolean;
  label: string;
  lastModifiedDate: string;
  locations: LocationType[];
  maxCapacity: number;
  model: VehicleModel | null;
  modelChoices: IDropdownOption[];
  plateNumber: string;
  preferredDriver: IDropdownOption | null;
  protection: ProtType;
  roofNumber: string;
  sharedLocationOptions: IDropdownOption[];
  sharedLocations: string[];
  totalKm: number;
  vehicleOwner: OwnerType;
}

export interface VehicleUpdate {
  agency?: AgencyApi;
  chassis_number?: string;
  gvlp_number?: string | null;
  is_active?: boolean;
  label?: string;
  max_capacity?: number;
  model?: string;
  plate_number?: string;
  preferred_driver?: string | null;
  protection?: ProtType;
  roof_number?: string | null;
  shared_locations?: SharedLocationApi[];
  vehicle_owner?: OwnerType;
}
