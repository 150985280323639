/* istanbul ignore file */
import React from 'react';
import axios from 'axios';
import { NavigateFunction } from 'react-router';
import { toast } from 'react-toastify';

import { LocalStorageKeys, SessionStorageKeys } from '@common/constants';
import { AuthProviderType } from '@common/interfaces';
import { StatusCode } from '@common/types';

export type UserType = 'azure' | 'b2c' | 'google' | 'non-federated';

export enum LocalStorageSSOKeys {
  ID_TOKEN = 'ID_TOKEN',
  IS_AUTHENTICATED = 'IS_AUTHENTICATED',
  LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
  USER_EMAIL = 'USER_EMAIL',
  USER_NAME = 'USER_NAME',
}

export interface SignInForm {
  email: string;
  password: string;
}

export const checkUserLogin = (userType: UserType) => {
  const ssoToken = localStorage.getItem(LocalStorageSSOKeys.ID_TOKEN);
  const federatedUserToken = localStorage.getItem(LocalStorageKeys.X_USER_TOKEN);

  if (
    (userType === 'non-federated' && !federatedUserToken) ||
    (userType === 'azure' && !ssoToken)
  ) {
    localStorage.removeItem(LocalStorageKeys.X_USER_TOKEN);
    localStorage.clear();
    window.location.reload();
  }

  window.history.pushState(null, '', window.location.href.split('?')[0]);
};

export const isTokenExpired = (): boolean => {
  const expiration = localStorage.getItem('EXPIRATION');
  const tokenExpiration = expiration ? parseInt(expiration, 10) : null;
  return tokenExpiration === null || tokenExpiration < Date.now() / 1000;
};

export const processNonFederatedUserFlow = async (
  userData: SignInForm,
  navigate: NavigateFunction,
  setLoaderSpinning: (value: React.SetStateAction<boolean>) => void,
) => {
  localStorage.clear();

  if (userData.email && userData.password) {
    try {
      const response = await axios.post(`/v2/rest-auth/login/`, userData, {
        baseURL: process.env.REACT_APP_HBH_API,
        headers: {
          'X-Subscription-Token': String(process.env.REACT_APP_SUBSCRIPTION_TOKEN),
        },
      });

      if (response.status === StatusCode.SuccessOK) {
        localStorage.setItem(LocalStorageSSOKeys.USER_EMAIL, JSON.stringify(userData.email));
        localStorage.setItem(LocalStorageSSOKeys.USER_NAME, JSON.stringify(userData.email));
        localStorage.setItem(LocalStorageSSOKeys.IS_AUTHENTICATED, String(true));
        localStorage.setItem(LocalStorageSSOKeys.LOGIN_IN_PROGRESS, String(false));
        localStorage.setItem(LocalStorageKeys.X_USER_TOKEN, response.data.key);

        window.location.href = '/';
      }
    } catch (e: any) {
      if (e?.response?.data?.status === StatusCode.ClientErrorForbidden) {
        navigate('forbidden');
      } else {
        toast.error('Please check entered data. Email and password are not valid');
        setLoaderSpinning(false);
      }
    }
  } else {
    toast.error('Please provide user email and password');
  }
};

export const processLogoutNonFederatedUser = () => {
  localStorage.removeItem(LocalStorageKeys.X_USER_TOKEN);
  localStorage.setItem(LocalStorageSSOKeys.IS_AUTHENTICATED, String(false));

  const params = new URLSearchParams({
    agency: sessionStorage.getItem(SessionStorageKeys.AGENCY) || '',
    facility_id: sessionStorage.getItem(SessionStorageKeys.FACILITY) || '',
    is_non_federated_user: String(true),
  });

  window.location.href = `/?${params.toString()}`;
};

export const processAuthProviderType = (authProvider: AuthProviderType): UserType => {
  const userTypeMap: { [key in AuthProviderType]: UserType } = {
    [AuthProviderType.azure]: 'azure',
    [AuthProviderType.b2c]: 'b2c',
    [AuthProviderType.google]: 'google',
    [AuthProviderType.nonFederated]: 'non-federated',
  };

  return userTypeMap[authProvider];
};
