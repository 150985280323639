import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@unbooking/ui-modules';
import { BookingRoute, BookingRouteReject, Bookings, BookingState } from '@common/interfaces';
import { Button } from '@components';

type RouteTableProps = {
  data: Bookings;
  rejectRoute: (route: BookingRouteReject) => void;
};

export const RouteTable = ({ data, rejectRoute }: RouteTableProps) => {
  const { t } = useTranslation();
  const { agencyFk, id, routes, state } = data;

  const columns = [
    {
      key: '',
      render: (r: BookingRoute) => <>#{r.bookedRouteOrder}</>,
    },
    {
      key: 'pickup',
      title: t('bookingDetails.pickupDate'),
      render: (r: BookingRoute) => (
        <div>
          {r.pickupDate}
          <div className="sub">{r.pickupTime}</div>
        </div>
      ),
    },
    {
      key: 'pickupLocation',
      title: t('bookingDetails.pickupLocation'),
      ellipsis: {
        showTitle: true,
      },
      render: (r: BookingRoute) => (
        <div>
          {r.pickupLocation}
          <div className="sub">{r.pickupTown}</div>
        </div>
      ),
    },
    {
      key: 'dropoffDate',
      title: t('bookingDetails.dropoffDate'),
      render: (r: BookingRoute) => (
        <div>
          {r.dropoffDate}
          {state !== BookingState.NotProcessed && <div className="sub">{r.dropoffTime}</div>}
        </div>
      ),
    },
    {
      key: 'dropoffLocation',
      title: t('bookingDetails.dropoffLocation'),
      ellipsis: {
        showTitle: true,
      },
      render: (r: BookingRoute) => (
        <div>
          {r.dropoffLocation}
          <div className="sub">{r.dropoffTown}</div>
        </div>
      ),
    },
    ...(state !== BookingState.NotProcessed
      ? [
          {
            dataIndex: 'driver',
            key: 'driver',
            title: t('common.driver'),
          },
          {
            dataIndex: 'vehicle',
            key: 'vehicle',
            title: t('mobility.plateNumber'),
          },
          {
            dataIndex: 'stateDisplay',
            key: 'stateDisplay',
            title: t('common.status'),
          },
        ]
      : []),
    {
      key: 'ctrl',
      title: '',
      render: (r: BookingRoute) =>
        state === BookingState.NotProcessed &&
        (r.routeState === BookingState.NotProcessed ? (
          <Button
            className="btn-reject"
            text={t('bookingDetails.btnReject')}
            variant="filled"
            onClick={() =>
              rejectRoute({ agencyId: agencyFk, bookingId: id, route: r.bookedRouteOrder })
            }
          />
        ) : (
          <span className="route-state">{r.routeState.split('_').join(' ')}</span>
        )),
    },
  ];

  return (
    <div className="table-routes">
      <Table columns={columns} data={routes} rowKey="bookedRouteOrder" variant="dark" />
    </div>
  );
};

export default memo(RouteTable);
