/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import type { FC } from 'react';
import { t } from 'i18next';

import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface VehicleFieldProps {
  vehiclesOptions: IDropdownOption[];
}

const VehicleField: FC<VehicleFieldProps> = ({ vehiclesOptions }) => (
  <div className="field" data-testid="planner-drive-form-field-vehicle">
    <Dropdown
      className="dark-border small-list"
      label={t('common.vehicle')}
      name="vehicle"
      options={vehiclesOptions}
      validate={notEmptyValidator}
    />
  </div>
);

export default VehicleField;
