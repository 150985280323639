import { useQuery } from 'react-query';
import { useRepository } from '@context';
import { ApiList, DriverApi } from '@common/interfaces';
import { Serializer } from '@common/utils';

const useDriverList = (
  agencyId: string,
  facilityId: string,
  pageSize: number,
  selectedPage: number,
  shared: boolean,
) => {
  const { mobilityRepository } = useRepository();

  return useQuery(
    ['get-driver-list', pageSize, selectedPage, shared],
    () =>
      mobilityRepository.getDrivers(
        facilityId,
        { agencyId, limit: pageSize, offset: (selectedPage - 1) * 10, ordering: 'name' },
        shared,
      ),
    {
      enabled: !!agencyId,
      select: (data: ApiList<DriverApi>) => ({
        count: data?.count || 0,
        data: data?.results.map(Serializer.formatDriver) || [],
      }),
    },
  );
};

export default useDriverList;
