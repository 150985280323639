/* istanbul ignore file */
import { BookingTripType, TransferType } from '@common/interfaces';
import { Airport, InTown, OutTown, Shuttle } from '@assets/svg/icons';

export const transferType = [
  {
    icon: <Airport />,
    label: 'Airport',
    value: TransferType.Airport as string,
  },
  {
    icon: <InTown />,
    label: 'In Town',
    value: TransferType.InTown as string,
  },
  {
    icon: <OutTown />,
    label: 'Out of town',
    value: TransferType.OutTown as string,
  },
  {
    icon: <Shuttle />,
    label: 'Shuttle',
    value: TransferType.Shuttle as string,
  },
];

export const tripType = [
  { value: BookingTripType.OneWay, label: 'One way' },
  { value: BookingTripType.RoundTrip, label: 'Round trip' },
  { value: BookingTripType.MultiLeg, label: 'Multi leg' },
];

export const getTransferIcon = (type: TransferType) => {
  const selectedType = transferType.find((i) => i.value === type);
  return selectedType ? selectedType.icon : <Shuttle />;
};
