import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { differenceInMinutes, parseISO } from 'date-fns';
import { Tooltip } from '@mantine/core';

import { Button, Pagination, Table } from '@unbooking/ui-modules';
import { Edit, Expand } from '@assets/svg/icons';
import { useRepository } from '@context';
import {
  ApiList,
  TravelRequestItineraries,
  TravelRequestListApi,
  TravelRequestTripList,
} from '@common/interfaces';
import { StatusCode } from '@common/types';
import { Serializer, formatDateString } from '@common/utils';
import { PageTitle } from '@components';
import { TripList } from './components';
import './styles.scss';

const isValidDuration = (itinerary: TravelRequestItineraries): boolean => {
  const dropoffDate = parseISO(`${itinerary.dropoffDate}T${itinerary.dropoffTime}`);
  const pickupDate = parseISO(`${itinerary.pickupDate}T${itinerary.pickupTime}`);
  const diff = differenceInMinutes(dropoffDate, pickupDate);

  return diff <= 24 * 60;
};

const TravelRequestList: FC = () => {
  const navigate = useNavigate();
  const { travelRequestRepository } = useRepository();
  const { t } = useTranslation();

  const [travelRequests, setTravelRequests] = useState<TravelRequestTripList[]>([]);

  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const { isLoading } = useQuery(
    ['get-travel-requests', pageSize, selectedPage],
    () =>
      travelRequestRepository.getTravelRequests({
        limit: pageSize,
        offset: (selectedPage - 1) * 10,
      }),
    {
      retry: false,
      onSuccess: (data: ApiList<TravelRequestListApi>) => {
        setTravelRequests(data.results.map(Serializer.formatTravelRequest));
        setTotalItems(data.count);
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === StatusCode.ClientErrorNotFound) {
          navigate('/undss-travel-request/profile');
        }
      },
    },
  );

  const renderActionsContent = (record: TravelRequestTripList): JSX.Element | null => {
    if (
      record.state === 'revoked' ||
      record.trips.some((item: TravelRequestItineraries) => !isValidDuration(item))
    ) {
      return null;
    }

    return (
      <div className="actions">
        {!record.state || record.state !== 'submitted' ? (
          <Button
            className="btn btn-edit"
            icon={<Edit />}
            variant="icon"
            onClick={() =>
              navigate(`/undss-travel-request/trip-edit/${record.id}`, {
                state: { tripData: record },
              })
            }
          />
        ) : null}
      </div>
    );
  };

  const renderBookingStateLabel = (record: TravelRequestTripList): JSX.Element | null => {
    const { state, trips } = record;

    const label = (text: string, tooltip: string): JSX.Element => (
      <span className="capitalize">
        <Tooltip label={tooltip} withinPortal>
          <span>{text}</span>
        </Tooltip>
      </span>
    );

    if (trips.some((item: TravelRequestItineraries) => !isValidDuration(item))) {
      return label(
        'Ineligible for submission',
        'As this booking is >24 hours, you will need to create a Security Clearance on UNDSS.org directly',
      );
    }

    if (state === 'submitted') {
      return label(
        'Submitted as UN Security Clearance Draft',
        'Please login to the UNDSS portal to complete the process',
      );
    }

    if (state === 'revoked') {
      return label(
        'UN Security Clearance Draft Revoked',
        'Your booking is canceled after the Security Clearance Draft is submitted. Please re-book a trip again and re-submit a clearance for approval.',
      );
    }

    return state ? <span className="capitalize">{state?.split('_').join(' ')}</span> : null;
  };

  const renderExpandedRow = (record: TravelRequestTripList) => (
    <TripList data={record.trips} key={record.id} />
  );

  const renderExpandedIcon = ({
    expanded,
    onExpand,
    record,
  }: {
    expanded: boolean;
    onExpand: any;
    record: TravelRequestTripList;
  }) =>
    record.trips?.length > 0 ? (
      <Button
        className={expanded ? 'icon-expanded' : 'icon-expand'}
        icon={<Expand />}
        variant="icon"
        onClick={() => onExpand(record)}
      />
    ) : null;

  const renderTransportationContent = (record: TravelRequestTripList): JSX.Element | null => {
    const { trips } = record;

    const pickup = trips.length
      ? `${trips[0].pickupTown} ${formatDateString(
          trips[0].pickupDate,
          'yyyy-MM-dd',
          'dd MMM yyyy',
        )} ${trips[0].pickupTime}`
      : null;

    const dropoff = trips.length
      ? `${trips[trips.length - 1].dropoffTown} ${formatDateString(
          trips[0].dropoffDate,
          'yyyy-MM-dd',
          'dd MMM yyyy',
        )} ${trips[trips.length - 1].dropoffTime}`
      : null;

    return (
      <div>
        <div>{pickup ?? 'N/A'}</div>
        <div>{dropoff}</div>
      </div>
    );
  };

  const columns = [
    {
      dataIndex: 'submitDate',
      key: 'submitDate',
      title: t('travelRequest.submittedDate'),
    },
    {
      dataIndex: 'refCode',
      key: 'refCode',
      title: t('common.refCode'),
    },
    {
      key: 'transportationBooking',
      title: t('travelRequest.transportationBooking'),
      render: renderTransportationContent,
    },
    {
      key: 'state',
      title: t('common.status'),
      render: renderBookingStateLabel,
    },
    {
      key: 'actions',
      title: '',
      render: renderActionsContent,
    },
  ];

  return (
    <section className="hbh-container travel-request-trip-list-page">
      <PageTitle
        className="page-title"
        title="Transportation Booking Draft Request for UNDSS Security Clearance"
      />

      <div className="table">
        <Table
          columns={columns}
          data={travelRequests}
          expandable={{
            expandIcon: renderExpandedIcon,
            expandedRowRender: renderExpandedRow,
            rowExpandable: (record: TravelRequestTripList) => record.trips?.length > 0,
          }}
          isLoading={isLoading}
          locale={{
            emptyText: (
              <div className="table-empty-text">
                There are currently no bookings to submit to UNDSS for <b>clearance</b> approval.
                Please login to <b>dss.un.org</b> to check.
              </div>
            ),
          }}
          rowClassName={(record: TravelRequestTripList) =>
            record.trips.some((item: TravelRequestItineraries) => !isValidDuration(item))
              ? 'row-disabled'
              : ''
          }
          variant="dark"
        />
      </div>

      {!isLoading && totalItems > 10 ? (
        <Pagination
          className="pagination"
          selectedPage={selectedPage}
          showJumper
          showPageSize
          totalPages={totalItems}
          variant="dark"
          onPageChange={setSelectedPage}
          onPageSizeChange={setPageSize}
        />
      ) : null}
    </section>
  );
};

export default TravelRequestList;
