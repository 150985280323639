/* istanbul ignore file */
import { FC, useEffect } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';

import { IDropdownOption } from '@ui-modules/types';
import { Dropdown, TextField } from '@ui-modules/informed';
import { DriveFormSteps } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface DropoffTownFieldProps {
  fieldGroup?: string;
  options?: IDropdownOption[];
  returnTrip?: boolean;
}

const DropOffTownField: FC<DropoffTownFieldProps> = ({
  fieldGroup,
  options,
  returnTrip = false,
}) => {
  const formApi = useFormApi();
  const formState = formApi.getFormState().values as any;
  const tripData = formState?.[DriveFormSteps.Trip];
  const prevPickupTownValue = tripData?.driveList ? tripData.driveList[0]?.pickupTown : null;

  useEffect(() => {
    if (returnTrip && prevPickupTownValue) {
      formApi.setValue(
        fieldGroup ? `${fieldGroup}.dropoffTown` : 'dropoffTown',
        prevPickupTownValue,
      );
    }
  }, [fieldGroup, formApi, prevPickupTownValue, returnTrip]);

  const dropoffLocation = fieldGroup ? `${fieldGroup}.dropoffLocation` : 'dropoffLocation';
  const pickupLocation = fieldGroup ? `${fieldGroup}.pickupLocation` : 'pickupLocation';
  const pickupTown = fieldGroup ? `${fieldGroup}.pickupTown` : 'pickupTown';

  return options ? (
    <div className="field" data-testid="planner-drive-form-field-dropoff-town">
      <Dropdown
        className="dark-border small-list"
        isClearable
        isCreatable
        label={t('bookingDetails.dropoffTown')}
        name="dropoffTown"
        options={options}
        validate={notEmptyValidator}
        onChange={(option) => {
          const pickupTownValue = formApi.getValue(pickupTown) as IDropdownOption;

          formApi.setValue(dropoffLocation, undefined);

          if (
            !pickupTownValue &&
            option &&
            options?.length === 2 &&
            options?.find((i: IDropdownOption) => i.value === option.value)
          ) {
            formApi.setValue(pickupLocation, undefined);

            formApi.setValue(
              pickupTown,
              options?.find((i: IDropdownOption) => i.value !== option.value),
            );
          }
        }}
      />
    </div>
  ) : (
    <div className="field">
      <TextField
        className="input"
        data-testid="planner-drive-form-field-dropoff-town"
        disabled={returnTrip}
        label={t('bookingDetails.dropoffTown')}
        name="dropoffTown"
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DropOffTownField;
