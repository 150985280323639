/* istanbul ignore file */
import { type Dispatch, type FC, type SetStateAction, useEffect } from 'react';
import { t } from 'i18next';
import { useFormApi } from 'informed';

import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { DriveFormSteps } from '@common/interfaces';
import { useRepository } from '@context';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

interface RequestUnitFieldProps {
  defaultValue?: string;
  facilityId: string;
  options: IDropdownOption[];
  onRegUnitLoad: Dispatch<SetStateAction<IDropdownOption[]>>;
}

const RequestUnitField: FC<RequestUnitFieldProps> = ({
  defaultValue,
  facilityId,
  options,
  onRegUnitLoad,
}) => {
  const { getValue, setValue } = useFormApi();
  const { plannerRepo } = useRepository();
  const { value: agencyOption } = useFieldState<IDropdownOption>('agency');
  const currentOption = getValue(`${DriveFormSteps.Main}.requestingUnit`) as IDropdownOption;

  useEffect(() => {
    const loadRequestUnit = async (agency: string): Promise<void> => {
      const response = await plannerRepo.getBookingRequestingUnits(facilityId, {
        agency,
      });

      onRegUnitLoad(response);

      if (
        currentOption &&
        !response.find((i: IDropdownOption) => i.value === currentOption.value)
      ) {
        setValue(`${DriveFormSteps.Main}.requestingUnit`, '');
      }
    };

    if (agencyOption && agencyOption?.value) {
      loadRequestUnit(agencyOption.value);
    } else {
      setValue(`${DriveFormSteps.Main}.requestingUnit`, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyOption, facilityId]);

  useEffect(() => {
    if (defaultValue && options?.length) {
      setValue(
        `${DriveFormSteps.Main}.requestingUnit`,
        options.find((option) => option.value === defaultValue),
      );
    }
  }, [defaultValue, options, setValue]);

  return (
    <div className="field" data-testid="planner-drive-form-field-requesting-unit">
      <Dropdown
        className="dropdown"
        label={t('common.requestingUnit')}
        name="requestingUnit"
        placeholder={options?.length ? 'Select...' : 'Provide user email first'}
        options={options}
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default RequestUnitField;
