/* istanbul ignore file */
import type { FC } from 'react';
import { SummaryType } from '@common/interfaces';
import { useToggleBoolean } from '@common/hooks';
import { Tooltip } from '@components';
import { ActiveSignature, InActiveSignature } from '@components/logbook';
import { Manual, PencilIcon } from '@assets/svg/icons';
import {
  ChevronDown,
  ChevronUp,
  Device,
  LocationMarker,
  Offline,
  Online,
  Star,
  Vehicle,
} from '@assets/svg/icons/logbook';
import './InfoBlock.styles.scss';

export enum InfoBlockType {
  rating,
  signature,
  tag,
  title,
  location,
  distance,
}

type InfoBlockStylesType = {
  marginRight?: number;
  width?: number | string;
  height?: number;
  justifyContent?: string;
  flex?: number;
  alignItems?: string;
};

type Props = {
  type: InfoBlockType;
  children?: React.ReactNode;
  style?: InfoBlockStylesType;
  clientSignature?: string;
  title?: string;
  rating?: number;
  content?: string | number;
  dropOffLocation?: string;
  pickUpLocation?: string;
  comment?: string;
  status?: string;
  isOnline?: boolean;
  isOffline?: boolean;
  offlineSignature?: string;
  distance?: { device: string; type: string; text: string };
};

const getSummaryTypeIcon = (type: string, text: string) => {
  const applyIcon = (icon: JSX.Element, tooltip: string): JSX.Element => (
    <Tooltip label={tooltip}>{icon}</Tooltip>
  );

  let icon: JSX.Element | null;

  switch (Number(type)) {
    case SummaryType.DriverManualInput:
      icon = applyIcon(<PencilIcon fill="#EAF2F9" />, text);
      break;
    case SummaryType.FocalPointManualInput:
      icon = applyIcon(<Manual fill="#EAF2F9" />, text);
      break;
    case SummaryType.VTS:
      icon = applyIcon(<Vehicle fill="#EAF2F9" />, text);
      break;
    case SummaryType.GPS:
      icon = applyIcon(<Device fill="#EAF2F9" />, text);
      break;
    default:
      icon = null;
      break;
  }

  return icon;
};

const RatingBlock = ({
  style,
  rating,
  status,
  comment,
}: {
  style?: InfoBlockStylesType;
  rating: number;
  status?: string;
  comment?: string;
}) => {
  const [isFeedbackOpened, toggleIsFeedbackOpened] = useToggleBoolean();

  return (
    <div
      style={{
        ...style,
      }}
      className="details-block-expanded"
    >
      <div
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          justifyContent: 'space-between',
          border: 0,
          ...style,
        }}
        className="details-block"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4>Rating</h4>
          <div className="details-block-stars">
            {Array.from({ length: 5 }, (_, i) => (
              <div key={Number(i)} className="details-block-star-container">
                <Star
                  height="18px"
                  width="18px"
                  fill={rating > Number(i) ? '#F3D31F' : '#B8BFC3'}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="details-block-status-container">
          <div className={status ? 'details-block-status-active' : 'details-block-status-inactive'}>
            <p className="capitalize">Status: {status || 'Not provided'}</p>
          </div>
          {comment && comment.length > 0 && (
            <button
              className="details-block-chevron"
              type="button"
              onClick={toggleIsFeedbackOpened}
            >
              {isFeedbackOpened ? <ChevronUp /> : <ChevronDown />}
            </button>
          )}
        </div>
      </div>
      {isFeedbackOpened && <p className="details-block-status-comment">{comment}</p>}
    </div>
  );
};

export const InfoBlock: FC<Props> = ({
  type,
  style,
  children,
  clientSignature,
  title,
  content,
  rating = 0,
  dropOffLocation,
  pickUpLocation,
  status,
  isOnline,
  isOffline,
  offlineSignature,
  comment,
  distance,
}: Props) => {
  switch (type) {
    case InfoBlockType.rating:
      return <RatingBlock style={style} rating={rating} status={status} comment={comment} />;
    case InfoBlockType.distance:
      return (
        <div style={{ minHeight: 57, ...style, padding: 8 }} className="details-block-tag">
          {distance && (
            <>
              <h4>{title}</h4>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="details-block-distance-tag">
                  {getSummaryTypeIcon(distance.type, distance.text)}
                  <p>{distance?.device}</p>
                </div>
              </div>
            </>
          )}
        </div>
      );
    case InfoBlockType.tag:
      return (
        <div style={{ minHeight: 52, ...style }} className="details-block-tag">
          <h4>{title}</h4>
          <div className="details-block-info-tag">
            <p>{content}</p>
          </div>
        </div>
      );
    case InfoBlockType.signature:
      return (
        <div
          style={{ height: 62, flex: 1, justifyContent: 'space-between', ...style }}
          className="details-block"
        >
          {clientSignature || isOffline ? (
            <ActiveSignature label="Signed" />
          ) : (
            <InActiveSignature label="Not Signed" />
          )}
          {(clientSignature || offlineSignature) && (
            <div>
              <div
                style={{
                  display: 'flex',
                  marginBottom: 4,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 4 }}>
                  <Online fill={isOnline ? '#3C8545' : '#677782'} />
                </div>
                <div>
                  <Offline fill={isOffline ? '#3C8545' : '#677782'} />
                </div>
              </div>
              <p className="details-block-signature-text" style={{ color: '#B8BFC3' }}>
                {clientSignature
                  ? clientSignature?.split('-')[1].replace(' ', '').replace(' ', '|')
                  : offlineSignature}
              </p>
            </div>
          )}
          {!clientSignature && !offlineSignature && (
            <div>
              <div
                style={{
                  display: 'flex',
                  marginBottom: 4,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 4 }}>
                  <Online fill={isOnline ? '#3C8545' : '#677782'} />
                </div>
                <Offline fill={isOffline ? '#3C8545' : '#677782'} />
              </div>
              <p className="details-block-signature-text" style={{ color: '#505253' }}>
                DD/MM/YY | 00:00
              </p>
            </div>
          )}
        </div>
      );
    case InfoBlockType.title:
      return (
        <div style={{ ...style }} className="details-block-titled">
          <h4>{title}</h4>
          {children}
        </div>
      );
    case InfoBlockType.location:
      return (
        <div className="details-block-location" style={{ height: 129 }}>
          <div className="details-block-location-pick-up">
            <h4>Pick-up</h4>
            <div className="row">
              <LocationMarker />
              <p>{pickUpLocation}</p>
            </div>
          </div>
          <div>
            <h4>drop-off</h4>
            <div className="row">
              <LocationMarker />
              <p>{dropOffLocation}</p>
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};
