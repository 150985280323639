/* istanbul ignore file */
import { FC, useEffect } from 'react';
import { t } from 'i18next';
import { addMinutes, isAfter, isEqual, isSameDay, setHours, setMinutes } from 'date-fns';
import { useFormApi } from 'informed';

import { DateField } from '@ui-modules/informed';
import { TIME_INTERVAL } from '@common/constants';
import { DriveFormSteps } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

interface PickupTimeFieldProps {
  fieldGroup?: string;
  index?: number;
  returnTrip?: boolean;
}

const PickupTimeField: FC<PickupTimeFieldProps> = ({ fieldGroup, index, returnTrip = false }) => {
  const formApi = useFormApi();
  const { value: dropoffTime } = useFieldState<Date>('dropoffTime');
  const { value: pickupDate } = useFieldState<Date>('pickupDate');

  const formState = formApi.getFormState().values as any;
  const tripData = formState?.[DriveFormSteps.Trip];
  const tripDropoffDate =
    tripData?.driveList && typeof index === 'number'
      ? tripData.driveList[index]?.dropoffDate
      : null;
  const tripDropoffTime =
    tripData?.driveList && typeof index === 'number'
      ? tripData.driveList[index]?.dropoffTime
      : null;

  const isSameDate = tripDropoffDate && isSameDay(pickupDate, tripDropoffDate);

  useEffect(() => {
    if (returnTrip && tripDropoffTime) {
      formApi.setValue(fieldGroup ? `${fieldGroup}.pickupTime` : 'pickupTime', tripDropoffTime);
    }
  }, [fieldGroup, formApi, returnTrip, tripDropoffTime]);

  return (
    <div className="field" data-testid="planner-drive-form-field-pickup-time">
      <DateField
        className="input"
        dateFormat="HH:mm"
        label={t('bookingDetails.pickupTime')}
        maxTime={isSameDate ? setHours(setMinutes(new Date(), 45), 23) : undefined}
        minTime={isSameDate ? tripDropoffTime : undefined}
        name="pickupTime"
        showTimeSelect
        showTimeSelectOnly
        timeCaption={undefined}
        timeFormat="HH:mm"
        timeIntervals={TIME_INTERVAL}
        validate={notEmptyValidator}
        onChange={(value: Date | string) => {
          if (isAfter(value as Date, dropoffTime) || isEqual(value as Date, dropoffTime)) {
            formApi.setValue(
              fieldGroup ? `${fieldGroup}.dropoffTime` : 'dropoffTime',
              addMinutes(value as Date, TIME_INTERVAL),
            );
          }
        }}
      />
    </div>
  );
};

export default PickupTimeField;
