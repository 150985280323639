/* istanbul ignore file */
function UnWomen(props: any) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.832 2.832a15.893 15.893 0 003.204-.468V0H8.832v2.832zM15.282 2.681c-.565.303-1.194.564-1.862.788v2.615A9.58 9.58 0 0016 2.991V2.25a9.089 9.089 0 01-.718.432zM12.038 6.973l-.002-.01V3.865c-1 .232-2.078.375-3.204.425v3.638a8.773 8.773 0 003.206-.954zM13.42 0v1.928C14.484 1.527 15.37 1.02 16 .462V0h-2.58zM7.161 0H3.814v2.33c1.02.269 2.152.444 3.347.5V0zM2.43 1.879V0H0v.462c.365.325.816.636 1.339.917.335.18.702.347 1.091.5zM7.161 4.286a18.023 18.023 0 01-3.347-.456v3.072a8.75 8.75 0 003.347 1.025V4.286zM2.43 5.975V3.428A10.796 10.796 0 010 2.248v.759a9.61 9.61 0 002.43 2.968zM3.8 17.138v-1.65H7.16v-1.647H3.8v-1.65h3.362V9.396C4.303 9.159 1.765 7.719 0 5.553V20h7.161v-2.862H3.8zM8.832 9.396v2.795h3.222v1.65H8.832v1.647h3.222v1.65H8.832V20H16V5.546c-1.766 2.169-4.306 3.611-7.168 3.85z"
        fill="#EAF2F9"
      />
    </svg>
  );
}

export default UnWomen;
