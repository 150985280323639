import type { FC } from 'react';
import type { DatePickerInputProps, DateValue, DatesRangeValue } from '@mantine/dates';
import { DatePickerInput } from '@mantine/dates';
import { Calendar } from '@assets/svg/icons';
import './DatePicker.styles.scss';

interface DatePickerProps extends DatePickerInputProps<'default' | 'range'> {
  format?: string;
  iconPosition?: 'left' | 'right';
  placeholder?: string;
  showIconOnly?: boolean;
  type?: 'default' | 'range';
  variant?: 'dark' | 'default' | 'light' | 'outline' | 'theme-dark';
  onChange?: ((value: DateValue | DatesRangeValue) => void) | undefined;
}

const DatePicker: FC<DatePickerProps> = ({
  format,
  iconPosition = 'right',
  placeholder = '',
  showIconOnly = false,
  type = 'default',
  variant = 'default',
  onChange,
  ...rest
}) => (
  <DatePickerInput
    classNames={{
      root: `date-picker date-picker-${variant} ${showIconOnly ? 'icon-only' : ''}`,
      wrapper: 'date-picker-wrapper',
      label: 'date-picker-label',
      icon: `date-picker-icon date-picker-icon-${iconPosition}`,
      input: 'date-picker-input',
      calendar: `date-picker-dropdown date-picker-dropdown-${variant}`,
      calendarHeaderControl: 'date-picker-dropdown-control',
      calendarHeaderLevel: 'date-picker-dropdown-month',
      day: 'date-picker-dropdown-day',
      pickerControl: 'date-picker-dropdown-select',
    }}
    hideOutsideDates
    icon={<Calendar />}
    placeholder={placeholder}
    type={type}
    valueFormat={format}
    variant="unstyled"
    onChange={onChange}
    {...rest}
  />
);

export default DatePicker;
