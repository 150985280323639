import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Loader, Tooltip } from '@components';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { useHBHLocation } from '@common/hooks/logbook';
import {
  AdditionalInfo,
  BookingInfo,
  InfoBlock,
  InfoBlockType,
  PassengerInfo,
} from '@components/logbook';
import { Carpool, ChevronLeft } from '@assets/svg/icons/logbook';
import './MobilityLogbookDetailsPage.styles.scss';

const MobilityLogbookDetailsPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logbookRepository } = useRepository();
  const { Logo } = useHBHLocation();
  const {
    facility: { city, country },
    facilityId,
  } = useFacility();

  const [id] = location.pathname.split('/').slice(-1);

  const { data, isLoading } = useQuery(['get-drive', id], () =>
    logbookRepository.getDriveById(facilityId, id),
  );

  return (
    <section className="mobility-logbook-details-page">
      <Loader fullScreen spinning={isLoading}>
        <nav className="mlb-nav">
          <button className="mlb-nav-back" type="button" onClick={() => navigate(-1)}>
            <ChevronLeft fill="#fff" />
          </button>
          <Logo fill="#E0E8ED" width={27.5} height={27.5} />
          {city && country ? (
            <>
              <p>
                {city}, {country}
              </p>
              <p>|</p>
              <p>Ref: {data?.booking.referenceCode}</p>
            </>
          ) : null}

          {data?.isCarpooled && (
            <Tooltip label="Rideshared Trip">
              <Carpool />
            </Tooltip>
          )}
        </nav>

        <div className="mlb-details-container">
          <div className="mlb-details-left-content">
            <div className="row" style={{ marginBottom: 16 }}>
              <InfoBlock
                comment={data?.feedback?.comment}
                rating={data?.feedback?.rate || 0}
                status={data?.status?.split('_').join(' ').toUpperCase()}
                type={InfoBlockType.rating}
              />
            </div>

            <div className="row" style={{ marginBottom: 16 }}>
              <InfoBlock
                type={InfoBlockType.signature}
                clientSignature={data?.feedback?.clientSignature}
                offlineSignature={data?.offlineSignature ?? ''}
                isOnline={!!data?.feedback?.clientSignature}
                isOffline={data?.isOfflineSignature}
                style={{ height: 62, width: 330, marginRight: 24, alignItems: 'center' }}
              />

              <InfoBlock
                type={InfoBlockType.tag}
                title="Total trip time"
                style={{ height: 62, width: 330 }}
                content={data?.duration ? data.duration : '-'}
              />
            </div>
            <div className="row" style={{ marginBottom: 26 }}>
              <InfoBlock
                type={InfoBlockType.distance}
                style={{ height: 68, width: '100%' }}
                title="Number of km"
                distance={{
                  device: data?.distance ? `${data?.distance} km` : '–',
                  type: `${data?.summaryType}`,
                  text: data?.summaryTypeDisplay || '',
                }}
              />
            </div>

            {data && <BookingInfo data={data} />}

            {data && (
              <PassengerInfo
                data={data}
                passenger={data.booking}
                title={data.additionalPassengers!.length > 0 ? 'Passenger 1' : 'Passenger info'}
              />
            )}

            {data?.additionalPassengers?.map((i, index) => (
              <PassengerInfo
                data={data}
                key={i.id}
                passenger={i}
                title={`Passenger ${index + 2}`}
              />
            ))}

            {data && <AdditionalInfo data={data} />}
          </div>
        </div>
      </Loader>
    </section>
  );
};

export default MobilityLogbookDetailsPage;
