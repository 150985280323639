/* istanbul ignore file */
import { addMinutes, format, isAfter, isBefore, setHours, setMinutes } from 'date-fns';
import { TIME_INTERVAL } from '@common/constants';
import { BFShuttleRoute } from '@common/interfaces';
import { BFRoute } from '../types';
import { FormSchemaType } from '../schema/default.schema';

export const getFormSubmitError = (e: Record<string, any>): string | null => {
  if (!e || typeof e !== 'object' || Array.isArray(e)) return null;

  let errorMsg: string | null = null;

  Object.keys(e)
    .sort()
    .some((key) => {
      const error = (e as Record<string, any>)[key];
      const record = Array.isArray(error) ? error[0] : error;
      if (record && typeof record === 'object') {
        const item = Object.keys(record)[0];
        errorMsg = `${
          item.charAt(0).toUpperCase() + item.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')
        }: ${record[item].message}`;
        return true;
      }
      return false;
    });

  return errorMsg;
};

export const checkConflictingRoutes = (data: FormSchemaType): string | null => {
  const isRouteInThePast = isBefore(
    new Date(`${format(data?.routes[0].pickupDate!, 'yyyy-MM-dd')}T${data?.routes[0].pickupTime}`),
    new Date(),
  );

  if (isRouteInThePast) return 'The pickup time cannot be earlier than the current time';

  const hasOverlap = data?.routes.slice(1).some((route, idx) => {
    const prevRoute = data?.routes[idx];
    const prevEndTime = addMinutes(
      new Date(`${format(prevRoute.pickupDate!, 'yyyy-MM-dd')}T${prevRoute.pickupTime}`),
      15,
    );
    const currentStartTime = new Date(
      `${format(route.pickupDate!, 'yyyy-MM-dd')}T${route.pickupTime}`,
    );

    return currentStartTime < prevEndTime;
  });

  if (hasOverlap) return 'Check routes, interval cannot be less than 15 minutes';

  return null;
};

export const validateRouteTime = (
  eventTime: Date,
  eventRouteIdx: number,
  routes: BFRoute[],
): string | null => {
  if (eventRouteIdx === 0) {
    const isRouteInThePast = isBefore(eventTime, new Date());
    if (isRouteInThePast) return 'The pickup time cannot be earlier than the current time';
  }

  const startTimeRange = addMinutes(eventTime, -TIME_INTERVAL);
  const endTimeRange = addMinutes(eventTime, TIME_INTERVAL);
  const startTimeRangeString = format(startTimeRange, 'yyyy-MM-dd HH:mm');
  const endTimeRangeString = format(endTimeRange, 'yyyy-MM-dd HH:mm');

  let msg = null;

  routes.some((route, idx) => {
    if (idx === eventRouteIdx) return false;

    const pickupDateTimeString = `${format(route.pickupDate!, 'yyyy-MM-dd')} ${route.pickupTime}`;

    const isWithinTimeRange =
      pickupDateTimeString >= startTimeRangeString &&
      pickupDateTimeString <= endTimeRangeString &&
      pickupDateTimeString !== startTimeRangeString &&
      pickupDateTimeString !== endTimeRangeString;

    if (isWithinTimeRange) {
      msg = 'Interval between routes cannot be less than 15 minutes';
      return true;
    }

    return false;
  });

  if (eventRouteIdx > 0) {
    const previousRoute = routes[eventRouteIdx - 1];
    const previousTime = setMinutes(
      setHours(
        new Date(previousRoute.pickupDate!),
        parseInt(previousRoute.pickupTime.split(':')[0], 10),
      ),
      parseInt(previousRoute.pickupTime.split(':')[1], 10),
    );

    if (isBefore(eventTime, previousTime)) {
      msg = 'Current route cannot be earlier than the previous';
    }
  }

  if (eventRouteIdx < routes.length - 1) {
    const nextRoute = routes[eventRouteIdx + 1];
    const nextTime = setMinutes(
      setHours(new Date(nextRoute.pickupDate!), parseInt(nextRoute.pickupTime.split(':')[0], 10)),
      parseInt(nextRoute.pickupTime.split(':')[1], 10),
    );

    if (isAfter(eventTime, nextTime)) {
      msg = 'Current route cannot be later than the next';
    }
  }

  return msg;
};

export const validRouteByDate = (date: Date, shuttleRoutes: BFShuttleRoute[]): BFShuttleRoute[] =>
  shuttleRoutes.filter((r) => {
    const isDateInRange = date >= r.startDate && date <= r.endDate;
    const isExcludedDate = r.excludedDates && r.excludedDates.includes(format(date, 'yyyy-MM-dd'));
    const dayOfWeek = date.getDay() - 1;
    const isAllowedDayOfWeek = r.weekdays
      ? r.weekdays.includes(dayOfWeek === -1 ? 6 : dayOfWeek)
      : true;

    return isDateInRange && !isExcludedDate && isAllowedDayOfWeek;
  });
