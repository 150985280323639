/* istanbul ignore file */
import type { FC } from 'react';
import { Checkbox, FieldState } from 'informed';
import cn from 'classnames';
import './CheckboxField.styles.scss';

interface CheckboxFieldProps {
  className?: string;
  disabled?: boolean;
  initialValue?: boolean;
  label: string;
  name: string;
  type?: 'default' | 'primary' | 'secondary' | 'white' | 'overlap';
  onChange?:
    | ((fieldState: FieldState, event: React.SyntheticEvent<Element, Event>) => void)
    | undefined;
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  className,
  disabled,
  label,
  name,
  type = 'default',
  ...rest
}) => (
  <div className="field-checkbox">
    <Checkbox className="input" disabled={disabled} id={name} name={name} {...rest} />
    <label
      className={cn(
        'label',
        {
          default: type === 'default',
          primary: type === 'primary',
          secondary: type === 'secondary',
          white: type === 'white',
          overlap: type === 'overlap',
          disabled,
        },
        className,
      )}
      htmlFor={name}
    >
      {label}
    </label>
  </div>
);

export default CheckboxField;
