import { type FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useDebounce } from 'usehooks-ts';
import { Checkbox } from '@mantine/core';
import { Form, FormState } from 'informed';

import type { IDropdownOption } from '@ui-modules/types';
import { Button } from '@ui-modules';
import type {
  ApiList,
  TravelRequestAgencyListApi,
  TravelRequestCitiesListApi,
  TravelRequestCountriesListApi,
  TravelRequestProfileCreate,
  TravelRequestProfileFormState,
  TravelRequestProfileSchemaApi,
} from '@common/interfaces';
import { useRepository } from '@context';
import { Serializer } from '@common/utils';
import { Fieldset, Panel } from '@components';
import { FormField } from '@components/Fieldset/types';
import { useUserInfo } from '@common/hooks';
import { StatusCode } from '@common/types';
import './styles.scss';

const TravelRequestProfile: FC = () => {
  const navigate = useNavigate();
  const { travelRequestRepository } = useRepository();
  const { t } = useTranslation();
  const { email } = useUserInfo();

  const [accepted, setAccepted] = useState<boolean>(true);
  const [agencies, setAgencies] = useState<IDropdownOption[]>([]);
  const [cities, setCities] = useState<IDropdownOption[]>([]);
  const [countries, setCountries] = useState<IDropdownOption[]>([]);
  const [genderOptions, setGenderOptions] = useState<IDropdownOption[]>([]);
  const [profileTypeOptions, setProfileTypeOptions] = useState<IDropdownOption[]>([]);
  const [salutationOptions, setSalutationOptions] = useState<IDropdownOption[]>([]);
  const [searchCity, setSearchCity] = useState<string>();

  const debouncedCitySearch = useDebounce(searchCity, 800);

  useQuery('get-agencies', () => travelRequestRepository.getAgencies(), {
    onSuccess: (data: TravelRequestAgencyListApi[]) => {
      setAgencies(data.map(Serializer.formatTravelRequestAgency));
    },
  });

  useQuery(
    ['get-cities', debouncedCitySearch],
    () => travelRequestRepository.getCities({ limit: 50, name: debouncedCitySearch }),
    {
      onSuccess: (data: ApiList<TravelRequestCitiesListApi>) => {
        setCities(data.results.map(Serializer.formatTravelRequestProfileCity));
      },
    },
  );

  useQuery('get-countries', () => travelRequestRepository.getCountries({ limit: 300 }), {
    onSuccess: (data: ApiList<TravelRequestCountriesListApi>) => {
      setCountries(data.results.map(Serializer.formatTravelRequestCountry));
    },
  });

  const { isLoading: isProfileSchemaLoading } = useQuery(
    'get-profile-schema',
    () => travelRequestRepository.getProfile(),
    {
      enabled: !!email,
      retry: false,
      onSuccess: ({ data }: TravelRequestProfileSchemaApi) => {
        const gender = data?.gender?.choices;
        const profileType = data?.profile_type_id?.choices;
        const salutation = data?.salutation_id?.choices;

        if (gender?.length) setGenderOptions(gender.map(Serializer.formatChoices));
        if (profileType?.length) setProfileTypeOptions(profileType.map(Serializer.formatChoices));
        if (salutation?.length) setSalutationOptions(salutation.map(Serializer.formatChoices));
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === StatusCode.ClientErrorConflict) {
          navigate('/undss-travel-request/trip-list/');
        }
      },
    },
  );

  const { mutate: createProfile, isLoading: isProfileCreateLoading } = useMutation<
    unknown,
    AxiosError,
    TravelRequestProfileCreate
  >('create-profile', (data) => travelRequestRepository.createProfile(data), {
    onSuccess: (status) => {
      if (status === StatusCode.SuccessCreated) {
        navigate('/undss-travel-request/trip-list/');
      }
    },
    onError: (error: any) => {
      if (error.message) {
        toast.error(error.message || t('common.errorMsgDefault'));
      }
    },
  });

  const handleSubmit = (data: FormState) => {
    const formState = { ...(data.values as unknown as TravelRequestProfileFormState) };

    const profileFormData = Serializer.mapTravelRequestProfileForm(formState);

    createProfile(profileFormData);
  };

  const fields: FormField[] = [
    {
      label: 'First Name',
      name: 'firstName',
      required: true,
      type: 'text',
    },
    {
      label: 'Middle Name',
      name: 'middleName',
      required: true,
      type: 'text',
    },
    {
      label: 'Last Name',
      name: 'lastName',
      required: true,
      type: 'text',
    },
    {
      defaultValue: email,
      label: 'Username',
      name: 'username',
      readonly: true,
      required: true,
      type: 'text',
    },
    {
      label: 'Gender',
      name: 'gender',
      required: true,
      type: 'dropdown',
      options: genderOptions,
    },
    {
      label: 'Date of Birth',
      name: 'dateOfBirth',
      required: true,
      type: 'date',
    },
    {
      label: 'Agency',
      name: 'agency',
      required: true,
      type: 'dropdown',
      options: agencies,
    },
    {
      label: 'Duty Station',
      name: 'dutyStationCityId',
      required: true,
      type: 'dropdown',
      options: cities,
      onInputChange: (value: string) => {
        setSearchCity(value);
      },
    },
    {
      label: 'Profile Type',
      name: 'profileTypeId',
      required: true,
      type: 'dropdown',
      options: profileTypeOptions,
    },
    {
      label: 'Salutation',
      name: 'salutationId',
      required: true,
      type: 'dropdown',
      options: salutationOptions,
    },
    {
      label: 'Nationality At Birth',
      name: 'countryCodeAtBirth',
      required: true,
      type: 'dropdown',
      options: countries,
    },
    {
      label: 'Nationality Present',
      name: 'countryCodeAtPresent',
      required: true,
      type: 'dropdown',
      options: countries,
    },
  ];

  return email && !isProfileSchemaLoading ? (
    <section className="hbh-container travel-request-profile-page">
      <Panel
        className="form-panel"
        isLoading={isProfileCreateLoading}
        title="To create the Clearance Request for your trip, please fill in the form below"
      >
        <Form className="form-profile" onSubmit={handleSubmit}>
          <Fieldset
            fields={fields}
            footer={
              <>
                <Checkbox
                  checked={accepted}
                  label={
                    <>
                      <span>I&apos;ve read and accept the Terms and Conditions*</span>
                      <br />
                      <span>
                        <a
                          href={`${process.env.REACT_APP_TERMS_LINK}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Click here
                        </a>
                        &nbsp; to open in a new window
                      </span>
                    </>
                  }
                  onChange={(e) => {
                    setAccepted(e.currentTarget.checked);
                  }}
                />
                <Button
                  className="btn"
                  disabled={!accepted}
                  text={t('common.btnNext')}
                  variant="primary"
                  type="submit"
                />
              </>
            }
          />
        </Form>
      </Panel>
    </section>
  ) : null;
};

export default TravelRequestProfile;
