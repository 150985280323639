import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { SegmentedControl, type SegmentedControlProps } from '@mantine/core';

export type SegmentControlProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<SegmentedControlProps, 'values' | 'defaultValues'>;

export function SegmentControl<T extends FieldValues>({
  control,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  onChange,
  ...props
}: SegmentControlProps<T>) {
  const {
    field: { value, onChange: fieldOnChange, ...field },
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <SegmentedControl
      value={value}
      onChange={(e) => {
        fieldOnChange(e);
        onChange?.(e);
      }}
      {...field}
      {...props}
    />
  );
}
