import { ReactNode } from 'react';
import { Dialog as DialogMantine, DialogProps as DialogMantineProps } from '@mantine/core';
import './Dialog.styles.scss';

export type DialogProps = {
  children: ReactNode;
  isLoading?: boolean;
  variant?: 'dark' | 'default';
} & Omit<DialogMantineProps, 'children' | 'variant'>;

const Dialog = ({
  children,
  isLoading = false,
  position = { bottom: 35, right: 25 },
  variant = 'default',
  onClose,
  ...rest
}: DialogProps) => (
  <DialogMantine
    classNames={{
      root: `dialog dialog-${variant} ${isLoading ? 'loading' : ''}`,
      closeButton: 'btn-close',
    }}
    position={position}
    variant={variant}
    onClose={onClose}
    {...rest}
  >
    {children}
  </DialogMantine>
);

export default Dialog;
