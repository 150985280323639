/* eslint-disable no-nested-ternary */
import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addMinutes, format, isAfter, parse } from 'date-fns';
import { Form, useFormApi } from 'informed';

import { Button } from '@unbooking/ui-modules';
import { DateField, TextField } from '@ui-modules/informed';
import {
  IDriveUpdate,
  IDriveWithWorkflow,
  IDriveWorkflow,
  ISharedDrives,
} from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { DateFormat } from '@common/types';
import { requiredField } from '@common/utils';
import { TimeField } from '@components';

import { calcDurationDrive } from '../../utils';
import './styles.scss';

type FieldProps = {
  disabled?: boolean;
  initialValue?: string | Date;
};

const DropOffDateField: FC<FieldProps> = ({ disabled, initialValue }) => {
  const formApi = useFormApi();
  const { value: pickupDate } = useFieldState<Date>('pickupDate');
  const { value: pickupTime } = useFieldState<string>('pickupTime');
  const { value: dropoffTime } = useFieldState<string>('dropoffTime');
  const { t } = useTranslation();

  return (
    <div className="field field-date">
      <DateField
        defaultValue={(initialValue as Date) || undefined}
        disabled={disabled}
        dropdownMode="select"
        label={t('common.inputEndDate')}
        minDate={pickupDate as Date}
        name="dropoffDate"
        showMonthDropdown
        showYearDropdown
        validate={requiredField}
        onChange={(value) => {
          const durationDrive = calcDurationDrive({
            endDate: value as Date,
            endTime: dropoffTime,
            startDate: pickupDate,
            startTime: pickupTime,
          });
          formApi.setValue('durationDrive', durationDrive);
        }}
      />
    </div>
  );
};

const DropOffTimeField: FC<FieldProps> = ({ disabled, initialValue }) => {
  const formApi = useFormApi();
  const { value: pickupDate } = useFieldState<Date>('pickupDate');
  const { value: pickupTime } = useFieldState<string>('pickupTime');
  const { value: dropoffDate } = useFieldState<Date>('dropoffDate');
  const { t } = useTranslation();

  return (
    <div className="field field-time">
      <TimeField
        disabled={disabled}
        initialValue={initialValue as string}
        label={t('common.inputEndTime')}
        name="dropoffTime"
        onChange={(value: string) => {
          let pickupTimeCurrent = pickupTime;
          const pickupDateTime = new Date(`${format(pickupDate, 'yyyy-MM-dd')} ${pickupTime}`);
          const dropoffDateTime = new Date(`${format(dropoffDate, 'yyyy-MM-dd')} ${value}`);

          if (isAfter(pickupDateTime, dropoffDateTime)) {
            const time = format(addMinutes(dropoffDateTime, -1), 'HH:mm');
            formApi.setValue('pickupTime', time);
            pickupTimeCurrent = time;
          }

          const durationDrive = calcDurationDrive({
            endDate: dropoffDate,
            endTime: value,
            startDate: pickupDate,
            startTime: pickupTimeCurrent,
          });
          formApi.setValue('durationDrive', durationDrive);
        }}
      />
    </div>
  );
};

const DurationDriveField: FC<FieldProps> = ({ disabled, initialValue }) => {
  const { t } = useTranslation();

  return (
    <div className="field field-time">
      <TextField
        disabled={disabled}
        initialValue={initialValue as string}
        label={`${t('common.duration')}, hr`}
        name="durationDrive"
      />
    </div>
  );
};

const PickUpDateField: FC<FieldProps> = ({ disabled, initialValue }) => {
  const formApi = useFormApi();
  const { value: pickupTime } = useFieldState<string>('pickupTime');
  const { value: dropoffDate } = useFieldState<Date>('dropoffDate');
  const { value: dropoffTime } = useFieldState<string>('dropoffTime');
  const { t } = useTranslation();

  return (
    <div className="field field-date">
      <DateField
        defaultValue={(initialValue as Date) || undefined}
        disabled={disabled}
        dropdownMode="select"
        label={t('common.inputStartDate')}
        name="pickupDate"
        showMonthDropdown
        showYearDropdown
        validate={requiredField}
        onChange={(value) => {
          let dropoffDateCurrent = dropoffDate;
          if (isAfter(value as Date, dropoffDate as Date)) {
            formApi.setValue('dropoffDate', value);
            dropoffDateCurrent = value as Date;
          }

          const durationDrive = calcDurationDrive({
            endDate: dropoffDateCurrent,
            endTime: dropoffTime,
            startDate: value as Date,
            startTime: pickupTime,
          });
          formApi.setValue('durationDrive', durationDrive);
        }}
      />
    </div>
  );
};

const PickUpTimeField: FC<FieldProps> = ({ disabled, initialValue }) => {
  const formApi = useFormApi();
  const { value: pickupDate } = useFieldState<Date>('pickupDate');
  const { value: dropoffDate } = useFieldState<Date>('dropoffDate');
  const { value: dropoffTime } = useFieldState<string>('dropoffTime');
  const { t } = useTranslation();

  return (
    <div className="field field-time">
      <TimeField
        disabled={disabled}
        initialValue={initialValue as string}
        name="pickupTime"
        label={t('common.inputStartTime')}
        onChange={(value: string) => {
          let dropoffTimeCurrent = dropoffTime;
          const pickupDateTime = new Date(`${format(pickupDate, 'yyyy-MM-dd')} ${value}`);
          const dropoffDateTime = new Date(`${format(dropoffDate, 'yyyy-MM-dd')} ${dropoffTime}`);

          if (isAfter(pickupDateTime, dropoffDateTime)) {
            const time = format(addMinutes(pickupDateTime, 1), 'HH:mm');
            formApi.setValue('dropoffTime', time);
            dropoffTimeCurrent = time;
          }

          const durationDrive = calcDurationDrive({
            endDate: dropoffDate,
            endTime: dropoffTimeCurrent,
            startDate: pickupDate,
            startTime: value,
          });

          formApi.setValue('durationDrive', durationDrive);
        }}
      />
    </div>
  );
};

const DistanceTraveledField: FC<FieldProps> = ({ disabled, initialValue }) => {
  const { t } = useTranslation();

  const validate = (value: unknown): string | void => {
    if (value === undefined || parseFloat(value as string) <= 0) {
      return `Distance can't be 0`;
    }
  };

  return (
    <div className="field field-odometer">
      <TextField
        data-testid="edit-drive-workflow-distance"
        disabled={disabled}
        initialValue={initialValue as string}
        label={t('report.distanceTraveled')}
        name="distance"
        placeholder="km"
        type="number"
        validate={validate}
      />
    </div>
  );
};

export interface IFormState {
  distance: string;
  dropoffDate: Date;
  dropoffTime: string;
  durationDrive: string;
  pickupDate: Date;
  pickupTime: string;
}

export interface IEditDrive {
  data: IDriveWithWorkflow;
  readOnly?: boolean;
  updateDrive: (data: IDriveUpdate) => void;
}

const EditDriveForm: FC<IEditDrive> = ({ data, readOnly = false, updateDrive }) => {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState<IFormState>();
  const [isDriveInFuture, setIsDriveInFuture] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setIsDriveInFuture(isAfter(new Date(`${data.startDate} ${data.startTime}`), new Date()));

      const pickupDate = data.startDate
        ? parse(data.startDate, DateFormat.ApiDateAlt, new Date())
        : new Date();
      const dropoffDate = data.endDate
        ? parse(data.endDate, DateFormat.ApiDateAlt, new Date())
        : new Date();
      const pickupTime = data.startTime || '00:00';
      const dropoffTime = data.endTime || '00:00';

      const durationDrive = calcDurationDrive({
        endDate: dropoffDate,
        endTime: dropoffTime,
        startDate: pickupDate,
        startTime: pickupTime,
      });

      setFormValues({
        distance: String(data.distance),
        dropoffDate,
        dropoffTime,
        durationDrive,
        pickupDate,
        pickupTime,
      });
    }
  }, [data]);

  const RideSharing: FC = () =>
    useMemo(
      () => (
        <>
          <h3>{t('report.rideSharing')}</h3>

          {data?.sharedDrives.map((item: ISharedDrives) => (
            <div className="row" key={item.bookingRef}>
              <div className="item">
                <div className="label">{t('common.vehicle')}</div>
                <div className="value">{item.vehicleLabel}</div>
              </div>
              <div className="item">
                <div className="label">Km</div>
                <div className="value">{item.mileage}</div>
              </div>
              <div className="item">
                <div className="label">{t('common.duration')}</div>
                <div className="value">{item.duration}</div>
              </div>
            </div>
          ))}
        </>
      ),
      [],
    );

  const Workflows: FC = () =>
    useMemo(
      () => (
        <>
          <h3>{t('report.workflows')}</h3>

          {data?.workflows.map((item: IDriveWorkflow) => (
            <div className="row" key={item.name}>
              <div className="item">
                <div className="label">{t('common.name')}</div>
                <div className="value">{item.name}</div>
              </div>
              <div className="item">
                <div className="label">Odometer</div>
                <div className="value">
                  {item?.startKm} / {item?.endKm}
                </div>
              </div>
              <div className="item">
                <div className="label">Km</div>
                <div className="value">
                  {item.distance || (item.endKm - item.startKm).toFixed(2)}
                </div>
              </div>
              <div className="item">
                <div className="label">{t('common.duration')}</div>
                <div className="value">{item.duration}</div>
              </div>
            </div>
          ))}
        </>
      ),
      [],
    );

  return (
    <Form
      className="form-drive-edit modal-light-form"
      onSubmit={(formState: Record<string, unknown>) => {
        const state = { ...(formState.values as IFormState) };

        updateDrive({
          distance: parseFloat(state.distance),
          end_date: format(state.dropoffDate, DateFormat.ApiDateAlt),
          end_time: state.dropoffTime,
          start_date: format(state.pickupDate, DateFormat.ApiDateAlt),
          start_time: state.pickupTime,
        });
      }}
    >
      {isDriveInFuture && (
        <div className="row">
          <span className="font-red">{t('report.isDriveInFuture')}</span>
        </div>
      )}

      <h2>{t('common.editDrive')}</h2>

      <h3>{t('report.durationAndDistance')}</h3>

      {formValues && (
        <div className="row">
          <div className="fieldset">
            <PickUpDateField disabled={readOnly} initialValue={formValues.pickupDate} />
            <DropOffDateField disabled={readOnly} initialValue={formValues.dropoffDate} />
            <PickUpTimeField disabled={readOnly} initialValue={formValues.pickupTime} />
            <DropOffTimeField disabled={readOnly} initialValue={formValues.dropoffTime} />
            <DurationDriveField disabled initialValue={formValues.durationDrive} />
            <DistanceTraveledField disabled={readOnly} initialValue={formValues.distance} />
          </div>
        </div>
      )}

      {data?.sharedDrives?.length ? <RideSharing /> : null}

      {data?.workflows?.length ? <Workflows /> : null}

      <div className="footer">
        <Button
          className="btn btn-submit"
          disabled={readOnly}
          text={t('common.btnConfirmChanges')}
          type="submit"
          variant="submit"
        />
      </div>
    </Form>
  );
};

export default EditDriveForm;
