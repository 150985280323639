/* istanbul ignore file */
import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { useFieldState } from '@common/hooks';
import '../../DriveForm.styles.scss';

const ManagerEmailField = () => {
  const { value: noPax } = useFieldState<boolean>('noPax');

  return (
    <div className="field">
      <TextField
        className="input"
        data-testid="planner-drive-form-field-manager-email"
        disabled={noPax}
        label={t('common.managerEmail')}
        name="managerEmail"
      />
    </div>
  );
};

export default ManagerEmailField;
