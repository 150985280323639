import { AgencyListApi, ApiList } from '@common/interfaces';
import { AgenciesListParams, IAgenciesRepository } from '@common/interfaces/AgenciesRepository';
import AxiosRepository from './axios.repository';

class AgenciesRepository extends AxiosRepository implements IAgenciesRepository {
  private buildParams(params?: AgenciesListParams) {
    const searchParams = new URLSearchParams();

    if (params?.domain) searchParams.append('domain', params.domain);
    if (params?.short_name) searchParams.append('short_name', params.short_name);
    if (params?.limit) searchParams.append('limit', params.limit.toString());
    if (params?.offset) searchParams.append('offset', params.offset.toString());

    return searchParams;
  }

  async getAgencies(params?: AgenciesListParams): Promise<ApiList<AgencyListApi>> {
    const { data } = await this.get(`/agencies/`, { params: this.buildParams(params) });
    return data;
  }
}

export default AgenciesRepository;
