import {
  BookingsForInvoicesListParams,
  CurrenciesParams,
  FinancialParams,
  AgencyInvoiceCreate,
  AgencyInvoicePatch,
  IFinancialRepository,
  IndInvoiceCreate,
  InvoicesListParams,
} from '@common/interfaces';
import AxiosRepository from './axios.repository';

class FinancialRepository extends AxiosRepository implements IFinancialRepository {
  private buildDriveCostsParams(params?: FinancialParams) {
    const searchParams = new URLSearchParams();

    if (params?.agencyId) searchParams.append('agency', params.agencyId);
    if (params?.fuelType) searchParams.append('fuel_type', params.fuelType.toString());
    if (params?.limit) searchParams.append('limit', String(params.limit));
    if (params?.month) searchParams.append('current_month', String(params.month));
    if (params?.offset) searchParams.append('offset', String(params.offset));
    if (params?.ordering) searchParams.append('ordering', params.ordering);
    if (params?.search) searchParams.append('search', params.search);
    if (params?.year) searchParams.append('current_year', String(params.year));

    return searchParams;
  }

  private buildInvoiceParams(params?: BookingsForInvoicesListParams) {
    const searchParams = new URLSearchParams();

    if (params?.agencyId) searchParams.append('agency_id', params.agencyId);
    if (params?.current_month) searchParams.append('current_month', String(params.current_month));
    if (params?.current_year) searchParams.append('current_year', String(params.current_year));
    if (params?.limit) searchParams.append('limit', String(params.limit));
    if (params?.offset) searchParams.append('offset', String(params.offset));
    if (params?.ordering) searchParams.append('ordering', params.ordering);
    if (params?.passenger_agency) searchParams.append('passenger_agency', params.passenger_agency);
    if (params?.search) searchParams.append('search', params.search);
    if (params?.vehicle_agency) searchParams.append('vehicle_agency', params.vehicle_agency);

    return searchParams;
  }

  async createAgencyInvoice(facility: string, payload: AgencyInvoiceCreate) {
    const { data } = await this.post(
      `/facilities/${facility}/financials/agency-invoices/`,
      payload,
    );
    return data;
  }

  async createIndividualInvoice(facility: string, payload: IndInvoiceCreate) {
    const { data } = await this.post(`/facilities/${facility}/financials/invoices/`, payload);
    return data;
  }

  async deleteAgencyInvoiceData(
    facility: string,
    invoiceId: string,
    deleteData: { drives: string[] },
  ) {
    const { data } = await this.delete(
      `/facilities/${facility}/financials/agency-invoices/${invoiceId}`,
      { data: deleteData },
    );
    return data;
  }

  async getAgencyInvoiceData(facility: string, invoiceId: string, params?: FinancialParams) {
    const { data } = await this.get(
      `/facilities/${facility}/financials/agency-invoices/${invoiceId}/`,
      {
        params: this.buildDriveCostsParams(params),
      },
    );
    return data;
  }

  async getAgencyInvoicePreview(facility: string, invoiceId: string) {
    const { data } = await this.get(
      `/facilities/${facility}/financials/agency-invoices/${invoiceId}/preview`,
      { responseType: 'blob' },
    );
    return data;
  }

  async getBookingsForInvoiceList(facility: string, params?: BookingsForInvoicesListParams) {
    const { data } = await this.get(`/facilities/${facility}/financials/invoices/bookings/`, {
      params: this.buildInvoiceParams(params),
    });
    return data;
  }

  async getDriveCosts(facility: string, params?: FinancialParams) {
    const { data } = await this.get(`/facilities/${facility}/financials/drive-costs/`, {
      params: this.buildDriveCostsParams(params),
    });
    return data;
  }

  async getIndividualInvoiceData(facility: string, bookingId: string) {
    const { data } = await this.get(
      `/facilities/${facility}/financials/invoices/bookings/${bookingId}/`,
    );
    return data;
  }

  async getInvoices(facility: string, params?: InvoicesListParams) {
    const { data } = await this.get(`/facilities/${facility}/financials/invoices/`, {
      params: this.buildInvoiceParams(params),
    });
    return data;
  }

  async getInvoiceCurrencies(facility: string, params?: CurrenciesParams) {
    const searchParams = new URLSearchParams();

    if (params?.limit) searchParams.append('limit', params.limit);
    if (params?.offset) searchParams.append('offset', params.offset);
    if (params?.iso_code) searchParams.append('iso_code', params.iso_code);

    const { data } = await this.get(`/facilities/${facility}/financials/invoices/currencies/`, {
      params: searchParams,
    });

    return data;
  }

  async getInvoicePaymentMethods(facility: string) {
    const { data } = await this.get(`/facilities/${facility}/financials/invoices/payment-methods/`);
    return data;
  }

  async getInvoiceFilters(facility: string) {
    const { data } = await this.get(
      `/facilities/${facility}/financials/invoices/bookings/filters/`,
    );
    return data;
  }

  async updateAgencyInvoice(facility: string, invoiceId: string, payload: AgencyInvoicePatch) {
    const { data } = await this.patch(
      `/facilities/${facility}/financials/agency-invoices/${invoiceId}/`,
      payload,
    );
    return data;
  }
}

export default FinancialRepository;
