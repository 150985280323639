/* istanbul ignore file */
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormApi } from 'informed';
import cn from 'classnames';

import { Button } from '@ui-modules';
import { TextField } from '@ui-modules/informed';
import { useFieldState } from '@common/hooks';
import { DriveFormSteps, TransferType, TripType } from '@common/interfaces';
import { Depart, Flag, Route, Shuttle } from '@assets/svg/icons';
import '../styles/modal.scss';

interface TransferTypeFieldProps {
  city: string;
  fieldGroup?: string;
  isEdit: boolean;
  isScheduled: boolean;
}

const TransferTypeField: FC<TransferTypeFieldProps> = ({
  city,
  fieldGroup,
  isEdit,
  isScheduled,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormApi();
  const { value: transferType } = useFieldState<TransferType>('transferType');

  const [activeTab, setActiveTab] = useState<TransferType | undefined>(transferType);

  const dropoffTownFieldName = fieldGroup
    ? `${DriveFormSteps.Trip}.${fieldGroup}[0].dropoffTown`
    : `${DriveFormSteps.Trip}.dropoffTown`;

  return (
    <div className={cn('field field-transfer-type', { edit: isEdit || isScheduled })}>
      <div className="label">
        {isEdit || isScheduled
          ? t('common.transferType')
          : `${t('common.select')} ${t('common.transferType')}`}
      </div>
      <TextField name="transferType" type="hidden" />

      <div className="tabs">
        {(!isEdit && !isScheduled) || transferType === TransferType.Airport ? (
          <Button
            className={cn('tab', { active: activeTab === TransferType.Airport })}
            data-testid="planner-drive-form-transfer-type-airport"
            disabled={transferType === TransferType.Airport}
            icon={<Depart />}
            text={t('common.transferTypeAirport')}
            variant="primary"
            onClick={() => {
              setActiveTab(TransferType.Airport);
              if (transferType) setValue(dropoffTownFieldName, city);
              setValue(`${DriveFormSteps.Trip}.transferType`, TransferType.Airport);
              setValue(`${DriveFormSteps.Trip}.typeOfTrip`, '');
            }}
          />
        ) : null}

        {(!isEdit && !isScheduled) || transferType === TransferType.InTown ? (
          <Button
            className={cn('tab', { active: activeTab === TransferType.InTown })}
            data-testid="planner-drive-form-transfer-type-in-town"
            disabled={transferType === TransferType.InTown}
            icon={<Flag />}
            text={t('common.inTown')}
            variant="primary"
            onClick={() => {
              setActiveTab(TransferType.InTown);
              if (transferType) setValue(dropoffTownFieldName, city);
              setValue(`${DriveFormSteps.Trip}.transferType`, TransferType.InTown);
              setValue(`${DriveFormSteps.Trip}.typeOfTrip`, '');
            }}
          />
        ) : null}

        {(!isEdit && !isScheduled) || transferType === TransferType.OutTown ? (
          <Button
            className={cn('tab', { active: activeTab === TransferType.OutTown })}
            data-testid="planner-drive-form-transfer-type--out-of-town"
            disabled={transferType === TransferType.OutTown}
            icon={<Route />}
            text={t('common.transferTypeOutTown')}
            variant="primary"
            onClick={() => {
              setActiveTab(TransferType.OutTown);
              if (transferType) setValue(dropoffTownFieldName, '');
              setValue(`${DriveFormSteps.Trip}.transferType`, TransferType.OutTown);
              setValue(`${DriveFormSteps.Trip}.typeOfTrip`, '');
            }}
          />
        ) : null}

        {(isEdit || isScheduled) && transferType === TransferType.Shuttle ? (
          <Button
            className={cn('tab', { active: activeTab === TransferType.Shuttle })}
            data-testid="planner-drive-form-transfer-type-shuttle"
            disabled={transferType === TransferType.Shuttle}
            icon={<Shuttle />}
            text={t('common.transferTypeShuttle')}
            variant="primary"
            onClick={() => {
              setActiveTab(TransferType.Shuttle);
              if (transferType) setValue(dropoffTownFieldName, '');
              setValue(`${DriveFormSteps.Trip}.transferType`, TransferType.Shuttle);
              setValue(`${DriveFormSteps.Trip}.typeOfTrip`, TripType.OneWay);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TransferTypeField;
