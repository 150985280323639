/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isBefore, isSameDay, parse } from 'date-fns';
import { useFormApi } from 'informed';
import { DateField } from '@ui-modules/informed';
import { useFieldState } from '@common/hooks';
import type { PlAcceptDrive } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import '../../BookingPanel.styles.scss';

type FieldProps = {
  acceptDrives?: PlAcceptDrive[];
  disabled?: boolean;
  leg: number;
  value?: string;
  onChange?: (value: Date | string) => void;
};

const DropOffDateField = ({ acceptDrives, disabled, leg, value, onChange }: FieldProps) => {
  const formApi = useFormApi();
  const { value: dropoffDate } = useFieldState<Date>(`${leg}.dropoffDate`);
  const { value: pickupDate } = useFieldState<Date>(`${leg}.pickupDate`);
  const { value: pickupTime } = useFieldState<Date>(`${leg}.pickupTime`);

  const [minDate, setMinDate] = useState<Date | undefined>();

  useEffect(() => {
    if (pickupDate) {
      setMinDate(pickupDate);

      if (leg > 1 && (isSameDay(dropoffDate, pickupDate) || isBefore(dropoffDate, pickupDate))) {
        formApi.setValue(`${leg}.dropoffDate`, pickupDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptDrives, dropoffDate, pickupDate, pickupTime, leg]);

  useEffect(() => {
    if (acceptDrives && value) {
      const isScheduled = acceptDrives?.[leg - 1].isScheduled;
      if (isScheduled) formApi.setValue(`${leg}.dropoffDate`, parse(value, DF.ApiDate, new Date()));
    }
  }, [acceptDrives, formApi, leg, value]);

  return (
    <div className="field">
      <DateField
        className="input"
        defaultValue={value ? parse(value, DF.ApiDate, new Date()) : undefined}
        disabled={disabled}
        label={t('bookingDetails.dropoffDate')}
        minDate={minDate}
        name={`${leg}.dropoffDate`}
        placeholder={t('bookingDetails.chooseTime')}
        showMonthDropdown
        showYearDropdown
        onChange={onChange}
      />
    </div>
  );
};

export default DropOffDateField;
