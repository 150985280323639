import { type Dispatch, type SetStateAction, useEffect, useReducer, useState } from 'react';
import type { DatesRangeValue } from '@mantine/dates';
import { useQuery } from 'react-query';
import { endOfMonth, format, isDate, startOfMonth } from 'date-fns';

import { FilterActionTypes } from '@common/constants';
import {
  LBAuxFilters,
  LBFilter,
  LBFilterAction,
  LBFilterRes,
  LBFilterSelected,
} from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';

type FiltersState = LBFilter[];

const initFilterState: FiltersState = [];

function filterReducer(state: FiltersState, action: LBFilterAction): FiltersState {
  switch (action.type) {
    case FilterActionTypes.INIT:
      return action.payload.map(({ title, options }) => ({ title, options }));

    case FilterActionTypes.CLEAR_ALL:
      return state.map((f) => ({ ...f, selected: undefined }));

    case FilterActionTypes.CLEAR_ONE:
      return state.map((f) => (f.title === action.payload ? { ...f, selected: undefined } : f));

    case FilterActionTypes.CHANGE_FILTER:
      return state.map((f) =>
        f.title === action.payload.title ? { ...f, selected: action.payload.option } : f,
      );

    default:
      return state;
  }
}

interface LogbookFiltersResponse {
  period: [Date | null, Date | null];
  isFilterLoading: boolean;
  logbookActiveFilters: LBAuxFilters;
  logbookFilters: FiltersState;
  selectedFilters: LBFilterSelected;
  setPeriod: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  logbookFilterDispatch: (_: LBFilterAction) => void;
}

export default function useLogbookFilters(): LogbookFiltersResponse {
  const { logbookRepository } = useRepository();
  const { facility, facilityId } = useFacility();

  const [period, setPeriod] = useState<DatesRangeValue>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [logbookFilters, logbookFilterDispatch] = useReducer(filterReducer, initFilterState);
  const [logbookActiveFilters, setLogbookActiveFilters] = useState<LBAuxFilters>({});
  const [selectedFilters, setSelectedFilters] = useState<LBFilterSelected>([]);

  const { data, isLoading: isFilterLoading } = useQuery<unknown, void, LBFilterRes>(
    ['filters', period],
    () =>
      logbookRepository.getFiltersData(facilityId, {
        dateEnd: period?.[1] ? format(period[1], 'yyyy-MM-dd') : undefined,
        dateStart: period?.[0] ? format(period[0], 'yyyy-MM-dd') : undefined,
      }),
    {
      enabled:
        Boolean(facility.agencyId) && Boolean(period) && isDate(period[0]) && isDate(period[1]),
      retry: false,
      onError: () => {
        logbookFilterDispatch({
          type: FilterActionTypes.INIT,
          payload: logbookFilters.map((i) => ({ ...i, selected: undefined, options: [] })),
        });
      },
    },
  );

  useEffect(() => {
    if (data) {
      logbookFilterDispatch({
        type: FilterActionTypes.INIT,
        payload: Object.keys(data).map((i) => ({ title: i, options: data[i] })),
      });
    }
  }, [data]);

  useEffect(() => {
    setSelectedFilters(
      logbookFilters
        .filter((i) => i.selected)
        .map((i) => ({ title: i.title, selected: i.selected! })),
    );
  }, [logbookFilters]);

  useEffect(() => {
    if (selectedFilters.length) {
      const activeFilters: LBAuxFilters = {};

      const filters = logbookFilters
        .filter((i) => i.selected)
        .map((i) => ({
          title: i.title,
          selected: i.options.find((j) => j.value === i.selected)?.key,
        }));

      filters.forEach((i) => {
        activeFilters[i.title] = i.selected!;
      });

      setLogbookActiveFilters(activeFilters);
    }
  }, [logbookFilters, selectedFilters, setLogbookActiveFilters]);

  return {
    period,
    isFilterLoading,
    logbookActiveFilters,
    logbookFilters,
    logbookFilterDispatch,
    selectedFilters,
    setPeriod,
  };
}
