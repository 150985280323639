/* eslint-disable react/destructuring-assignment */
/* istanbul ignore file */
function Vehicle(props: any) {
  return (
    <svg
      width={20}
      height={12}
      viewBox="0 0 20 12"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.171 2.044h-.836c-.334 0-.502.172-.502.515v3.204c0 .343.168.514.558.514h.725c.39 0 .558-.171.558-.514V2.559c0-.343-.168-.515-.503-.515zM18.845 6.964c-.065-.011-.111-.058-.111-.172 0-.171.077-.39.11-.572.026-.135-.032-.268-.167-.286-.175-.024-.278-.229-.278-.4 0-.23.111-.344.335-.401.071-.022.127-.028.167-.058.005-.103.115-.623-.725-.858-.502-.114-3.123-.572-3.904-.686-.113-.027-.223-.043-.335-.171a57.306 57.306 0 01-1.505-1.945C11.93.785 11.255.43 10.592.27 9.93.11 4.844.157 4.402.27c-.441.113-.898.423-.921.92-.023.498-.028 4-.083 5.545 0 .286-.114.277-.278.286-.165.01-.335.053-.335.286 0 .233.078 1.03.14 1.193.05.127.318.349.696-.278.703-1.162 1.561-1.646 2.789-1.601 1.285.046 1.985.743 2.487 1.887.056.114.111.23.278.23h3.87c.111 0 .159-.05.223-.173.502-1.2 1.394-1.887 2.677-1.944 1.283-.058 2.23.63 2.845 1.83.052-.147.278-.915.334-1.087.111-.343 0-.358-.28-.4zM7.413 3.366a.22.22 0 01-.217.223H5.288a.22.22 0 01-.217-.223V1.638a.22.22 0 01.217-.223h1.908c.12 0 .217.1.217.223v1.728zm1.444.223a.22.22 0 01-.218-.223V1.638a.22.22 0 01.218-.223h.838a2.08 2.08 0 011.666.843l.994 1.33H8.857z"
        fill={props.fill}
      />
      <path
        d="M6.24 7.152c-1.259 0-2.28 1.048-2.28 2.34 0 1.293 1.02 2.341 2.28 2.341 1.261 0 2.282-1.047 2.282-2.34s-1.02-2.34-2.281-2.34zm0 3.384c-.56 0-1.016-.468-1.016-1.043 0-.576.457-1.044 1.017-1.044.56 0 1.017.468 1.017 1.044 0 .575-.456 1.043-1.017 1.043zM16.034 7.152c-1.26 0-2.281 1.048-2.281 2.34 0 1.293 1.02 2.341 2.281 2.341 1.26 0 2.281-1.047 2.281-2.34s-1.02-2.34-2.281-2.34zm0 3.384c-.561 0-1.017-.468-1.017-1.043 0-.576.457-1.044 1.017-1.044.561 0 1.017.468 1.017 1.044 0 .575-.456 1.043-1.017 1.043z"
        fill={props.fill}
      />
    </svg>
  );
}

export default Vehicle;
