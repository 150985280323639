import { type FC, useEffect, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DatesRangeValue } from '@mantine/dates';

import type { IDropdownOption } from '@ui-modules/types';
import { BFShuttleRoute } from '@common/interfaces';
import { DateInputFormat } from '@common/types';
import { InputDatePicker, InputText, Select } from '@components';
import type { BFRoute } from '../types';
import { allowDay, calcMinDate, findRange, uniqOptions, validRouteByDate } from '../utils';

type ShuttleRouteProps = {
  form: UseFormReturn<any>;
  idx: number;
  isRoundTrip: boolean;
  routes: BFRoute[];
  shuttleRoutes: BFShuttleRoute[];
};

const ShuttleRoute: FC<ShuttleRouteProps> = ({ form, idx, isRoundTrip, routes, shuttleRoutes }) => {
  const { t } = useTranslation();
  const { control: ctrl, setValue } = form;

  const [currentDate, setCurrentDate] = useState<Date>();
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [weekdays, setWeekdays] = useState<number[]>([]);

  const [dropoffLocationOptions, setDropoffLocationOptions] = useState<IDropdownOption[]>([]);
  const [dropoffTownOptions, setDropoffTownOptions] = useState<IDropdownOption[]>([]);
  const [pickupLocationOptions, setPickupLocationOptions] = useState<IDropdownOption[]>([]);
  const [pickupTimeOptions, setPickupTimeOptions] = useState<IDropdownOption[]>([]);
  const [pickupTownOptions, setPickupTownOptions] = useState<IDropdownOption[]>([]);

  const [selectedPickupLocation, setSelectedPickupLocation] = useState<string>();
  const [selectedPickupTown, setSelectedPickupTown] = useState<string>();
  const [selectedRoute, setSelectedRoute] = useState<BFShuttleRoute | null>(null);
  const [shuttleDateRange, setDateRange] = useState<DatesRangeValue>([null, null]);

  const availableRoutes = isRoundTrip
    ? shuttleRoutes.filter((i: BFShuttleRoute) => i.relatedScheduleId)
    : shuttleRoutes;
  const isReturnStep = isRoundTrip && idx === 1;

  useEffect(() => {
    if (availableRoutes?.length) {
      setDateRange(findRange(availableRoutes));

      const weekdaysData = availableRoutes.reduce((acc, route) => {
        route.weekdays.forEach((day) => {
          if (!acc.includes(day)) acc.push(day);
        });
        return acc;
      }, [] as number[]);

      setWeekdays(weekdaysData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shuttleRoutes]);

  useEffect(() => {
    if (selectedRoute) {
      const pickupTownIdx = selectedRoute.locations.findIndex(
        (i) => i.town === selectedPickupTown && i.location === selectedPickupLocation,
      );

      if (pickupTownIdx !== -1) {
        const nextTowns = selectedRoute.locations.slice(pickupTownIdx + 1).map((i) => i.town);

        setDropoffTownOptions(uniqOptions(nextTowns));
      }

      setValue(`routes.${idx}.scheduledRouteId`, selectedRoute.uuid);
    }
  }, [idx, selectedPickupLocation, selectedPickupTown, selectedRoute, setValue]);

  // Round Trip
  const [outwardTrip] = routes;
  const { dropoffLocation, dropoffTown, pickupDate, pickupLocation, pickupTime, pickupTown } =
    outwardTrip;
  const returnRoute = shuttleRoutes.find((r) => r.uuid === outwardTrip.relatedScheduleId);

  useEffect(() => {
    if (isRoundTrip && routes.length === 2 && idx === 1 && dropoffLocation && dropoffTown) {
      if (returnRoute) {
        const outwardStart = returnRoute?.locations.find(
          (l) => l.location === dropoffLocation && l.town === dropoffTown,
        );
        const outwardEnd = returnRoute?.locations.find(
          (l) => l.location === pickupLocation && l.town === pickupTown,
        );

        if (pickupDate && outwardStart && outwardEnd && outwardStart.pickupTime) {
          const date = calcMinDate(pickupDate, pickupTime, outwardStart.pickupTime, weekdays);
          setMinDate(date);
          setValue(`routes.${idx}.pickupDate`, date);
        }

        const pickUpTown = returnRoute?.locations.slice(0, -1).map((l) => l.town);
        const pickUpLoc = returnRoute?.locations
          .slice(0, -1)
          .filter((l) => l.town === dropoffTown)
          .map((i) => i.location);
        const pickUpTime = returnRoute?.locations
          .slice(0, -1)
          .filter((l) => l.location === dropoffLocation && l.town === dropoffTown)
          .map((i) => i.pickupTime);
        const locIdx = returnRoute.locations.findIndex(
          (i) => i.location === outwardStart?.location && i.town === outwardStart?.town,
        );
        const dropTown = returnRoute.locations.slice(locIdx + 1).map((i) => i.town);
        const dropLoc = returnRoute?.locations
          .slice(locIdx + 1)
          .filter((i) => i.town === outwardEnd?.town)
          .map((i) => i.location);

        setPickupTownOptions(uniqOptions(pickUpTown));
        setPickupLocationOptions(uniqOptions(pickUpLoc));
        setPickupTimeOptions(uniqOptions(pickUpTime));
        setDropoffTownOptions(uniqOptions(dropTown));
        setDropoffLocationOptions(uniqOptions(dropLoc));

        setSelectedPickupTown(outwardStart?.town);
        setSelectedPickupLocation(outwardStart?.location);

        setValue(`routes.${idx}.pickupTown`, outwardStart?.town);
        setValue(`routes.${idx}.pickupLocation`, outwardStart?.location);
        setValue(`routes.${idx}.pickupTime`, outwardStart?.pickupTime);
        setValue(`routes.${idx}.dropoffTown`, outwardEnd?.town);
        setValue(`routes.${idx}.dropoffLocation`, outwardEnd?.location);
        setValue(`routes.${idx}.dropoffTime`, outwardEnd?.dropoffTime);
        setValue(`routes.${idx}.scheduledRouteId`, returnRoute.uuid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropoffLocation, idx, isRoundTrip]);
  // END Round Trip

  const handlePickupDateChange = (value: Date) => {
    setCurrentDate(value);

    if (isReturnStep) return;

    const validRoutesByDate = validRouteByDate(value, availableRoutes);
    const uniqTownOptions = uniqOptions(
      validRoutesByDate.flatMap((r) => r.locations.slice(0, -1).map((i) => i.town)),
    );

    if (!validRoutesByDate?.length) toast.warn(t('bookingForm.msgNoRoutes'));

    setPickupTownOptions(uniqTownOptions);
    setPickupTimeOptions([]);
    setPickupLocationOptions([]);
    setSelectedRoute(null);
    setValue(`routes.${idx}.dropoffLocation`, '');
    setValue(`routes.${idx}.dropoffTown`, '');
    setValue(`routes.${idx}.pickupLocation`, '');
    setValue(`routes.${idx}.pickupTime`, '');
    setValue(`routes.${idx}.pickupTown`, '');
  };

  const handlePickupTownChange = (selectedTown: string) => {
    setSelectedPickupTown(selectedTown);
    setSelectedRoute(null);
    setValue(`routes.${idx}.dropoffLocation`, '');
    setValue(`routes.${idx}.dropoffTown`, '');
    setValue(`routes.${idx}.pickupLocation`, '');
    setValue(`routes.${idx}.pickupTime`, '');

    const locOpt = [];

    if (isReturnStep && returnRoute) {
      locOpt.push(
        ...uniqOptions(
          returnRoute?.locations
            .slice(0, -1)
            .filter((l) => l.town === selectedTown)
            .map((i) => i.location),
        ),
      );
    } else {
      const filteredRoutes = validRouteByDate(currentDate!, availableRoutes).filter((r) =>
        r.locations.slice(0, -1).some((i) => i.town === selectedTown),
      );
      locOpt.push(
        ...uniqOptions(
          filteredRoutes.flatMap((r) =>
            r.locations
              .slice(0, -1)
              .filter((i) => i.town === selectedTown)
              .map((i) => i.location),
          ),
        ),
      );
    }

    setPickupLocationOptions(locOpt);
  };

  const handlePickupLocationChange = (selectedLocation: string) => {
    const timeOpt = [];
    setSelectedPickupLocation(selectedLocation);
    setSelectedRoute(null);

    if (isReturnStep && returnRoute) {
      const pickupLocIdx = returnRoute.locations.findIndex(
        (i) => i.town === selectedPickupTown! && i.location === selectedLocation,
      );
      const dropoffCity = returnRoute?.locations.slice(pickupLocIdx + 1).map((i) => i.town);
      setDropoffTownOptions(uniqOptions(dropoffCity));
      if (dropoffCity?.length === 1) setValue(`routes.${idx}.dropoffTown`, dropoffCity[0]);

      timeOpt.push(
        ...uniqOptions(
          returnRoute?.locations
            .filter((l) => l.location === selectedLocation)
            .map((i) => i.pickupTime),
        ),
      );
    } else {
      setValue(`routes.${idx}.dropoffLocation`, '');
      setValue(`routes.${idx}.dropoffTown`, '');
      setValue(`routes.${idx}.pickupTime`, '');

      const selectedLocationRoutes = validRouteByDate(currentDate!, availableRoutes)
        .filter((r) => r.locations.slice(0, -1).some((i) => i.town === selectedPickupTown))
        .filter((r) =>
          r.locations
            .slice(0, -1)
            .some((i) => i.town === selectedPickupTown && i.location === selectedLocation),
        );
      timeOpt.push(
        ...uniqOptions(
          selectedLocationRoutes.flatMap((r) =>
            r.locations
              .filter((i) => i.town === selectedPickupTown && i.location === selectedLocation)
              .map((i) => i.pickupTime),
          ),
        ),
      );
    }

    setPickupTimeOptions(timeOpt);
  };

  const handlePickupTimeChange = (selectedTime: string) => {
    if (isReturnStep) return;

    const route = validRouteByDate(currentDate!, availableRoutes).find((r: BFShuttleRoute) =>
      r.locations.some(
        (i) =>
          i.town === selectedPickupTown &&
          i.location === selectedPickupLocation &&
          i.pickupTime === selectedTime,
      ),
    );

    setValue(`routes.${idx}.dropoffLocation`, '');
    setValue(`routes.${idx}.dropoffTown`, '');
    if (route) setSelectedRoute(route);
  };

  const handleDropoffLocationChange = (location: string) => {
    if (isReturnStep) return;

    const selectedDropoffLocation = selectedRoute?.locations.find((i) => i.location === location);

    setValue(`routes.${idx}.dropoffTime`, selectedDropoffLocation?.dropoffTime);
    setValue(`routes.${idx}.relatedScheduleId`, selectedRoute?.relatedScheduleId);
  };

  const handleDropoffTownChange = (selectedTown: string) => {
    const fieldName = `routes.${idx}.dropoffLocation`;
    const relatedRoute = isReturnStep && returnRoute ? returnRoute : selectedRoute;
    const locIdx = relatedRoute?.locations.findIndex(
      (i) => i.town === selectedTown && i.location === selectedPickupLocation,
    );
    const dropoffLoc = relatedRoute?.locations
      .slice(locIdx! + 1)
      .filter((i) => i.town === selectedTown)
      .map((i) => i.location);

    if (dropoffLoc?.length) {
      setDropoffLocationOptions(uniqOptions(dropoffLoc));

      if (dropoffLoc.length === 1) {
        setValue(fieldName, dropoffLoc[0]);
        handleDropoffLocationChange(dropoffLoc[0]);
      } else if (!isReturnStep) {
        setValue(fieldName, '');
      }
    }
  };

  useEffect(() => {
    if (pickupLocationOptions?.length === 1) {
      const loc = pickupLocationOptions[0].value;
      setValue(`routes.${idx}.pickupLocation`, loc);
      handlePickupLocationChange(loc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx, pickupLocationOptions]);

  useEffect(() => {
    if (pickupTimeOptions?.length === 1) {
      const time = pickupTimeOptions[0].value;
      setValue(`routes.${idx}.pickupTime`, time);
      handlePickupTimeChange(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx, pickupTimeOptions]);

  useEffect(() => {
    if (dropoffTownOptions?.length === 1) {
      const town = dropoffTownOptions[0].value;
      setValue(`routes.${idx}.dropoffTown`, town);
      handleDropoffTownChange(town);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx, dropoffTownOptions]);

  return (
    <fieldset data-testid={`bf-trip-details-fieldset-${idx}`}>
      {isRoundTrip && (
        <legend>{idx ? t('bookingDetails.returnTrip') : t('bookingDetails.outwardTrip')}</legend>
      )}

      <InputDatePicker
        name={`routes.${idx}.pickupDate`}
        className="field"
        control={ctrl}
        excludeDate={(date) => !allowDay(date, weekdays)}
        label={t('bookingDetails.pickupDate')}
        minDate={minDate}
        maxDate={shuttleDateRange[1] || undefined}
        required
        valueFormat={DateInputFormat.DateByDots}
        onChange={handlePickupDateChange}
        data-testid={`bf-pickup-date-${idx}`}
      />
      <Select
        name={`routes.${idx}.pickupTown`}
        className="field"
        control={ctrl}
        label={t('bookingDetails.pickupTown')}
        options={pickupTownOptions}
        placeholder="Select pickup date first"
        required
        onChange={handlePickupTownChange}
        data-testid={`bf-pickup-town-${idx}`}
      />
      <Select
        name={`routes.${idx}.pickupLocation`}
        className="field"
        control={ctrl}
        label={t('bookingDetails.pickupLocation')}
        options={pickupLocationOptions}
        placeholder="Select pickup town first"
        required
        onChange={handlePickupLocationChange}
        data-testid={`bf-pickup-location-${idx}`}
      />
      <Select
        name={`routes.${idx}.pickupTime`}
        className="field"
        control={ctrl}
        label={t('bookingDetails.pickupTime')}
        options={pickupTimeOptions}
        placeholder="Select pickup location first"
        required
        onChange={handlePickupTimeChange}
        data-testid={`bf-pickup-time-${idx}`}
      />
      <div className="line">Set up pickup first</div>
      <Select
        name={`routes.${idx}.dropoffTown`}
        className="field"
        control={ctrl}
        label={t('bookingDetails.dropoffTown')}
        options={dropoffTownOptions}
        required
        onChange={handleDropoffTownChange}
        data-testid={`bf-dropoff-town-${idx}`}
      />
      <Select
        name={`routes.${idx}.dropoffLocation`}
        className="field"
        control={ctrl}
        label={t('bookingDetails.dropoffLocation')}
        options={dropoffLocationOptions}
        required
        onChange={handleDropoffLocationChange}
        data-testid={`bf-dropoff-location-${idx}`}
      />
    </fieldset>
  );
};

type SectionProps = {
  form: UseFormReturn<any>;
  isRoundTrip: boolean;
  purpose: IDropdownOption[];
  routes: BFRoute[];
  shuttleRoutes: BFShuttleRoute[];
};

const ShuttleSection: FC<SectionProps> = ({
  form,
  isRoundTrip,
  purpose,
  routes,
  shuttleRoutes,
}) => {
  const { t } = useTranslation();
  const { control: ctrl } = form;

  return (
    <>
      {Array.from({ length: routes?.length }, (_, i) => (
        <ShuttleRoute
          form={form}
          idx={i}
          isRoundTrip={isRoundTrip}
          key={`shuttle-route-${i}`}
          routes={routes}
          shuttleRoutes={shuttleRoutes}
        />
      ))}

      <fieldset data-testid="bf-extra-fieldset">
        <Select
          name="bookingExtra.purpose"
          className="field"
          control={ctrl}
          label={t('common.purpose')}
          options={purpose}
          required
          data-testid="bf-extra-purpose"
        />
        <InputText
          name="bookingExtra.remarks"
          className="field"
          control={ctrl}
          label={`${t('common.remarks')} (${t('common.optional')})`}
          data-testid="bf-extra-remarks"
        />
      </fieldset>
    </>
  );
};

export default ShuttleSection;
