import type { ComponentProps, FC } from 'react';
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend, Tooltip);

interface ChartProps {
  colors: string[];
  dataset?: {
    data: number[];
    labels: string[];
    total: number;
  };
  emptyText?: string;
  height: number;
  title?: string | JSX.Element;
  totalText?: string;
}

const createChartConfig = (props: ChartProps): ComponentProps<typeof Pie> => ({
  data: {
    labels: props.dataset?.labels || [],
    datasets: [
      {
        backgroundColor: props.colors,
        borderWidth: 0,
        data: props.dataset?.data || [],
        label: ' ',
      },
    ],
  },
  options: {
    color: 'white',
    maintainAspectRatio: false,
    offset: 20,
    radius: 110,
    plugins: {
      legend: {
        align: 'center',
        position: 'right',
        labels: {
          font: { weight: '600' },
          padding: 20,
          pointStyle: 'circle',
          usePointStyle: true,
        },
      },
    },
  },
});

const PieChart: FC<ChartProps> = (props) => {
  const { dataset, emptyText, height, title, totalText } = props;

  return (
    <div className="chart">
      {title && <h3>{title}</h3>}

      {dataset?.total && (
        <div style={{ height }}>
          {totalText ? (
            <div className="chart-data-total">
              {dataset?.total} {totalText}
            </div>
          ) : null}

          <Pie {...createChartConfig(props)} />
        </div>
      )}

      {!dataset?.total && emptyText && <div className="chart-data-empty">{emptyText}</div>}
    </div>
  );
};

export default PieChart;
