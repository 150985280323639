/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import { type FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayField, Input, useFormApi, useFormState } from 'informed';

import type { GoogleSuggestion } from '@common/types';
import {
  Drive,
  DriveFormSteps as Steps,
  DriveFormState,
  PlDrive,
  PlVehicle,
  ShuttleStop,
  StepOptions,
  TransferType,
  TripType,
  PlDriver,
} from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { Button } from '@components';
import { Add } from '@assets/svg/icons';

import {
  AddressField,
  CommentField,
  DriverField,
  DropoffDateField,
  DropoffLocationField,
  DropoffTimeField,
  DropoffTownField,
  GoogleLocationField,
  PickupDateField,
  PickupLocationField,
  PickupTimeField,
  PickupTownField,
  VehicleField,
} from '../../../../fields';
import '../../../../styles/modal.scss';

const multiLegLimit = 20;
let multiLegCount = 0;

interface StepProps {
  drive?: Drive;
  drivers: PlDriver[];
  initMultileg: Record<string, unknown>;
  isEditProcess: boolean;
  options: StepOptions;
  sharedDrive: PlDrive | null;
  showGoogleLocation: boolean;
  shuttleStops?: ShuttleStop[];
  transferType: TransferType;
  tripType: TripType;
  vehicles: PlVehicle[];
}

const TripStepFieldset: FC<StepProps> = ({
  drive,
  drivers,
  isEditProcess,
  initMultileg,
  options,
  sharedDrive,
  showGoogleLocation,
  shuttleStops,
  transferType,
  tripType,
  vehicles,
}) => {
  const { setValue } = useFormApi();
  const { t } = useTranslation();
  const { facility } = useFacility();
  const { city, country, countryCode } = facility;
  const { driverOpt, locOpt, vehicleOpt } = options;
  const { values } = useFormState() as any;
  const formState = { ...(values as DriveFormState)?.[Steps.Trip] };

  const isMultiLeg = tripType === TripType.MultiLeg && transferType !== TransferType.Airport;
  const isRoundTrip = tripType === TripType.RoundTrip;
  const isShuttle = !!shuttleStops?.length;
  const fieldGroup = isMultiLeg ? 'driveListMultileg' : 'driveList';

  const setLegValues = (prefix: string, data: GoogleSuggestion) => {
    const { isFacilityLocation, lat, lng, town } = data.dataset || {};
    setValue?.(`${prefix}AddressRequired`, isFacilityLocation);
    setValue?.(`${prefix}Town`, town || city);
    setValue?.(`${prefix}Lat`, lat ?? '');
    setValue?.(`${prefix}Lng`, lng ?? '');
  };

  const handleLocationChange = (name: string, leg: number) => (e: GoogleSuggestion | string) => {
    if (typeof e === 'object') {
      const fieldset = `${Steps.Trip}.${fieldGroup}`;
      setLegValues(`${fieldset}.${leg}.${name}`, e);

      const data = formState?.[fieldGroup];
      if (isMultiLeg && leg < Number(data?.length) - 1 && name === 'dropoff') {
        const nextLegPrefix = `${fieldset}.${leg + 1}.pickup`;
        setLegValues(nextLegPrefix, e);
        setValue?.(`${nextLegPrefix}Location`, e.value);
      }
    }
  };

  const sectionTitle =
    transferType !== TransferType.Airport && isRoundTrip
      ? isEditProcess
        ? drive?.bookedRouteOrder === 1
          ? t('bookingDetails.outwardTrip')
          : t('bookingDetails.returnTrip')
        : `1. ${t('bookingDetails.outwardTrip')}`
      : null;
  const showField = !showGoogleLocation || isShuttle;

  return !isMultiLeg ? (
    <ArrayField name={fieldGroup}>
      {() => (
        <ArrayField.Items>
          {({ index, name }) => {
            const isReturnTrip = !isMultiLeg && index === 1;
            const data = formState?.[fieldGroup];

            if (data && index >= data?.length) return <Fragment key={name} />;

            return (
              <Fragment key={name}>
                {sectionTitle && <div className="section-title">{sectionTitle}</div>}

                <div className="row field-group">
                  {showGoogleLocation && !isShuttle && (
                    <>
                      <Input name="dropoffLat" hidden />
                      <Input name="dropoffLng" hidden />
                      <Input name="dropoffTown" hidden />
                      <Input name="pickupLat" hidden />
                      <Input name="pickupLng" hidden />
                      <Input name="pickupTown" hidden />
                      <GoogleLocationField
                        city={city}
                        country={`${country} [${countryCode}]`}
                        fieldGroup={name}
                        idx={index}
                        label={`${t('bookingDetails.pickupAddress')} (Google Maps)`}
                        name="pickupLocation"
                        required
                        onChange={handleLocationChange('pickup', index)}
                      />
                      <GoogleLocationField
                        city={city}
                        country={`${country} [${countryCode}]`}
                        fieldGroup={name}
                        idx={index}
                        label={`${t('bookingDetails.dropoffAddress')} (Google Maps)`}
                        name="dropoffLocation"
                        required
                        onChange={handleLocationChange('dropoff', index)}
                      />
                      <AddressField idx={index} label="Pick-up Address" name="pickup" />
                      <AddressField idx={index} label="Drop-off Address" name="dropoff" />
                    </>
                  )}

                  <PickupDateField fieldGroup={name} />
                  {showField && (
                    <PickupLocationField
                      fieldGroup={name}
                      index={index}
                      isEdit={isEditProcess}
                      options={locOpt}
                      returnTrip={isReturnTrip}
                      scheduled={shuttleStops}
                    />
                  )}
                  <PickupTimeField fieldGroup={name} scheduled={shuttleStops} />
                  {showField && <PickupTownField fieldGroup={name} scheduled={shuttleStops} />}

                  <DropoffDateField />
                  {showField && (
                    <DropoffLocationField
                      fieldGroup={name}
                      index={index}
                      isEdit={isEditProcess}
                      returnTrip={isReturnTrip}
                      options={locOpt}
                      scheduled={shuttleStops}
                    />
                  )}
                  <DropoffTimeField fieldGroup={name} scheduled={shuttleStops} />
                  {showField && <DropoffTownField fieldGroup={name} scheduled={shuttleStops} />}

                  <VehicleField options={vehicleOpt} />
                  <DriverField
                    driver={drive?.driver}
                    drivers={drivers}
                    fieldGroup={name}
                    isEdit={isEditProcess}
                    options={driverOpt}
                    sharedDrive={isEditProcess ? null : sharedDrive}
                    vehicles={vehicles}
                  />
                  <CommentField label={t('planner.commentPax')} name="commentToPax" />
                  <CommentField label={t('planner.commentDriver')} name="commentToDriver" />
                </div>
              </Fragment>
            );
          }}
        </ArrayField.Items>
      )}
    </ArrayField>
  ) : (
    <ArrayField name={fieldGroup}>
      {({ addWithInitialValue }) => (
        <>
          <ArrayField.Items>
            {({ name, remove, index }) => {
              multiLegCount = index + 2;
              return (
                <Fragment key={name}>
                  {isMultiLeg && (
                    <div className="section-title">
                      Leg {isEditProcess ? drive?.bookedRouteOrder : index + 1}
                      {index > 1 && !isEditProcess && (
                        <Button
                          className="btn-multileg-remove"
                          leftIcon={<div>&#215;</div>}
                          variant="icon"
                          onClick={remove}
                        />
                      )}
                    </div>
                  )}

                  <div className="field-group">
                    {showGoogleLocation && (
                      <>
                        <Input name="dropoffLat" hidden />
                        <Input name="dropoffLng" hidden />
                        <Input name="dropoffTown" hidden />
                        <Input name="pickupLat" hidden />
                        <Input name="pickupLng" hidden />
                        <Input name="pickupTown" hidden />
                        <GoogleLocationField
                          city={city}
                          country={`${country} [${countryCode}]`}
                          fieldGroup={name}
                          idx={index}
                          isMultiLeg
                          label={`${t('bookingDetails.pickupAddress')} (Google Maps)`}
                          name="pickupLocation"
                          required
                          onChange={handleLocationChange('pickup', index)}
                        />
                        <GoogleLocationField
                          city={city}
                          country={`${country} [${countryCode}]`}
                          fieldGroup={name}
                          idx={index}
                          isMultiLeg
                          label={`${t('bookingDetails.dropoffAddress')} (Google Maps)`}
                          name="dropoffLocation"
                          required
                          onChange={handleLocationChange('dropoff', index)}
                        />
                        <AddressField
                          idx={index}
                          isMultiLeg
                          label="Pick-up Address"
                          name="pickup"
                        />
                        <AddressField
                          idx={index}
                          isMultiLeg
                          label="Drop-off Address"
                          name="dropoff"
                        />
                      </>
                    )}

                    <PickupDateField fieldGroup={name} />
                    <PickupTimeField fieldGroup={name} />
                    {!showGoogleLocation && (
                      <>
                        <PickupTownField fieldGroup={name} />
                        <PickupLocationField fieldGroup={name} index={index} />
                      </>
                    )}

                    <DropoffDateField />
                    <DropoffTimeField fieldGroup={name} />
                    {!showGoogleLocation && (
                      <>
                        <DropoffTownField fieldGroup={name} />
                        <DropoffLocationField fieldGroup={name} index={index} />
                      </>
                    )}

                    <VehicleField options={vehicleOpt} />
                    <DriverField
                      driver={drive?.driver}
                      drivers={drivers}
                      options={driverOpt}
                      fieldGroup={name}
                      isEdit={isEditProcess}
                      sharedDrive={sharedDrive}
                      vehicles={vehicles}
                    />
                  </div>

                  <div className="row">
                    <CommentField label={t('planner.commentPax')} name="commentToPax" />
                    <CommentField label={t('planner.commentDriver')} name="commentToDriver" />
                  </div>
                </Fragment>
              );
            }}
          </ArrayField.Items>

          {isMultiLeg && !isEditProcess && multiLegCount <= multiLegLimit && (
            <div className="row">
              <Button
                className="btn-multileg-add"
                leftIcon={<Add />}
                text={t('planner.addLeg')}
                variant="primary"
                onClick={() => addWithInitialValue(initMultileg)}
              />
            </div>
          )}
        </>
      )}
    </ArrayField>
  );
};

export default TripStepFieldset;
