import type { FC } from 'react';
import { Purpose } from '@common/constants/logbook';
import { LBDriveDetails } from '@common/interfaces/LogbookAPI';
import { ExpansionPanel, InfoBlock, InfoBlockType } from '@components/logbook';
import { Clock } from '@assets/svg/icons/logbook';

interface AdditionalInfoProps {
  data: LBDriveDetails;
}

const AdditionalInfo: FC<AdditionalInfoProps> = ({ data }) => {
  const firstRowStyle = { marginRight: 24, height: 89, justifyContent: 'space-between', flex: 1 };

  return (
    <ExpansionPanel title="Additional info">
      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock style={firstRowStyle} type={InfoBlockType.title} title="Purpose">
          <p> {(data?.purpose && Purpose[data.purpose]) || 'N/A'}</p>
        </InfoBlock>

        <InfoBlock style={firstRowStyle} type={InfoBlockType.title} title="Flight time">
          <div className="row-centered">
            <Clock />
            {data?.flightArrivalTime && data?.flightArrivalTime.length > 0 ? (
              <p className="details-info-text-row">{data?.flightArrivalTime}</p>
            ) : (
              <p className="details-info-text-row">N/A</p>
            )}
          </div>
        </InfoBlock>

        <InfoBlock
          style={{ ...firstRowStyle, marginRight: 0 }}
          type={InfoBlockType.title}
          title="Flight number"
        >
          <p>{data?.flightNumber?.length ? data?.flightNumber : 'N/A'}</p>
        </InfoBlock>
      </div>

      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock
          style={{ ...firstRowStyle, marginRight: 0, height: undefined }}
          type={InfoBlockType.title}
          title="Remarks"
        >
          <p style={{ width: 650, overflowWrap: 'break-word' }}>
            {data.remarks && data.remarks.length > 0 ? data?.remarks : 'N/A'}
          </p>
        </InfoBlock>
      </div>
    </ExpansionPanel>
  );
};

export default AdditionalInfo;
