/* istanbul ignore file */
interface Errors {
  [key: string]: Record<string, unknown>;
}

export const getErrors = (errorObject: Errors, includeKey = false): string => {
  if (typeof errorObject === 'object') {
    const text: string[] = [];

    Object.keys(errorObject).forEach((key) => {
      const prefix = includeKey ? `${key}: ` : '';

      if (typeof errorObject[key] === 'string') {
        text.push(`${prefix}${errorObject[key]}`);
      } else if (typeof errorObject[key] === 'object') {
        text.push(`${prefix}${errorObject[key][0]}`);
      } else {
        text.push('Something went wrong on this page');
      }
    });

    return text.join('; ');
  }

  return 'Something went wrong on this page.';
};

export const getRecurringDriveErrorsText = (e: any): string[] => {
  const errorsList: string[] = [];

  e.response.data.errors.forEach((error: any) => {
    if (error) {
      errorsList.push(error);
    }
  });

  return errorsList;
};
