/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';

import { Button } from '@unbooking/ui-modules';
import '../../DriveForm.styles.scss';

const ButtonSubmit: FC = () => (
  <Button
    className="btn-submit"
    data-testid="planner-drive-form-btn-save"
    text={t('common.btnSave')}
    type="submit"
    variant="primary"
  />
);

export default ButtonSubmit;
