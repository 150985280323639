import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { useAuth } from '@unbooking/ui-auth';
import type { IAvailableFacilities, IAvailableFacilitiesApi } from '@common/interfaces';
import { useGlobalContext, useRepository } from '@context';
import { SessionStorageKeys } from '@common/constants';
import { StatusCode } from '@common/types';
import { Serializer } from '@common/utils';

const DO_URL = process.env.REACT_APP_DO_URL;

interface FacilityPermissionRes {
  facilities: IAvailableFacilities[];
  isLoading: boolean;
}

export default function useFacilityPermission(): FacilityPermissionRes {
  const { accountRepository } = useRepository();
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { setAgencyId, setAgencyName, setFacilityId } = useGlobalContext();
  const navigate = useNavigate();

  const [availableFacilities, setAvailableFacilities] = useState<IAvailableFacilities[]>([]);

  const redirectUrl = sessionStorage.getItem(SessionStorageKeys.REDIRECT_URL);
  const checkException = [DO_URL].some((e) => pathname?.startsWith(e!)) && !redirectUrl;

  const { isLoading } = useQuery(
    'get-available-facilities',
    () => accountRepository.getAvailableFacilities(),
    {
      enabled: isAuthenticated && checkException,
      onSuccess: (data: IAvailableFacilitiesApi[]) => {
        setAvailableFacilities(data.map(Serializer.formatAvailableFacilities));
      },
      onError: (error: AxiosError) => {
        if (error.response) {
          const { status } = error.response;
          if (status === StatusCode.ClientErrorForbidden) {
            navigate('/forbidden');
          }
        }
      },
    },
  );

  useEffect(() => {
    const agencyId = sessionStorage.getItem(SessionStorageKeys.AGENCY_ID)!;
    const facilityId = sessionStorage.getItem(SessionStorageKeys.FACILITY)!;
    const [, , , path, pathFacilityId, pathAgencyName, pathAgencyId] = pathname.split('/');

    if (path === 'facility') {
      if (!isAuthenticated) return navigate('/');

      if (
        pathAgencyId &&
        pathFacilityId &&
        (pathAgencyId !== agencyId || pathFacilityId !== facilityId)
      ) {
        sessionStorage.setItem(SessionStorageKeys.AGENCY, pathAgencyName);
        sessionStorage.setItem(SessionStorageKeys.AGENCY_ID, pathAgencyId);
        sessionStorage.setItem(SessionStorageKeys.FACILITY, pathFacilityId);
        setAgencyId(pathAgencyId);
        setAgencyName(pathAgencyName);
        setFacilityId(pathFacilityId);
      }
    }
  }, [isAuthenticated, navigate, pathname, setAgencyId, setAgencyName, setFacilityId]);

  return {
    facilities: availableFacilities,
    isLoading,
  };
}
