/* istanbul ignore file */
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Multistep, useFormState, useMultistepApi } from 'informed';
import { format } from 'date-fns';

import type { IDropdownOption } from '@ui-modules/types';
import { Drive, DriveFormState, DriveFormSteps, TransferType } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { Button } from '@components';
import { ArrowRight } from '@assets/svg/icons';
import { formatDateString } from '@common/utils';
import { findOption } from '../../../utils';
import '../../../styles/modal.scss';

interface StepProps {
  drive?: Drive;
  drivers: IDropdownOption[];
  vehicles: IDropdownOption[];
}

const ConfirmStep: FC<StepProps> = ({ drive, drivers, vehicles }) => {
  const { t } = useTranslation();
  const { setCurrent } = useMultistepApi();
  const { values } = useFormState() as unknown as { values: DriveFormState };
  const { driver, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicle } = drive || {};
  const data = values?.tripInfo?.driveList?.[0];
  const type =
    values?.tripInfo?.transferType === TransferType.Shuttle
      ? t('common.inTown')
      : t('common.transferTypeShuttle');

  return (
    <Multistep.Step step={DriveFormSteps.Confirm}>
      <section className="confirm">
        <div className="warning">
          {t('planner.warnShuttleChange')} {type}
        </div>

        {data && drive && (
          <ul className="recap">
            <li>
              <span>Pick-up: </span>
              {formatDateString(pickupDate!, DF.ApiDateAlt, DF.BookingInfoDate)}{' '}
              {formatDateString(pickupTime!, DF.ApiTimeFull, DF.ApiTime)} <ArrowRight />{' '}
              {format(data?.pickupDate!, DF.BookingInfoDate)}{' '}
              {format(data?.pickupTime!, DF.ApiTime)}
            </li>
            <li>
              <span>Drop-off: </span>
              {formatDateString(dropoffDate!, DF.ApiDateAlt, DF.BookingInfoDate)}{' '}
              {formatDateString(dropoffTime!, DF.ApiTimeFull, DF.ApiTime)} <ArrowRight />{' '}
              {format(data?.dropoffDate!, DF.BookingInfoDate)}{' '}
              {format(data?.dropoffTime!, DF.ApiTime)}
            </li>
            <li>
              <span>Driver: </span>
              {findOption(drivers!, driver!)?.label} <ArrowRight /> {data?.driver?.label}
            </li>
            <li>
              <span>Vehicle: </span>
              {findOption(vehicles!, vehicle!)?.label} <ArrowRight /> {data?.vehicle?.label}
            </li>
          </ul>
        )}

        <footer>
          <Button
            text={t('common.no')}
            variant="danger"
            onClick={() => setCurrent(DriveFormSteps.Trip)}
          />
          <Button text={t('common.yes')} type="submit" variant="submit" />
        </footer>
      </section>
    </Multistep.Step>
  );
};

export default ConfirmStep;
