/* istanbul ignore file */
import camelcaseKeys from 'camelcase-keys';
import { format, parseISO } from 'date-fns';
import type { IDropdownOption } from '@ui-modules/types';
import type {
  AgencyListApi,
  BFShuttleRoute,
  BFShuttleRouteApi,
  BookingCreateApi,
  SchemaChoiceApi,
} from '@common/interfaces';
import { BookingTripType } from '@common/interfaces';
import { DateFormat } from '@common/types';
import { BookingFormType, BookingType, FormSchemaType } from '../schema/default.schema';

class BFSerializer {
  private static async convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = (e) => reject(e);
    });
  }

  private static transformData(data: Record<string, any>): any {
    return camelcaseKeys(data, { deep: true });
  }

  public static formatAgencyOptions(data: AgencyListApi): IDropdownOption {
    const { shortName, uuid } = BFSerializer.transformData(data);
    return {
      label: shortName,
      value: uuid,
    };
  }

  public static formatChoices(data: SchemaChoiceApi): IDropdownOption {
    return {
      label: data.display_name,
      value: String(data.value),
    };
  }

  public static formatShuttleRoute(data: BFShuttleRouteApi): BFShuttleRoute {
    const { endDate, startDate, ...rest } = BFSerializer.transformData(data);
    return {
      ...rest,
      endDate: parseISO(endDate),
      startDate: parseISO(startDate),
    };
  }

  public static async mapFormDataToBookingCreate(data: FormSchemaType): Promise<BookingCreateApi> {
    const {
      addRecurring,
      agentDetails,
      bookingDetails,
      bookingExtra,
      bookingFormType,
      passengersInfo,
      recurring,
      routes,
      transferType,
      travellerDetails,
      typeOfTrip,
    } = data;

    const isBFColleague = bookingFormType === BookingFormType.Colleague;
    const isBFShuttle = transferType === BookingType.Shuttle;
    const details = isBFColleague ? travellerDetails : bookingDetails;
    const extra = isBFColleague ? travellerDetails : bookingExtra;

    const submitData = {
      booking_details: {
        agency_fk: details?.agency,
        email: details?.email?.toLowerCase(),
        first_name: details?.firstName,
        index_number: details?.indexNumber,
        last_name: details?.lastName,
        phone_number: details?.phoneNumber,
      },
      driver_detail: {
        passenger_infos: passengersInfo.map((pax) => ({
          agency: pax.agency,
          email: pax.email,
          first_name: pax.firstName,
          last_name: pax.lastName,
          phone_number: pax.phoneNumber,
          requesting_unit: pax.requestingUnit,
        })),
        routes: routes?.map((route) => {
          const { dropoffLat, dropoffLng, pickupLat, pickupLng } = route;
          const hasLatLng = dropoffLat && pickupLat;

          return {
            dropoff_location: route.dropoffLocExtra
              ? `${route.dropoffLocExtra} | ${route.dropoffLocation}`
              : route.dropoffLocation,
            dropoff_town: route.dropoffTown,
            pickup_date: format(route.pickupDate!, DateFormat.ApiDate),
            pickup_location: route.pickupLocExtra
              ? `${route.pickupLocExtra} | ${route.pickupLocation}`
              : route.pickupLocation,
            pickup_time: route.pickupTime,
            pickup_town: route.pickupTown,
            ...(hasLatLng && {
              dropoff_latitude: dropoffLat,
              dropoff_longitude: dropoffLng,
              pickup_latitude: pickupLat,
              pickup_longitude: pickupLng,
            }),
            ...(isBFShuttle && {
              dropoff_date: format(route.pickupDate!, DateFormat.ApiDate),
              dropoff_time: route.dropoffTime,
              scheduled_route_id: route.scheduledRouteId,
            }),
          };
        }),
        transfer_type: transferType,
        type_of_trip: typeOfTrip || BookingTripType.OneWay,
      },
      booking_extra: {
        budget_code: bookingExtra.budgetCode,
        manager_email: bookingExtra.managerEmail,
        n_of_passengers: bookingExtra.pax,
        remarks: bookingExtra.remarks,
        requesting_unit: extra?.requestingUnit,
        ...(bookingExtra.flightArrivalDepartureTime && {
          flight_arrival_departure_time: bookingExtra.flightArrivalDepartureTime,
        }),
        ...(bookingExtra.flightNumber && {
          flight_number: bookingExtra.flightNumber,
        }),
        ...(transferType !== BookingType.Airport && {
          purpose: bookingExtra.purpose,
        }),
      },
      is_booking_on_behalf_of_colleague: isBFColleague,
    } as unknown as BookingCreateApi;

    if (agentDetails) {
      submitData.agent_details = {
        booking_agent_email: agentDetails.email,
        booking_agent_first_name: agentDetails.firstName,
        booking_agent_last_name: agentDetails.lastName,
      };
    }

    if (bookingDetails.attachments?.length) {
      const files = await Promise.all(
        bookingDetails.attachments.map(BFSerializer.convertFileToBase64),
      );
      submitData.booking_details.attachments = files.filter(Boolean);
    }

    if (addRecurring) {
      const { recurringFrequency, recurringPeriod, recurringUntil } = recurring || {};
      const { pickupDate } = routes[0];

      submitData.recurring = {
        start_date: pickupDate! && format(new Date(pickupDate), DateFormat.ApiDate),
        end_date: format(new Date(recurringUntil!), DateFormat.ApiDate),
        frequency: +recurringPeriod!,
      };

      if (recurringPeriod === '3' && recurringFrequency) {
        const frequencyValue = +recurringFrequency;
        submitData.recurring.weekdays =
          frequencyValue === 6
            ? [...Array.from(Array(4).keys()), 6]
            : [...Array.from(Array(frequencyValue).keys())];
      }
    }

    return submitData;
  }
}

export default BFSerializer;
