import type { FC } from 'react';
import { ReactComponent as Cancel } from '@assets/svg/icons/logbook/Cancel.svg';
import './Tag.styles.scss';

type TagProps = { title: string; value: string; onCancel: (title: string) => void };

const Tag: FC<TagProps> = ({ title, value, onCancel }) => (
  <div className="tag">
    <p>
      {title.toUpperCase()}: {value}
    </p>
    <button className="tag-cancel" type="button" onClick={() => onCancel(title)}>
      <Cancel />
    </button>
  </div>
);

export default Tag;
