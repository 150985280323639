/* eslint-disable react/destructuring-assignment */
/* istanbul ignore file */
function Online(props: any) {
  return (
    <svg
      width={18}
      height={13}
      viewBox="0 0 18 13"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.013 7.153a.696.696 0 01-.548-.27.777.777 0 01.091-1.056c3.17-2.78 7.774-2.78 10.944 0a.777.777 0 01.091 1.056.689.689 0 01-1.003.097c-2.643-2.317-6.478-2.316-9.12 0a.69.69 0 01-.455.173z"
        fill={props.fill}
      />
      <path
        d="M16.538 4.49a.688.688 0 01-.471-.187C12.039.566 5.962.566 1.933 4.303a.688.688 0 01-1.005-.067.777.777 0 01.063-1.058c4.566-4.237 11.452-4.237 16.018 0a.778.778 0 01.063 1.058.696.696 0 01-.534.255zM11.479 9.823a.685.685 0 01-.412-.139 3.422 3.422 0 00-4.127 0 .69.69 0 01-.993-.177.776.776 0 01.168-1.046 4.79 4.79 0 015.777 0c.32.24.396.708.168 1.046a.701.701 0 01-.581.316zM9 12.74a.714.714 0 01-.506-.218.76.76 0 01-.206-.532.81.81 0 01.206-.533.72.72 0 011.012 0 .81.81 0 01.207.533.76.76 0 01-.207.532.714.714 0 01-.506.218z"
        fill={props.fill}
      />
    </svg>
  );
}

export default Online;
