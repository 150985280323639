import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Blood, Copy, DriverLicense, DriverPlaceholder, Edit, ReadOnly } from '@assets/svg/icons';
import { DriverType } from '@common/interfaces';
import { formatDateString } from '@common/utils';
import { DateFormat as DF } from '@common/types';
import { Button } from '@components';
import './Driver.styles.scss';

export interface DriverProps {
  driver: DriverType;
  isEditable?: boolean;
  isLoading?: boolean;
  onDriverEdit?: (driverId: string) => void;
  onDriverStatusChange?: (driver: DriverType) => void;
}

const Driver = ({
  driver,
  isEditable,
  isLoading,
  onDriverEdit,
  onDriverStatusChange,
}: DriverProps) => {
  const { t } = useTranslation();
  const { agency, bloodGroupDisplay, email, lastName, licenseNumber, name, phoneNumber, photo } =
    driver;
  const { deleted, sinceDate, untilDate } = driver.currentUnavailability || {};
  const fromDate = sinceDate && formatDateString(sinceDate, DF.ApiDate, DF.ApiDateAlt);
  const toDate = untilDate && formatDateString(untilDate, DF.ApiDate, DF.ApiDateAlt);

  const copyToClipboard = (str: string): void => {
    navigator.clipboard.writeText(str);
    toast.success('Copied to clipboard');
  };

  const getStatus = () => {
    if (fromDate && toDate) {
      return (
        <>
          {t('mobility.unavailable')}
          <br />
          {`${t('common.from')} ${fromDate} ${t('common.to')} ${toDate}`}
        </>
      );
    }

    if (deleted && fromDate) {
      return (
        <>
          {t('common.disable')}
          <br />
          {`${t('common.from')} ${fromDate}`}
        </>
      );
    }

    return null;
  };

  return (
    <div className="driver-item">
      <div className="driver-info">
        <div className="driver-photo">
          {photo ? (
            <img src={photo} alt={`${name} ${lastName}`} />
          ) : (
            <DriverPlaceholder data-testid="driver-photo-placeholder" />
          )}
          {getStatus() && <div className="driver-status">{getStatus()}</div>}
        </div>

        <div className="driver-details">
          <div className="driver-details-agency">
            {agency?.logo ? <img src={agency.logo} alt={agency.shortName} /> : agency?.shortName}
          </div>

          <div className="driver-details-info">
            <div className="driver-details-info-title">
              <div className="name">{`${name} ${lastName}`}</div>
              {driver.sharedAgenciesCount > 0 && (
                <div className="shared">{t('mobility.shared')}</div>
              )}
            </div>

            {email && (
              <Button
                className="contact"
                rightIcon={<Copy className="icon" />}
                text={email}
                variant="transparent"
                onClick={() => copyToClipboard(email)}
              />
            )}

            {phoneNumber && (
              <Button
                className="contact"
                rightIcon={<Copy className="icon" />}
                text={phoneNumber}
                variant="transparent"
                onClick={() => copyToClipboard(email)}
              />
            )}

            {bloodGroupDisplay && (
              <div className="blood">
                <Blood />
                {bloodGroupDisplay}
              </div>
            )}
          </div>
        </div>

        <div className="driver-license">
          <Button
            className="btn-edit"
            leftIcon={isEditable ? <Edit /> : <ReadOnly />}
            text={isEditable ? t('common.btnEdit') : t('common.btnView')}
            variant="icon"
            onClick={() => onDriverEdit?.(driver.id)}
          />

          <div className="driver-license-header">
            <DriverLicense />
            {t('mobility.driverLicense')}
          </div>

          <div className="driver-license-data">
            <div className="driver-license-number">
              <div className="label">{t('common.number')}</div>
              <div className="value">{licenseNumber}</div>
            </div>
            {driver?.licenseExpirationDate && (
              <div className="driver-license-expiration">
                <div className="label">{t('mobility.expirationDate')}</div>
                <div className="value">{driver?.licenseExpirationDate}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditable && (
        <div className="driver-ctrl">
          <Button
            className={`btn ${driver?.currentUnavailability ? 'btn-available' : 'btn-unavailable'}`}
            disabled={isLoading}
            text={
              driver?.currentUnavailability
                ? t('mobility.btnMarkAvailable')
                : t('mobility.btnMarkUnavailable')
            }
            variant="transparent"
            onClick={() => onDriverStatusChange?.(driver)}
          />
        </div>
      )}
    </div>
  );
};

export default Driver;
