import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { Serializer } from '@common/utils';
import { IDropdownOption } from '@ui-modules/types';
import { DriverType } from '@common/interfaces';

interface IDrivers {
  isLoading: boolean;
  driverOptions: IDropdownOption[];
  drivers: DriverType[];
}

const useDrivers = (): IDrivers => {
  const { mobilityRepository } = useRepository();

  const {
    facility: { agencyId },
    facilityId,
  } = useFacility();

  const [drivers, setDrivers] = useState<DriverType[]>([]);
  const [driverOptions, setDriverOptions] = useState<IDropdownOption[]>([]);

  const { data: driverData, isLoading } = useQuery(
    'get-drivers',
    () =>
      mobilityRepository.getDrivers(facilityId, {
        agencyId,
        limit: 500,
      }),
    { enabled: !!agencyId, refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (driverData) {
      setDrivers(driverData.results.map(Serializer.formatDriver));
    }
  }, [driverData, setDrivers]);

  useEffect(() => {
    if (drivers) {
      setDriverOptions(
        drivers.map(
          (d: DriverType): IDropdownOption => ({
            label: `${d.name} ${d.lastName}`,
            value: d.id.toString(),
          }),
        ),
      );
    }
  }, [drivers, setDriverOptions]);

  return { isLoading, drivers, driverOptions };
};

export default useDrivers;
