import { FeedbackParams, IFeedbackRepository, FeedbackUpdateApi } from '@common/interfaces';
import fileDownload from 'js-file-download';
import AxiosRepository from './axios.repository';

class FeedbackRepository extends AxiosRepository implements IFeedbackRepository {
  private buildParams(params?: FeedbackParams) {
    const searchParams = new URLSearchParams();

    if (params?.agencyId) searchParams.append('agency_id', params.agencyId);
    if (params?.createdAfter) searchParams.append('created_after', params.createdAfter);
    if (params?.createdBefore) searchParams.append('created_before', params.createdBefore);
    if (params?.limit) searchParams.append('limit', String(params.limit));
    if (params?.offset) searchParams.append('offset', String(params.offset));
    if (params?.passengerAgency) searchParams.append('passenger_agency', params.passengerAgency);
    if (params?.vehicleAgency) searchParams.append('vehicle_agency', params.vehicleAgency);
    if ('reviewed' in params!) searchParams.append('reviewed', params.reviewed ? 'True' : 'False');

    return searchParams;
  }

  async getFeedbacks(facility: string, params?: FeedbackParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(`/facilities/${facility}/planner/drives/feedbacks/?${query}`);
    return data;
  }

  async getLatestFeedbacks(facility: string, params?: FeedbackParams) {
    const query = this.buildParams(params);
    const { data } = await this.get(`/facilities/${facility}/latest-feedbacks/?${query}`);
    return data;
  }

  async exportFeedbacks(facility: string, params?: FeedbackParams) {
    const { data, headers } = await this.get(
      `/facilities/${facility}/planner/drives/feedbacks/export/`,
      { params: this.buildParams(params), responseType: 'blob' },
    );

    const filename = headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].split(';')[0]
      : '';

    fileDownload(new File([data], filename), filename);

    return data;
  }

  async updateFeedbackStatus(facility: string, feedbackId: string, payload: FeedbackUpdateApi) {
    const { data } = await this.put(
      `/facilities/${facility}/planner/drives/feedbacks/${feedbackId}/`,
      payload,
    );
    return data;
  }
}

export default FeedbackRepository;
