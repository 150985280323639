import type { FC } from 'react';
import type { DashboardFiguresItem } from '@common/interfaces';
import { Booking, Drive, Ride, TripsDetails, User } from '@assets/svg/icons';
import './FiguresSection.styles.scss';

interface FiguresProps {
  data?: DashboardFiguresItem;
}

const FiguresSection: FC<FiguresProps> = ({ data }) => (
  <section className="figures">
    <div>
      <h2>Number of actual passengers</h2>
      <p>
        <span className="icon">
          <User />
        </span>
        {data?.passengerCount}
      </p>
    </div>
    <div>
      <h2>Number of actual trips</h2>
      <p>
        <span className="icon">
          <Ride />
        </span>
        {data?.tripCount}
      </p>
    </div>
    <div>
      <h2>Number of bookings</h2>
      <p>
        <span className="icon">
          <Booking />
        </span>
        {data?.bookingCount}
      </p>
    </div>
    <div>
      <h2>Number of active vehicles today</h2>
      <p>
        <span className="icon">
          <TripsDetails />
        </span>
        {data?.vehicleCount}
      </p>
    </div>
    <div>
      <h2>Number of active drivers today</h2>
      <p>
        <span className="icon">
          <Drive />
        </span>
        {data?.driverCount}
      </p>
    </div>
  </section>
);

export default FiguresSection;
