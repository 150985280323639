import {
  DriveCreateExisted,
  DriveFormDataApi,
  DriveUpdateApi,
  DriveUpdate,
  IMobilityPlannerRepository,
  MobilityPlannerParams,
  ShuttleConfigUpdateApi,
} from '@common/interfaces';
import AxiosRepository from './axios.repository';

class MobilityPlannerRepository extends AxiosRepository implements IMobilityPlannerRepository {
  private buildParams(params?: MobilityPlannerParams) {
    const searchParams = new URLSearchParams();

    if (params?.agency) searchParams.append('agency', params.agency);
    if (params?.agencyId) searchParams.append('agency_id', params.agencyId);
    if (params?.date) searchParams.append('date', params.date);
    if (params?.isTemporary) searchParams.append('is_temporary', params.isTemporary);
    if (params?.skipShuttle) searchParams.append('skip_shuttle_check', 'true');

    return searchParams;
  }

  async cancelDrive(facility: string, driveId: string) {
    const { data } = await this.post(`/facilities/${facility}/planner/drives/${driveId}/cancel/`);

    return data;
  }

  async createDrive(facility: string, drive: DriveFormDataApi, params?: MobilityPlannerParams) {
    const query = this.buildParams(params);
    const { data } = await this.post(
      `/facilities/${facility}/planner/booking-form/?${query}`,
      drive,
    );
    return data;
  }

  async createDrives(
    facility: string,
    payload: DriveCreateExisted,
    params?: MobilityPlannerParams,
  ) {
    const query = this.buildParams(params);
    const { data } = await this.post(
      `/facilities/${facility}/planner/booking-form/existed/${payload.booking}/?${query}`,
      payload,
    );
    return data;
  }

  async getBookingRequestingUnits(facility: string, params?: MobilityPlannerParams) {
    const { data } = await this.get(`/facilities/${facility}/bookings/requesting-units/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  async getDrive(facility: string, driveId: string, params?: MobilityPlannerParams) {
    const { data } = await this.get(`/facilities/${facility}/planner/drives/${driveId}/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  async getDriveSchema(facility: string) {
    const { data } = await this.get(`/facilities/${facility}/planner/booking-form/`);

    return data;
  }

  async getDrivers(facility: string, params?: MobilityPlannerParams) {
    const { data } = await this.get(`/facilities/${facility}/planner/drivers/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  async getDrives(facility: string, params?: MobilityPlannerParams) {
    const isWeekly = params?.isWeek ? 'weekly/' : '';
    const url = `/facilities/${facility}/planner/drives/${isWeekly}`;
    const { data } = await this.get(url, { params: this.buildParams(params) });
    return data;
  }

  async getQuestWorkflows(facility: string, params?: MobilityPlannerParams) {
    const { data } = await this.get(`/facilities/${facility}/planner/drives/quest-workflows/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  async getShuttleEvents(facility: string, params?: MobilityPlannerParams) {
    const isWeekly = params?.isWeek ? 'weekly/' : '';
    const url = `/facilities/${facility}/planner/drives/shuttle-events/${isWeekly}`;
    const { data } = await this.get(url, { params: this.buildParams(params) });

    return data;
  }

  async getVehicles(facility: string, params?: MobilityPlannerParams) {
    const { data } = await this.get(`/facilities/${facility}/planner/vehicles/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  async updateDrive(facility: string, bookingId: string, drives: DriveUpdate[]) {
    const { data } = await this.patch(
      `/facilities/${facility}/planner/booking-form/processed/${bookingId}/`,
      { drives },
    );
    return data;
  }

  async updatePlannerDrive(facility: string, bookingId: string, drive: DriveUpdateApi) {
    const { data } = await this.patch(
      `/facilities/${facility}/planner/drives/${bookingId}/`,
      drive,
    );
    return data;
  }

  async updateShuttleConfig(facility: string, payload: ShuttleConfigUpdateApi) {
    const { data } = await this.patch(`/facilities/${facility}/planner/shuttle/config/`, payload);

    return data;
  }
}

export default MobilityPlannerRepository;
