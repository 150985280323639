import { type FC, useState, useEffect } from 'react';
import { FeedbackStatus, IStatusSelector } from '@common/interfaces';
import { DropdownCaret } from '@assets/svg/icons';
import './styles.scss';

interface IStatusSelectorComponent {
  controlledComponent?: boolean;
  status?: FeedbackStatus;
  onChange?: (status: FeedbackStatus) => void;
}

const options: IStatusSelector[] = [
  { value: 'not_processed', color: '#af2f39', label: 'To be processed', disabled: true },
  { value: 'under_process', label: 'Under process', color: '#a18b20' },
  { value: 'forwarded_for_action', label: 'Forward for action', color: '#ce821a' },
  { value: 'closed', label: 'Closed', color: '#005f37' },
];

const getOptionFromStatus = (status: FeedbackStatus) =>
  options.find((o: IStatusSelector) => o.value === status);

const StatusSelector: FC<IStatusSelectorComponent> = ({
  controlledComponent = true, // That param allow the component value be controlled from the parent
  status = 'not_processed',
  onChange,
}) => {
  const [currentStatus, setCurrentStatus] = useState<IStatusSelector | undefined>(
    getOptionFromStatus(status),
  );

  const [isOpen, setOpen] = useState<boolean>(false);

  const onStatusChange = (feedbackStatus: FeedbackStatus): void => {
    if (!controlledComponent) setCurrentStatus(getOptionFromStatus(feedbackStatus));
    setOpen(!isOpen);
    onChange?.(feedbackStatus);
  };

  useEffect(() => {
    setCurrentStatus(getOptionFromStatus(status));
  }, [status]);

  return (
    <div className="status-selector">
      <button
        className={`status-selector-btn ${isOpen ? 'open' : ''}`}
        style={{ backgroundColor: currentStatus?.color }}
        data-testid={`status-selector-btn-${currentStatus?.value}`}
        type="button"
        onClick={() => setOpen(!isOpen)}
      >
        <span className="status-selector-btn-label">{currentStatus?.label}</span>
        <span className="status-selector-btn-icon">
          <DropdownCaret />
        </span>
      </button>
      {isOpen ? (
        <div className="status-selector-dropdown">
          {options.map((item: IStatusSelector) => (
            <button
              className={status === item.value ? 'current' : ''}
              disabled={item.disabled || item.value === status}
              key={item.value}
              type="button"
              onClick={() => onStatusChange(item.value)}
            >
              {item.label}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default StatusSelector;
