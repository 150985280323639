import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Loader as Spinner } from '@components';
import { Loader } from '@googlemaps/js-api-loader';
import axios from 'axios';

const G_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const G_API_URL_GEO = 'https://maps.googleapis.com/maps/api/geocode/json';
const G_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID;

type LatLngLiteral = google.maps.LatLngLiteral;
type GoogleMapsProps = { defaultCity?: string; onSelectLocation: (value: LatLngLiteral) => void };

const GoogleMaps = ({ defaultCity, onSelectLocation }: GoogleMapsProps) => {
  const loader = useRef<Loader | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);

  const [position, setPosition] = useState<LatLngLiteral>({ lat: 0, lng: 0 });

  const addMarker = useCallback(async () => {
    if (mapRef.current) {
      loader.current?.importLibrary('marker').then(({ AdvancedMarkerElement }) => {
        if (!markerRef.current) {
          markerRef.current = new AdvancedMarkerElement({ position, map: mapRef.current });
        } else {
          markerRef.current.position = position;
        }
        mapRef.current?.setCenter(position);
      });
    }
  }, [position]);

  const getCoordinates = useCallback(
    async (city: string) => {
      const { data } = await axios.get(G_API_URL_GEO, {
        params: { address: encodeURIComponent(city), key: G_API_KEY },
      });

      if (data?.results?.length > 0) {
        const coords = data.results[0].geometry.location;
        setPosition(coords);
        onSelectLocation(coords);
      }
    },
    [onSelectLocation],
  );

  const initMap = useCallback(async () => {
    loader.current = new Loader({ apiKey: G_API_KEY ?? '', version: 'weekly' });
    const { Map } = await loader.current.importLibrary('maps');
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new Map(mapContainerRef.current, {
        center: position,
        zoom: 10,
        mapId: G_MAP_ID,
      });

      mapRef.current.addListener('click', (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
          const coords = { lat: event.latLng.lat(), lng: event.latLng.lng() };
          setPosition(coords);
          onSelectLocation(coords);
        }
      });

      if (defaultCity) getCoordinates(defaultCity);
    }
  }, [defaultCity, position, getCoordinates, onSelectLocation]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  useEffect(() => {
    addMarker();
  }, [addMarker]);

  return (
    <div id="map" ref={mapContainerRef} style={{ height: '400px', width: '100%' }}>
      <Spinner spinning={!mapRef.current} />
    </div>
  );
};

export default React.memo(GoogleMaps);
