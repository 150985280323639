/* istanbul ignore file */
import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

const PaxAgencyField = () => (
  <div className="field">
    <TextField
      className="input"
      label={`${t('common.agency')} *`}
      name="agency"
      validate={notEmptyValidator}
    />
  </div>
);

export default PaxAgencyField;
