import { useQuery } from 'react-query';
import { useRepository } from '@context';
import { ApiList, VehicleApi } from '@common/interfaces';
import { Serializer } from '@common/utils';

const useVehicleList = (
  agencyId: string,
  facilityId: string,
  pageSize: number,
  selectedPage: number,
  shared: boolean,
) => {
  const { mobilityRepository } = useRepository();

  return useQuery(
    ['get-vehicle-list', pageSize, selectedPage, shared],
    () =>
      mobilityRepository.getVehicles(
        facilityId,
        { agencyId, limit: pageSize, offset: (selectedPage - 1) * 10 },
        shared,
      ),
    {
      enabled: !!agencyId,
      select: (data: ApiList<VehicleApi>) => ({
        count: data?.count || 0,
        data: data?.results.map(Serializer.formatVehicle) || [],
      }),
    },
  );
};

export default useVehicleList;
