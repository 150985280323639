/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { charValidator, notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

const PaxNameField: FC = () => (
  <div className="field">
    <TextField
      className="input"
      label={`${t('common.name')} *`}
      name="firstName"
      validate={(value) => notEmptyValidator(value) || charValidator(value)}
    />
  </div>
);

export default PaxNameField;
