import { createContext, ReactNode, useMemo } from 'react';
import type { FieldValues, UseFormProps } from 'react-hook-form/dist/types';
import type { SubmitHandler, UseFormReturn, ValidationMode } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';
import '../styles/Form.styles.scss';

type FormContextType = {
  mode: keyof ValidationMode | undefined;
  schema: yup.ObjectSchema<yup.AnyObject> | undefined;
};

const defaultFormContext: FormContextType = {
  mode: 'onSubmit',
  schema: undefined,
};

export const FormContext = createContext<FormContextType>(defaultFormContext);

export type FormProps<TFieldValues extends FieldValues> = {
  children: ReactNode | ((methods: UseFormReturn<TFieldValues>) => ReactNode);
  className?: string;
  schema?: yup.ObjectSchema<any> | undefined;
  theme?: 'dark' | 'light';
  onSubmit: SubmitHandler<TFieldValues>;
};

export function Form<TFieldValues extends FieldValues>({
  children,
  className,
  mode,
  schema = yup.object({}),
  theme = 'light',
  resolver,
  onSubmit,
  ...props
}: Omit<UseFormProps<TFieldValues>, 'reValidateMode'> & FormProps<TFieldValues>) {
  const methods = useForm({
    ...props,
    mode,
    resolver: yupResolver(schema),
  });

  const contextValue = useMemo(() => ({ mode, schema }), [mode, schema]);

  return (
    <FormContext.Provider value={contextValue}>
      <FormProvider {...methods}>
        <form className={cn(`theme-${theme}`, className)} onSubmit={methods.handleSubmit(onSubmit)}>
          {typeof children === 'function' ? children(methods) : children}
        </form>
      </FormProvider>
    </FormContext.Provider>
  );
}

export default Form;
