import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainMenu } from '@unbooking/ui-modules';
import { Loader } from '@components';
import { useFacility } from '@common/hooks';
import { HistoryTime, Receipt, ServiceSettings, Vehicle } from '@assets/svg/icons';
import { DriverLogo } from '@assets/svg/logos';
import './styles.scss';

const FinancialsMenuPage: FC = () => {
  const navigate = useNavigate();
  const {
    agencyName,
    facility: { city, country, facilityName },
    isLoading,
  } = useFacility();
  const { t } = useTranslation();

  const menuButtons = [
    {
      className: 'btn-cost-recovery-report',
      icon: <Vehicle />,
      label: t('common.costRecoveryReport'),
      onClick: () => navigate('cost-recovery-report'),
    },
    {
      className: 'btn-create-invoice',
      icon: <Receipt />,
      label: t('invoice.createInvoice'),
      onClick: () => navigate('create-invoice-list'),
    },
    {
      className: 'btn-invoice-history',
      icon: <HistoryTime />,
      label: t('invoice.invoiceHistory'),
      onClick: () => navigate('history-invoice-list'),
    },
    {
      className: 'btn-service-cost',
      icon: <ServiceSettings />,
      label: t('common.serviceCostSettings'),
      onClick: () => navigate('service-cost-setting'),
    },
  ];

  return (
    <Loader color="white" spinning={isLoading}>
      <MainMenu
        logo={<DriverLogo />}
        menuButtons={menuButtons}
        title={
          city && country && !isLoading
            ? `${facilityName}, ${city}, ${country} | ${agencyName}`
            : ''
        }
      />
    </Loader>
  );
};

export default FinancialsMenuPage;
