import { FC, SyntheticEvent, useEffect, useState } from 'react';
import './styles.scss';

export interface CheckboxProps {
  className?: string;
  isChecked?: boolean;
  label: string;
  name: string;
  variant?: 'white' | 'blue';
  onChange?: (checked: boolean) => unknown;
}

const CheckboxCustom: FC<CheckboxProps> = ({
  className,
  isChecked,
  label,
  name,
  variant = 'white',
  onChange,
  ...rest
}) => {
  const [checked, setChecked] = useState(isChecked);

  const onChangeHandler = (event: SyntheticEvent): void => {
    setChecked(!checked);
    onChange?.(!checked);
    event.stopPropagation();
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className={`checkbox checkbox-${variant} ${className}`}>
      <label className="checkbox-label" htmlFor={name}>
        <div className="checkbox-wrap">
          <input
            checked={checked}
            data-testid="input-checkbox"
            id={name}
            name={name}
            type="checkbox"
            onChange={onChangeHandler}
            {...rest}
          />
          <span className="checkbox-checked" />
        </div>
        {label}
      </label>
    </div>
  );
};

export default CheckboxCustom;
