/* istanbul ignore file */
export { authConfig } from './auth';
export * from './api';
export * from './date';
export * from './filters';
export * from './logbook';

export const SUPPORT_EMAIL = process.env.REACT_APP_EMAIL_SUPPORT ?? 'global.accomodation@wfp.org';
export const TIME_INTERVAL = 15;

export const LocalStorageKeys = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  AUTH_TYPE: 'AUTH_TYPE',
  EMAIL: 'email',
  EXPIRATION: 'expiration',
  ID_TOKEN: 'ID_TOKEN',
  LOCATION: 'location',
  LOGIN_IN_PROGRESS: 'LOGIN_IN_PROGRESS',
  USER_EMAIL: 'USER_EMAIL',
  USER_NAME: 'USER_EMAIL',
  X_USER_TOKEN: 'X-User-Token',
};

export const SessionStorageKeys = {
  AGENCY: 'agency',
  AGENCY_ID: 'agencyId',
  FACILITY: 'facility',
  REDIRECT_URL: 'redirectUrl',
  SELECTED_LANGUAGE: 'selectedLang',
};
