/* istanbul ignore file */
import { TFunction } from 'i18next';
import { Depart, Flag, Fleet, Route } from '@assets/svg/icons';
import { BookingTripType } from '@common/interfaces';
import { BookingFormType, BookingType } from '../schema/default.schema';

export const getFormTypes = (t: TFunction) => [
  { label: t('bookingForm.tabSelf'), value: BookingFormType.Self },
  { label: t('bookingForm.tabColleague'), value: BookingFormType.Colleague },
];

export const getTripTypes = (isShuttle: boolean) => [
  { value: BookingTripType.OneWay, label: 'One way' },
  { value: BookingTripType.RoundTrip, label: 'Round trip' },
  ...(!isShuttle ? [{ value: BookingTripType.MultiLeg, label: 'Multi leg' }] : []),
];

export const getTransferTypes = (hasParam: boolean, hasRoute: boolean, t: TFunction) => {
  const createType = (Icon: React.ComponentType, labelKey: string, value: BookingType) => ({
    label: (
      <div className="transfer-type-label">
        <Icon />
        <span>{t(labelKey).toString()}</span>
      </div>
    ),
    value,
  });

  const transferTypes = [];

  if (hasParam) {
    transferTypes.push(createType(Fleet, 'common.transferTypeShuttle', BookingType.Shuttle));
  } else {
    transferTypes.push(
      createType(Depart, 'common.transferTypeAirport', BookingType.Airport),
      createType(Flag, 'common.inTown', BookingType.InTown),
      createType(Route, 'common.transferTypeOutTown', BookingType.OutTown),
    );

    if (hasRoute) {
      transferTypes.push(createType(Fleet, 'common.transferTypeShuttle', BookingType.Shuttle));
    }
  }

  return transferTypes;
};
