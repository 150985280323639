import { useTranslation } from 'react-i18next';
import { Button } from '@components';

const UNBH_HOME_LINK = process.env.REACT_APP_UNBH_HOME_LINK;

type SectionProps = { city: string; email: string; isoCode: string; title: JSX.Element };

const SuccessSection = ({ city, email, isoCode, title }: SectionProps) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const generateLink = (service: string) =>
    `${UNBH_HOME_LINK}/${lang}/explore/country/${isoCode?.toLowerCase()}/?service=${service}`;

  const linkClinic = generateLink('Covid+Testing,Instant+Clinics,UN+Clinic');
  const linkGuesthouse = generateLink('UN+Guesthouse');

  return (
    <section className="booking-success-section" data-testid="bf-success-section">
      <h1 className="booking-success-section-title " data-testid="bf-success-title">
        {title}
      </h1>
      <div className="booking-success-section-status">
        <h2 data-testid="bf-success-message">{t('bookingForm.msgSuccess')}.</h2>
        <p data-testid="bf-success-email">
          {t('bookingForm.msgCopySentToEmail')}: {email}
        </p>
      </div>

      <footer data-testid="bf-success-footer">
        <h3>More services in {city}</h3>

        <Button
          className="btn btn-guesthouse"
          link={linkGuesthouse}
          text="Book UN Guesthouse now"
          data-testid="bf-btn-guesthouse"
        />

        <Button className="btn btn-clinic" link={linkClinic} text="Book UN Clinics now" />

        <Button
          className="btn outline"
          link={`${UNBH_HOME_LINK}`}
          text={t('bookingForm.btnHomePage')}
          data-testid="bf-btn-home-page"
        />
      </footer>
    </section>
  );
};

export default SuccessSection;
