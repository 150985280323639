import type { ChangeEvent, FC } from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useDebounce } from 'usehooks-ts';
import { DatesRangeValue } from '@mantine/dates';

import { Pagination, Table } from '@unbooking/ui-modules';
import type {
  ApiList,
  BookingParams,
  BookingRouteReject,
  Bookings,
  BookingsApi,
  BulkAssignApi,
  BulkAssignBooking,
  BulkAssignBookingUpdate,
  FilterParams,
} from '@common/interfaces';
import { BForwardType, BulkAssignStatus } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { DateFormat } from '@common/types';
import { Serializer } from '@common/utils';
import { useRepository } from '@context';
import { Button, Dialog, Modal, Tabs } from '@components';
import { Check, Download, Search } from '@assets/svg/icons';
import { BulkAssign, ExportRange, RouteReject, RouteTable } from './components';
import type { ExportData, ForwardPayload } from './utils';
import { getColumns, getExpandedIcon } from './utils';
import Filters from './components/Filters/Filters';
import './BookingsPage.styles.scss';

const BookingsPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { bookingRepository } = useRepository();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city, country } = facility;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const tabs = useMemo(() => [t('booking.notProcessed'), t('booking.processed')], [t]);
  const search = useDebounce(searchQuery, 1000);

  const [bulkAssign, setBulkAssign] = useState<BulkAssignBooking[]>([]);
  const [filters, setFilters] = useState<FilterParams>();
  const [isBulkAssignModalOpen, setBulkAssignModalOpen] = useState<boolean>(false);
  const [isForwardProcessing, setForwardProcessing] = useState<boolean>(false);
  const [isModalExportOpen, setIsModalExportOpen] = useState<boolean>(false);
  const [period, setPeriod] = useState<DatesRangeValue>([null, null]);
  const [routeReject, setRouteReject] = useState<BookingRouteReject | null>(null);

  const [bookings, setBookings] = useState<Bookings[]>([]);
  const [ordering, setOrdering] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const bookingRequestParams = useMemo((): BookingParams => {
    const start = period[0] ? format(period[0], DateFormat.ApiDate) : undefined;
    const end = period[1] ? format(period[1], DateFormat.ApiDate) : start;

    return {
      agencyId,
      ordering,
      limit: pageSize,
      offset: !search ? (selectedPage - 1) * 10 : undefined,
      search: search?.trim(),
      show: selectedTab ? 'processed' : 'not-processed',
      filters,
      dateAfter: start,
      dateBefore: end,
    };
  }, [agencyId, ordering, pageSize, period, search, selectedPage, selectedTab, filters]);

  const { isFetching, refetch: getBookings } = useQuery(
    ['get-bookings', bookingRequestParams, ...[filters]],
    () => bookingRepository.getBookings(facilityId, bookingRequestParams),
    {
      enabled: !!agencyId,
      onSuccess(data: ApiList<BookingsApi>) {
        setBookings(data.results.map(Serializer.formatBooking));
        setTotalItems(data.count);
      },
    },
  );

  const { mutate: exportData, isLoading: fileLoad } = useMutation<unknown, AxiosError, ExportData>(
    'export-bookings',
    ({ ids, rangeEnd, rangeStart }) =>
      bookingRepository.exportBookings(facilityId, { agencyId, ids, rangeEnd, rangeStart }),
    {
      onError: (error: any) => {
        if (error) toast.error(t('common.msgErrorExport'));
      },
    },
  );

  const { mutate: forwardBooking } = useMutation<unknown, AxiosError, ForwardPayload>(
    'forward-booking',
    ({ agencyFk, bookingId }) => bookingRepository.forwardBooking(facilityId, agencyFk, bookingId),
    {
      onSuccess: () => {
        toast.success(t('bookingList.bookingForwarded'));
        getBookings();
        setForwardProcessing(false);
      },
      onError: (e) => {
        if (e.message) toast.error(e.message || t('common.errorMsgDefault'));
      },
    },
  );

  const exportCurrentView = (): void => {
    if (bookings?.length) exportData({ ids: bookings.map((i) => i.id).join(',') });
  };

  const onBulkAssignClick = (record: Bookings) => {
    const isRecordInBulk = bulkAssign.some((i) => i.id === record.id);

    if (isRecordInBulk) {
      setBulkAssign(bulkAssign.filter((i) => i.id !== record.id));
    } else {
      setBulkAssign([...bulkAssign, record] as BulkAssignBooking[]);
    }
  };

  const resetBulkAssign = () => {
    setBulkAssign([]);
    setBulkAssignModalOpen(false);
  };

  const { mutate: handleBulkAssign, isLoading: isBulkAssignProcessing } = useMutation(
    'bulk-assign-processing',
    (payload: BulkAssignBookingUpdate[]) =>
      bookingRepository.updateBookingsWithBulkAssign(facilityId, payload),
    {
      onSuccess: () => {
        const bookingsCount = bulkAssign.filter((i) => i.status === BulkAssignStatus.Ok).length;
        toast.success(`${bookingsCount} bookings processed automatically`);
        resetBulkAssign();
        getBookings();
      },
    },
  );

  const { mutate: verifyBulkAssign, isLoading: isVerifying } = useMutation(
    'verify-bulk-assign',
    () =>
      bookingRepository.verifyBulkAssign(facilityId, {
        agency: agencyId,
        booking_uuids: bulkAssign.map((i) => i.id),
      }),
    {
      onSuccess: (data: BulkAssignApi[]) => {
        const bulkVerifyResponse = data.map(Serializer.formatBookingBulkAssign);
        const bulkDataMap = new Map(bulkVerifyResponse.map((i) => [i.bookingId, i]));
        const bulkAssignData = bulkAssign.map((booking: BulkAssignBooking) => ({
          ...booking,
          ...bulkDataMap.get(booking.id),
        }));

        setBulkAssign(bulkAssignData);
        setBulkAssignModalOpen(true);
      },
      onError: (e: AxiosError) => {
        if (e.message) toast.error(e.message || t('common.errorMsgDefault'));
      },
    },
  );

  const { mutate: handleRouteReject, isLoading: isRouteRejection } = useMutation(
    'route-reject',
    (payload: BookingRouteReject) => bookingRepository.rejectRoute(facilityId, payload),
    {
      onSuccess: () => {
        toast.success('The booking route was rejected');
        setRouteReject(null);
        getBookings();
      },
    },
  );

  const expandedRowRender = (booking: Bookings) => (
    <RouteTable
      data={booking}
      key={booking.id}
      rejectRoute={(route: BookingRouteReject) => {
        setRouteReject(route);
      }}
    />
  );

  const columns = getColumns(
    bulkAssign,
    isForwardProcessing,
    selectedTab,
    navigate,
    t,
    forwardBooking,
    setForwardProcessing,
    onBulkAssignClick,
  );

  return (
    <section className="hbh-container booking-list">
      <header>
        <h1>
          {t('bookingList.title')} {city}, {country} | {agencyName}
        </h1>
        <div className="search">
          <Search className="search-icon" />
          <input
            className="search-input"
            name="search"
            placeholder={t('bookingList.inputSearch')}
            type="text"
            value={searchQuery}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(e.currentTarget.value);
            }}
          />
        </div>
      </header>

      <section className="booking-list-tabs">
        <Tabs
          selectedTab={selectedTab}
          tabs={tabs}
          onTabSelect={(tab) => {
            setSelectedPage(1);
            setSelectedTab(tabs.indexOf(tab));
          }}
        />
      </section>

      <section className="booking-list-panel">
        <Filters
          selectedTab={selectedTab}
          setActiveFilters={setFilters}
          setActivePeriod={setPeriod}
        />

        <div className="booking-list-export">
          <Button
            className="btn btn-export"
            leftIcon={<Download />}
            text={t('common.btnExport')}
            variant="filled"
            onClick={() => setIsModalExportOpen(true)}
          />
        </div>
      </section>

      <section className={`booking-list-${selectedTab ? 'processed' : 'not-processed'} table`}>
        <Table
          columns={columns}
          data={bookings}
          isLoading={isFetching}
          expandable={{
            expandIcon: getExpandedIcon,
            expandedRowRender,
            rowExpandable: (record: Bookings) => record.routes?.length > 0,
          }}
          rowClassName={(record: Bookings) =>
            record.forwardKey === BForwardType.Rejected ||
            record.forwardKey === BForwardType.ProcessedByOtherAgency
              ? 'row-disabled'
              : ''
          }
          variant="dark"
          onChangeColumnOrder={setOrdering}
        />

        <div className="pagination">
          {!isFetching && totalItems > 10 ? (
            <Pagination
              selectedPage={selectedPage}
              showJumper
              totalPages={totalItems}
              variant="dark"
              onPageChange={setSelectedPage}
              onPageSizeChange={setPageSize}
            />
          ) : null}
        </div>
      </section>

      <BulkAssign
        data={bulkAssign}
        isLoading={isBulkAssignProcessing}
        opened={isBulkAssignModalOpen}
        onClose={resetBulkAssign}
        onSubmit={handleBulkAssign}
      />

      <RouteReject
        isLoading={isRouteRejection}
        opened={!!routeReject}
        onClose={() => setRouteReject(null)}
        onSubmit={(data) => routeReject && handleRouteReject({ ...data, ...routeReject })}
      />

      <Dialog
        isLoading={isVerifying}
        opened={!!bulkAssign.length && !isBulkAssignModalOpen}
        position={{ bottom: 35, right: 25 }}
        variant="dark"
      >
        <div className="dialog-bulk-assignment">
          <h3>
            {bulkAssign.length} {t('bookingList.selectedShuttle')}
          </h3>

          <Button
            className="btn btn-process"
            leftIcon={<Check />}
            text="Process automatically"
            variant="filled"
            onClick={verifyBulkAssign}
          />

          <Button
            className="btn"
            text={t('common.btnCancel')}
            variant="outline"
            onClick={resetBulkAssign}
          />
        </div>
      </Dialog>

      <Modal
        showBtnClose
        variant="dark"
        visible={isModalExportOpen}
        onClose={() => setIsModalExportOpen(false)}
      >
        <section className="bookings-export">
          <p>{t('bookingList.msgModalExport')}</p>
          <Button
            className="btn btn-export"
            disabled={fileLoad}
            leftIcon={<Download />}
            text={t('bookingList.btnExportCurrentView')}
            variant="transparent"
            onClick={exportCurrentView}
          />
          <div className="bookings-export-sep">Or</div>
          <p>{t('bookingList.modalTitle')}</p>
          <ExportRange
            onClose={() => setIsModalExportOpen(false)}
            onSubmit={(data) =>
              exportData({
                rangeStart: data.start,
                rangeEnd: data.end,
              })
            }
          />
        </section>
      </Modal>
    </section>
  );
};

export default BookingsPage;
