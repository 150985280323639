/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';
import { TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

const PaxSurnameField: FC = () => (
  <div className="field">
    <TextField
      className="input"
      label={`${t('common.surname')} *`}
      name="lastName"
      validate={notEmptyValidator}
    />
  </div>
);

export default PaxSurnameField;
