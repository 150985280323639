import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, addWeeks, format, isBefore, isEqual } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

import type { IDropdownOption } from '@ui-modules/types';
import { DateFormat, DateInputFormat } from '@common/types';
import { InputDatePicker, RadioGroup, Select } from '@components';
import { BFDailyFrequency, BFRecurringPeriod } from '../types';

const getWeeklyRecurringDrives = (currentDate: Date, untilDate: Date) => {
  const weeklyDrives = [];
  let iterationDate = new Date(currentDate);

  while (isBefore(iterationDate, untilDate) || isEqual(iterationDate, untilDate)) {
    weeklyDrives.push(format(new Date(iterationDate), DateFormat.BookingInfoDate));
    iterationDate = addWeeks(iterationDate, 1);
  }

  return weeklyDrives;
};

type SectionProps = { ctrl: any; currentDate: Date; recurring: Record<string, any> | null };

const RecurringSection = ({ ctrl, currentDate, recurring }: SectionProps) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language === 'fr' ? fr : enUS;
  const { recurringFrequency, recurringPeriod, recurringUntil } = recurring || {};

  const recurringPeriodOptions = useMemo<IDropdownOption[]>(
    () => [
      {
        label: t('common.daily'),
        value: BFRecurringPeriod.Daily,
      },
      {
        label: t('common.weekly'),
        value: BFRecurringPeriod.Weekly,
      },
    ],
    [t],
  );

  const dailyFrequencyOptions = useMemo<IDropdownOption[]>(
    () => [
      {
        label: t('common.recurring.workingDaysFromMonday'),
        value: BFDailyFrequency.WorkingDaysFromMonday,
      },
      {
        label: t('common.recurring.workingDaysFromSunday'),
        value: BFDailyFrequency.WorkingDaysFromSunday,
      },
      {
        label: t('common.recurring.allDays'),
        value: BFDailyFrequency.AllDays,
      },
    ],
    [t],
  );

  const renderDailyRecurring = () => (
    <div className="recurring-info-item">
      {t('common.recurring.dailyRecurringDrive')} {t('common.from')}
      <em>{format(currentDate, DateFormat.BookingInfoDate, { locale })}</em> {t('common.to')}
      <em>{format(recurringUntil, DateFormat.BookingInfoDate, { locale })}</em>
      {recurringFrequency === BFDailyFrequency.WorkingDaysFromMonday
        ? `(${t('common.recurring.onlyWorkingDays')})`
        : ''}
    </div>
  );

  const renderWeeklyRecurring = () =>
    getWeeklyRecurringDrives(currentDate, recurringUntil).map((date, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="recurring-info-item" key={`${idx}-${date}`}>
        {t('common.recurring.weeklyRecurringDrive')} <i>{date}</i>
      </div>
    ));

  const renderRecurringInfo = () => {
    if (!recurringPeriod || !recurringUntil) return null;

    return (
      <div className="recurring-info">
        <h3>{t('common.recurring.drivesOverview')}:</h3>
        {recurringPeriod === BFRecurringPeriod.Daily
          ? renderDailyRecurring()
          : renderWeeklyRecurring()}
      </div>
    );
  };

  return (
    <fieldset data-testid="bf-recurring-details-fieldset">
      <h2>{t('common.recurring.addRecurring')}</h2>

      <Select
        name="recurring.recurringPeriod"
        className="field"
        control={ctrl}
        label={t('common.recurring.repeatDrive')}
        options={recurringPeriodOptions}
        required
        data-testid="bf-recurring-period"
      />

      <InputDatePicker
        name="recurring.recurringUntil"
        className="field"
        control={ctrl}
        hideOutsideDates
        label={t('common.recurring.repeatUntil')}
        minDate={addDays(currentDate, 1)}
        maxDate={addDays(currentDate, 29)}
        required
        valueFormat={DateInputFormat.DateByDots}
        data-testid="bf-recurring-until"
      />

      {recurringPeriod === BFRecurringPeriod.Daily && (
        <RadioGroup
          name="recurring.recurringFrequency"
          className="field wide"
          control={ctrl}
          defaultValue={dailyFrequencyOptions[0].value}
          label={t('common.recurring.dailyFrequency')}
          options={dailyFrequencyOptions}
          orientation="vertical"
          data-testid="bf-recurring-frequency"
        />
      )}

      {renderRecurringInfo()}
    </fieldset>
  );
};

export default RecurringSection;
