/* istanbul ignore file */
import { Globe, Pickup, Pin, Shuttle } from '@assets/svg/icons';

export enum FilterDict {
  BookingStates = 'state',
  PaxAgencies = 'passenger_agency',
  TransferType = 'transfer_type',
  TripTypes = 'type_of_trip',
}

export const FilterIcon: { [x: string]: JSX.Element } = {
  [FilterDict.BookingStates]: <Pin />,
  [FilterDict.PaxAgencies]: <Globe />,
  [FilterDict.TransferType]: <Shuttle />,
  [FilterDict.TripTypes]: <Pickup />,
};

export const FilterLabel: { [x: string]: string } = {
  [FilterDict.BookingStates]: 'All Statuses',
  [FilterDict.PaxAgencies]: 'All Agencies',
  [FilterDict.TransferType]: 'Transfer Type',
  [FilterDict.TripTypes]: 'Trip Type',
};
