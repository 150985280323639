import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Form } from 'informed';
import { format } from 'date-fns';

import { Button, Table } from '@unbooking/ui-modules';
import { Dropdown } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';

import { CURRENT_MONTH, CURRENT_YEAR, MONTHS, YEARS } from '@common/constants';
import { ICountryReport, ReportParams } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { formatSecToHours, Serializer } from '@common/utils';
import { PageTitle } from '@components';
import { useRepository } from '@context';
import { DateFormat } from '@common/types';
import { Download } from '@assets/svg/icons';
import './styles.scss';

interface FormStateProps {
  month: IDropdownOption;
  year: IDropdownOption;
}

const CountryReportPage: FC = () => {
  const {
    agencyName,
    facility: { agencyId, city, country },
    facilityId,
  } = useFacility();
  const { reportRepository } = useRepository();
  const { t } = useTranslation();

  const [reportData, setData] = useState<ICountryReport[]>([]);
  const [filterMonth, setFilterMonth] = useState<string>(CURRENT_MONTH.value);
  const [filterYear, setFilterYear] = useState<string>(CURRENT_YEAR.value);

  const {
    data: countryReport,
    isLoading: isCountryReportLoading,
    refetch: getCountryReport,
  } = useQuery(
    ['get-country-report', filterMonth, filterYear],
    () =>
      reportRepository.getCountryReport(facilityId, {
        agencyId,
        month: filterMonth,
        year: filterYear,
      }),
    { enabled: !!agencyId, refetchOnWindowFocus: false },
  );

  const { mutate: createCountryReport, isLoading: isCountryReportProcessing } = useMutation<
    unknown,
    AxiosError,
    string
  >('create-country-report', (date) => reportRepository.createCountryReport(facilityId, date), {
    onSuccess: () => {
      getCountryReport();
    },
    onError: (e) => {
      if (e) toast.error(t('common.errorMsgDefault'));
    },
  });

  const { mutate: exportCountryReport, isLoading: isExportProcessing } = useMutation(
    'export-country-report',
    (params: ReportParams) => reportRepository.exportCountryReport(facilityId, params),
    {
      onError: (error: any) => {
        if (error?.response) toast.error(t('common.msgErrorExport'));
      },
    },
  );

  useEffect(() => {
    if (countryReport) {
      const mappedCountryReport = countryReport?.map(Serializer.formatCountryReport);

      if (mappedCountryReport.length) {
        const data = mappedCountryReport.reduce((a: ICountryReport, b: ICountryReport) => ({
          agencyPax: a.agencyPax + b.agencyPax,
          airportDrives: a.airportDrives + b.airportDrives,
          bookingsCount: a.bookingsCount + b.bookingsCount,
          carpooledDrives: a.carpooledDrives + b.carpooledDrives,
          carpooledPax: a.carpooledPax + b.carpooledPax,
          co2Saving: a.co2Saving + b.co2Saving,
          drivers: a.drivers + b.drivers,
          drives: a.drives + b.drives,
          drivesDieselPetrol: a.drivesDieselPetrol + b.drivesDieselPetrol,
          drivesElectric: a.drivesElectric + b.drivesElectric,
          efficiencySaving: a.efficiencySaving + b.efficiencySaving,
          inTownDrives: a.inTownDrives + b.inTownDrives,
          outOfTownDrives: a.outOfTownDrives + b.outOfTownDrives,
          pax: a.pax + b.pax,
          rideSharedDrives: a.rideSharedDrives + b.rideSharedDrives,
          rideSharedPax: a.rideSharedPax + b.rideSharedPax,
          totalDrivesKm: a.totalDrivesKm + b.totalDrivesKm,
          totalDrivesTime: a.totalDrivesTime + b.totalDrivesTime,
          vehicles: a.vehicles + b.vehicles,
        }));

        const total = Object.fromEntries(
          Object.entries(data).map((item) => [item[0], parseFloat(Number(item[1]).toFixed(2))]),
        );

        const tableData = [{ ...total, locationName: 'Country Total' }, ...mappedCountryReport].map(
          (item: ICountryReport, idx: number) => ({
            ...item,
            id: idx,
          }),
        );

        setData(tableData);
      }
    }
  }, [countryReport]);

  const columns = [
    {
      dataIndex: 'locationName',
      key: 'locationName',
      title: '',
    },
    {
      dataIndex: 'vehicles',
      key: 'vehicles',
      title: t('report.fleet'),
    },
    {
      dataIndex: 'drivers',
      key: 'drivers',
      title: t('common.drivers'),
    },
    {
      dataIndex: 'drives',
      key: 'drives',
      title: t('common.trips'),
    },
    {
      dataIndex: 'drivesElectric',
      key: 'drivesElectric',
      title: t('common.tripsEV'),
    },
    {
      dataIndex: 'drivesDieselPetrol',
      key: 'drivesDieselPetrol',
      title: t('common.tripsDieselPetrol'),
    },
    {
      dataIndex: 'bookingsCount',
      key: 'bookingsCount',
      title: t('crumbs.bookings'),
    },
    {
      dataIndex: 'pax',
      key: 'pax',
      title: t('common.passengers'),
    },
    {
      key: 'agencyPax',
      title: t('report.agencyPaxVsNot'),
      render: (r: ICountryReport) => (
        <span>{r.pax && r.agencyPax ? Math.trunc((r.agencyPax / r.pax) * 100) : 'n.a.'}%</span>
      ),
    },
    {
      key: 'airportDrives',
      title: t('common.airport'),
      render: (r: ICountryReport) => (
        <span>
          {r.drives && r.airportDrives ? Math.trunc((r.airportDrives / r.drives) * 100) : 'n.a.'}%
        </span>
      ),
    },
    {
      key: 'inTownDrives',
      title: t('common.inTown'),
      render: (r: ICountryReport) => (
        <span>
          {r.drives && r.inTownDrives ? Math.trunc((r.inTownDrives / r.drives) * 100) : 'n.a.'}%
        </span>
      ),
    },
    {
      key: 'outOfTownDrives',
      title: t('common.outOfTown'),
      render: (r: ICountryReport) => (
        <span>
          {r.drives && r.outOfTownDrives
            ? Math.trunc((r.outOfTownDrives / r.drives) * 100)
            : 'n.a.'}
          %
        </span>
      ),
    },
    {
      dataIndex: 'carpooledDrives',
      key: 'carpooledDrives',
      title: t('report.carpooledTrip'),
    },
    {
      dataIndex: 'carpooledPax',
      key: 'carpooledPax',
      title: `${t('common.carpooled')} Pax`,
    },
    {
      dataIndex: 'rideSharedDrives',
      key: 'rideSharedDrives',
      title: t('report.ridesharedTrip'),
    },
    {
      dataIndex: 'rideSharedPax',
      key: 'rideSharedPax',
      title: `${t('common.rideshared')} pax`,
    },
    {
      dataIndex: 'co2Saving',
      key: 'co2Saving',
      title: `${t('report.co2Saving')} (Kg)`,
    },
    {
      dataIndex: 'efficiencySaving',
      key: 'efficiencySaving',
      title: `${t('report.efficiencySaving')} $`,
    },
    {
      dataIndex: 'totalDrivesKm',
      key: 'totalDrivesKm',
      title: `${t('common.total')} KM`,
    },
    {
      dataIndex: 'totalDrivesTime',
      key: 'totalDrivesTime',
      title: t('report.totalTime'),
      render: (r: number) => <span>{r ? formatSecToHours(r) : '00:00'}</span>,
    },
  ];

  return (
    <section className="hbh-container country-report">
      <PageTitle
        title={`${city}, ${country} ${t('report.countryReporting')} | ${agencyName}`}
        bottomLine
        tools={
          <Button
            className="btn btn-export"
            disabled={isExportProcessing}
            form="report_filters_form"
            icon={<Download />}
            text={t('common.btnExportData')}
            type="submit"
            variant="submit"
          />
        }
      />

      <div className="country-report-container">
        <div className="row">
          <Form
            className="country-report-form"
            id="report_filters_form"
            onSubmit={(formState: Record<string, unknown>) => {
              const state = { ...(formState.values as FormStateProps) };

              exportCountryReport({
                agencyId,
                month: state.month.value,
                year: state.year.value,
              });
            }}
          >
            <div className="filters">
              <div className="field month" data-testid="report-filter-month">
                <Dropdown
                  defaultValue={CURRENT_MONTH}
                  label={t('common.month')}
                  name="month"
                  options={MONTHS}
                  onChange={({ value }: IDropdownOption) => setFilterMonth(value)}
                />
              </div>

              <div className="field year" data-testid="report-filter-year">
                <Dropdown
                  defaultValue={CURRENT_YEAR}
                  label={t('common.year')}
                  name="year"
                  options={YEARS}
                  onChange={({ value }: IDropdownOption) => setFilterYear(value)}
                />
              </div>

              <Button
                className="btn btn-calc-report"
                text={t('common.btnCalculateNow')}
                onClick={() =>
                  createCountryReport(
                    format(new Date(+filterYear, +filterMonth - 1, 1), DateFormat.ApiDate),
                  )
                }
              />
            </div>
          </Form>
        </div>

        <div className="country-report-table">
          <Table
            columns={columns}
            data={reportData}
            isLoading={isCountryReportLoading || isCountryReportProcessing}
            variant="light"
          />
        </div>
      </div>
    </section>
  );
};

export default CountryReportPage;
