import { LocalStorageKeys } from '@common/constants';
import { AgencyIcons } from '@common/utils';

type UseLocationReturnType = {
  location: string;
  Logo: any;
};

export default function useHBHLocation(): UseLocationReturnType {
  const rowLocation = localStorage.getItem(LocalStorageKeys.LOCATION)?.split('|');
  const location = rowLocation ? `${rowLocation[2]}, ${rowLocation[1]}` : '';
  const agency = rowLocation && rowLocation[0];
  const Logo = AgencyIcons.icon(agency);
  return { location, Logo };
}
