import { type FC, Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Loader, PageTitle } from '@components';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { DriveCostsType } from '@common/interfaces';
import { Serializer } from '@common/utils';
import './ServiceCostSettingsPage.styles.scss';

const CostItem: FC<{ label: string; value?: number }> = ({ label, value }) => (
  <li>
    <div className="label">{label}</div>
    <div className="value">{value ?? '-'}</div>
  </li>
);

const ServiceCostSettingsPage: FC = () => {
  const { t } = useTranslation();
  const { financialRepo } = useRepository();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city, country } = facility;

  const [driveCosts, setDriveCosts] = useState<DriveCostsType | null>(null);

  const { isLoading } = useQuery(
    'get-drive-costs',
    () => financialRepo.getDriveCosts(facilityId, { agencyId }),
    {
      enabled: !!agencyId,
      onSuccess: (data) => {
        if (data) setDriveCosts(Serializer.formatDriveCosts(data));
      },
    },
  );

  const isHourEqual = driveCosts ? driveCosts.diesel.perHour === driveCosts.petrol.perHour : false;
  const isKmEqual = driveCosts ? driveCosts.diesel.perKm === driveCosts.petrol.perKm : false;

  return (
    <section className="service-cost-settings-page">
      <PageTitle
        title={`${t('mobility.serviceCostSettingsTitle')} ${city}, ${country} | ${agencyName}`}
        bottomLine
      />

      <Loader fullScreen spinning={isLoading} />

      <div className="drive-costs">
        {driveCosts ? (
          <ul>
            {isHourEqual ? (
              <CostItem
                label="Internal Combustion Engine (per hour)"
                value={driveCosts.diesel.perHour}
              />
            ) : (
              <>
                <CostItem label="Diesel (per hour)" value={driveCosts.diesel.perHour} />
                <CostItem label="Petrol (per hour)" value={driveCosts.petrol.perHour} />
              </>
            )}

            {isKmEqual ? (
              <CostItem
                label="Internal Combustion Engine (per km)"
                value={driveCosts.diesel.perKm}
              />
            ) : (
              <>
                <CostItem label="Diesel (per km)" value={driveCosts.diesel.perKm} />
                <CostItem label="Petrol (per km)" value={driveCosts.petrol.perKm} />
              </>
            )}

            <CostItem label="Electric (per hour)" value={driveCosts.electric.perHour} />
            <CostItem label="Electric (per km)" value={driveCosts.electric.perKm} />
          </ul>
        ) : (
          <div className="drive-costs-empty">{t('mobility.noCostSettings')}</div>
        )}
      </div>
    </section>
  );
};

export default ServiceCostSettingsPage;
