/* istanbul ignore file */
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useCallback, useMemo } from 'react';
import { t } from 'i18next';
import { useFieldState, useFormApi } from 'informed';
import { Dropdown } from '@ui-modules/informed';
import type { IDropdownOption as Option } from '@ui-modules/types';
import type { PlDriver, PlResource, PlVehicle } from '@common/interfaces';
import { notEmptyValidator } from '@common/utils';
import '../../BookingPanel.styles.scss';

type FieldProps = {
  disabled?: boolean;
  drivers?: PlDriver[];
  leg: number;
  resource?: [PlResource | null, Dispatch<SetStateAction<PlResource | null>>];
  value?: string;
  vehicle?: string;
  vehicles: PlVehicle[];
  onChange?: (value: Option) => void;
};

const VehicleField = ({
  disabled,
  drivers,
  leg,
  resource = [null, () => {}],
  value,
  vehicle,
  vehicles,
  onChange,
}: FieldProps) => {
  const formApi = useFormApi();
  const { value: driver } = useFieldState(`${leg}.driver`);

  const options = useMemo(
    () => vehicles.map(({ id, title }: PlVehicle): Option => ({ label: title, value: id })),
    [vehicles],
  );
  const getOption = useCallback(
    (val: string) => options.find((i: Option) => i.value === val),
    [options],
  );
  const [scheduledResource, setScheduledResource] = resource;

  useEffect(() => {
    if (scheduledResource?.vehicleId) {
      formApi.setValue(`${leg}.vehicle`, getOption(scheduledResource?.vehicleId!));
      setScheduledResource(null);
      return;
    }

    if (value) formApi.setValue(`${leg}.vehicle`, getOption(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leg, options, scheduledResource, value]);

  useEffect(() => {
    const selectedDriver = drivers?.find((v: PlDriver) => v.id === (driver as Option)?.value);

    if (selectedDriver?.preferredVehicle) {
      const preferredDriver = getOption(selectedDriver?.preferredVehicle);

      if (preferredDriver) formApi.setValue(`${leg}.vehicle`, preferredDriver);
    }
  }, [driver, drivers, formApi, getOption, leg]);

  return (
    <div className="field" data-testid={`planner-booking-panel-vehicle-${leg}`}>
      <Dropdown
        className="dropdown"
        defaultValue={getOption(vehicle!)}
        isDisabled={disabled}
        label={t('mobility.selectVehicle')}
        name={`${leg}.vehicle`}
        options={options}
        validate={notEmptyValidator}
        onChange={onChange}
      />
    </div>
  );
};

export default VehicleField;
