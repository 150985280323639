/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { addDays, addMinutes, isAfter, isSameDay, isSameMinute, parse } from 'date-fns';
import { t } from 'i18next';
import { useFormApi } from 'informed';
import { DateField } from '@ui-modules/informed';
import { TIME_INTERVAL } from '@common/constants';
import type { PlAcceptDrive } from '@common/interfaces';
import { useFieldState } from '@common/hooks';
import { DateFormat as DF } from '@common/types';
import { getFullDate } from '@common/utils';
import '../../BookingPanel.styles.scss';

type FieldProps = {
  acceptDrives?: PlAcceptDrive[];
  disabled?: boolean;
  leg: number;
  value: string;
  onChange?: (value: Date | string) => void;
};

const PickupTimeField = ({ acceptDrives, disabled, leg, value, onChange }: FieldProps) => {
  const formApi = useFormApi();
  const { value: dropoffDate } = useFieldState<Date>(`${leg}.dropoffDate`);
  const { value: dropoffTime } = useFieldState<Date>(`${leg}.dropoffTime`);
  const { value: pickupDate } = useFieldState<Date>(`${leg}.pickupDate`);

  const [maxTime, setMaxTime] = useState<Date | undefined>();
  const [minTime, setMinTime] = useState<Date | undefined>();

  useEffect(() => {
    if (acceptDrives?.length && leg > 1) {
      const prevData = acceptDrives[leg - 2];
      const isSameDate = pickupDate
        ? isSameDay(parse(prevData.dropoffDate, DF.ApiDate, new Date()), pickupDate)
        : false;

      if (isSameDate) {
        const prevTime = parse(prevData.dropoffTime, DF.ApiTime, new Date());
        setMaxTime(parse('23:45', DF.ApiTime, new Date()));
        setMinTime(prevTime);

        if (isAfter(prevTime, parse(value, DF.ApiTime, new Date()))) {
          formApi.setValue(`${leg}.pickupTime`, prevTime);

          if (isAfter(prevTime, dropoffTime) || isSameMinute(prevTime, dropoffTime)) {
            formApi.setValue(`${leg}.dropoffTime`, addMinutes(prevTime, TIME_INTERVAL));
          }
        }
      } else {
        setMaxTime(undefined);
        setMinTime(undefined);
      }
    }

    if (acceptDrives && value) {
      const isScheduled = acceptDrives?.[leg - 1].isScheduled;
      if (isScheduled) formApi.setValue(`${leg}.pickupTime`, parse(value, DF.ApiTime, new Date()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptDrives, formApi, leg, pickupDate, value]);

  return (
    <div className="field">
      <DateField
        className="input"
        dateFormat="HH:mm"
        defaultValue={value ? parse(value, DF.ApiTime, new Date()) : undefined}
        disabled={disabled}
        label={t('bookingDetails.pickupTime')}
        maxTime={maxTime}
        minTime={minTime}
        name={`${leg}.pickupTime`}
        showTimeSelect
        showTimeSelectOnly
        timeCaption={undefined}
        timeFormat="HH:mm"
        timeIntervals={TIME_INTERVAL}
        onChange={(val: Date | string) => {
          const isSameDate = isSameDay(pickupDate, dropoffDate);
          const pickupTime = val as Date;

          if (
            isSameDate &&
            (isAfter(pickupTime, dropoffTime) || isSameMinute(pickupTime, dropoffTime))
          ) {
            const pickupFullDate = getFullDate(pickupDate, pickupTime);
            const dropoffFullDate = getFullDate(dropoffDate, dropoffTime);

            // set dropoffDate
            if (
              isAfter(pickupFullDate, dropoffFullDate) &&
              isSameMinute(pickupTime, parse('23:45', DF.ApiTime, new Date()))
            ) {
              formApi.setValue(`${leg}.dropoffDate`, addDays(pickupDate, 1));
            }

            // set dropoffTime
            formApi.setValue(`${leg}.dropoffTime`, addMinutes(pickupTime, TIME_INTERVAL));
          }

          onChange?.(pickupTime);
        }}
      />
    </div>
  );
};

export default PickupTimeField;
