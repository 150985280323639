import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { BookingRouteReject } from '@common/interfaces';
import { Button, InputText, Modal } from '@components';
import './RouteReject.styles.scss';

type FormSchema = { rejectComment: string };

type RouteRejectProps = {
  isLoading: boolean;
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<BookingRouteReject>) => void;
};

const RouteReject = ({ isLoading, opened, onClose, onSubmit }: RouteRejectProps) => {
  const { t } = useTranslation();

  const handleFormSubmit = (data: FormSchema) => {
    onSubmit(data);
  };

  const { control, handleSubmit, reset } = useForm<FormSchema>({
    defaultValues: { rejectComment: '' },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!opened) reset({ rejectComment: '' });
  }, [opened, reset]);

  return (
    <Modal
      className={cn('modal-route-reject', { loading: isLoading })}
      showBtnClose
      title={t('bookingList.rejectModalTitle')}
      variant="dark"
      visible={opened}
      onClose={onClose}
    >
      <section>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <InputText
            name="rejectComment"
            control={control}
            label={t('bookingList.rejectComment')}
          />

          <footer>
            <Button
              className="btn"
              text={t('common.btnCancel')}
              variant="outline"
              onClick={onClose}
            />
            <Button
              className="btn btn-reject"
              text={t('common.btnConfirm')}
              type="submit"
              variant="filled"
            />
          </footer>
        </form>
      </section>
    </Modal>
  );
};

export default RouteReject;
