/* eslint-disable react/destructuring-assignment */
import type { FC } from 'react';
import Online from '@assets/svg/icons/logbook/Online';
import Offline from '@assets/svg/icons/logbook/Offline';
import { ReactComponent as Like } from '@assets/svg/icons/logbook/Like.svg';
import { ReactComponent as DisLike } from '@assets/svg/icons/logbook/Dislike.svg';
import './ClientSignature.styles.scss';

type ClientSignatureProps = {
  data: string;
};

type ClientSignatureLabelProps = {
  label: string;
};

export const ActiveSignature: React.FC<ClientSignatureLabelProps> = (
  props: ClientSignatureLabelProps,
) => (
  <div className="client-signature-active" data-testid="client-signature-active">
    <Like fill="#E0E8ED" />
    <p className="client-signature-text">{props.label}</p>
  </div>
);

export const InActiveSignature: React.FC<ClientSignatureLabelProps> = (
  props: ClientSignatureLabelProps,
) => (
  <div className="client-signature-inactive" data-testid="client-signature-inactive">
    <DisLike />
    <p className="client-signature-text">{props.label}</p>
  </div>
);

export const ClientSignature: FC<ClientSignatureProps> = ({ data }) => {
  const [clientSignature, isOnline, isOffline, offlineSignature] = data.split('|');
  const nullUndefinedNullToBool = (value: string) => !(value === 'null' || value === 'undefined');

  return (
    <div style={{ display: 'flex' }}>
      <div className="client-signature-container">
        {clientSignature || isOffline === 'true' || offlineSignature ? (
          <>
            <ActiveSignature label="Yes" />
            <div style={{ display: 'flex' }}>
              {nullUndefinedNullToBool(offlineSignature) &&
                !nullUndefinedNullToBool(clientSignature) && (
                  <p className="client-signature-date">{offlineSignature}</p>
                )}
              {nullUndefinedNullToBool(clientSignature) && (
                <p className="client-signature-date">{clientSignature.split('-')[1]}</p>
              )}
            </div>
          </>
        ) : (
          <InActiveSignature label="No" />
        )}
      </div>
      <div
        style={{
          marginTop: 4,
          marginRight: 4,
          marginLeft: 8,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 35,
        }}
      >
        <Online fill={isOnline === 'true' ? '#3C8545' : '#677782'} />
        <Offline fill={isOffline === 'true' ? '#3C8545' : '#677782'} />
      </div>
    </div>
  );
};
