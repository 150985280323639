/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';
import { useFormApi } from 'informed';
import { TextField } from '@ui-modules/informed';
import { DriveFormSteps } from '@common/interfaces';
import { emailValidator } from '@common/utils';
import '../../DriveForm.styles.scss';

const validateEmailUnique = (data: Record<string, unknown>[] | undefined, value: string) => {
  if (data && data.length > 1) {
    const isEmailUnique = data.some((i) => i.email !== value);

    if (!isEmailUnique) {
      return 'Email must be unique for each passenger';
    }
  }

  return undefined;
};

const PaxEmailField: FC = () => {
  const formApi = useFormApi();

  return (
    <div className="field">
      <TextField
        className="input"
        label={`${t('common.email')} *`}
        name="email"
        validate={(value) => {
          const formState = formApi.getFormState().values as any;
          const passengersInfo = formState?.[DriveFormSteps.Main]?.passengersInfo;

          const emailError = emailValidator(value);
          const uniqueEmailError = validateEmailUnique(passengersInfo, value as string);

          if (emailError) {
            return emailError;
          }

          if (uniqueEmailError) {
            return uniqueEmailError;
          }
        }}
      />
    </div>
  );
};

export default PaxEmailField;
