import type { DashboardDriveApi } from '@common/interfaces';
import { ChartDataItem } from './types';

const getLabel = (label: string): string => label.split(/,\s+/)[0];

export const prepareDriveData = (
  data: DashboardDriveApi[],
): { drives: ChartDataItem[]; mileage: ChartDataItem[] } => {
  const drives = data.map((i) => ({ label: getLabel(i.label), value: i.total_drives }));
  const mileage = data.map((i) => ({
    label: getLabel(i.label),
    value: parseFloat(i.total_mileage),
  }));

  return { drives, mileage };
};
