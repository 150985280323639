import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format } from 'date-fns';

import { IDropdownOption } from '@ui-modules/types';
import { DriverPlaceholder } from '@assets/svg/icons';
import { useRepository } from '@context';
import { useFacility, useVehicles } from '@common/hooks';
import { Serializer } from '@common/utils';
import {
  DriverCreate,
  DriverDetails,
  DriverDetailsApi,
  DriverUpdate,
  LocationApi,
} from '@common/interfaces';
import { DateFormat } from '@common/types';
import { Button, Field, Form, Loader } from '@components';
import { DriverFormSchema } from '@schemas';
import { bloodGroupOptions } from '@pages/DriverListPage/utils';
import './DriverEdit.styles.scss';

export interface FormState {
  bloodGroup: string;
  city: string;
  country: string;
  email: string;
  lastName: string;
  licenseExpirationDate: Date;
  licenseNumber: string;
  name: string;
  phoneNumber: string;
  preferredVehicle: string | null;
  sharedLocations: string[];
}

export interface DriverEditProps {
  driverId: string | null;
  isLoading: boolean;
  readOnly?: boolean;
  createDriver: (data: DriverCreate) => void;
  updateDriver: ({ driverId, data }: { driverId: string; data: DriverUpdate }) => void;
}

const DriverEdit = ({
  driverId,
  isLoading,
  readOnly = false,
  createDriver,
  updateDriver,
}: DriverEditProps) => {
  const { t } = useTranslation();
  const { mobilityRepository } = useRepository();
  const { vehicleOptions } = useVehicles();
  const { agencyName, facility, facilityId: fid } = useFacility();
  const { agencyId, city, country } = facility;
  const isEditProcess = !!driverId;

  const [details, setDetails] = useState<DriverDetails | null>(null);
  const [sharedAvailableLocations, setSharedAvailableLocations] = useState<IDropdownOption[]>();

  const { isFetching: isDriverDetailsLoading } = useQuery(
    'get-driver-details',
    () => mobilityRepository.getDriverDetails(fid, driverId!, { agencyId }),
    {
      enabled: isEditProcess,
      onSuccess: (data: DriverDetailsApi) => {
        setDetails(Serializer.formatDriverDetails(data));
      },
      onError: (error: AxiosError) => {
        if (error.message) toast.error(error.message || t('common.errorMessageDefault'));
      },
    },
  );

  useQuery('get-shared-locations', () => mobilityRepository.getSharedLocations(fid, { agencyId }), {
    enabled: !isEditProcess,
    onSuccess: (data: LocationApi[]) => {
      setSharedAvailableLocations(data?.map(Serializer.formatSharedLocations));
    },
  });

  const handleSubmit = useCallback(
    async ({
      bloodGroup,
      email,
      lastName,
      licenseExpirationDate,
      licenseNumber,
      name,
      phoneNumber,
      preferredVehicle,
      sharedLocations,
    }: FormState) => {
      const data = {
        email,
        last_name: lastName,
        license_expiration_date: licenseExpirationDate
          ? format(licenseExpirationDate, DateFormat.ApiDate)
          : null,
        license_number: licenseNumber || null,
        name,
        phone_number: phoneNumber,
        preferred_vehicle: preferredVehicle || null,
        shared_locations: (sharedLocations || [])?.map(Serializer.mapSharedLocationToUpdate),
        ...(bloodGroup ? { blood_group: Number(bloodGroup) } : {}),
      };

      if (isEditProcess) {
        updateDriver({ driverId: details?.id!, data });
      } else {
        createDriver({ agency: agencyId, ...data });
      }
    },
    [agencyId, createDriver, details?.id, isEditProcess, updateDriver],
  );

  const {
    bloodGroupDisplay,
    lastModifiedDate,
    lastName,
    locations,
    name,
    photo,
    sharedLocationOptions,
    sharedLocations,
  } = details || {};

  const initFormData = isLoading
    ? {}
    : {
        bloodGroup: details?.bloodGroup || '',
        email: isEditProcess ? details?.email : '',
        name: isEditProcess ? details?.name : '',
        lastName: isEditProcess ? details?.lastName : '',
        licenseExpirationDate: isEditProcess ? details?.licenseExpirationDate : undefined,
        licenseNumber: isEditProcess ? details?.licenseNumber : '',
        phoneNumber: isEditProcess ? details?.phoneNumber : '',
        preferredVehicle: isEditProcess ? details?.preferredVehicle?.value : '',
        sharedLocations: isEditProcess ? details?.sharedLocations : [],
      };

  const schema = useMemo(() => DriverFormSchema(), []);

  const ownedAgency = locations?.length ? locations[0].agency : agencyName;
  const ownedCity = locations?.length ? locations[0].city : city;
  const ownedCountry = locations?.length ? locations[0].country : country;

  return (
    <Loader spinning={isDriverDetailsLoading || isLoading}>
      <Form
        className="form-driver-edit"
        defaultValues={initFormData}
        schema={schema}
        onSubmit={handleSubmit}
      >
        <h2>{isEditProcess ? t('mobility.editDriver') : t('mobility.addDriver')}</h2>

        {isEditProcess && (
          <div className="row driver">
            <div className="driver-photo">
              {photo ? (
                <img src={photo} alt="" />
              ) : (
                <div className="driver-photo-placeholder">
                  <DriverPlaceholder />
                </div>
              )}
            </div>

            <div className="driver-info">
              <div className="name">
                {name} {lastName}
              </div>

              {sharedLocations?.length! > 0 && (
                <div className="shared">
                  {t('mobility.shared')}
                  <span className="shared-label">
                    {sharedLocations?.length} {t('common.agencies')}
                  </span>
                </div>
              )}
            </div>

            <div className="row-group-right">
              {bloodGroupDisplay && (
                <Field
                  name="bloodGroup"
                  input="dropdown"
                  className="field-blood-group"
                  disabled={readOnly}
                  label={t('mobility.bloodGroup')}
                  options={bloodGroupOptions}
                />
              )}

              <Field
                name="preferredVehicle"
                input="dropdown"
                className="field-preferred"
                clearable
                disabled={readOnly}
                label={t('mobility.preferredVehicle')}
                options={vehicleOptions}
              />
            </div>
          </div>
        )}

        <h3>{t('mobility.personalInfo')}</h3>
        <div className="row">
          <Field name="name" disabled={readOnly} label={t('common.firstName')} />
          <Field name="lastName" disabled={readOnly} label={t('common.lastName')} />
          <Field
            name="email"
            className="field-email"
            disabled={isEditProcess || readOnly}
            label={t('common.emailAddress')}
            type="email"
          />
          <Field name="phoneNumber" disabled={readOnly} label={t('common.phoneNumber')} />
        </div>

        <div className="row">
          <div className="driver-license">
            <h3>{t('mobility.driverLicense')}</h3>
            <div className="row">
              <Field name="licenseNumber" disabled={readOnly} label={t('common.number')} />
              <Field
                name="licenseExpirationDate"
                input="date-picker"
                className="field-expiration"
                clearable
                disabled={readOnly}
                label={t('mobility.expirationDate')}
                valueFormat="DD/MM/YYYY"
              />
            </div>
          </div>
        </div>

        <h3>{t('mobility.shared')}</h3>
        <div className="row">
          <Field
            name="sharedLocations"
            input="multi-select"
            className="field-shared"
            clearable
            disabled={readOnly}
            options={isEditProcess ? sharedLocationOptions : sharedAvailableLocations}
          />
        </div>

        <div className="footer">
          <div className="info">
            {t('mobility.ownedBy')}: {ownedCity}, {ownedCountry} - {ownedAgency}
            {isEditProcess ? ` (${t('mobility.lastModified')} ${lastModifiedDate})` : null}
          </div>

          {!readOnly ? (
            <Button
              className="btn btn-submit"
              text={t('common.btnConfirmChanges')}
              type="submit"
              variant="submit"
            />
          ) : null}
        </div>
      </Form>
    </Loader>
  );
};

export default DriverEdit;
