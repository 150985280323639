import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LocalStorageKeys, SessionStorageKeys } from '@common/constants';
import { AuthProviderType } from '@common/interfaces';
import { UserType } from '@common/utils';

interface ParsedParamsResult {
  federatedUserLogout: boolean;
  parsed: boolean;
  userType: UserType;
}

export default function useParamsParse(): ParsedParamsResult {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const agency = query.get('agency');
  const facility = query.get('facility_id');
  const federatedUserLogout = !!query.get('logout');
  const isAzureUser = query.get(AuthProviderType.azure);
  const isB2CUser = query.get(AuthProviderType.b2c);
  const isGoogleUser = query.get(AuthProviderType.google);
  const isNonFederatedUser = query.get(AuthProviderType.nonFederated);

  let userType: UserType =
    !agency && !facility ? JSON.parse(localStorage.getItem(LocalStorageKeys.AUTH_TYPE)!) : 'azure';

  if (isAzureUser === 'True') userType = 'azure';
  if (isB2CUser === 'True') userType = 'b2c';
  if (isGoogleUser === 'True') userType = 'google';
  if (isNonFederatedUser === 'True') userType = 'non-federated';

  useEffect(() => {
    if (agency && facility) {
      sessionStorage.setItem(SessionStorageKeys.AGENCY, agency.split(',')[0]);
      sessionStorage.setItem(SessionStorageKeys.FACILITY, facility);
    }
  }, [agency, facility]);

  return {
    federatedUserLogout,
    parsed: !!(agency && facility),
    userType,
  };
}
