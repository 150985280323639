/* eslint-disable no-nested-ternary */
import type { FC } from 'react';
import { RequestingUnits } from '@common/constants/logbook';
import { LBAuxPassenger, LBBooking, LBDriveDetails } from '@common/interfaces/LogbookAPI';
import { ExpansionPanel, InfoBlock, InfoBlockType } from '@components/logbook';

interface PassengerInfoProps {
  data: LBDriveDetails;
  passenger: LBBooking | LBAuxPassenger;
  title: string;
}

const PassengerInfo: FC<PassengerInfoProps> = ({ data, passenger, title }) => {
  const firstRowStyle = { marginRight: 24, height: 89, flex: 1, justifyContent: 'space-between' };
  const secondRowStyle = { marginRight: 24, height: 89, justifyContent: 'space-between' };

  return (
    <ExpansionPanel title={title} isOpenDefault>
      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock style={firstRowStyle} type={InfoBlockType.title} title="Passenger name">
          <p>
            {passenger.firstName} {passenger.lastName}
          </p>
        </InfoBlock>

        <InfoBlock
          style={{ ...firstRowStyle, marginRight: 0 }}
          type={InfoBlockType.title}
          title="Email"
        >
          <p>{passenger.email}</p>
        </InfoBlock>
      </div>
      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock
          style={{ ...secondRowStyle, flex: 2 }}
          type={InfoBlockType.title}
          title="Phone number"
        >
          <p>{passenger.phoneNumber}</p>
        </InfoBlock>

        <InfoBlock
          style={{ ...secondRowStyle, flex: 1.25 }}
          type={InfoBlockType.title}
          title="Agency"
        >
          <p>{passenger.agency}</p>
        </InfoBlock>

        <InfoBlock
          style={{ ...secondRowStyle, marginRight: 0, flex: 4 }}
          type={InfoBlockType.title}
          title="Requesting unit"
        >
          {(passenger as LBAuxPassenger).requestingUnit ? (
            <p>{RequestingUnits[(passenger as LBAuxPassenger).requestingUnit]}</p>
          ) : data?.requestingUnit ? (
            <p>{RequestingUnits[data?.requestingUnit]}</p>
          ) : null}
        </InfoBlock>
      </div>
    </ExpansionPanel>
  );
};

export default PassengerInfo;
