/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from 'react';
import { ChevronDown, ChevronUp } from '@assets/svg/icons/logbook';
import './ExpansionPanel.styles.scss';

type ExpansionPanelProps = {
  children: React.ReactNode;
  isOpenDefault?: boolean;
  title: string;
};

const ExpansionPanel: FC<ExpansionPanelProps> = ({ children, isOpenDefault, title }) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return (
    <div className="expansion-panel">
      <div
        className={`expansion-panel-container ${!isOpen ? 'expansion-panel-closed' : ''}`}
        onClick={!isOpen ? open : undefined}
      >
        <p className="expansion-panel-title">{title}</p>
        {isOpen ? (
          <button className="expansion-panel-chevron-opened" type="button" onClick={close}>
            <ChevronUp data-testid="expansion-panel-chevron-opened" height="8px" width="14px" />
          </button>
        ) : (
          <button className="expansion-panel-chevron-closed" type="button" onClick={open}>
            <ChevronDown data-testid="expansion-panel-chevron-closed" height="8px" width="14px" />
          </button>
        )}
      </div>
      {isOpen ? children : null}
    </div>
  );
};

export default ExpansionPanel;
