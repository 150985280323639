import { IChecklistStatus, IVehiclesChecklist } from '@common/interfaces';

export const getChecklistOverallStatus = (
  checklist: IVehiclesChecklist,
): { status: IChecklistStatus; statusName: string } => {
  const { criticalIssuesCount, issuesCount, submitted, todayDrivesCount } = checklist;
  let status: IChecklistStatus;
  let statusName: string;

  switch (true) {
    case !submitted && todayDrivesCount === 0:
      status = 'not-used';
      statusName = 'Not used';
      break;
    case !submitted && todayDrivesCount > 0:
      status = 'not-reported';
      statusName = 'Not reported';
      break;
    case criticalIssuesCount >= 1:
      status = 'critical';
      statusName = 'Critical Issues';
      break;
    case issuesCount >= 1:
      status = 'issues';
      statusName = 'Issues';
      break;
    default:
      status = 'clear';
      statusName = 'Clear';
      break;
  }

  return { status, statusName };
};
