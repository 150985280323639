export enum BookingStateType {
  accepted = 'Accepted',
  cancelled = 'Cancelled',
  cancelled_by_user = 'Cancelled by user',
  finished = 'Finished',
  not_processed = 'Not Processed',
  ongoing = 'Ongoing',
  rejected = 'Rejected',
}

export enum DriveType {
  is_carpooled = 'Carpooled',
  is_own_agency_drive = 'Own Agency Passenger',
  is_ride_shared = 'Rideshared',
  is_ride_shared_and_carpooled = 'Rideshared + Carpooled',
}

export enum TransferTypeDisplay {
  airport = 'Airport',
  in_town = 'In Town',
  out_of_town = 'Out Of Town',
}

export enum TripTypeDisplay {
  'one-way' = 'One Way',
  'round-trip' = 'Round Trip',
  'multi-leg' = 'Multi-leg',
}

export enum UtilType {
  trips = 'Trips',
  mileage = 'Mileage',
}

export type ChartDataItem = { label: string; value: number };
export type ChartPieData = { data: number[]; labels: string[]; total: number };
export type ChartBarData = {
  agenda: string[];
  data: [
    {
      data: number[];
      label: string;
    },
  ];
};
