import { addMinutes, format, isSameDay, parse } from 'date-fns';
import { TIME_INTERVAL } from '@common/constants';

interface RouteDateTime {
  minDate: Date;
  minTime?: Date;
}

export const getRouteMinDateTime = (idx: number, watch: (field: string) => any): RouteDateTime => {
  const currentDate = watch(`routes.${idx}.pickupDate`);
  const prevDate = idx > 0 ? watch(`routes.${idx - 1}.pickupDate`) : null;
  const prevTime = idx > 0 ? watch(`routes.${idx - 1}.pickupTime`) : null;
  const isSameDate = !idx && isSameDay(currentDate, new Date());
  const isValidPrevDate = Boolean(prevDate && prevTime);

  const minRouteTime =
    isValidPrevDate && isSameDay(currentDate, prevDate)
      ? addMinutes(
          parse(`${format(prevDate, 'yyyy-MM-dd')} ${prevTime}`, 'yyyy-MM-dd HH:mm', new Date()),
          TIME_INTERVAL,
        )
      : undefined;

  return {
    minDate: idx > 0 && prevDate ? prevDate : new Date(),
    minTime: minRouteTime || (isSameDate ? addMinutes(new Date(), TIME_INTERVAL) : undefined),
  };
};
