import { AxiosInstance } from 'axios';
import { createContext, FC, ReactNode, useContext } from 'react';
import { axios as axiosInstance } from '@common/utils';
import {
  AccountRepository,
  AgenciesRepository,
  BookingRepository,
  FacilityRepository,
  FeedbackRepository,
  FinancialRepository,
  LogbookRepository,
  MobilityRepository,
  PlannerRepository,
  ReportRepository,
  TravelRequestRepository,
  VehiclesChecklistRepository,
} from '@common/repositories';

const getRepositories = (axios: AxiosInstance) => ({
  agenciesRepository: new AgenciesRepository(axios),
  accountRepository: new AccountRepository(axios),
  bookingRepository: new BookingRepository(axios),
  facilityRepository: new FacilityRepository(axios),
  feedbackRepository: new FeedbackRepository(axios),
  financialRepo: new FinancialRepository(axios),
  logbookRepository: new LogbookRepository(axios),
  mobilityRepository: new MobilityRepository(axios),
  plannerRepo: new PlannerRepository(axios),
  reportRepository: new ReportRepository(axios),
  travelRequestRepository: new TravelRequestRepository(axios),
  vehiclesChecklistRepository: new VehiclesChecklistRepository(axios),
});

const RepositoryContext = createContext(getRepositories(axiosInstance));

interface IRepositoryProvider {
  axios: AxiosInstance;
  children?: ReactNode;
}

const RepositoryProvider: FC<IRepositoryProvider> = ({ axios, children }) => (
  <RepositoryContext.Provider value={getRepositories(axios)}>{children}</RepositoryContext.Provider>
);

const useRepository = () => useContext(RepositoryContext);

export { RepositoryProvider, useRepository };
