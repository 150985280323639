import { useTranslation } from 'react-i18next';
import { ProtType, VehicleType } from '@common/interfaces';
import { formatDateString } from '@common/utils';
import { DateFormat as DF } from '@common/types';
import {
  Armoured,
  Edit,
  ReadOnly,
  Vehicle as VehicleIcon,
  VehicleChassis as Chassis,
  VehicleKm,
  VehiclePax,
  VehiclePlaceholder,
  VehicleRoof,
} from '@assets/svg/icons';
import { Button } from '@components';
import './Vehicle.styles.scss';

export type VehicleProps = {
  isEditable?: boolean;
  isLoading?: boolean;
  vehicle: VehicleType;
  onVehicleEdit?: (vehicleId: string) => void;
  onVehicleStatusChange?: (vehicle: VehicleType) => void;
};

const Vehicle = ({
  isEditable,
  isLoading,
  vehicle,
  onVehicleEdit,
  onVehicleStatusChange,
}: VehicleProps) => {
  const { t } = useTranslation();
  const { agency, chassisNumber, label, model, plateNumber, protection, roofNumber, totalKm } =
    vehicle;
  const { deleted, sinceDate, untilDate } = vehicle.currentUnavailability || {};
  const fromDate = sinceDate && formatDateString(sinceDate, DF.ApiDate, DF.ApiDateAlt);
  const toDate = untilDate && formatDateString(untilDate, DF.ApiDate, DF.ApiDateAlt);

  const details = [
    { id: 1, icon: <VehicleIcon />, label: t('mobility.plateNumber'), value: plateNumber },
    { id: 2, icon: <VehicleKm />, label: t('mobility.totalKm'), value: totalKm },
    { id: 3, icon: <VehicleRoof />, label: t('mobility.roofNumber'), value: roofNumber },
    { id: 4, icon: <Chassis />, label: t('mobility.chassisNumber'), value: chassisNumber },
    { id: 5, icon: <VehiclePax />, label: 'N°Pax', value: vehicle?.maxCapacity },
  ];

  const getStatus = () => {
    if (fromDate && toDate) {
      return (
        <>
          {t('mobility.unavailable')}
          <br />
          {`${t('common.from')} ${fromDate} ${t('common.to')} ${toDate}`}
        </>
      );
    }

    if (deleted && fromDate) {
      return (
        <>
          {t('common.disable')}
          <br />
          {`${t('common.from')} ${fromDate}`}
        </>
      );
    }

    return null;
  };

  return (
    <div className="vehicle-item">
      <div className="vehicle-info">
        <div className="vehicle-image">
          {model?.image ? (
            <img src={model.image} alt={model.name} data-testid="vehicle-image" />
          ) : (
            <VehiclePlaceholder />
          )}
          {getStatus() && <div className="vehicle-status">{getStatus()}</div>}
        </div>
        <div className="vehicle-details">
          <div className="details-header">
            <img src={agency?.logo} alt={agency?.shortName} className="agency-logo" />
            <div className="details-title">
              {model ? model.name : label}
              {protection === ProtType.Armoured && (
                <div className="armoured">
                  <div className="armoured-icon">
                    <Armoured />
                  </div>
                  {t('mobility.msgShareArmoured')}
                </div>
              )}
            </div>
            {vehicle.sharedAgenciesCount > 0 && (
              <div className="shared-label">{t('mobility.shared')}</div>
            )}
            <Button
              className="btn-edit"
              leftIcon={isEditable ? <Edit /> : <ReadOnly />}
              text={isEditable ? t('common.btnEdit') : t('common.btnView')}
              variant="icon"
              onClick={() => onVehicleEdit?.(vehicle?.id)}
              data-testid="mobility-edit-vehicle"
            />
          </div>
          <div className="details-content">
            {details?.map((item) => (
              <div key={item.id} className="item">
                <div className="icon">{item.icon}</div>
                <div>
                  <div className="label">{item.label}</div>
                  <div className="value">{item.value}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isEditable && (
        <div className="vehicle-ctrl">
          <Button
            className={`btn ${vehicle.currentUnavailability ? 'btn-available' : 'btn-unavailable'}`}
            disabled={isLoading}
            text={
              vehicle.currentUnavailability
                ? t('mobility.btnMarkAvailable')
                : t('mobility.btnMarkUnavailable')
            }
            variant="transparent"
            onClick={() => onVehicleStatusChange?.(vehicle)}
          />
        </div>
      )}
    </div>
  );
};

export default Vehicle;
