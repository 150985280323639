import type { IDropdownOption } from '@ui-modules/types';
import type { PlDriver, PlVehicle } from '@common/interfaces';
import { TripType } from '@common/interfaces';

const DEFAULT_MAX_PASSENGER_CAPACITY = 4;

export const findOption = (
  options?: IDropdownOption[],
  value?: string,
): IDropdownOption | undefined => options?.find((option) => option.value === value);

export const getDefaultOption = (option?: IDropdownOption, spare = ''): IDropdownOption =>
  option || { label: spare, value: spare };

export const mapDriverOptions = (drivers: PlDriver[]): IDropdownOption[] =>
  drivers.map(
    (d: PlDriver): IDropdownOption => ({
      label: `${d.name} ${d.lastName}`,
      value: d.id?.toString(),
    }),
  );

export const mapPaxOptions = (vehicles: PlVehicle[], vehicleId: string): IDropdownOption[] => {
  const currentVehicle = vehicles?.find((v: PlVehicle) => v.id === vehicleId);
  const vehicleMaxCapacity = currentVehicle?.maxCapacity || DEFAULT_MAX_PASSENGER_CAPACITY;

  return Array.from({ length: vehicleMaxCapacity }, (_, idx) => ({
    label: String(idx + 1),
    value: String(idx + 1),
  }));
};

export const mapLocationOptions = (data: string[] = []): IDropdownOption[] =>
  data.map((v: string) => ({ label: v, value: v }));

export const mapVehicleOptions = (vehicles: PlVehicle[]): IDropdownOption[] =>
  vehicles.map((v: PlVehicle) => ({ label: v.title, value: v.id }));

export const tripTypeOpt = [
  { value: TripType.OneWay, label: 'One way' },
  { value: TripType.RoundTrip, label: 'Round trip' },
  { value: TripType.MultiLeg, label: 'Multi leg' },
];

export const weekdays = [
  { value: '0', label: 'Mon' },
  { value: '1', label: 'Tue' },
  { value: '2', label: 'Wed' },
  { value: '3', label: 'Thu' },
  { value: '4', label: 'Fri' },
  { value: '5', label: 'Sat' },
  { value: '6', label: 'Sun' },
];
