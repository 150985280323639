import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { Radio, type RadioGroupProps as RGProps } from '@mantine/core';
import { IRadio } from '@common/interfaces';
import './RadioGroup.styles.scss';

export type RadioGroupProps<T extends FieldValues> = {
  disabledOption?: string | null;
  options: IRadio[];
  orientation?: 'horizontal' | 'vertical';
} & UseControllerProps<T> &
  Omit<RGProps, 'children' | 'defaultValue' | 'value'>;

export function RadioGroup<T extends FieldValues>({
  control,
  defaultValue,
  disabledOption,
  name,
  options,
  orientation = 'horizontal',
  rules,
  shouldUnregister,
  onChange,
  ...props
}: RadioGroupProps<T>) {
  const {
    field: { value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <Radio.Group
      classNames={{
        root: `input-radio input-radio-${orientation}`,
        label: 'input-radio-label',
      }}
      error={fieldState.error?.message}
      value={value}
      onChange={(e) => {
        fieldOnChange(e);
        onChange?.(e);
      }}
      {...field}
      {...props}
    >
      {options?.map((option: IRadio) => (
        <Radio
          disabled={disabledOption === option.value}
          key={option.value}
          label={option.label}
          value={option.value}
        />
      ))}
    </Radio.Group>
  );
}
