/* istanbul ignore file */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, addWeeks, format, isBefore, isEqual } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { RadioGroup, useFormApi, useFormState } from 'informed';

import { Dropdown, DateField } from '@ui-modules/informed';
import { DriveFormSteps } from '@common/interfaces';
import { DateFormat } from '@common/types';
import { notEmptyValidator } from '@common/utils';
import '../../../styles/modal.scss';

export interface RecurDriveProps {
  currentDate?: Date;
  isMultileg?: boolean;
  step: string;
}

const getWeeklyRecurringDrives = (currentDate: Date, untilDate: Date) => {
  const weeklyDrives = [];
  let iterationDate = new Date(currentDate);

  while (isBefore(iterationDate, untilDate) || isEqual(iterationDate, untilDate)) {
    weeklyDrives.push(format(new Date(iterationDate), DateFormat.BookingInfoDate));
    iterationDate = addWeeks(iterationDate, 1);
  }

  return weeklyDrives;
};

const RecurDrive: FC<RecurDriveProps> = ({
  currentDate = new Date(),
  isMultileg = false,
  step,
}) => {
  const formApi = useFormApi();
  const { values } = useFormState() as Record<string, any>;
  const { t, i18n } = useTranslation();

  const locale = i18n.language === 'fr' ? fr : enUS;

  const recurringPeriod = values?.[step]?.recurringPeriod;
  const recurringUntil = values?.[step]?.recurringUntil;
  const recurringFrequency = values?.[step]?.recurringFrequency;

  const pickupDate = isMultileg
    ? values[DriveFormSteps.Trip]?.driveListMultileg?.[0].pickupDate
    : values[DriveFormSteps.Trip]?.driveList?.[0].pickupDate;

  useEffect(() => {
    if (recurringUntil && pickupDate && recurringUntil < pickupDate) {
      formApi.resetField(`${step}.recurringUntil`);
    }
  }, [pickupDate, recurringUntil, formApi, step]);

  const recurringOptions = useMemo(
    () => [
      {
        label: t('common.daily'),
        value: '3',
      },
      {
        label: t('common.weekly'),
        value: '2',
      },
    ],
    [t],
  );

  const dailyFrequencyOptions = useMemo(
    () => [
      {
        label: t('common.recurring.workingDaysFromMonday'),
        value: '5',
      },
      {
        label: t('common.recurring.workingDaysFromSunday'),
        value: '6',
      },
      {
        label: t('common.recurring.allDays'),
        value: '7',
      },
    ],
    [t],
  );

  return (
    <section className="recurring-drive-section">
      <h3 className="section-title">{t('common.recurring.addRecurring')}</h3>

      <div className="row">
        <div className="column">
          <div className="field">
            <Dropdown
              className="dropdown"
              label={t('common.recurring.repeatDrive')}
              name="recurringPeriod"
              options={recurringOptions}
              validate={notEmptyValidator}
            />
          </div>
        </div>
        <div className="column">
          <div className="field">
            <DateField
              className="input"
              dropdownMode="select"
              label={t('common.recurring.repeatUntil')}
              minDate={addDays(pickupDate || currentDate, 1)}
              maxDate={addDays(pickupDate || currentDate, 29)}
              name="recurringUntil"
              placeholder="Please select date"
              showMonthDropdown
              showYearDropdown
              validate={notEmptyValidator}
            />
          </div>
        </div>
      </div>

      {recurringPeriod?.value === '3' && (
        <div className="recurring-frequency">
          <label className="hbh-date-label">{t('common.recurring.dailyFrequency')}</label>
          <RadioGroup
            defaultValue={dailyFrequencyOptions[0].value}
            name="recurringFrequency"
            options={dailyFrequencyOptions}
          />
        </div>
      )}

      {recurringPeriod && recurringUntil ? (
        <div className="creating-recurring-panel">
          <h3 className="section-title">{t('common.recurring.drivesOverview')}</h3>

          {recurringPeriod?.value === '3' ? (
            <p className="recurring-drive">
              {t('common.recurring.dailyRecurringDrive')} {t('common.from')}{' '}
              <i>
                {format(currentDate, DateFormat.BookingInfoDate, {
                  locale,
                })}
              </i>{' '}
              {t('common.to')}{' '}
              <i>
                {format(recurringUntil, DateFormat.BookingInfoDate, {
                  locale,
                })}
              </i>{' '}
              {recurringFrequency === '5' ? `(${t('common.recurring.onlyWorkingDays')})` : ''}
            </p>
          ) : (
            getWeeklyRecurringDrives(currentDate, recurringUntil).map((date, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <p className="recurring-drive" key={`${idx}-${date}`}>
                {t('common.recurring.weeklyRecurringDrive')} <i>{date}</i>
              </p>
            ))
          )}
        </div>
      ) : null}
    </section>
  );
};

export default RecurDrive;
