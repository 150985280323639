import { type ChangeEvent, type FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { Form } from 'informed';
import { useDebounce } from 'usehooks-ts';

import { Button, Pagination, Table } from '@unbooking/ui-modules';
import { Dropdown, TextField } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { requiredField, Serializer } from '@common/utils';
import {
  FuelType,
  IDriveUpdate,
  IDriveWithWorkflow,
  ReportDetailsList,
  ReportParams,
  SummaryType,
} from '@common/interfaces';
import { Modal, Tooltip } from '@components';
import { CURRENT_MONTH, CURRENT_YEAR, MONTHS, YEARS } from '@common/constants';
import {
  Carpooled,
  Edit,
  Electric,
  Manual,
  PencilIcon,
  ReadOnly,
  Vehicle,
} from '@assets/svg/icons';
import Device from '@assets/svg/icons/logbook/Device';
import EditDriveForm from './EditDriveForm';

interface IFormState {
  approvedBy: string;
  certifiedBy: string;
  isCarpooled: IDropdownOption;
  isRideShared: IDropdownOption;
  month: IDropdownOption;
  preparedBy: string;
  search: string;
  year: IDropdownOption;
}

interface IReportDetails {
  username: string;
  exportDrives: (data: ReportParams) => void;
}

const ReportDetails: FC<IReportDetails> = ({ username, exportDrives }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    facility: { agencyId },
    facilityId,
  } = useFacility();
  const { reportRepository } = useRepository();
  const { t } = useTranslation();

  const [currentDrive, setCurrentDrive] = useState<ReportDetailsList | null>(null);
  const [driveData, setDriveData] = useState<IDriveWithWorkflow | null>();
  const [filterCarpooled, setFilterCarpooled] = useState<string>();
  const [filterRideShared, setFilterRideShared] = useState<string>();
  const [filterMonth, setFilterMonth] = useState<string>(CURRENT_MONTH.value);
  const [filterYear, setFilterYear] = useState<string>(CURRENT_YEAR.value);
  const [reportDetailsData, setReportDetailsData] = useState<ReportDetailsList[]>([]);
  const [reportDetailsColumnOrder, setReportDetailsColumnOrder] = useState<string>('');
  const [reportDetailsPageSize, setReportDetailsPageSize] = useState<number>(10);
  const [reportDetailsSelectedPage, setReportDetailsSelectedPage] = useState<number>(1);
  const [reportDetailsTotalItems, setReportDetailsTotalItems] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>();

  const debouncedSearch = useDebounce(searchQuery, 1000);

  const reportOptions: IDropdownOption[] = [
    { value: '', label: t('common.showAll') },
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ];

  const {
    data: reportData,
    isLoading: isReportDataLoading,
    refetch: getReports,
  } = useQuery(
    [
      'cost-recovery-report',
      debouncedSearch,
      filterCarpooled,
      filterRideShared,
      filterMonth,
      filterYear,
      reportDetailsSelectedPage,
      reportDetailsPageSize,
      reportDetailsColumnOrder,
    ],
    () =>
      reportRepository.getReports(facilityId, {
        agencyId,
        isCarpooled: filterCarpooled ? filterCarpooled === 'true' : undefined,
        isRideShared: filterRideShared ? filterRideShared === 'true' : undefined,
        limit: reportDetailsPageSize,
        month: filterMonth,
        offset: !debouncedSearch ? (reportDetailsSelectedPage - 1) * 10 : undefined,
        ordering: reportDetailsColumnOrder,
        pickupDate: format(new Date(), ' yyyy-MM-dd'),
        search: debouncedSearch?.trim(),
        year: filterYear,
      }),
    { enabled: !!agencyId, refetchOnWindowFocus: false },
  );

  const { isLoading: isDriveLoading, mutate: getDriveWithWorkflow } = useMutation<
    unknown,
    AxiosError,
    string
  >('get-drive', (driveId: string) => reportRepository.getDriveWithWorkflow(facilityId, driveId), {
    onSuccess: (data: any) => {
      setDriveData(Serializer.formatDriveWithWorkflow(data));
    },
    onError: (error) => {
      if (error) {
        toast.error(t('report.errorMsgDrive'));
      }
    },
  });

  const { mutateAsync: updateDrive } = useMutation<
    unknown,
    AxiosError,
    { driveId: string; data: IDriveUpdate }
  >(
    'update-drive',
    ({ driveId, data }) => reportRepository.updateDrive(facilityId, driveId, data),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgUpdateDrive'));
        getReports();
        setCurrentDrive(null);
        setDriveData(null);
      },
      onError: (error) => {
        if (error.message) {
          toast.error(error.message || t('common.errorMsgDefault'));
        }
      },
    },
  );

  useEffect(() => {
    if (reportData) {
      setReportDetailsData(reportData.results.map(Serializer.formatReportDetails));
      setReportDetailsTotalItems(reportData.count);
    }
  }, [reportData]);

  const getOdometerDetails = (record: ReportDetailsList): JSX.Element => {
    const data = record.vtsData || record.details;
    const odometer = data ? `${data?.startKm}/${data?.endKm}` : '';

    return <Tooltip label={odometer}>{odometer}</Tooltip>;
  };

  const getSummaryTypeIcon = (record: ReportDetailsList): JSX.Element | null => {
    const applyIcon = (icon: JSX.Element, tooltip: string): JSX.Element => (
      <Tooltip label={tooltip}>{icon}</Tooltip>
    );

    let icon: JSX.Element | null;

    switch (record.summaryType) {
      case SummaryType.DriverManualInput:
        icon = applyIcon(<PencilIcon />, record.summaryTypeDisplay);
        break;
      case SummaryType.FocalPointManualInput:
        icon = applyIcon(<Manual />, record.summaryTypeDisplay);
        break;
      case SummaryType.VTS:
        icon = applyIcon(<Vehicle />, record.summaryTypeDisplay);
        break;
      case SummaryType.GPS:
        icon = applyIcon(<Device />, record.summaryTypeDisplay);
        break;
      default:
        icon = null;
        break;
    }

    return icon;
  };

  const columns = [
    {
      dataIndex: 'pickupDate',
      key: 'pickupDate',
      title: t('report.performedOn'),
      width: 90,
    },
    {
      dataIndex: 'vehicle',
      key: 'vehicle',
      title: t('common.vehicle'),
      ellipsis: {
        showTitle: true,
      },
      width: 120,
    },
    {
      dataIndex: 'passenger',
      key: 'passenger',
      title: t('common.pax'),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: 'agency',
      key: 'agency',
      title: t('common.agencyPax'),
      ellipsis: {
        showTitle: true,
      },
      width: 80,
    },
    {
      dataIndex: 'refCode',
      key: 'refCode',
      title: t('common.refCode'),
      width: 55,
    },
    {
      dataIndex: 'budgetCode',
      key: 'budgetCode',
      title: t('common.budgetCode'),
      width: 55,
    },
    {
      dataIndex: 'typeOfTrip',
      key: 'typeOfTrip',
      title: t('common.typeOfTrip'),
      width: 70,
    },
    {
      dataIndex: 'driverFullName',
      key: 'driverFullName',
      title: t('common.driver'),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      dataIndex: 'pax',
      key: 'pax',
      title: 'Pax N°',
      width: 40,
    },
    {
      dataIndex: 'transferType',
      key: 'transferType',
      title: t('common.transferType'),
      width: 80,
    },
    {
      ellipsis: {
        showTitle: true,
      },
      key: 'odometer',
      title: 'Odometer',
      width: 100,
      render: getOdometerDetails,
    },
    {
      key: 'km',
      title: 'Km',
      width: 40,
      render: (record: ReportDetailsList) => (
        <span>
          {!record.hasWorkflow ? (
            <Tooltip label={t('report.autoFilled')}>{record.km}</Tooltip>
          ) : (
            record.km
          )}
        </span>
      ),
    },
    {
      key: 'duration',
      title: t('common.duration'),
      width: 60,
      render: (record: ReportDetailsList) => (
        <span>
          {!record.hasWorkflow ? (
            <Tooltip label={t('report.autoFilled')}>{record.duration}</Tooltip>
          ) : (
            record.duration
          )}
        </span>
      ),
    },
    {
      dataIndex: 'isCarpooled',
      key: 'isCarpooled',
      title: t('common.carpooled'),
      width: 70,
      render: (record: boolean) => <span>{record ? 'Yes' : 'No'}</span>,
    },
    {
      dataIndex: 'isRideShared',
      key: 'isRideShared',
      title: t('common.rideshared'),
      width: 75,
      render: (record: boolean) => <span>{record ? 'Yes' : 'No'}</span>,
    },
    {
      key: 'price',
      title: `${t('common.price')} $`,
      render: (record: ReportDetailsList) => (
        <span
          data-testid={`cost-recovery-vehicle-details${record.isCarpooled ? '-carpooled' : ''}${
            record.isRideShared ? '-shared' : ''
          }`}
        >
          {!record.hasWorkflow ? (
            <Tooltip label={t('report.autoFilled')}>
              ${record.price ? record.price : '0.00'}
            </Tooltip>
          ) : (
            `$${record.price ? record.price : '0.00'}`
          )}
        </span>
      ),
    },
    {
      key: 'status',
      title: '',
      render: (record: ReportDetailsList) => (
        <div className="col-status">
          <div className="col-status-summary">{getSummaryTypeIcon(record)}</div>

          <div className="col-status-fuel-type">
            {record.fuelType === FuelType.Electric ? (
              <Tooltip label="Electric">
                <Electric />
              </Tooltip>
            ) : null}
          </div>

          <div className="col-status-shared" data-testid="report-details-trip-rideshared">
            {record.isRideShared ? (
              <Tooltip label={t('report.ridesharedTrip')}>
                <Carpooled />
              </Tooltip>
            ) : null}
          </div>

          <div className="col-status-carpooled" data-testid="report-details-trip-carpooled">
            {record.isCarpooled ? (
              <Tooltip label={t('report.carpooledTrip')}>
                <Carpooled />
              </Tooltip>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      key: 'actions',
      title: '',
      width: 80,
      render: (record: ReportDetailsList) => (
        <div className="col-actions">
          <Tooltip
            label={
              record?.isEditableByFocalPoint
                ? t('report.editTripData')
                : t('report.upcomingTripsMsg')
            }
          >
            <Button
              className="btn btn-edit"
              data-testid="report-details-edit"
              icon={record?.isEditableByFocalPoint ? <Edit /> : <ReadOnly />}
              variant="icon"
              onClick={() => {
                if (!isDriveLoading) {
                  setCurrentDrive(record);
                  getDriveWithWorkflow(record.id);
                }
              }}
            />
          </Tooltip>
          <Button
            className="btn btn-booking-details"
            variant="transparent"
            onClick={() => {
              const path = location.pathname.split('/').slice(0, -2).join('/');
              navigate(`${path}/bookings/${record.bookingId}`);
            }}
          />
        </div>
      ),
    },
  ];

  const initFormData: Record<string, unknown> = {
    approvedBy: '',
    certifiedBy: '',
    isCarpooled: reportOptions[0],
    isRideShared: reportOptions[0],
    month: CURRENT_MONTH,
    preparedBy: username,
    year: CURRENT_YEAR,
  };

  return (
    <section className="report-details">
      <div className="row">
        <Form
          className="report-details-form"
          id="report_filters_form"
          initialValues={initFormData}
          onSubmit={(formState: Record<string, unknown>) => {
            const state = { ...(formState.values as IFormState) };
            exportDrives({
              agencyId,
              approvedBy: state.approvedBy,
              certifiedBy: state.certifiedBy,
              isCarpooled: state.isCarpooled.value ? state.isCarpooled.value === 'true' : undefined,
              isRideShared: state.isRideShared.value
                ? state.isRideShared.value === 'true'
                : undefined,
              month: state.month.value,
              preparedBy: state.preparedBy,
              search: state.search,
              year: state.year.value,
            });
          }}
        >
          <div className="export-fields">
            <div className="field">
              <TextField
                disabled
                label={t('report.inputPreparedBy')}
                name="preparedBy"
                placeholder={t('report.inputPreparedBy')}
              />
            </div>

            <div className="field">
              <TextField
                label={t('report.inputCertifiedBy')}
                name="certifiedBy"
                placeholder={t('report.inputCertifiedBy')}
                validate={requiredField}
              />
            </div>

            <div className="field">
              <TextField
                label={t('report.inputApprovedBy')}
                name="approvedBy"
                placeholder={t('report.inputApprovedBy')}
                validate={requiredField}
              />
            </div>
          </div>

          <div className="filters">
            <div className="field search" data-testid="report-details-input-search">
              <input
                className="input-search"
                type="text"
                placeholder={t('report.inputSearch')}
                value={searchQuery}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(event.currentTarget.value);
                }}
              />
            </div>

            <div className="field carpooled" data-testid="report-details-select-carpooled">
              <Dropdown
                id="cost_recovery_report_carpooled_dropdown"
                label={t('common.carpooled')}
                name="isCarpooled"
                options={reportOptions}
                onChange={({ value }: IDropdownOption) => {
                  setFilterCarpooled(value);
                  setReportDetailsSelectedPage(1);
                }}
              />
            </div>

            <div className="field rideshared" data-testid="report-details-select-rideshared">
              <Dropdown
                id="cost_recovery_report_rideshared_dropdown"
                label={t('common.rideshared')}
                name="isRideShared"
                options={reportOptions}
                onChange={({ value }: IDropdownOption) => {
                  setFilterRideShared(value);
                  setReportDetailsSelectedPage(1);
                }}
              />
            </div>

            <div className="field month">
              <Dropdown
                id="cost_recovery_report_month_dropdown"
                label={t('common.month')}
                name="month"
                options={MONTHS}
                onChange={({ value }: IDropdownOption) => {
                  setFilterMonth(value);
                  setReportDetailsSelectedPage(1);
                }}
              />
            </div>

            <div className="field year">
              <Dropdown
                id="cost_recovery_report_year_dropdown"
                label={t('common.year')}
                name="year"
                options={YEARS}
                onChange={({ value }: IDropdownOption) => {
                  setFilterYear(value);
                  setReportDetailsSelectedPage(1);
                }}
              />
            </div>
          </div>
        </Form>
      </div>

      <div className="report-details-table">
        <Table
          columns={columns}
          data={reportDetailsData}
          isLoading={isReportDataLoading}
          variant="light"
          onChangeColumnOrder={setReportDetailsColumnOrder}
        />
      </div>

      {reportDetailsTotalItems > 10 ? (
        <Pagination
          className="pagination"
          selectedPage={reportDetailsSelectedPage}
          showJumper
          showPageSize
          totalPages={reportDetailsTotalItems}
          variant="light"
          onPageChange={setReportDetailsSelectedPage}
          onPageSizeChange={setReportDetailsPageSize}
        />
      ) : null}

      <Modal
        showBtnClose
        variant="light"
        visible={!!driveData}
        onClose={() => {
          setCurrentDrive(null);
          setDriveData(null);
        }}
      >
        {currentDrive && driveData && (
          <EditDriveForm
            data={driveData}
            readOnly={!currentDrive.isEditableByFocalPoint}
            updateDrive={(data: IDriveUpdate) => updateDrive({ driveId: currentDrive.id, data })}
          />
        )}
      </Modal>
    </section>
  );
};

export default ReportDetails;
