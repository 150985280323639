/* istanbul ignore file */
import { t } from 'i18next';
import { useFieldState } from 'informed';
import { DateField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import '../styles/modal.scss';

const DropoffDateField = ({ label }: { label?: string }) => {
  const { value: pickupDate } = useFieldState('pickupDate');

  return (
    <div className="field field-date" data-testid="planner-drive-form-field-dropoff-date">
      <DateField
        className="input"
        label={label ?? t('bookingDetails.dropoffDate')}
        minDate={pickupDate as Date}
        name="dropoffDate"
        showMonthDropdown
        showYearDropdown
        validate={notEmptyValidator}
      />
    </div>
  );
};

export default DropoffDateField;
