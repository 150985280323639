/* istanbul ignore file */
import type { FC } from 'react';
import { t } from 'i18next';

import { TextField } from '@ui-modules/informed';
import { useFieldState } from '@common/hooks';
import '../../DriveForm.styles.scss';

const IndexNumberField: FC = () => {
  const { value: noPax } = useFieldState<boolean>('noPax');

  return (
    <div className="field">
      <TextField
        className="input"
        data-testid="planner-drive-form-field-index-number"
        disabled={noPax}
        label={t('common.indexNumber')}
        name="indexNumber"
      />
    </div>
  );
};

export default IndexNumberField;
