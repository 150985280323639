import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@ui-modules';
import { CloseRound } from '@assets/svg/icons';
import './styles.scss';

interface DeleteBlockProps {
  id: number | string;
  deleteAction: (id: number | string) => void;
}

const DeleteBlock: FC<DeleteBlockProps> = ({ id, deleteAction }) => {
  const { t } = useTranslation();

  const [isStartDeleting, setStartDeleting] = useState(false);

  return !isStartDeleting ? (
    <Button
      className="btn btn-delete"
      data-testid="invoice-charges-delete-btn"
      icon={<CloseRound />}
      variant="icon"
      onClick={() => setStartDeleting(true)}
    />
  ) : (
    <div className="charges-actions" data-testid="invoice-charges-actions">
      {t('common.delete')}?
      <div className="buttons">
        <Button
          className="btn"
          data-testid="invoice-charges-delete-cancel"
          text={t('common.no')}
          variant="primary"
          onClick={() => setStartDeleting(false)}
        />
        <Button
          className="btn"
          data-testid="invoice-charges-delete-confirm"
          text={t('common.yes')}
          variant="secondary"
          onClick={() => deleteAction(id)}
        />
      </div>
    </div>
  );
};

export default DeleteBlock;
