/* istanbul ignore file */
import camelcaseKeys from 'camelcase-keys';
import { format, parse, parseISO } from 'date-fns';
import { IDropdownOption } from '@ui-modules/types';
import {
  AvailableLocationsApi,
  AvailableLocations,
  BookingDetailsApi,
  BookingDetails,
  BookingDriveApi,
  BookingRoute,
  BookingState,
  BookingsApi,
  Bookings,
  BDDrive,
  BDRouteApi,
  BulkAssignApi,
  BulkAssign,
  Drive,
  DriveApi,
  DriveCostsApi,
  DriveCostsType,
  DriveCreateApi,
  DriveEditStatus,
  DriveFormDataApi,
  DriveFormState,
  DriverApi,
  DriverType,
  FormDrive,
  DriveUpdateApi,
  DriveUpdate,
  DriverDetailsApi,
  DriverDetails,
  IAvailableFacilitiesApi,
  IAvailableFacilities,
  ICountryReportApi,
  ICountryReport,
  IDriveWithWorkflowApi,
  IDriveWithWorkflow,
  IFacilityApi,
  IFacility,
  IFeedbacksList,
  FeedbackListApi,
  IMonthlySummaryApi,
  IMonthlySummary,
  IndInvoiceDetailsApi,
  IndInvoiceDetails,
  IndInvoiceListApi,
  IndInvoiceList,
  InvoiceHistoryApi,
  InvoiceHistory,
  ReportDetailsListApi,
  ReportDetailsList,
  ISummaryListApi,
  ISummaryList,
  IUserInfoApi,
  IUserInfo,
  LBDrivesApi,
  LBDrives,
  LocationApi,
  LocationType,
  PlAcceptDrive,
  PlDriveApi,
  PlDrive,
  PlDriverApi,
  PlDriver,
  PlEvent,
  PlShuttleApi,
  PlVehicleApi,
  PlVehicle,
  QuestWorkflowsApi,
  ResViewType,
  SchemaChoiceApi,
  SharedLocationApi,
  ShuttleConfigFormState,
  ShuttleConfigUpdateApi,
  ShuttleStopsApi,
  TransferType,
  TravelRequestAgencyListApi,
  TravelRequestCitiesListApi,
  TravelRequestCountriesListApi,
  TravelRequestItinerariesApi,
  TravelRequestListApi,
  TravelRequestProfileFormState,
  TravelRequestProfileCreate,
  TravelRequestTripEditFormItinerary,
  TravelRequestTripItinerary,
  TravelRequestTripList,
  TripType,
  VehicleApi,
  VehicleDetailsApi,
  VehicleDetails,
  VehicleType,
  VehicleModelListApi,
} from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { getFieldValue } from './common';
import { formatDateString, formatStringToDate } from './date';

class Serializer {
  private static getEventClass(
    status: DriveEditStatus,
    isConflicting: boolean,
    isShuttleEvent: boolean,
  ): string {
    if (isConflicting) return 'fc-event-red';
    if (isShuttleEvent) return 'fc-event-shuttle';

    switch (status) {
      case DriveEditStatus.Blue:
        return 'fc-event-blue';
      case DriveEditStatus.Green:
        return 'fc-event-green';
      default:
        return 'fc-event-grey';
    }
  }

  private static transformData(data: Record<string, any>): any {
    return camelcaseKeys(data, { deep: true });
  }

  // Common ==================================================================
  public static formatAvailableFacilities(data: IAvailableFacilitiesApi): IAvailableFacilities {
    return {
      agencies: data.managed_agencies.map((item) => ({
        label: item.short_name,
        value: item.uuid,
      })),
      city: {
        label: data.city.name,
        value: String(data.city.id),
      },
      country: {
        label: data.country.name,
        value: String(data.country.id),
      },
      facilityName: data.name,
      id: String(data.id),
    };
  }

  public static formatAvailableLocations(data: AvailableLocationsApi): AvailableLocations {
    return {
      city: data.city.name,
      cityId: String(data.city.id),
      country: data.country.name,
      emergency: data.emergency_level,
      id: data.id,
      isoCode: data.country.iso_code,
      facilityName: data.name,
    };
  }

  public static formatChoices(data: SchemaChoiceApi): IDropdownOption {
    return { label: data.display_name, value: String(data.value) };
  }

  public static formatFacility(data: IFacilityApi): IFacility {
    const { cityName, countryName, name, ...rest } = Serializer.transformData(data);

    return { ...rest, city: cityName, country: countryName, facilityName: name || 'UN Mobility' };
  }

  public static formatUser(data: IUserInfoApi): IUserInfo {
    const { indexno, ...rest } = Serializer.transformData(data);

    return { ...rest, index: indexno };
  }

  // Booking =================================================================
  public static formatBooking(data: BookingsApi): Bookings {
    const {
      agency,
      dispatchDate,
      forwardStatusPerAgency,
      numberOfPax,
      pickupDate,
      pickupTime,
      referenceCode,
      routes,
      uuid,
      ...rest
    } = Serializer.transformData(data);

    return {
      ...rest,
      agencyName: agency,
      dispatchDate: dispatchDate
        ? formatDateString(dispatchDate, DF.ApiDateAlt, DF.BookingInfoDate)
        : '',
      forwardStatus: forwardStatusPerAgency,
      id: uuid,
      pax: numberOfPax,
      pickupDate: pickupDate ? formatDateString(pickupDate, DF.ApiDate, DF.BookingInfoDate) : '',
      pickupTime: format(parse(pickupTime, DF.ApiTimeFull, new Date()), DF.ApiTime),
      refCode: referenceCode,
      routes: routes.map((item: BookingRoute) => ({
        ...item,
        dropoffDate: formatDateString(item.dropoffDate, DF.ApiDate, DF.BookingInfoDate),
        dropoffTime: format(parse(item.dropoffTime, DF.ApiTimeFull, new Date()), DF.ApiTime),
        pickupDate: formatDateString(item.pickupDate, DF.ApiDate, DF.BookingInfoDate),
        pickupTime: format(parse(item.pickupTime, DF.ApiTimeFull, new Date()), DF.ApiTime),
      })),
    };
  }

  public static formatBookingBulkAssign(data: BulkAssignApi): BulkAssign {
    const { routes, ...rest } = Serializer.transformData(data);

    return { ...rest, bulkAssignRoutes: routes };
  }

  public static formatBookingDetails(data: BookingDetailsApi, isAccept?: boolean): BookingDetails {
    const {
      agencyFk,
      dispatchDate,
      drives,
      nOfPassengers,
      recurring,
      referenceCode,
      uuid,
      ...rest
    } = Serializer.transformData(data);

    return {
      ...rest,
      agencyFk: agencyFk
        ? {
            agencyId: agencyFk.agencyIdOnHbh,
            completeName: agencyFk.completeName,
            id: agencyFk.uuid,
            shortName: agencyFk.shortName,
          }
        : null,
      dispatchDate: dispatchDate ? formatDateString(dispatchDate, 'dd/MM/yyyy', 'dd MMM yyyy') : '',
      drives:
        drives?.map(({ uuid: id, ...restProps }: BookingDriveApi, idx: number) => ({
          ...restProps,
          driveNumber: idx + 1,
          id,
        })) || [],
      id: uuid,
      pax: nOfPassengers,
      recurringData: recurring || null,
      refCode: referenceCode,
      routes: data?.routes
        ?.filter((r: BDRouteApi) => (isAccept ? r.state === BookingState.NotProcessed : true))
        .map((route: BDRouteApi, idx: number) => {
          const {
            dropoffTime,
            pickupTime,
            uuid: routeId,
            ...otherProps
          } = Serializer.transformData(route);

          return {
            ...otherProps,
            dropoffTime: dropoffTime ? formatDateString(dropoffTime, 'HH:mm:ss', 'HH:mm') : '',
            id: routeId,
            pickupTime: pickupTime ? formatDateString(pickupTime, 'HH:mm:ss', 'HH:mm') : '',
            routeNumber: idx + 1,
          };
        }),
    };
  }

  // Feedback ================================================================
  public static formatFeedback(data: FeedbackListApi): IFeedbacksList {
    const { uuid, ...rest } = Serializer.transformData(data);

    return { ...rest, id: uuid };
  }

  // Financial ===============================================================
  public static formatDriveCosts(data: DriveCostsApi): DriveCostsType {
    return Serializer.transformData(data);
  }

  public static formatIndividualInvoiceTable(data: IndInvoiceListApi): IndInvoiceList {
    const { referenceCode, ...rest } = Serializer.transformData(data);

    return { ...rest, refCode: referenceCode };
  }

  public static formatIndividualInvoiceDetails(data: IndInvoiceDetailsApi): IndInvoiceDetails {
    const { agencyFk, referenceCode, ...rest } = Serializer.transformData(data);
    const { uuid, ...restProps } = agencyFk || {};

    return {
      ...rest,
      agency: agencyFk ? { ...restProps, id: uuid } : null,
      refCode: referenceCode,
    };
  }

  public static formatInvoiceHistory(data: InvoiceHistoryApi): InvoiceHistory {
    return Serializer.transformData(data);
  }

  // Mobility =================================================================
  private static formatLocation(data: LocationType): IDropdownOption {
    return {
      label: `${data.agency} - ${data.name} - ${data.city}, ${data.country}`,
      value: `${data.agencyId}|${data.locationId}`,
    };
  }

  public static formatDriver(data: DriverApi): DriverType {
    const { agency, currentUnavailability, licenseExpirationDate, uuid, ...rest } =
      Serializer.transformData(data);
    const { logoUrl, uuid: agencyId, ...restAgencyProps } = agency || {};
    const { deleted, uuid: id, ...restProps } = currentUnavailability || {};

    return {
      ...rest,
      agency: agency ? { ...restAgencyProps, id: agencyId, logo: logoUrl } : null,
      currentUnavailability: currentUnavailability
        ? { ...restProps, deleted: deleted || false, id }
        : null,
      id: uuid,
      licenseExpirationDate: licenseExpirationDate
        ? formatDateString(licenseExpirationDate, 'yyyy-MM-dd', 'dd/MM/yyyy')
        : '',
    };
  }

  public static formatDriverDetails(data: DriverDetailsApi): DriverDetails {
    const {
      agency,
      currentUnavailability,
      lastModifiedDate: mod,
      licenseExpirationDate: exp,
      licenseNumber,
      phoneNumber,
      sharedLocationOptions,
      sharedLocations,
      uuid,
      ...rest
    } = Serializer.transformData(data);
    const { uuid: agencyId, ...restAgency } = agency || {};
    const { uuid: id, ...restProps } = currentUnavailability || {};

    return {
      ...rest,
      agency: agency ? { ...restAgency, id: agencyId } : null,
      currentUnavailability: currentUnavailability ? { ...restProps, id } : null,
      id: uuid,
      lastModifiedDate: mod ? formatDateString(mod, 'dd/MM/yyyy HH:mm', 'MMM. dd, yyyy') : '',
      licenseExpirationDate: exp ? formatStringToDate(exp, DF.ApiDate) : undefined,
      licenseNumber: licenseNumber || '',
      phoneNumber: phoneNumber || '',
      sharedLocationOptions: sharedLocationOptions?.map(Serializer.formatLocation),
      sharedLocations: sharedLocations
        ?.map(Serializer.formatLocation)
        ?.map((i: IDropdownOption) => i.value),
    };
  }

  public static formatSharedLocations(data: LocationApi): IDropdownOption {
    return {
      label: `${data.agency} - ${data.name} - ${data.city}, ${data.country}`,
      value: `${data.agency_id}|${data.location_id}`,
    };
  }

  public static formatVehicle(data: VehicleApi): VehicleType {
    const { agency, currentUnavailability, model, preferredDriver, uuid, ...rest } =
      Serializer.transformData(data);
    const { logoUrl, uuid: agencyId, ...restAgencyProps } = agency || {};
    const { deleted, uuid: id, ...p } = currentUnavailability || {};
    const { fullName, image, uuid: modelId } = model || {};
    const { uuid: prefId, ...restPrefProps } = preferredDriver || {};

    return {
      ...rest,
      agency: agency ? { ...restAgencyProps, id: agencyId, logo: logoUrl } : null,
      currentUnavailability: currentUnavailability ? { ...p, deleted: deleted || false, id } : null,
      id: uuid,
      model: model ? { id: modelId, image, name: fullName } : null,
      preferredDriver: preferredDriver ? { ...restPrefProps, id: prefId } : null,
    };
  }

  public static formatVehicleDetails(data: VehicleDetailsApi): VehicleDetails {
    const { agency, maxCapacity, model, sharedLocationOptions, sharedLocations, uuid, ...rest } =
      Serializer.transformData(data);
    const { logoUrl, uuid: agencyId, ...restAgencyProps } = agency || {};
    const { fullName, image, pk: modelId } = model || {};

    return {
      ...rest,
      agency: agency ? { ...restAgencyProps, id: agencyId, logo: logoUrl } : null,
      gvlpNumber: data.gvlp_number || '',
      id: uuid,
      maxCapacity: maxCapacity || 1,
      model: model ? { id: modelId, image, name: fullName } : null,
      sharedLocationOptions: sharedLocationOptions?.map(Serializer.formatLocation),
      sharedLocations: sharedLocations
        ?.map(Serializer.formatLocation)
        ?.map((i: IDropdownOption) => i.value),
    };
  }

  public static formatVehicleModels(data: VehicleModelListApi): IDropdownOption {
    return { label: data.full_name, value: data.pk };
  }

  public static mapSharedLocationToUpdate(data: string): SharedLocationApi {
    const [agencyId, locationId] = data.split('|');

    return { agency_id: agencyId, location_id: locationId };
  }

  // Mobility Logbook =============================================================
  public static formatLogbookDrives(data: LBDrivesApi): LBDrives {
    const { booking, feedback, nOfPassengers, isOfflineSignature, offlineSignature, ...rest } =
      Serializer.transformData(data);

    return {
      ...rest,
      booking,
      clientSignature:
        feedback?.clientSignature || isOfflineSignature
          ? `${
              feedback?.clientSignature
            }|${!!feedback?.clientSignature}|${isOfflineSignature}|${offlineSignature}`
          : '',
      feedback,
      isOfflineSignature,
      offlineSignature,
      pax: nOfPassengers,
      paxAgency: booking.agency?.toUpperCase() || '—',
      paxName: `${booking.firstName} ${booking.lastName}` || '—',
    };
  }

  // Mobility Planner =============================================================
  public static formatDriveToFormData(data: DriveApi): Drive {
    const {
      booking: { nOfPassengers, referenceCode, ...restProps },
      uuid,
      ...rest
    } = Serializer.transformData(data);

    return {
      ...rest,
      booking: {
        ...restProps,
        pax: nOfPassengers,
        refCode: referenceCode,
      },
      id: uuid,
    };
  }

  public static formatDriverToPlanner(data: PlDriverApi): PlDriver {
    const { driverIdentifier, ...rest } = Serializer.transformData(data);

    return { ...rest, id: driverIdentifier, title: `${data.name} ${data.last_name}` };
  }

  public static formatDrives(data: PlDriveApi): PlDrive {
    const {
      bookingReferenceCode,
      driverPhoneNumber,
      passengerNumberOfPax,
      passengerRemarks,
      uuid,
      ...rest
    } = Serializer.transformData(data);

    return {
      ...rest,
      bookingRefCode: bookingReferenceCode,
      driverPhone: driverPhoneNumber ?? '',
      id: uuid,
      passengerPax: Number(passengerNumberOfPax) || null,
      passengerRemarks: passengerRemarks ?? '',
      workflowDescription: data?.workflow_description || '',
    };
  }

  public static formatDrivesToEvents({
    data,
    resourceType,
  }: {
    data: PlDriveApi[];
    resourceType: ResViewType;
  }): PlEvent[] {
    return data.map((drive: PlDriveApi) => {
      const dropoffDate = `${drive.dropoff_date} ${drive.dropoff_time}`;
      const pickupDate = `${drive.pickup_date} ${drive.pickup_time}`;

      return {
        className: Serializer.getEventClass(
          drive.editable_state,
          drive.is_conflicting,
          drive.booking_transfer_type === TransferType.Shuttle,
        ),
        end: parse(dropoffDate, DF.ApiDateAltFull, new Date()),
        id: drive.uuid,
        resourceId: resourceType === ResViewType.Driver ? drive.driver_id : drive.vehicle_id,
        start: parse(pickupDate, DF.ApiDateAltFull, new Date()),
        title: drive.driver_first_name,
        extendedProps: Serializer.formatDrives(drive),
      };
    });
  }

  public static formatQuestToEvents(
    drivers: PlDriver[],
    resourceType: ResViewType,
    workflows: QuestWorkflowsApi[],
  ): PlEvent[] {
    return workflows.map((item: QuestWorkflowsApi, idx: number) => {
      const driver = drivers.find((d: PlDriver) => d.id === item.driver_id);

      return {
        className: 'fc-event-quest-workflow',
        end: parseISO(item.end_trip_date_time?.slice(0, -1)),
        id: `${item.drive_ref_code}-${idx}`,
        resourceId: resourceType === ResViewType.Driver ? item.driver_id : item.vehicle_id,
        start: parseISO(item.start_trip_date_time?.slice(0, -1)),
        extendedProps: {
          bookingRefCode: item.drive_ref_code,
          driverAgency: driver ? driver.driverAgency : '',
          driverEmail: driver ? driver.email : '',
          driverFirstName: driver ? driver.name : '',
          driverId: item.driver_id,
          driverLastName: driver ? driver.lastName : '',
          driverPhone: driver ? driver.phoneNumber : '',
          isQuestWorkflowsEvent: true,
        },
      };
    });
  }

  public static formatQuestToDrives(data: QuestWorkflowsApi[]): PlDrive[] {
    return data.map((item: QuestWorkflowsApi, idx: number) => ({
      bookingRefCode: item.drive_ref_code,
      bookingTransferType: TransferType.InTown,
      driverId: item.driver_id,
      dropoffDate: format(parseISO(item.end_trip_date_time?.slice(0, -1)), 'dd/MM/yyyy'),
      dropoffTime: format(parseISO(item.end_trip_date_time?.slice(0, -1)), 'HH:mm:ss'),
      id: `${item.drive_ref_code.replace(/\s/g, '')}-${idx}`,
      isConflicting: false,
      pickupDate: format(parseISO(item.start_trip_date_time?.slice(0, -1)), 'dd/MM/yyyy'),
      pickupTime: format(parseISO(item.start_trip_date_time?.slice(0, -1)), 'HH:mm:ss'),
      vehicleId: item.vehicle_id,
    }));
  }

  public static formatShuttleToDrives(data: PlShuttleApi): PlDrive {
    return {
      bookingRefCode: '',
      bookingTransferType: data.transfer_type,
      driverAgency: data.driver_agency,
      driverEmail: data.driver_email,
      driverFirstName: data.driver_label,
      driverPhone: data.driver_phone_number,
      driverId: data.driver_id,
      dropoffDate: formatDateString(data.dropoff_date, DF.ApiDate, DF.ApiDateAlt),
      dropoffLocation: data.dropoff_location,
      dropoffTime: data.dropoff_time,
      dropoffTown: data.dropoff_town,
      id: data.scheduled_route_id,
      isConflicting: false,
      passengerPax: data.vehicle_max_capacity,
      pickupDate: formatDateString(data.pickup_date, DF.ApiDate, DF.ApiDateAlt),
      pickupLocation: data.pickup_location,
      pickupTime: data.pickup_time,
      pickupTown: data.pickup_town,
      shuttleConfig: {
        id: data.assignment_id,
        stops: data.stops?.map((i: ShuttleStopsApi) => {
          const { dropoffTime, pickupTime, ...rest } = Serializer.transformData(i);

          return {
            ...rest,
            dropoffTime: formatDateString(dropoffTime, DF.ApiTimeFull, DF.ApiTime),
            pickupTime: formatDateString(pickupTime, DF.ApiTimeFull, DF.ApiTime),
          };
        }),
        weekdays: data?.assignment_weekdays,
      },
      scheduledRouteId: data.scheduled_route_id,
      vehicleId: data.vehicle_id,
      vehicleLabel: data.vehicle_label,
    };
  }

  public static formatShuttleToEvents(data: PlShuttleApi[], resourceType: ResViewType): PlEvent[] {
    return data.map((item: PlShuttleApi, idx: number) => {
      const start = `${item.pickup_date} ${item.pickup_time || '00:00:00'}`;
      const end = `${item.dropoff_date} ${item.dropoff_time || '00:00:00'}`;

      return {
        className: 'fc-event-shuttle-route',
        end: parse(end, DF.ApiDateFull, new Date()),
        id: `${item.scheduled_route_id}-${idx}`,
        order: 1,
        resourceId: resourceType === ResViewType.Driver ? item.driver_id : item.vehicle_id,
        start: parse(start, DF.ApiDateFull, new Date()),
        title: 'Shuttle',
        extendedProps: {
          ...Serializer.formatShuttleToDrives(item),
          isShuttleEvent: true,
        },
      };
    });
  }

  public static formatVehicleToPlanner(data: PlVehicleApi): PlVehicle {
    const { label, vehicleIdentifier, ...rest } = Serializer.transformData(data);

    return { ...rest, id: vehicleIdentifier, title: label };
  }

  public static mapAcceptingDataToDrives(
    data: PlAcceptDrive[],
    shuttleId: string | null,
  ): DriveCreateApi[] {
    return data.map((i) => ({
      comment_to_driver: i.commentDriver || null,
      comment_to_pax: i.commentPax || null,
      driver: i.driverId || '',
      dropoff_date: i.dropoffDate,
      dropoff_location: i.dropoffLocation,
      dropoff_time: i.dropoffTime,
      dropoff_town: i.dropoffTown,
      pickup_date: i.pickupDate,
      pickup_location: i.pickupLocation,
      pickup_time: i.pickupTime,
      pickup_town: i.pickupTown,
      route_uuid: i.id,
      scheduled_route_id: shuttleId || i.scheduledRouteId,
      vehicle: i.vehicleId || '',
      uuid: i.id,
    }));
  }

  public static mapDriveToUpdate(data: BDDrive): DriveUpdate {
    return {
      driver: data.driver,
      dropoff_date: data.dropoffDate,
      dropoff_location: data.dropoffLocation,
      dropoff_time: data.dropoffTime,
      dropoff_town: data.dropoffTown,
      is_active: data.isActive,
      pickup_date: data.pickupDate,
      pickup_location: data.pickupLocation,
      pickup_time: data.pickupTime,
      pickup_town: data.pickupTown,
      state: data.state,
      uuid: data.id,
      vehicle: data.vehicle,
    };
  }

  public static mapDriveEditToUpdate(data: FormDrive, drive: BDDrive): DriveUpdate {
    return {
      comment_to_driver: data.commentDriver || null,
      comment_to_pax: data.commentPax || null,
      driver: data.driver.value,
      dropoff_date: format(new Date(data.dropoffDate), DF.ApiDate),
      dropoff_location: drive.dropoffLocation,
      dropoff_time: format(new Date(data.dropoffTime), DF.ApiTime),
      dropoff_town: drive.dropoffTown,
      is_active: drive.isActive,
      pickup_date: format(new Date(data.pickupDate), DF.ApiDate),
      pickup_location: drive.pickupLocation,
      pickup_time: format(new Date(data.pickupTime), DF.ApiTime),
      pickup_town: drive.pickupTown,
      state: drive.state,
      uuid: drive.id,
      vehicle: data.vehicle.value,
    };
  }

  public static mapPlannerDriveForm(
    data: DriveFormState,
    routeId?: string | null,
    verifiedTransfer?: TransferType | null,
  ): DriveFormDataApi {
    const {
      mainInfo,
      returnTrip,
      tripInfo: { transferType, typeOfTrip },
    } = data;

    const isMultileg = typeOfTrip?.value === TripType.MultiLeg;
    const isRoundTrip = typeOfTrip?.value === TripType.RoundTrip;
    const { recurringFrequency, recurringPeriod, recurringUntil } = isRoundTrip
      ? returnTrip
      : data.tripInfo;

    // eslint-disable-next-line no-nested-ternary
    const driveData = isMultileg
      ? data.tripInfo.driveListMultileg
      : isRoundTrip
      ? [...(data.tripInfo.driveList || []), ...(data.returnTrip.driveList || [])]
      : data.tripInfo.driveList;

    const pax = parseInt(mainInfo.pax?.value ?? '0', 10);
    const transferTypeValue = verifiedTransfer || transferType;

    const submitData = {
      booking_details: {
        agency: mainInfo.agency?.label,
        agency_fk: mainInfo.agency?.value,
        email: mainInfo.email?.toLowerCase(),
        first_name: mainInfo.firstName,
        index_number: mainInfo.indexNumber,
        last_name: mainInfo.lastName,
        phone_number: mainInfo.phoneNumber,
      },
      driver_detail: {
        passenger_infos: (mainInfo.passengersInfo || [])
          .slice(0, Math.max(0, Number(pax) - 1))
          .map((i) => ({
            agency: i.agency,
            email: i.email,
            first_name: i.firstName,
            last_name: i.lastName,
            phone_number: i.phoneNumber,
            requesting_unit: (i.requestingUnit as IDropdownOption)?.value,
          })),
        transfer_type: transferTypeValue,
        type_of_trip: typeOfTrip?.value ?? TripType.OneWay,
      },
      booking_extra: {
        budget_code: mainInfo.budgetCode ?? '',
        manager_email: mainInfo.managerEmail ?? '',
        n_of_passengers: pax,
        remarks: mainInfo.remarks || null,
        requesting_unit: mainInfo.requestingUnit?.value ?? '',
        purpose: data.tripInfo.purpose?.value ?? '',
      },
      drives: driveData?.map((drive) => {
        const {
          dropoffAddress,
          dropoffLat,
          dropoffLng,
          estimated,
          pickupAddress,
          pickupLat,
          pickupLng,
        } = drive;
        const scheduledRouteId = (transferTypeValue === TransferType.Shuttle && routeId) || null;
        const hasLatLng = dropoffLat && pickupLat && !scheduledRouteId;

        return {
          driver: drive.driver?.value,
          dropoff_date: format(new Date(drive.dropoffDate), DF.ApiDate),
          dropoff_time: format(new Date(drive.dropoffTime), DF.ApiTime),
          dropoff_location: dropoffAddress
            ? `${dropoffAddress} | ${getFieldValue(drive.dropoffLocation)}`
            : getFieldValue(drive.dropoffLocation),
          dropoff_town: getFieldValue(drive.dropoffTown),
          pickup_date: format(new Date(drive.pickupDate), DF.ApiDate),
          pickup_time: format(new Date(drive.pickupTime), DF.ApiTime),
          pickup_location: pickupAddress
            ? `${pickupAddress} | ${getFieldValue(drive.pickupLocation)}`
            : getFieldValue(drive.pickupLocation),
          pickup_town: getFieldValue(drive.pickupTown),
          scheduled_route_id: scheduledRouteId,
          vehicle: drive.vehicle?.value,
          ...(estimated &&
            hasLatLng && {
              estimated_distance: estimated.distance,
              estimated_duration: estimated.duration,
            }),
          ...(hasLatLng && {
            dropoff_latitude: dropoffLat,
            dropoff_longitude: dropoffLng,
            pickup_latitude: pickupLat,
            pickup_longitude: pickupLng,
          }),
          ...(drive?.commentToDriver && { comment_to_driver: drive.commentToDriver }),
          ...(drive?.commentToPax && { comment_to_pax: drive.commentToPax }),
        };
      }),
      has_passengers: pax > 0,
      is_booking_on_behalf_of_colleague: false,
    } as DriveFormDataApi;

    if (data.addRecurring && recurringPeriod) {
      const pickupDate = submitData.drives[0].pickup_date;

      submitData.recurring_option = {
        start_date: pickupDate && format(new Date(pickupDate), DF.ApiDate),
        end_date: format(new Date(recurringUntil!), DF.ApiDate),
        frequency: recurringPeriod?.value,
      };

      if (recurringPeriod?.value === '3' && recurringFrequency) {
        const frequencyValue = +recurringFrequency;
        submitData.recurring_option.weekdays =
          frequencyValue === 6
            ? [...Array.from(Array(4).keys()), 6]
            : [...Array.from(Array(frequencyValue).keys())];
      }
    }

    return submitData;
  }

  public static mapPlannerDriveToUpdate({
    data,
    driveId,
    routeId,
    verifiedTransfer,
  }: {
    data: DriveFormState;
    driveId: string;
    routeId?: string | null;
    verifiedTransfer?: TransferType | null;
  }): DriveUpdateApi {
    const {
      mainInfo: {
        agency,
        budgetCode,
        email,
        firstName,
        indexNumber,
        lastName,
        managerEmail,
        noPax,
        pax,
        passengersInfo,
        phoneNumber,
        remarks,
        requestingUnit,
      },
      tripInfo: { driveList, driveListMultileg, purpose, transferType, typeOfTrip },
    } = data;

    const transferTypeValue = verifiedTransfer || transferType;
    const driveData =
      typeOfTrip?.value === TripType.MultiLeg && driveListMultileg?.length
        ? driveListMultileg[0]
        : driveList?.[0];
    const scheduledRouteId = (transferTypeValue === TransferType.Shuttle && routeId) || null;

    const {
      dropoffAddress,
      dropoffLat,
      dropoffLng,
      estimated,
      pickupAddress,
      pickupLat,
      pickupLng,
    } = driveData || {};

    return {
      booking: {
        agency: agency?.label,
        agency_fk: agency?.value,
        budget_code: budgetCode || '',
        email: email || '',
        first_name: firstName || '',
        index_number: indexNumber || '',
        last_name: lastName || '',
        manager_email: managerEmail || '',
        n_of_passengers: pax ? Number(pax?.value) : 0,
        phone_number: phoneNumber || '',
        purpose: purpose?.value || '',
        remarks: remarks || null,
        passengers_info: (passengersInfo || [])
          .slice(0, Math.max(0, Number(pax?.value) - 1))
          .map((i) => ({
            agency: i.agency,
            email: i.email,
            first_name: i.firstName,
            last_name: i.lastName,
            phone_number: i.phoneNumber,
            pk: i.pk,
            requesting_unit: (i.requestingUnit as IDropdownOption)?.value,
          })),
        requesting_unit: requestingUnit.value || '',
        transfer_type: transferTypeValue,
        type_of_trip: typeOfTrip?.value ?? TripType.OneWay,
      },
      driver: driveData?.driver?.value || null,
      dropoff_date: format(driveData?.dropoffDate!, DF.ApiDate),
      dropoff_location: dropoffAddress
        ? `${dropoffAddress} | ${getFieldValue(driveData?.dropoffLocation!)}`
        : getFieldValue(driveData?.dropoffLocation!),
      dropoff_time: format(driveData?.dropoffTime!, DF.ApiTime),
      dropoff_town: driveData?.dropoffTown ? getFieldValue(driveData?.dropoffTown) : '',
      has_passengers: !noPax,
      pickup_date: format(driveData?.pickupDate!, DF.ApiDate),
      pickup_location: pickupAddress
        ? `${pickupAddress} | ${getFieldValue(driveData?.pickupLocation!)}`
        : getFieldValue(driveData?.pickupLocation!),
      pickup_time: format(driveData?.pickupTime!, DF.ApiTime),
      pickup_town: driveData?.pickupTown ? getFieldValue(driveData?.pickupTown) : '',
      uuid: driveId,
      scheduled_route_id: scheduledRouteId,
      vehicle: driveData?.vehicle?.value || null,
      ...(driveData?.commentToDriver && { comment_to_driver: driveData.commentToDriver }),
      ...(driveData?.commentToPax && { comment_to_pax: driveData.commentToPax }),
      ...(!scheduledRouteId && {
        ...(estimated && {
          estimated_distance: estimated.distance,
          estimated_duration: estimated.duration,
        }),
        ...(dropoffLat && {
          dropoff_latitude: dropoffLat,
          dropoff_longitude: dropoffLng,
        }),
        ...(pickupLat && {
          pickup_latitude: pickupLat,
          pickup_longitude: pickupLng,
        }),
      }),
    };
  }

  public static mapShuttleConfigToUpdate(
    data: ShuttleConfigFormState,
    formData: Partial<PlDrive>,
  ): ShuttleConfigUpdateApi {
    return {
      driver_id: data.driver.value,
      end_date: format(data?.dropoffDate, DF.ApiDate),
      scheduled_route_id: formData.scheduledRouteId!,
      start_date: format(data?.pickupDate, DF.ApiDate),
      uuid: formData.shuttleConfig?.id!,
      vehicle_id: data.vehicle.value,
      weekdays: data.weekdays.map(Number),
    };
  }

  // Reports =================================================================
  public static formatCountryReport(data: ICountryReportApi): ICountryReport {
    const { drives, electricVehicleDrives, ...rest } = Serializer.transformData(data);

    return {
      ...rest,
      drives,
      drivesDieselPetrol: drives - electricVehicleDrives,
      drivesElectric: electricVehicleDrives,
    };
  }

  public static formatDriveWithWorkflow(data: IDriveWithWorkflowApi): IDriveWithWorkflow {
    return Serializer.transformData(data);
  }

  public static formatMonthlySummary(data: IMonthlySummaryApi): IMonthlySummary {
    const { amount, greenFund, totalMileage, totalTime, ...rest } = Serializer.transformData(data);

    return {
      ...rest,
      amount: amount || '0.0',
      greenFund: greenFund || '0.0',
      totalMileage: totalMileage || '0',
      totalTime: totalTime || '00:00',
    };
  }

  public static formatReportDetails(data: ReportDetailsListApi): ReportDetailsList {
    const { budgetCode, referenceCode, uuid, ...rest } = Serializer.transformData(data);

    return { ...rest, budgetCode: budgetCode ?? '', id: uuid, refCode: referenceCode };
  }

  public static formatSummary(data: ISummaryListApi): ISummaryList {
    return Serializer.transformData(data);
  }

  // UNDSS Travel Request =====================================================

  public static formatTravelRequest(data: TravelRequestListApi): TravelRequestTripList {
    return {
      id: data.uuid,
      refCode: data.reference_code,
      state: data.travel_request_state ?? 'not_submitted',
      submitDate: data.travel_request_submit_date ?? '—',
      trips: data.itineraries.map((item: TravelRequestItinerariesApi) => ({
        countryCode: item.country_code,
        dropoffCityLabel: item.dropoff_city_label,
        dropoffCityOption:
          item.dropoff_city_label && item.dropoff_city_value
            ? { label: item.dropoff_city_label, value: item.dropoff_city_value }
            : undefined,
        dropoffCityValue: item.dropoff_city_value,
        dropoffDate: item.dropoff_date,
        dropoffTime: item.dropoff_time,
        dropoffTown: item.dropoff_town,
        id: item.uuid,
        inTransit: Boolean(item.in_transit),
        officialBusiness: Boolean(item.official_business),
        pickupCityLabel: item.pickup_city_label,
        pickupCityOption:
          item.pickup_city_label && item.pickup_city_value
            ? { label: item.pickup_city_label, value: item.pickup_city_value }
            : undefined,
        pickupCityValue: item.pickup_city_value,
        pickupDate: item.pickup_date,
        pickupTime: item.pickup_time,
        pickupTown: item.pickup_town,
        purpose: item.purpose,
        remarks: item.remarks,
        vehicleModel: item.vehicle_model,
        vehiclePlateNumber: item.vehicle_plate_number,
      })),
    };
  }

  public static formatTravelRequestAgency(data: TravelRequestAgencyListApi): IDropdownOption {
    return { label: `${data.short_name} (${data.long_name})`, value: String(data.agency_id) };
  }

  public static formatTravelRequestProfileCity(data: TravelRequestCitiesListApi): IDropdownOption {
    return { label: `${data.name} (${data.country_code})`, value: String(data.external_id) };
  }

  public static formatTravelRequestCity(data: TravelRequestCitiesListApi): IDropdownOption {
    return { label: `${data.name} (${data.country_code})`, value: String(data.uuid) };
  }

  public static formatTravelRequestCountry(data: TravelRequestCountriesListApi): IDropdownOption {
    return { label: `${data.name} (${data.country_code})`, value: data.country_code };
  }

  public static mapTravelRequestProfileForm(
    data: TravelRequestProfileFormState,
  ): TravelRequestProfileCreate {
    return {
      country_code_at_birth: data.countryCodeAtBirth.value,
      country_code_at_present: data.countryCodeAtPresent.value,
      date_of_birth: format(new Date(data.dateOfBirth), 'yyyy-MM-dd'),
      first_name: data.firstName,
      gender: data.gender.value,
      last_name: data.lastName,
      middle_name: data.middleName,
      profile_details: {
        agency_id: Number(data.agency.value),
        duty_station_city_id: Number(data.dutyStationCityId.value),
      },
      profile_type_id: Number(data.profileTypeId.value),
      salutation_id: Number(data.salutationId.value),
      user_name: data.username,
    };
  }

  public static mapTravelRequestTripForm(
    data: TravelRequestTripEditFormItinerary,
  ): TravelRequestTripItinerary {
    return {
      arrival_city_id: data.arrivalCity.value,
      arrival_date: formatDateString(
        `${format(data.arrivalDate, 'yyyy-MM-dd')} ${data.arrivalTime}`,
        'yyyy-MM-dd HH:mm:ss',
        "yyyy-MM-dd'T'HH:mm",
      ),
      departure_city_id: data.departureCity.value,
      departure_date: formatDateString(
        `${format(data.departureDate, 'yyyy-MM-dd')} ${data.departureTime}`,
        'yyyy-MM-dd HH:mm:ss',
        "yyyy-MM-dd'T'HH:mm",
      ),
      drive_id: data.id,
      in_transit: data.inTransit,
      official_business: data.officialBusiness,
      purpose: data.purpose,
      remarks: data.remarks,
    };
  }
}

export default Serializer;
