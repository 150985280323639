import type { FC, ReactNode } from 'react';
import { Loader as LoaderComponent, LoaderProps } from '@mantine/core';
import cn from 'classnames';
import './styles.scss';

interface SpinnerProps extends LoaderProps {
  children?: ReactNode;
  fullScreen?: boolean;
  spinning?: boolean;
  text?: string;
}

const Loader: FC<SpinnerProps> = ({
  children,
  fullScreen = false,
  spinning = false,
  text,
  ...rest
}: SpinnerProps) => (
  <div className={cn('page-loader', { 'page-loader-single': !children })}>
    {spinning && (
      <div
        className={cn('page-loader-spinner', { 'page-loader-full-screen': fullScreen })}
        data-testid="page-loader"
      >
        <LoaderComponent radius="xl" size="lg" type="oval" {...rest} />
        {text}
      </div>
    )}
    {children && spinning ? <div className="page-loader-content-wrap">{children}</div> : children}
  </div>
);

export default Loader;
