/* istanbul ignore file */
import { BFShuttleRoute } from '@common/interfaces';
import { DateFormat } from '@common/types';
import { DatesRangeValue } from '@mantine/dates';
import { addDays, addMinutes, getDay, max, min, parse, startOfHour } from 'date-fns';

export const calcMinDate = (date: Date, start: string, end: string, weekdays: number[]): Date => {
  const startTime = parse(start, DateFormat.ApiTime, new Date());
  const endTime = parse(end, DateFormat.ApiTime, new Date());
  let minDate = startTime > endTime ? addDays(date, 1) : date;

  while (!weekdays.includes(getDay(minDate) - 1)) {
    minDate = addDays(minDate, 1);
  }

  return minDate;
};

export const findRange = (shuttleRoutes: BFShuttleRoute[]): DatesRangeValue => {
  if (!shuttleRoutes || shuttleRoutes.length === 0) {
    return [null, null];
  }

  const minDate = min(shuttleRoutes.map((route) => route.startDate));
  const maxDate = max(shuttleRoutes.map((route) => route.endDate));

  return [minDate, maxDate];
};

export const timeQuarter = (date: Date): Date => {
  const roundedTime = startOfHour(date);
  const roundedMinutes = Math.ceil(date.getMinutes() / 15) * 15;

  return addMinutes(roundedTime, roundedMinutes);
};
