import type { FC } from 'react';
import './styles.scss';

interface BalanceProps {
  label: string;
  value: string;
  wide?: boolean;
}

const Balance: FC<BalanceProps> = ({ label, value, wide = false }) => (
  <section
    className={`${'invoice-total'} ${wide ? 'wide' : ''}`}
    data-testid="invoice-total-section"
  >
    <span className="label">{label}</span>
    <span data-testid="invoice-total-balance" className="value">
      <span className="currency">$</span>
      {value}
    </span>
  </section>
);

export default Balance;
