import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainMenu } from '@unbooking/ui-modules';
import { Loader } from '@components';
import { useFacility } from '@common/hooks';
import { ChecklistMenu, Driver, MobilityLogbook, Vehicle } from '@assets/svg/icons';
import { DriverLogo } from '@assets/svg/logos';
import './styles.scss';

const MobilityMenuPage: FC = () => {
  const navigate = useNavigate();
  const {
    agencyName,
    facility: { city, country, facilityName },
    isLoading,
  } = useFacility();
  const { t } = useTranslation();

  const menuButtons = [
    {
      label: t('mobility.vehiclesChecklist'),
      className: 'btn-checklist',
      icon: <ChecklistMenu />,
      onClick: () => navigate('vehicles-checklist'),
    },
    {
      label: t('mobility.myDrivers'),
      className: 'btn-my-drivers',
      icon: <Driver />,
      onClick: () => navigate('driver-list'),
    },
    {
      label: t('mobility.myVehicles'),
      className: 'btn-my-vehicles',
      icon: <Vehicle />,
      onClick: () => navigate('vehicle-list'),
    },
    {
      label: t('mobility.mobilityLogbook'),
      className: 'btn-logbook',
      icon: <MobilityLogbook />,
      onClick: () => navigate('logbook'),
    },
  ];

  return (
    <Loader color="white" spinning={isLoading}>
      <MainMenu
        logo={<DriverLogo />}
        menuButtons={menuButtons}
        title={
          city && country && !isLoading
            ? `${facilityName}, ${city}, ${country} | ${agencyName}`
            : ''
        }
      />
    </Loader>
  );
};

export default MobilityMenuPage;
