import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ReviewRating } from '@components';
import { FeedbackStatus, IFeedbacksList, FeedbackUpdateApi } from '@common/interfaces';
import { formatDateString } from '@common/utils';
import {
  DropdownCaret,
  FeedbackCalendar,
  FeedbackDriver,
  FeedbackUser,
  Ride,
  Vehicle,
} from '@assets/svg/icons';
import { ReviewAnswerForm, StatusSelector } from '..';
import './styles.scss';

export interface IFeedback {
  color: 'red' | 'yellow' | 'green' | 'grey';
  item: IFeedbacksList;
  showRating?: boolean;
  updateFeedbackStatus?: ({
    feedbackId,
    feedbackData,
  }: {
    feedbackId: string;
    feedbackData: FeedbackUpdateApi;
  }) => void;
}

const Feedback: FC<IFeedback> = ({ color, item, showRating = true, updateFeedbackStatus }) => {
  const { t } = useTranslation();

  const [commentVisible, setCommentVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<FeedbackStatus>();

  const getReviewStarHexColorCode = (colorType: string): string => {
    switch (colorType) {
      case 'red':
        return '#b21217';
      case 'yellow':
        return '#c6bb1a';
      case 'green':
        return '#0c9415';
      case 'grey':
        return '#444546';
      default:
        return '#fff';
    }
  };

  const getFormConfirmButtonTextByStatus = (nextStatus: FeedbackStatus): string => {
    switch (nextStatus) {
      case 'under_process':
      case 'closed':
        return t('feedback.btnConfirmRemark');
      case 'forwarded_for_action':
        return t('feedback.btnForward');
      default:
        return '';
    }
  };

  const getFormPlaceholderByStatus = (nextStatus: FeedbackStatus): string => {
    switch (nextStatus) {
      case 'under_process':
        return t('feedback.placeholderStatusUnderProcess');
      case 'forwarded_for_action':
        return t('feedback.placeholderStatusForwarded');
      case 'closed':
        return t('feedback.placeholderStatusClosed');
      default:
        return '';
    }
  };

  const getFormTitleByStatus = (nextStatus: FeedbackStatus): string => {
    switch (nextStatus) {
      case 'under_process':
        return t('feedback.insertRemark');
      case 'forwarded_for_action':
        return t('feedback.suggestion');
      case 'closed':
        return t('feedback.confirmOrEditRemark');
      default:
        return '';
    }
  };

  const getLastActionText = (lastActionValue: FeedbackStatus): string => {
    switch (lastActionValue) {
      case 'under_process':
        return t('feedback.processing');
      case 'forwarded_for_action':
        return t('feedback.forwardForAction');
      case 'closed':
        return t('feedback.closed');
      default:
        return '';
    }
  };

  const getTriggerText = (): string => {
    const { comment, remarkSet } = item;
    const subject =
      comment && remarkSet.length > 0 ? t('feedback.commentAndRemark') : t('feedback.comment');
    const action = commentVisible ? t('feedback.close') : t('feedback.see');

    return `${action} ${subject}`;
  };

  const onPendingStatusChange = (status: FeedbackStatus): void => {
    setPendingStatus(status);
    setModalVisible(true);
  };

  const {
    comment,
    created,
    drive: {
      bookingReferenceCode,
      driverFullName,
      dropoffLocation,
      pickupDate,
      pickupLocation,
      vehiclePlateNumber,
    },
    id,
    lastStatusChangedBy,
    rate,
    remarkSet,
    status,
  } = item;

  return (
    <section className="feedback-container">
      <section className="feedback">
        <div className="feedback-created">
          <div className="feedback-created-icon">
            <FeedbackUser />
          </div>
          <div className="feedback-created-date">
            {formatDateString(created, 'dd/MM/yyyy HH:mm', 'dd MMM yyyy')}
          </div>
        </div>

        {showRating && (
          <div className="feedback-rate">
            <ReviewRating
              backgroundColor={getReviewStarHexColorCode(color)}
              orientation="left"
              value={rate}
            />
          </div>
        )}

        <div className="feedback-details">
          <div className="feedback-details-header">
            <div className="feedback-details-review">
              <ul className="feedback-details-review-subject">
                <li>
                  <div className="icon">
                    <FeedbackDriver />
                  </div>
                  <div className="text">
                    <div className="label">{t('common.driver')}</div>
                    <div className="value">{driverFullName}</div>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <FeedbackCalendar />
                  </div>
                  <div className="text">
                    <div className="label">{t('feedback.dateOfRide')}</div>
                    <div className="value">{pickupDate}</div>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <Ride />
                  </div>
                  <div className="text">
                    <div className="label">{t('feedback.ride')}</div>
                    <div className="value">{`${pickupLocation} - ${dropoffLocation}`}</div>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <Vehicle />
                  </div>
                  <div className="text">
                    <div className="label">{t('mobility.plateNumber')}</div>
                    <div className="value">{vehiclePlateNumber}</div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="feedback-status-selector">
              <StatusSelector status={status} onChange={onPendingStatusChange} />
            </div>
          </div>

          <div
            className={commentVisible || !comment ? 'feedback-comment open' : 'feedback-comment'}
          >
            <div className="comment">
              <div className="question">{comment}</div>

              {remarkSet
                .filter((i) => i.remark.trim().length > 0)
                .map((i) => (
                  <div className="feedback-answer-wrap" key={i.created}>
                    <div className="feedback-answer">
                      <div className="feedback-answer-by">
                        <div className="feedback-answer-by-prefix">{t('feedback.remarkedBy')}</div>
                        <div className="feedback-answer-by-name">{i.remarkedBy}</div>
                        <div className="feedback-answer-date">{created}</div>
                      </div>

                      <div className="feedback-answer-text">
                        <span
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: i.remark.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="feedback-footer">
            <div className="feedback-id">
              {bookingReferenceCode ? `ID #${bookingReferenceCode}` : ''}
            </div>

            <div className="comment-trigger">
              {(comment || (remarkSet && remarkSet.length > 0)) && (
                <button
                  className={commentVisible ? 'btn-trigger enabled' : 'btn-trigger'}
                  type="button"
                  onClick={() => {
                    setCommentVisible(!commentVisible);
                  }}
                >
                  <span className="text">{getTriggerText()}</span>
                  <span className="icon-caret">
                    <DropdownCaret />
                  </span>
                </button>
              )}
            </div>

            <div className="last-action">
              {!!lastStatusChangedBy && (
                <>
                  {`${status && getLastActionText(status)} by `}
                  <span className="last-action-by">{lastStatusChangedBy}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal
        showBtnClose
        variant="light"
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <ReviewAnswerForm
          confirmButtonText={pendingStatus && getFormConfirmButtonTextByStatus(pendingStatus)}
          feedback={(pendingStatus === 'forwarded_for_action' && comment) || undefined}
          needAdminEmail={false}
          placeholder={pendingStatus && getFormPlaceholderByStatus(pendingStatus)}
          requireValidation={pendingStatus === 'forwarded_for_action'}
          title={pendingStatus && getFormTitleByStatus(pendingStatus)}
          onConfirm={(value: string) => {
            if (pendingStatus) {
              updateFeedbackStatus?.({
                feedbackId: id,
                feedbackData: {
                  comment: value && value.trim().length > 0 ? value : '',
                  status: pendingStatus,
                },
              });
            }
            setPendingStatus(undefined);
          }}
        />
      </Modal>
    </section>
  );
};

export default Feedback;
