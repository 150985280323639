import {
  IVehiclesChecklistRepository,
  VehicleChecklistAgg,
  VehicleChecklistDetailsApi,
  VehicleChecklistParams,
  VehicleChecklistSummary,
} from '@common/interfaces';
import AxiosRepository from './axios.repository';

class VehiclesChecklistRepository extends AxiosRepository implements IVehiclesChecklistRepository {
  private buildParams(params: VehicleChecklistParams) {
    const searchParams = new URLSearchParams({
      created_before: params.createdBefore,
      created_after: params.createdAfter,
      location: params.locationId,
      page_size: '200',
    });

    if (params.agencyId) searchParams.append('agency', params.agencyId);
    if (params.driverId) searchParams.append('driver', params.driverId);

    return searchParams;
  }

  async getChecklists(facility: string, params: VehicleChecklistParams) {
    const query = this.buildParams(params);
    const { data } = await this.get<VehicleChecklistAgg>(
      `/facilities/${facility}/mobility/quality-checklists/?${query}`,
    );

    return data;
  }

  async getChecklistDetails(facility: string, id: string) {
    const { data } = await this.get<VehicleChecklistDetailsApi>(
      `/facilities/${facility}/mobility/quality-checklists/${id}/`,
    );

    return data;
  }

  async getChecklistSummary(facility: string, params: VehicleChecklistParams) {
    const query = this.buildParams(params);

    // TODO: there will be a new endpoint in the future
    const { data } = await this.get<VehicleChecklistSummary>(
      `/facilities/${facility}/mobility/quality-checklists/?${query}`,
    );

    return data;
  }
}

export default VehiclesChecklistRepository;
